import { Image } from "@chakra-ui/react";

const DuplicateIcon = () => {
    return(
        <Image
            src="/images/icons/Duplicate.svg"
            transition="filter .2s"
            _hover={{
                transition: "filter .2s",
                filter: "brightness(.3)",
            }}
        />
    )
}

export { DuplicateIcon }