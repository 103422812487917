import { Api } from "@/services";

export function useSubSellers() {
    const paymentMethodsUrl = "sellers/default-payments-method"
    const subSellersUrl = "/sellers/subseller"
    const subSellerPaymentRequestUrl = "/payment-links/subseller"
    const paymentLinksUrl = "/payment-links"


    const paymentMethods = () => {
        return Api.get(paymentMethodsUrl)
    }

    const subSellers = () => {
        return Api.get(`${subSellersUrl}`)
    }   
     
    const subSellerPaymentRequest = () => {
        return Api.get(subSellerPaymentRequestUrl)
    }   
    
    const paymentLinks = () => {
        return Api.post(paymentLinksUrl)
    }







    return {
        paymentMethods,
        subSellers,
        subSellerPaymentRequest,
        paymentLinks
        
    }

}