import { Api } from "@/services";

export function useTerms() {
    const baseUrlCurrentTerms = "/sellers/user-terms/current";
    const baseUrlVersionTerms = "/sellers/version-terms";

    const isValidTerms = () => {
        return Api.get(`${baseUrlCurrentTerms}`,);
    };

    const currentTerms = () => {
        return Api.patch(`${baseUrlCurrentTerms}`,);
    }

    return {
        isValidTerms,
        currentTerms,
    };
}




