import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Button, Center, Divider } from "@chakra-ui/react";
import * as yup from "yup";
import { add, format, formatISO } from "date-fns";
import { PaymentMethod } from "../../../layouts/SejaPago/PaymentMethod";
import { PlanSelect } from "../../../layouts/SejaPago/PlanSelect";
import { PeriodicityButton } from "../../../layouts/SejaPago/PeriodicityButton";
import { formatCurrency, formatCurrencyToApi, getApplicationName, showToast } from "@/utils";
import {
  Plan,
  ClientData,
  PaymentMethodTest,
  PaymentLinkSubSellerData,
  ValueDataProps,
  CustomSignatureProps,
  SubSellerTaxResponse
} from "@/models";
import { useCustomLink, usePlan } from "@/hooks";
import {
  Alert,
  Select,
  Input,
  InputSearch,
  AccordionTextArea,
  InputCurrency,
} from "@/components";
import { installments } from "@/utils";
import { formatPercentage } from "@/utils/formatPercentage";
import { formatPercentageToApi } from "@/utils/formatPercentageToApi";
import { NewContainer } from "@/layouts/SejaPago/NewContainer";
import { CustomFields } from "@/components/Forms/CustomFields";
import { TimeDatePickerInput } from "@/components/Forms/TimeDatePickerInput";
import { AditionalValue } from "@/components/Forms/AditionalValue";
import { SubSellerModal } from "@/components/SubSellersModal";
import { InfoCircleOutlined } from "@/styles/icons";
import { PaymentSplit } from "@/layouts/SejaPago/PaymentSplit";
const paymentMethods: Array<PaymentMethodTest> = [
  {
    tagName: "creditCardWithoutAntecipation",
    name: "Cartão de Crédito",
    reg: "enableCreditCard",
  },
  { tagName: "bankSlip", name: "Boleto", reg: "enableBankSlip" },
  { tagName: "pix", name: "PIX", reg: "enablePix" },
];

const createPaymentSubscriptionSchema = yup
  .object()
  .shape({
    plan: yup.string(),
    value: yup.string().required("Preenchimento obrigatório!"),
    additionalTitle: yup.string().max(60, 'O título deve ter no máximo 60 caracteres!'),
    additionalValue: yup.string(),
    additionalInstallments: yup.string(),
    bankSlipDescription: yup.string(),
  })
  .test("paymentMethodsValidation", null, (obj) => {
    if (obj.enableBankSlip || obj.enableCreditCard || obj.enablePix) {
      return true;
    }
    return new yup.ValidationError(
      "Selecione ao menos um método de pagamento.",
      null,
      "paymentMethods"
    );
  });

const periodicityData = [
  {
    name: "Mensal",
    key: "MONTHLY",
    position: "start",
    quantityMonths: 1,
  },
  {
    name: "Semestral",
    key: "SEMESTER",
    position: "middle",
    quantityMonths: 6,
  },
  {
    name: "Anual",
    key: "YEARLY",
    position: "end",
    quantityMonths: 12,
  },
];


export interface subSellerData {
  id: number;
  name: string;
  documentType: string;
  document: string;
  sellerId: number;
  idSubSeller: number;
  amount: number;
  isActive: boolean;
  openingDate?: string;
  paymentType: string;
  paymentValue: number;
  defaultPaymentValue: number;
  defaultPaymentType: string;
  description?: string;
  address: {
    id: number;
    country: string;
    state: string;
    city: string;
    district: string;
    street: string;
    zipCode: string;
    number: string;
    complement: string;
  };
  account: {
    id?: number;
    bank?: number;
    bankDigit: string;
    issuer?: string;
    issuerDigit: string;
    number?: string;
    pixKey: string;
    pixKeyType: string;
  };
  contact: {
    id: number;
    countryCode: string;
    name: string;
    email: string;
    phone: string;
  };
  owner: {
    ownerName: string;
    ownerDocument: string;
    birthdate?: string;
  };
}

export function CustomSignature({
  asEdition,
  initialData,
  initialDataPlan,
  punctualButton,
  setPunctualButton,
  setPreviewData,
  previewData,
}: CustomSignatureProps) {

  const timeoutRef = useRef(null);
  const options = [
    { title: "Recorrente", name: "Recorrente", value: "Recorrente" },
    { title: "Pontual", name: "Pontual", value: "Pontual" }
  ]
  const [dateSwitchState, setDateSwitchState] = useState(
    initialData?.scheduledDate ? true : false
  );
  const [timerState, setTimerState] = useState(false);
  const [expirationSwitchState, setExpirationSwitchState] = useState(false);
  const viewMetadados = localStorage.getItem("barte-features-list-user").includes("view_payment_link_metadata")
  const [metadados, setMetadados] = useState([]);
  const [creditCardType, setCreditCardType] = useState("RECURRENT");
  const [defaultIdPlan, setDefaultIdPlan] = useState(null);
  const [defaultBankSlipDescription, setDefaultBankSlipDescription] = useState("");
  const [clientList, setClientList] = useState<Array<ClientData>>();
  const [selectedClient, setSelectedClient] = useState<ClientData>();
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [firstLoad, setFirstLoad] = useState(
    !!initialData ? !!initialData : false
  );

  const [selectedPlanData, setSelectedPlanData] = useState<Plan>();
  const [selectedTimeCurrence, setSelectedTimeCurrence] = useState();
  const [metadadosDisabled, setMetadadosDisabled] = useState<boolean>(false)

  const [periodicityButton, setPeriodicityButton] = useState<string>(
    initialData?.paymentSubscription?.type
      ? initialData?.paymentSubscription?.type
      : "MONTHLY"
  );
  const [valueData, setValueData] = useState<ValueDataProps>();
  const [additionalValue, setAdditionalValue] = useState(
    initialData?.paymentSubscription?.additionalValue ? true : false
  );
  const [plansList, setPlansList] = useState<Array<Plan>>(
    initialDataPlan ? initialDataPlan : null
  );

  const [loading, setLoading] = useState(false);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [url, setUrl] = useState("");


  const [uuid, setUuid] = useState(
    window.location.search.split("?id=").join("")
  );

  const { getPlans } = usePlan();
  const {
    getCustomLinkData,
    handleDeleteCustomLink,
    getSplitValue,
    putCustomLink,
    postCustomLink,
  } = useCustomLink();
  const navigate = useNavigate();
  const cancelRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
    watch,
    getValues
  } = useForm({
    resolver: yupResolver(createPaymentSubscriptionSchema),
  });
  const values = getValues();
  const bankSlipDescriptionValue = watch("bankSlipDescription");
  const title = watch("idPlan");
  const value = watch("value");
  const enableCreditCard = watch("enableCreditCard");
  const enableCreditCardPercent = watch("enableCreditCardPercent")
  const enablePix = watch("enablePix")
  const enablePixPercent = watch("enablePixPercent")
  const customInstallmentsValuesPix = watch("customInstallmentsValuesPix")
  const enableBankSlip = watch("enableBankSlip")
  const scheduleDate = watch("date")
  const enableBankSlipPercent = watch("enableBankSlipPercent")
  const customInstallmentsValuesBankSlip = watch("customInstallmentsValuesBankSlip")
  const aditionalValue = watch("additionalValue")
  const ExpireParsedTimer = watch("date")
  const viewSplit = localStorage.getItem("barte-features-list-user").includes("view_split_subseller_payment_link") && watch("enableSplit")
  const [splitModalSubseller, setSplitModalSubseller] = useState<boolean>(false);
  const [valuePayment, setValuePayment] = useState("");
  const [subSellersData, setSubSellersData] = useState<Array<subSellerData>>([] as Array<subSellerData>);
  const [subSellerTaxResponse, setSubSellerTaxResponse] = useState<SubSellerTaxResponse>();
  const [split, setSplit] = useState(false)


  const [bankSlipDescriptionState, setBankSlipDescriptionState] = useState(true);
  const [geralInformation, setGeralInformation] = useState(
    {
      title: "",
      value: ""
    }
  );
  const [isOk, setIsOk] = useState(
    {
      geralInformation: false,
      paymentMethods: false,
    }
  );



  function translatePeriodicityName(period: string) {
    const periodicityTypes = {
      MONTHLY: {
        name: "mês",
      },
      SEMESTER: {
        name: "semestre",
      },
      YEARLY: {
        name: "ano",
      },
    };
    return periodicityTypes[period].name;
  }

  function setCustomSignatureData(data?: any, plans?: any) {
    setValue(
      "additionalValue",
      formatCurrency({ value: data?.paymentSubscription?.additionalValue })
    );
    setValue("bankSlipDescription", data?.bankSlipDescription);
    setDefaultBankSlipDescription(data?.bankSlipDescription);
    setValue("additionalTitle", data?.paymentSubscription?.additionalTitle);
    setValue(
      "additionalInstallments",
      String(data?.paymentSubscription?.additionalInstallments) ?
        String(data?.paymentSubscription?.additionalInstallments) :
        1
    );

    setValue("enableCreditCard", data?.allowedPaymentMethods?.creditCardMethod);
    setValue("enableBankSlip", data?.allowedPaymentMethods?.bankSlipMethod);
    setValue("enablePix", data?.allowedPaymentMethods?.pixMethod);

    setValue(
      "enableCreditCardPercent",
      data?.allowedPaymentMethods?.creditCardMethod?.discount?.amount ?
        formatPercentage({ value: data?.allowedPaymentMethods?.creditCardMethod?.discount?.amount }) :
        ""
    );
    setValue(
      "enableBankSlipPercent",
      data?.allowedPaymentMethods?.bankSlipMethod?.discount?.amount ?
        formatPercentage({ value: data?.allowedPaymentMethods?.bankSlipMethod?.discount?.amount }) :
        ""
    );
    setValue(
      "enablePixPercent",
      data?.allowedPaymentMethods?.pixMethod?.discount?.amount ?
        formatPercentage({ value: data?.allowedPaymentMethods?.pixMethod?.discount?.amount }) :
        ""
    );

    setUuid(data?.uuid);

    const matchedPlan = plansList
      ? plansList?.filter(
        (item) => item?.id === data?.paymentSubscription?.idPlan
      )
      : plans?.filter((item) => item?.id === data?.paymentSubscription?.idPlan);
    setPeriodicityButton(data?.paymentSubscription?.type);
    setSelectedPlanData(matchedPlan[0]);
    setValue("idPlan", matchedPlan[0]?.id);
    setDefaultIdPlan(matchedPlan[0]?.id);
    setValueData({
      originalValue: data?.paymentSubscription?.value,
      originalValuePerMonth: data?.paymentSubscription?.valuePerMonth,
      valuePerMonth: data?.paymentSubscription?.valuePerMonth,
    });
    setValue(
      "value",
      formatCurrency({ value: data?.paymentSubscription?.value })
    );

    if (data?.scheduledDate) {
      setDateSwitchState(true);
      setValue("date", add(new Date(data?.scheduledDate), { days: 1 }));
    }
    if (enableBankSlip || enableCreditCard || enablePix) {
      setIsOk((prevState) => ({
        ...prevState,
        paymentMethods: true
      }));
    }

    if ((value && selectedPlanData)) {
      setIsOk((prevState) => ({
        ...prevState,
        geralInformation: true
      }));
    }
    const formattedSubSellerData = data?.subSellerResponse?.map(subS => {
      return {
        ...subS,
        defaultPaymentValue: subS.paymentValue,
        defaultPaymentType: subS.paymentType,
      }
    })
    setSubSellersData(formattedSubSellerData);
    if (initialData?.subSellerResponse.length >= 1) {
      setSplit(true)
    }
  }

  async function handleGetDataToDuplicate(uuid: string, plans?: any) {
    try {
      const { data } = await getCustomLinkData(uuid);
      setDateSwitchState(!!data?.scheduledDate);
      setAdditionalValue(!!data?.paymentSubscription?.additionalValue);
      setCustomSignatureData(data, plans);
    } catch (error) {
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  async function getInitialData() {
    setValue("date", new Date());
    try {
      const response = await getPlans();
      setPlansList(response?.data);
      if (initialData) return setCustomSignatureData(initialData);
      if (uuid) return handleGetDataToDuplicate(uuid, response.data);
      setSelectedPlanData(response?.data[0]);
      setValue(
        "value",
        formatCurrency({ value: response?.data[0]?.basicValues[0]?.value })
      );
      setValue("additionalInstallments", "1");
      setValue("idPlan", response?.data[0]?.id);
    } catch (error) {
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }
  const handleSubscription: SubmitHandler<any> = async (
    values
  ) => {
    const applicationName = getApplicationName({});
    const parsedDate = expirationSwitchState && formatISO(values?.date, { representation: "date" });
    const formattedValue = formatCurrencyToApi(values?.value);
    const formattedAdditionalValue = formatCurrencyToApi(
      values?.additionalValue
    );
    if (bankSlipDescriptionState && bankSlipDescriptionValue?.length > 130) {
      return setError("bankSlipDescription", { type: "custom", message: "A descrição do boleto deve ter no máximo 130 caracteres!" });
    }

    let subSellersDataFilter = []
    if (subSellersData) {
      subSellersDataFilter = subSellersData?.map((item) => ({
        idSubSeller: item?.id,
        paymentType: item?.paymentType,
        paymentValue: item?.paymentValue,
        amount: item?.paymentType === "PERCENTAGE" ? formattedValue / 100 * (100 - item?.paymentValue) : item?.paymentValue
      })).filter(sub => sub?.idSubSeller !== undefined);


    }
    const paymentSubscriptionDTO = {
      type: "SUBSCRIPTION",
      scheduledDate: expirationSwitchState ? parsedDate : null,
      bankSlipDescription: bankSlipDescriptionState && values?.bankSlipDescription?.length > 0 ? values?.bankSlipDescription : null,
      uuidSellerClient: selectedClient?.uuid,
      subSellerPaymentRequest: split ? subSellersDataFilter : [],
      metadata: metadadosDisabled ? metadados : null,
      paymentSubscription: {
        idPlan: Number(selectedPlanData?.id),
        valuePerMonth: periodicityButton === "MONTHLY" ? formattedValue : formattedValue / getPeriodicityNumber(),
        type: periodicityButton,
        additionalValue: values.additionalValue ? formattedAdditionalValue : null,
        additionalTitle: values.additionalValue ? values?.additionalTitle : null,
        additionalInstallments: values.additionalValue
          ? Number(values?.additionalInstallments)
          : 1,
      },
      allowedPaymentMethods: {
        creditCardMethod: values?.enableCreditCard
          ? {
            type: "RECURRENT",
            discount: values?.enableCreditCardPercent
              ? {
                amount: formatPercentageToApi(values?.enableCreditCardPercent),
                type: "PERCENT",
              }
              : null,
          }
          : null,
        bankSlipMethod: values?.enableBankSlip
          ? {
            discount: values?.enableBankSlipPercent
              ? {
                amount: formatPercentageToApi(values?.enableBankSlipPercent),
                type: "PERCENT",
              }
              : null,
          }
          : null,
        pixMethod: values?.enablePix
          ? {
            discount: values?.enablePixPercent
              ? {
                amount: formatPercentageToApi(values?.enablePixPercent),
                type: "PERCENT",
              }
              : null,
          }
          : null,
      },
    };

    try {
      setLoading(true);
      if (asEdition) {
        const { data } = await putCustomLink(
          initialData?.uuid,
          paymentSubscriptionDTO
        );
        setUrl(`${import.meta.env.VITE_CHECKOUT_URL}payment-link/${data?.uuid}`)
      } else {
        const { data } = await postCustomLink(paymentSubscriptionDTO);
        setUrl(`${import.meta.env.VITE_CHECKOUT_URL}payment-link/${data?.uuid}`)
      }

      setConfirmModalState(!confirmModalState);
      setSplitModalSubseller(false);
    } catch (error) {
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    } finally {
      setLoading(false);
    }

  };

  async function handleDelete() {
    try {
      const response = await handleDeleteCustomLink(initialData?.uuid);
      showToast({
        message: "Link customizado excluído com sucesso!",
        type: "success",
      });
      setDeleteModalState(!deleteModalState);
      navigate("/seja-pago");
    } catch (error) {
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }
  function handleSelectPlanOrPeriodicity() {
    if (selectedPlanData && periodicityButton) {
      const planItens = selectedPlanData?.bullets?.map((bullet) => ` ${bullet?.description}`)
      if (selectedPlanData?.id !== defaultIdPlan && defaultIdPlan !== null || uuid === "") {
        setValue("bankSlipDescription", `${selectedPlanData?.description} - ${planItens}`);
      } else {
        setValue("bankSlipDescription", defaultBankSlipDescription);
      }
      const data = selectedPlanData?.basicValues?.filter(
        (item) => item?.type === periodicityButton
      );

      if (firstLoad) {
        setFirstLoad(false);
        return;
      }
      setValue("value", formatCurrency({ value: data[0]?.value }));
      setValueData({
        originalValue: data[0]?.value,
        originalValuePerMonth: data[0]?.valuePerMonth,
        valuePerMonth: data[0]?.valuePerMonth,
      });
    }
  }

  function getPeriodicityNumber(): number {
    if (!periodicityButton) return;
    return periodicityData.find((item) => item.key === periodicityButton)
      .quantityMonths;
  }

  function handleChange(name: string, value: any) {
    setGeralInformation((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  async function handleSplitValues() {
    try {
      const splitBodyRequest: Array<PaymentLinkSubSellerData> = subSellersData.map(item => {
        //@ts-ignore
        let paymentValue = item?.value ? item?.value : item?.paymentValue;
        return {
          idSubSeller: item?.id,
          amount: formatCurrencyToApi(watch("value")),
          paymentType: item?.paymentType,
          paymentValue: Number(paymentValue)
        }
      })
      const { data } = await getSplitValue(splitBodyRequest);
      //@ts-ignore
      setSubSellerTaxResponse(data);
    } catch (error) {
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  useEffect(() => {
    handleSelectPlanOrPeriodicity();
  }, [selectedPlanData, periodicityButton]);

  useEffect(() => {
    setValueData({
      ...valueData,
      valuePerMonth:
        formatCurrencyToApi(String(watch("value"))) / getPeriodicityNumber(),
    });
  }, [watch("value")]);

  useEffect(() => {
    getInitialData();
  }, []);








  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    const formattedValue = formatCurrencyToApi(value);
    timeoutRef.current = setTimeout(() => {
      const updatedGeralInformation = {
        ...previewData,
        title: selectedPlanData?.title,
        custonFields: { metadados },
        cliente: selectedClient?.name,
        hasDescription: bankSlipDescriptionState,
        description: bankSlipDescriptionValue,
        value: value,
        aditionalValue: aditionalValue,
        valuePerMonth: periodicityButton === "MONTHLY" ? formattedValue : formattedValue / getPeriodicityNumber(),
        subSellersData: subSellersData,
        enablePix: enablePix,
        enableCreditCard: enableCreditCard,
        enableBankSlip: enableBankSlip,
        boleto: {
          discount: enableBankSlipPercent,
          installments: customInstallmentsValuesBankSlip,

        },
        creditcard: {
          discount: enableCreditCardPercent,
          scheduleDate: format(scheduleDate, 'dd/MM/yyyy'),
        },
        pix: {
          discount: enablePixPercent,
          tax: customInstallmentsValuesPix,
          installments: customInstallmentsValuesPix,

        },
        expireDate: expirationSwitchState ? format(ExpireParsedTimer, 'dd/MM/yyyy') : ""


      };

      setPreviewData(updatedGeralInformation);
    }, 1000);
    if (value !== "") {
      setIsOk((prevState) => ({
        ...prevState,
        geralInformation: true
      }));
    }
    if ((value === "" || value === null || value === " ")) {
      setIsOk((prevState) => ({
        ...prevState,
        geralInformation: false
      }));
    }
    if (enablePix === true || enableCreditCard === true || enableBankSlip === true) {
      setIsOk((prevState) => ({
        ...prevState,
        paymentMethods: true
      }));
    }
    
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };


  }, [metadados, selectedClient, bankSlipDescriptionValue,
    title, value, enableCreditCard, enableCreditCardPercent, enableBankSlipPercent,
    , enablePix, enablePixPercent, customInstallmentsValuesPix, aditionalValue, bankSlipDescriptionState,
    enableBankSlip, expirationSwitchState, customInstallmentsValuesBankSlip, ExpireParsedTimer, creditCardType, scheduleDate, subSellersData
  ]);

  useEffect(() => {
    if (split === true) {
      setValue("enableBankSlip", false);
      setValue("enablePix", false);
      setValue('enableCreditCard', true)
    }
  }, [split])

  useEffect(() => {
    if (enableBankSlip === true || enablePix === true) {
      setSplit(false);
    }

    if (
      previewData?.enablePix === true ||
      previewData?.enableCreditCard === true ||
      previewData?.enableBankSlip === true
    ) {
      setIsOk({
        ...isOk,
        paymentMethods: true
      })
    } if (
      previewData?.enablePix === false &&
      previewData?.enableCreditCard === false &&
      previewData?.enableBankSlip === false
    ) {
      setIsOk({
        ...isOk,
        paymentMethods: false
      })
    }

  }, [previewData])



  useEffect(() => {
    if (enableCreditCard === false) {
      setValue("enableCreditCardPercent", null)
    }
    if (!enableBankSlip) {
      setValue("enableBankSlipPercent", null)

    }
    if (!enablePix) {
      setValue("enablePixPercent", null)
    }

  }, [enableCreditCard, enableBankSlip, enablePix])

  return (
    <>
      <Alert
        isConfirmAlert={false}
        isOpen={deleteModalState}
        onConfirm={handleDelete}
        onClose={() => setDeleteModalState(!deleteModalState)}
        title={`Tem certeza que deseja excluir o link para essa cobrança pontual?`}
        messageBody="Excluí-lo desativará o link para futuras contratações."
        closeButtonText="Não"
        confirmButtonText="Sim"
        leastDestructiveRef={cancelRef}
      />
      <Alert
        isConfirmAlert={true}
        isOpen={confirmModalState}
        onConfirm={() => (
          setConfirmModalState(!confirmModalState), navigate("/seja-pago")
        )}
        onClose={() => setConfirmModalState(!confirmModalState)}
        title="Cobrança criada com sucesso"
        messageBody={`Clique no link abaixo para copiar:`}
        urlBody={url}
        leastDestructiveRef={cancelRef}
      />

      {viewSplit && (
        <SubSellerModal
          isOpen={splitModalSubseller}
          headerIcon={<InfoCircleOutlined color="#FFC53D" size={19} />}
          headerText="Revise os valores do split"
          totalValue={valuePayment}
          paymentLinkValue={watch("value") ?? "0"}
          subSellerList={subSellersData}
          confirmButtonText="Criar link"
          closeButtonText="Voltar"
          subSellerTaxResponse={subSellerTaxResponse}
          barteTaxValue={previewData?.creditCardTax === "EARLY_SELLER" ? formatCurrency({ value: (formatCurrencyToApi(watch("value")) - previewData?.arrayInstallments?.installmentsToSeller[previewData?.arrayInstallments?.installmentsToSeller?.length - 1]?.receivedAmount) ?? 0 }) : formatCurrency({ value: (formatCurrencyToApi(watch("value")) - previewData?.arrayInstallments?.installmentsToSeller[0]?.receivedAmount) ?? 0 })}
          onClose={() => setSplitModalSubseller(false)}
          onConfirm={() => handleSubscription(values)}
          asSubmitModal
        />
      )}
      {/* {asEdition && (
        <Flex
          flexDir="column"
          maxW="520px"
          w="520px"
          margin="0 auto"
          marginTop="1.5rem"
        >
          <Stack spacing="4">
            <Flex align="center" justify="space-between">
              <Heading>Assinatura customizada</Heading>
              <Image
                onClick={() => setDeleteModalState(!deleteModalState)}
                marginRight=".7rem"
                cursor="pointer"
                src="/images/icons/trash-delete.svg"
                transition="filter .2s"
                _hover={{
                  transition: "filter .2s",
                  filter: "brightness(.3)",
                }}
              />
            </Flex>
            <UrlToClipBoard text={initialData?.url} />
          </Stack>
        </Flex>
      )} */}

      <Flex
        as="form"
        w="100%"
        flexDir="column"
        onSubmit={handleSubmit(handleSubscription)}
      >
        <NewContainer
          w="100%"
          type="check"
          title="Informações gerais"
          isOk={isOk.geralInformation}
          p="25px"


        >
          {!asEdition &&
            <Select
              w="100%"
              name="tipo de cobrança"
              label="tipo de cobrança"
              defaultValue="Recorrente"
              onChange={(e) => {
                setPunctualButton(e.target.value === "Pontual");
              }}
              optionsList={options}
            />

          }

          <PeriodicityButton
            Options={periodicityData}
            selectedOption={periodicityButton}
            setSelectedOption={setPeriodicityButton}
          />

          <Input
            hasMonthlyValue={periodicityButton !== "MONTHLY"}
            valueData={valueData}
            periodicityName={translatePeriodicityName(periodicityButton)}
            asSubscription
            valueLenght={watch("value")?.length}
            mask="currency"
            type="text"
            label="Valor"
            name="value"
            register={{ ...register("value") }}
          />
          <PlanSelect
            plansList={plansList}
            setValue={setValue}
            setSelectedPlanData={setSelectedPlanData}
            borderRadius=".2rem"
            register={{ ...register("idPlan") }}
            name="idPlan"
            label="Plano"

          />
          {/* <DatePickerInput
            locale="ptBR"
            tooltipText="
            Data de vencimento da primeira cobrança.
            A data dos próximos vencimentos,
            será no mesmo dia do mês."
            startEnabled={dateSwitchState}
            disabledState={dateSwitchState}
            setDisabledState={setDateSwitchState}
            label="Data de Vencimento"
            hasSwitch
            isCustomizableDate
            selectedDate={new Date()}
            control={control}
            name="date"
            register={{ ...register("date") }}
            rules={{ required: "Preenchimento obrigatório!" }}
          /> */}

          <InputSearch
            name="clients"
            label="Clientes"
            placeholder="Nome do cliente"
            initialClient={initialData?.sellerClient}
            clients={clientList}
            setClients={setClientList}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
          />

        </NewContainer>
        <Center w='100%' >
          <Divider orientation='horizontal' />
        </Center>
        <NewContainer
          title="Métodos de pagamento"
          type="check"
          isOk={previewData?.enableBankSlip || previewData?.enableCreditCard || previewData?.enablePix}
          p="25px"

        >
          <PaymentMethod
            setIsOk={setIsOk}
            onCreation={!asEdition}
            creditCardType="RECURRENT"
            setCreditCardType={setCreditCardType}
            register={register}
            data={paymentMethods}
            errors={errors?.paymentMethods}
            watch={watch}
            setValue={setValue}
            paymentLinkType={periodicityButton ? "customSignature" : "paymentOrder"}
            split={split}
          />
        </NewContainer>
        <Center w='100%' >
          <Divider orientation='horizontal' />
        </Center>
        <NewContainer
          title="Configurações avançadas"
          type="retract"
          flexDir="column"
          p="15px 25px 15px 0"

        >
          <TimeDatePickerInput
            format="dd/MM/yyyy"
            timerState={timerState}
            setTimerState={setTimerState}
            startEnabled
            label="Expirar Link"
            name="date"
            register={{ ...register("date") }}
            hasSwitch
            control={control}
            disabledState={expirationSwitchState}
            setDisabledState={setExpirationSwitchState}
            errors={errors?.timer}
            setValue={setValue}
          />

          <AccordionTextArea
            name="bankSlipDescription"
            hasSwitch={bankSlipDescriptionState}
            register={{ ...register("bankSlipDescription") }}
            value={bankSlipDescriptionValue}
            errors={errors?.bankSlipDescription}
            asDoubleEffect
            switchFunction={(e) => setBankSlipDescriptionState(e.target.checked)}
          />

          <AditionalValue
            setValue={setValue}
            name={'aditionalValue'}
            register={{ ...register('aditionalValue') }}
            label="Valor adicional"
            hasSwitch
            defaultIsOpen={initialData?.paymentSubscription.additionalValue !== undefined}
          >


            <Input
              label="Título"
              type="text"
              name="additionalTitle"
              register={{ ...register("additionalTitle") }}
              containerDisabled={true}
            />

            <InputCurrency
              label="Valor"
              setValue={setValue}
              name="additionalValue"
              maxLength={10}
              register={{ ...register("additionalValue") }}
              onChange={(e) => {
                handleChange("additionalValue", e.target.value)
              }}
              placeholder="Valor"
            />

            <Select
              name="additionalInstallments"
              tooltipText="
                          Parcelamento será cobrado de forma
                          recorrente, assim como a assinatura."
              label="Parcelamento máximo"
              register={{ ...register("additionalInstallments") }}
              containerDisabled={true}
              optionsList={installments}
              defaultValue={1}
              setValue={setValue}
            />

          </AditionalValue>
          {viewMetadados && (
            <CustomFields
              setMetadados={setMetadados}
              defaultMetadados={initialData?.metadata}
              hasSwitch
              setMetadadosDisabled={setMetadadosDisabled}

              name="Dados internos (metadados)"
              label="Dados internos (metadados)"
            />
          )}
          {viewSplit && (
            <PaymentSplit
              setSplit={setSplit}
              split={split}
              title={"Split de pagamento"}
              setSubSellersData={setSubSellersData}
              subSellersData={subSellersData}
              newClient={{} as subSellerData}
            />
          )}

        </NewContainer>
        <Center w='100%' >
          <Divider orientation='horizontal' />
        </Center>

        <Flex justifyContent="end">
          <Button
            type={split ? "button" : "submit"}
            isLoading={loading}
            alignSelf="center"
            onClick={() => {
              if (split) {
                handleSplitValues()
                setSplitModalSubseller(!splitModalSubseller)
              }
            }}
            variant="solid"
            bgColor="blue.200"
            color="white"
            borderRadius="3"
            margin="1rem 0"
            transition="filter .2s"
            _hover={{
              transition: "filter .2s",
              filter: "brightness(.9)",
            }}
            _active={{
              filter: "brightness(.6)",
            }}
            _focus={{}}
            disabled={isOk.geralInformation === false || !(previewData?.enableBankSlip || previewData?.enableCreditCard || previewData?.enablePix)}
          >
            Criar cobrança
          </Button>
        </Flex>
      </Flex>
    </>
  );
}