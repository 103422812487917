import { DashboardChargeData } from "@/models";
import { Flex } from "@chakra-ui/react";
import { ChargeMessage } from "../charge-message";

interface DashboardChargeList {
  charges: Array<DashboardChargeData>;
}

export function DashboardChargeList({ charges }: DashboardChargeList) {
  return (
    <>
      <Flex
        w="100%"
        flexDir="column"
        p="0 1rem"
      >
        {charges.map(charge => (
          <ChargeMessage
            key={charge?.uuid}
            uuid={charge.uuid}
            buyerName={charge.buyerName}
            paidDate={charge?.paidDate}
            expirationDate={charge.expirationDate}
            amount={charge.amount}
            status={charge.status}
          />
        ))}
      </Flex>
    </>
  );
}