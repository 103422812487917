import { WithAuth } from "@/hoc";
import { Flex } from "@chakra-ui/react";
import { AddClient } from "./_adicionar_cliente";

const AdicionarCliente = () => {
  return (
    <Flex
      w="100%"
      alignItems="center"
      justifyContent="center"
    >
      <AddClient />
    </Flex>
  );
};

export default WithAuth(AdicionarCliente);
