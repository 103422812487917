import {
  CustomLink,
  CustomLinkPaginator,
  PaymenLinkSubSellerResponse,
  PaymentLinkRequestData,
  PaymentLinkSubSellerData,
  SubSellerTaxResponse,
} from "@/models";
import { Api } from "@/services";
import { filterAndPageableToRequest } from "@/utils";
import { body } from "msw/lib/types/context";

export function useCustomLink() {
  const baseUrl = "/payment-links";

  const getCustomLink = (tabName: string, filter: object, pageable: object) => {
    return Api.get<CustomLinkPaginator>(
      `${baseUrl}/${tabName}/${filterAndPageableToRequest({
        filter,
        pageable,
      })}`
    );
  };

  const putCustomLink = (uuid: string, data: PaymentLinkRequestData) => {
    return Api.put<CustomLink>(`${baseUrl}/${uuid}`, data);
  };

  const getCustomLinkData = (uuid: string) => {
    let isDefaultApp = location?.hostname?.includes('barte.com');
    
    return Api.get<CustomLink>(`${baseUrl}/${uuid}?whiteLabel=${!isDefaultApp}`);
  };

  const postCustomLink = (data: PaymentLinkRequestData) => {
    return Api.post<CustomLink>(`${baseUrl}/`, data);
  };

  const handleDeleteCustomLink = (uuid: string) => {
    return Api.delete<CustomLink>(`${baseUrl}/${uuid}`);
  };

  const installmentsPayment = (amount: number, installments: number) => {
    return Api.get(
      `${baseUrl}/installments-payment?amount=${amount}&maxInstallments=${installments}`
    );
  };

  const getSplitValue = (data: Array<PaymentLinkSubSellerData>) => {
    return Api.patch<SubSellerTaxResponse>(`${baseUrl}/sub-seller/payment`, data);
  }

  return {
    getCustomLinkData,
    getCustomLink,
    putCustomLink,
    postCustomLink,
    handleDeleteCustomLink,
    installmentsPayment,
    getSplitValue
  };
}
