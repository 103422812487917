import styled from "styled-components";

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: ${(props) => props?.isOpen ? "flex": "none"};
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1000;
  background-color: #00000015;

  > div {
    width: 100%;
    max-width: 416px;
    border-radius: 2px;
    background-color: #FFFFFF;
    position: absolute;
    overflow-y: scroll;

    > div.box {
      position: relative;
      > div.top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 25px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 100;

        > button {

        }

        > p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #000000D9;
        }

      }

      > div.middle {
        display: flex;
        flex-direction: column;
        gap: 24px;
        background-color: var(--new-gray-25);
        padding: 24px 25px;

        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 95px;

          > div.client {
            > p:first-child {
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #000000;
            }
            > p:last-child {
              font-weight: 700;
              font-size: 14px;
              line-height: 22px;
            }
          }

          > div.error {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            > p:first-child {
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
            }

            > div {
              padding: 0px 6px;
              border-radius: 4px;
              background: rgba(196, 44, 44, 0.1);
              
              > p {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
              }
            }
          }

          > div.charge-amount {
            > p:first-child {
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
            }

            > p:last-child {
              font-size: 14px;
              line-height: 22px;
              font-weight: 700;
              color: #000000;
            }
          }

          > div.card-data {
            > p:first-child {
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
            }

            > p:last-child {
              font-size: 14px;
              line-height: 22px;
              font-weight: 700;
              color: #000000;
            }
          }
        }

      }

      > div.try {
        display: flex;
        flex-direction: column;
        padding: 25px;
        gap: 29px;
        > div {
          display: flex;
          flex-direction: column;
          > div:first-child {
            display: flex;
            flex-direction: column;
            gap: 9px;
            > p:first-child {
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #000000;
            }

            > p:last-child {
              font-size: 14px;
              line-height: 22px;
              font-weight: 700;
            }
          }

          > div:last-child {
            display: flex;
            flex-direction: column;
            gap: 4px;
            > p:first-child {
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
            }
            > p:last-child {
              font-size: 14px;
              line-height: 22px;
              font-weight: 700;
              color: #8E1E1E;
            }
          }
        }
      }
      
      > div.footer {
        display: flex;
        justify-content: flex-end;
        padding: 10px 16px;
        position: sticky;
        bottom: 0;
        background-color: white;
        z-index: 100;

        border-top: 1px solid var(--new-gray-25);

        > button {
          padding: 5px 16px;
          border-radius: 2px;
          border: 1px solid #D9D9D9;
        }
      }
    }
  }
`;