import { IconProps } from "@/models";

export function PlusCircle({
  size = 20,
  color = "#000",
  opacity = "1",
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_756_987)">
        <path d="M7.56247 1.91364C4.75427 1.91364 2.47653 4.19137 2.47653 6.99957C2.47653 9.80778 4.75427 12.0855 7.56247 12.0855C10.3707 12.0855 12.6484 9.80778 12.6484 6.99957C12.6484 4.19137 10.3707 1.91364 7.56247 1.91364ZM10.1875 7.3277C10.1875 7.38785 10.1383 7.43707 10.0781 7.43707H7.99997V9.5152C7.99997 9.57535 7.95075 9.62457 7.89059 9.62457H7.23434C7.17419 9.62457 7.12497 9.57535 7.12497 9.5152V7.43707H5.04684C4.98669 7.43707 4.93747 7.38785 4.93747 7.3277V6.67145C4.93747 6.61129 4.98669 6.56207 5.04684 6.56207H7.12497V4.48395C7.12497 4.42379 7.17419 4.37457 7.23434 4.37457H7.89059C7.95075 4.37457 7.99997 4.42379 7.99997 4.48395V6.56207H10.0781C10.1383 6.56207 10.1875 6.61129 10.1875 6.67145V7.3277Z" />
        <path
          d="M7.56247 0.874573C4.18005 0.874573 1.43747 3.61715 1.43747 6.99957C1.43747 10.382 4.18005 13.1246 7.56247 13.1246C10.9449 13.1246 13.6875 10.382 13.6875 6.99957C13.6875 3.61715 10.9449 0.874573 7.56247 0.874573ZM7.56247 12.0855C4.75427 12.0855 2.47653 9.80778 2.47653 6.99957C2.47653 4.19137 4.75427 1.91364 7.56247 1.91364C10.3707 1.91364 12.6484 4.19137 12.6484 6.99957C12.6484 9.80778 10.3707 12.0855 7.56247 12.0855Z"
          fill={color}
        />
        <path
          d="M10.0781 6.56207H7.99997V4.48395C7.99997 4.42379 7.95075 4.37457 7.89059 4.37457H7.23434C7.17419 4.37457 7.12497 4.42379 7.12497 4.48395V6.56207H5.04684C4.98669 6.56207 4.93747 6.61129 4.93747 6.67145V7.3277C4.93747 7.38785 4.98669 7.43707 5.04684 7.43707H7.12497V9.5152C7.12497 9.57535 7.17419 9.62457 7.23434 9.62457H7.89059C7.95075 9.62457 7.99997 9.57535 7.99997 9.5152V7.43707H10.0781C10.1383 7.43707 10.1875 7.38785 10.1875 7.3277V6.67145C10.1875 6.61129 10.1383 6.56207 10.0781 6.56207Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_756_987">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.562469 -0.000427246)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
