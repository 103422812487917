import { Api_Banking } from "@/services/Api/bankingApiConfig";

export function usePixLimit() {
  const baseUrl = "accounts/limits";

  const getPixLimit = () => Api_Banking.get(baseUrl);

  const updatePixLimit = (data: any) =>
    Api_Banking.patch(baseUrl, data);


  return { getPixLimit, updatePixLimit };
}
