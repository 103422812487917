import { useForm } from "react-hook-form";
import { showToast } from "@/utils";
import { useEffect, useState } from "react";
import { useSettings } from "@/hooks";
import { DefaultTax } from "@/models";
import { formatPercentageToNumber } from "@/utils/formatPercentageToNumber";

interface DefaultPaymentMethods {
  creditCardMethod: {
    state: boolean;
  },
  bankSlipMethod: {
    state: boolean;
  },
  pixMethod: {
    state: boolean;
  }
}

export function usePaymentMethods() {
  const [initialData, setInitialData] = useState<DefaultPaymentMethods>(null);
  const [defaultTax, setDefaultTax] = useState<DefaultTax>(null);

  const [interestError, setInterstError] = useState(false);
  const [fineError, setFineError] = useState(false);

  const {
    getSellerCheckoutDefault,
    patchSellerCheckoutDefault,
    patchDefaultTax,
    getDefaultTax
  } = useSettings();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  async function getInitialData() {
    try {
      const paymentMethodDefault = await getSellerCheckoutDefault();
      const defaultTax = await getDefaultTax();

      setDefaultTax({
        bankSlipTax: {
          fine: {
            enable: defaultTax?.data?.bankSlipTax?.fine?.enable,
            percentage: formatPercentageToNumber(defaultTax?.data?.bankSlipTax?.fine?.percentage) as string
          },
          interest: {
            enable: defaultTax?.data?.bankSlipTax?.interest?.enable,
            percentage: formatPercentageToNumber(defaultTax?.data?.bankSlipTax?.interest?.percentage) as string
          }
        }
      });

      setInitialData({
        creditCardMethod: {
          state: paymentMethodDefault?.data?.defaultEnableCreditCard
        },
        bankSlipMethod: {
          state: paymentMethodDefault?.data?.defaultEnableBankSlip
        },
        pixMethod: {
          state: paymentMethodDefault?.data?.defaultEnablePixPayment
        }
      });

      setValue("defaultEnableCreditCard",
        paymentMethodDefault?.data?.defaultEnableCreditCard ? "Ligado" : "Desligado");
      setValue("defaultEnableBankSlip",
        paymentMethodDefault?.data?.defaultEnableBankSlip ? "Ligado" : "Desligado");
      setValue("defaultEnablePixPayment",
        paymentMethodDefault?.data?.defaultEnablePixPayment ? "Ligado" : "Desligado");

    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  function organizeDefaultPatchData(e: any) {
    const allPaymentMethods = {
      defaultEnableCreditCard: watch("defaultEnableCreditCard") === "Ligado" ? true : false,
      defaultEnableBankSlip: watch("defaultEnableBankSlip") === "Ligado" ? true : false,
      defaultEnablePixPayment: watch("defaultEnablePixPayment") === "Ligado" ? true : false,
    }

    allPaymentMethods[e?.target?.name] = e?.target?.value === "Ligado" ? true : false;

    return allPaymentMethods;
  }

  async function handleEnablePaymentMethod(value: any) {
    try {
      const checkoutDefaultPatchData = organizeDefaultPatchData(value);

      await patchSellerCheckoutDefault(checkoutDefaultPatchData);

      showToast({
        type: "success",
        message: "Método de pagamento alterado com sucesso.",
      });

    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  function handleDefaultTaxSwitchChange(e: any) {
    let bankSlipTax = {
      bankSlipTax: {
        interest: {
          enable: e.target.checked,
          percentage: defaultTax?.bankSlipTax?.interest?.percentage
        },
        fine: {
          enable: e.target.checked,
          percentage: defaultTax?.bankSlipTax?.fine?.percentage
        }
      }
    }

    setDefaultTax(bankSlipTax);

    setTimeout(() => {
      handlePatchDefaultTax(bankSlipTax);
    }, 1000);
  }

  function handleDefaultTaxInputChange(e: any) {
    const lastValue = formatPercentageToNumber(defaultTax.bankSlipTax[e.target.name].percentage, true)
    const newValue = formatPercentageToNumber(e.target.value, true)
    const sameValueTyped = lastValue === newValue;

    let bankSlipTax = {
      bankSlipTax: {
        ...defaultTax.bankSlipTax
      }
    }

    if (e?.target?.name === "fine") {
      bankSlipTax = {
        bankSlipTax: {
          interest: {
            ...defaultTax.bankSlipTax.interest,
          },
          fine: {
            enable: defaultTax?.bankSlipTax?.fine?.enable,
            percentage: e.target.value
          }
        }
      }
    } else {
      bankSlipTax = {
        bankSlipTax: {
          fine: {
            ...defaultTax.bankSlipTax.fine,
          },
          interest: {
            enable: defaultTax?.bankSlipTax?.interest?.enable,
            percentage: e.target.value
          }
        }
      }
    }

    if (!sameValueTyped) {
      setDefaultTax(bankSlipTax);

      setTimeout(() => {
        handlePatchDefaultTax(bankSlipTax);
      }, 1000);

    } else {
      return;
    }
  }

  async function handlePatchDefaultTax(data?: DefaultTax) {
    try {

      const formattedDataDTO = {
        bankSlipTax: {
          fine: {
            enable: data?.bankSlipTax?.fine?.enable,
            percentage: formatPercentageToNumber(data?.bankSlipTax?.fine?.percentage, true)
          },
          interest: {
            enable: data?.bankSlipTax?.interest?.enable,
            percentage: formatPercentageToNumber(data?.bankSlipTax?.interest?.percentage, true)
          }
        }
      }

      await patchDefaultTax(formattedDataDTO as any);

      showToast({
        type: "success",
        message: "Configuração de juros e multa default atualizadas.",
      });

    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  const paymentMethodBase = [
    {
      title: "Ligado",
      id: 1,
      value: "Ligado"
    },
    {
      title: "Desligado",
      id: 2,
      value: "Desligado"
    }
  ];

  useEffect(() => {
    getInitialData();
  }, []);

  return {
    initialData,
    handleSubmit,
    handleEnablePaymentMethod,
    register,
    handlePatchDefaultTax,
    handleDefaultTaxInputChange,
    handleDefaultTaxSwitchChange,
    defaultTax,
    fineError,
    interestError,
    errors,
    paymentMethodBase
  }
}