import { PaidNotification } from "./PaidNotification";
import { LateNotification } from "./LateNotification";
import { LatePaidNotification } from "./LatePaidNotification";
import { SellerNotificationPanel } from "@/models/NotificationPanel";
import { ReactNode, RefCallback } from "react";

interface MessageNotificationProps {
  data: SellerNotificationPanel;
  ref?: any;
}

export function MessageNotification({ data, ref }: MessageNotificationProps) {

  const items = {
    "NOTIFICATION_PAYMENT_MADE": PaidNotification,
    "NOTIFICATION_RECOVERED_DEFAULTS": LatePaidNotification,
    "NOTIFICATION_PAYMENT_LATE": LateNotification
  }

  const Component = items[data.notificationPanelType];

  return (
    <Component data={data.variablesResponse} timeDiff={data.timeDiff} notifiedAt={data.notifiedAt} />
  )
}