export const Countries = [ 
  {label: 'Afeganistão', value: 'AF'}, 
  {label: 'Ilhas Aland', value: 'AX'}, 
  {label: 'Albânia', value: 'AL'}, 
  {label: 'Argélia', value: 'DZ'}, 
  {label: 'Samoa Americana', value: 'AS'}, 
  {label: 'Andorra', value: 'AD'}, 
  {label: 'Angola', value: 'AO'}, 
  {label: 'Anguilla', value: 'AI'}, 
  {label: 'Antártica', value: 'AQ'}, 
  {label: 'Antígua e Barbuda', value: 'AG'}, 
  {label: 'Argentina', value: 'AR'}, 
  {label: 'Armênia', value: 'AM'}, 
  {label: 'Aruba', value: 'AW'}, 
  {label: 'Austrália', value: 'AU'}, 
  {label: 'Áustria', value: 'AT'}, 
  {label: 'Azerbaijão', value: 'AZ'}, 
  {label: 'Bahamas', value: 'BS'}, 
  {label: 'Bahrein', value: 'BH'}, 
  {label: 'Bangladesh', value: 'BD'}, 
  {label: 'Barbados', value: 'BB'}, 
  {label: 'Bielorrússia', value: 'BY'}, 
  {label: 'Bélgica', value: 'BE'}, 
  {label: 'Belize', value: 'BZ'}, 
  {label: 'Benim', value: 'BJ'}, 
  {label: 'Bermudas', value: 'BM'}, 
  {label: 'Butão', value: 'BT'}, 
  {label: 'Bolívia', value: 'BO'}, 
  {label: 'Bósnia e Herzegovina', value: 'BA'}, 
  {label: 'Botsuana', value: 'BW'}, 
  {label: 'Ilha Bouvet', value: 'BV'}, 
  {label: 'Brasil', value: 'BR'}, 
  {label: 'Território Britânico do Oceano Índico', value: 'IO'}, 
  {label: 'Brunei Darussalam', value: 'BN'}, 
  {label: 'Bulgária', value: 'BG'}, 
  {label: 'Burkina Faso', value: 'BF'}, 
  {label: 'Burundi', value: 'BI'}, 
  {label: 'Camboja', value: 'KH'}, 
  {label: 'Camarões', value: 'CM'}, 
  {label: 'Canadá', value: 'CA'}, 
  {label: 'cabo Verde', value: 'CV'}, 
  {label: 'Ilhas Cayman', value: 'KY'}, 
  {label: 'República Centro-Africana', value: 'CF'}, 
  {label: 'Chad', value: 'TD'}, 
  {label: 'Chile', value: 'CL'}, 
  {label: 'China', value: 'CN'}, 
  {label: 'Ilha do Natal', value: 'CX'}, 
  {label: 'Ilhas Cocos (Keeling)', value: 'CC'}, 
  {label: 'Colômbia', value: 'CO'}, 
  {label: 'Comores', value: 'KM'}, 
  {label: 'Congo', value: 'CG'}, 
  {label: 'Congo, República Democrática do', value: 'CD'}, 
  {label: 'Ilhas Cook', value: 'CK'}, 
  {label: 'Costa Rica', value: 'CR'}, 
  {label: 'Costa do Marfim', value: 'CI'}, 
  {label: 'Croatia', value: 'HR'}, 
  {label: 'Cuba', value: 'CU'}, 
  {label: 'Cyprus', value: 'CY'}, 
  {label: 'Czech Republic', value: 'CZ'}, 
  {label: 'Dinamarca', value: 'DK'}, 
  {label: 'Djibuti', value: 'DJ'}, 
  {label: 'Dominica', value: 'DM'}, 
  {label: 'República Dominicana', value: 'DO'}, 
  {label: 'Equador', value: 'EC'}, 
  {label: 'Egito', value: 'EG'}, 
  {label: 'El Salvador', value: 'SV'}, 
  {label: 'Guiné Equatorial', value: 'GQ'}, 
  {label: 'Eritreia', value: 'ER'}, 
  {label: 'Estônia', value: 'EE'}, 
  {label: 'Etiópia', value: 'ET'}, 
  {label: 'Ilhas Malvinas (Malvinas)', value: 'FK'}, 
  {label: 'ilhas Faroe', value: 'FO'}, 
  {label: 'Fiji', value: 'FJ'}, 
  {label: 'Finlândia', value: 'FI'}, 
  {label: 'França', value: 'FR'}, 
  {label: 'Guiana Francesa', value: 'GF'}, 
  {label: 'Polinésia Francesa', value: 'PF'}, 
  {label: 'Territórios do Sul da França', value: 'TF'}, 
  {label: 'Gabão', value: 'GA'}, 
  {label: 'Gâmbia', value: 'GM'}, 
  {label: 'Geórgia', value: 'GE'}, 
  {label: 'Alemanha', value: 'DE'}, 
  {label: 'Gana', value: 'GH'}, 
  {label: 'Gibraltar', value: 'GI'}, 
  {label: 'Grécia', value: 'GR'}, 
  {label: 'Groenlândia', value: 'GL'}, 
  {label: 'Granada', value: 'GD'}, 
  {label: 'Guadalupe', value: 'GP'}, 
  {label: 'Guam', value: 'GU'}, 
  {label: 'Guatemala', value: 'GT'}, 
  {label: 'Guernsey', value: 'GG'}, 
  {label: 'Guiné', value: 'GN'}, 
  {label: 'Guiné-Bissau', value: 'GW'}, 
  {label: 'Guiana', value: 'GY'}, 
  {label: 'Haiti', value: 'HT'}, 
  {label: 'Ilha Heard e Ilhas Mcdonald', value: 'HM'}, 
  {label: 'Santa Sé (Estado da Cidade do Vaticano)', value: 'VA'}, 
  {label: 'Honduras', value: 'HN'}, 
  {label: 'Hong Kong', value: 'HK'}, 
  {label: 'Hungria', value: 'HU'}, 
  {label: 'Islândia', value: 'IS'}, 
  {label: 'Índia', value: 'IN'}, 
  {label: 'Indonésia', value: 'ID'}, 
  {label: 'Irã, República Islâmica do Irã', value: 'IR'}, 
  {label: 'Iraque', value: 'IQ'}, 
  {label: 'Irlanda', value: 'IE'}, 
  {label: 'Ilha de Man', value: 'IM'}, 
  {label: 'Israel', value: 'IL'}, 
  {label: 'Itália', value: 'IT'}, 
  {label: 'Jamaica', value: 'JM'}, 
  {label: 'Japão', value: 'JP'}, 
  {label: 'Jersey', value: 'JE'}, 
  {label: 'Jordânia', value: 'JO'}, 
  {label: 'Cazaquistão', value: 'KZ'}, 
  {label: 'Quênia', value: 'KE'}, 
  {label: 'Kiribati', value: 'KI'}, 
  {label: 'Coréia, República Popular Democrática da', value: 'KP'}, 
  {label: 'Republica da Coréia', value: 'KR'}, 
  {label: 'Kuwait', value: 'KW'}, 
  {label: 'Quirguistão', value: 'KG'}, 
  {label: 'República Democrática Popular do Laos', value: 'LA'}, 
  {label: 'Letônia', value: 'LV'}, 
  {label: 'Líbano', value: 'LB'}, 
  {label: 'Lesoto', value: 'LS'}, 
  {label: 'Libéria', value: 'LR'}, 
  {label: 'Jamahiriya Árabe Líbia', value: 'LY'}, 
  {label: 'Listenstaine', value: 'LI'}, 
  {label: 'Lituânia', value: 'LT'}, 
  {label: 'Luxemburgo', value: 'LU'}, 
  {label: 'Macau', value: 'MO'}, 
  {label: 'Macedônia, Antiga República Iugoslava da', value: 'MK'}, 
  {label: 'Madagáscar', value: 'MG'}, 
  {label: 'Malawi', value: 'MW'}, 
  {label: 'Malásia', value: 'MY'}, 
  {label: 'Maldivas', value: 'MV'}, 
  {label: 'Mali', value: 'ML'}, 
  {label: 'Malta', value: 'MT'}, 
  {label: 'Ilhas Marshall', value: 'MH'}, 
  {label: 'Martinica', value: 'MQ'}, 
  {label: 'Mauritânia', value: 'MR'}, 
  {label: 'Maurícia', value: 'MU'}, 
  {label: 'Mayotte', value: 'YT'}, 
  {label: 'México', value: 'MX'}, 
  {label: 'Micronésia, Estados Federados da', value: 'FM'}, 
  {label: 'Moldávia, República da', value: 'MD'}, 
  {label: 'Mônaco', value: 'MC'}, 
  {label: 'Mongólia', value: 'MN'}, 
  {label: 'Montserrat', value: 'MS'}, 
  {label: 'Marrocos', value: 'MA'}, 
  {label: 'Moçambique', value: 'MZ'}, 
  {label: 'Mianmar', value: 'MM'}, 
  {label: 'Namíbia', value: 'NA'}, 
  {label: 'Nauru', value: 'NR'}, 
  {label: 'Nepal', value: 'NP'}, 
  {label: 'Holanda', value: 'NL'}, 
  {label: 'Antilhas Holandesas', value: 'AN'}, 
  {label: 'Nova Caledônia', value: 'NC'}, 
  {label: 'Nova Zelândia', value: 'NZ'}, 
  {label: 'Nicarágua', value: 'NI'}, 
  {label: 'Níger', value: 'NE'}, 
  {label: 'Nigéria', value: 'NG'}, 
  {label: 'Niue', value: 'NU'}, 
  {label: 'Ilha Norfolk', value: 'NF'}, 
  {label: 'Ilhas Marianas do Norte', value: 'MP'}, 
  {label: 'Noruega', value: 'NO'}, 
  {label: 'Omã', value: 'OM'}, 
  {label: 'Paquistão', value: 'PK'}, 
  {label: 'Palau', value: 'PW'}, 
  {label: 'Território Palestino, Ocupado', value: 'PS'}, 
  {label: 'Panamá', value: 'PA'}, 
  {label: 'Papua Nova Guiné', value: 'PG'}, 
  {label: 'Paraguai', value: 'PY'}, 
  {label: 'Peru', value: 'PE'}, 
  {label: 'Filipinas', value: 'PH'}, 
  {label: 'Pitcairn', value: 'PN'}, 
  {label: 'Polônia', value: 'PL'}, 
  {label: 'Portugal', value: 'PT'}, 
  {label: 'Porto Rico', value: 'PR'}, 
  {label: 'Catar', value: 'QA'}, 
  {label: 'Reunião', value: 'RE'}, 
  {label: 'Romênia', value: 'RO'}, 
  {label: 'Rússia', value: 'RU'}, 
  {label: 'RUANDA', value: 'RW'}, 
  {label: 'Santa Helena', value: 'SH'}, 
  {label: 'São Cristóvão e Nevis', value: 'KN'}, 
  {label: 'Santa Lúcia', value: 'LC'}, 
  {label: 'São Pedro e Miquelon', value: 'PM'}, 
  {label: 'São Vicente e Granadinas', value: 'VC'}, 
  {label: 'Samoa', value: 'WS'}, 
  {label: 'São Marinho', value: 'SM'}, 
  {label: 'São Tomé e Príncipe', value: 'ST'}, 
  {label: 'Arábia Saudita', value: 'SA'}, 
  {label: 'Senegal', value: 'SN'}, 
  {label: 'Sérvia e Montenegro', value: 'CS'}, 
  {label: 'Seicheles', value: 'SC'}, 
  {label: 'Serra Leoa', value: 'SL'}, 
  {label: 'Cingapura', value: 'SG'}, 
  {label: 'Eslováquia', value: 'SK'}, 
  {label: 'Eslovênia', value: 'SI'}, 
  {label: 'Ilhas Salomão', value: 'SB'}, 
  {label: 'Somália', value: 'SO'}, 
  {label: 'África do Sul', value: 'ZA'}, 
  {label: 'Ilhas Geórgia do Sul e Sandwich do Sul', value: 'GS'}, 
  {label: 'Espanha', value: 'ES'}, 
  {label: 'Sri Lanka', value: 'LK'}, 
  {label: 'Sudão', value: 'SD'}, 
  {label: 'Surilabel', value: 'SR'}, 
  {label: 'Svalbard e Jan Mayen', value: 'SJ'}, 
  {label: 'Suazilândia', value: 'SZ'}, 
  {label: 'Suécia', value: 'SE'}, 
  {label: 'Suíça', value: 'CH'}, 
  {label: 'República Árabe da Síria', value: 'SY'}, 
  {label: 'Taiwan, província da China', value: 'TW'}, 
  {label: 'Tajiquistão', value: 'TJ'}, 
  {label: 'Tanzânia, República Unida da', value: 'TZ'}, 
  {label: 'Tailândia', value: 'TH'}, 
  {label: 'Timor-Leste', value: 'TL'}, 
  {label: 'Togo', value: 'TG'}, 
  {label: 'Toquelau', value: 'TK'}, 
  {label: 'Tonga', value: 'TO'}, 
  {label: 'Trindade e Tobago', value: 'TT'}, 
  {label: 'Tunísia', value: 'TN'}, 
  {label: 'Turquia', value: 'TR'}, 
  {label: 'Turcomenistão', value: 'TM'}, 
  {label: 'Ilhas Turcas e Caicos', value: 'TC'}, 
  {label: 'Tuvalu', value: 'TV'}, 
  {label: 'Uganda', value: 'UG'}, 
  {label: 'Ucrânia', value: 'UA'}, 
  {label: 'Emirados Árabes Unidos', value: 'AE'}, 
  {label: 'Reino Unido', value: 'GB'}, 
  {label: 'Estados Unidos', value: 'US'}, 
  {label: 'Ilhas Menores Distantes dos Estados Unidos', value: 'UM'}, 
  {label: 'Uruguai', value: 'UY'}, 
  {label: 'Uzbequistão', value: 'UZ'}, 
  {label: 'Vanuatu', value: 'VU'}, 
  {label: 'Venezuela', value: 'VE'}, 
  {label: 'Vietnã', value: 'VN'}, 
  {label: 'Ilhas Virgens, Britânico', value: 'VG'}, 
  {label: 'Ilhas Virgens, EUA', value: 'VI'}, 
  {label: 'Wallis e Futuna', value: 'WF'}, 
  {label: 'Saara Ocidental', value: 'EH'}, 
  {label: 'Iémen', value: 'YE'}, 
  {label: 'Zâmbia', value: 'ZM'}, 
  {label: 'Zimbábue', value: 'ZW'} 
];