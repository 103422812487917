import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import {
    formatCurrency,
    formatDate,
} from "@/utils";


interface StatementCharge {
    amount: number;
    creditOperation: boolean;
    description: string;
    executionDate: string;
    providerType: string;
    statementType: string;
  }
  
  interface StatementChargeProps {
    statementCharge: StatementCharge[];
  }

  
const ReceivableTable = ({ statementCharge }: StatementChargeProps) => {

    const creditOperations = statementCharge?.filter(item => item?.creditOperation === true);

    return (
        <>
            <Flex w="100%" gap="6" alignItems="center" mt="8">
                <Text as="h2" fontSize="20" fontWeight="600" mb="8">
                    Recebíveis
                </Text>
            </Flex>
            <Table>
                <Thead>
                    <Tr>
                        <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
                            Parcela
                        </Th>
                        <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
                            Valor
                        </Th>
                        <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
                            Data de execuçao
                        </Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {(creditOperations?.length > 0) &&
                        creditOperations?.map((split, index) => (
                            <Tr key={index}>
                                <Td>
                                    {split?.description}
                                </Td>
                                <Td>
                                    {split?.amount?.toLocaleString('pt-BR', {  style: 'currency',  currency: 'BRL' })}
                                </Td>
                                <Td>
                                    {formatDate(split?.executionDate)}
                                </Td>
                            </Tr>
                        ))}
                </Tbody>
            </Table>
        </>
    );
};

export { ReceivableTable };