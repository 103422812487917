import { IconProps } from "@/models/Icon";

export function DownloadIcon({ size = 20, color = "#000", opacity="1"}: IconProps) {
    return(
        <svg width={size} height={size} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M8.85993 11.3218C8.87664 11.3432 8.89798 11.3604 8.92234 11.3723C8.94671 11.3842 8.97346 11.3903 9.00056 11.3903C9.02766 11.3903 9.05441 11.3842 9.07877 11.3723C9.10314 11.3604 9.12448 11.3432 9.14118 11.3218L11.6412 8.15888C11.7327 8.0428 11.6501 7.87093 11.5006 7.87093H9.84654V0.317358C9.84654 0.219143 9.76618 0.138786 9.66797 0.138786H8.32868C8.23047 0.138786 8.15011 0.219143 8.15011 0.317358V7.8687H6.50056C6.35101 7.8687 6.26842 8.04057 6.35993 8.15664L8.85993 11.3218ZM17.1702 10.5406H15.8309C15.7327 10.5406 15.6523 10.6209 15.6523 10.7191V14.1566H2.34877V10.7191C2.34877 10.6209 2.26842 10.5406 2.1702 10.5406H0.830915C0.732701 10.5406 0.652344 10.6209 0.652344 10.7191V15.1388C0.652344 15.5339 0.97154 15.8531 1.36663 15.8531H16.6345C17.0296 15.8531 17.3488 15.5339 17.3488 15.1388V10.7191C17.3488 10.6209 17.2684 10.5406 17.1702 10.5406Z" 
                fill={color} 
                fillOpacity={opacity}
            />
        </svg>
    )
}