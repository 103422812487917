import { CloseIcon } from "@/styles/icons/CloseIcon";
import { useState } from "react";
import { Overlay } from "./customModal.styled";

interface Props {
  isOpen?: boolean;
  closeButtonText?: string;
  confirmButtonText?: string;
  asSubmitModal?: boolean;
  headerText?: string;
  headerIcon?: any;
  modalBody?: any;
  onClose?: () => void;
  onConfirm?: () => void;
}

export function CustomModal({
  isOpen,
  closeButtonText,
  confirmButtonText,
  asSubmitModal,
  headerText,
  headerIcon,
  onClose,
  onConfirm,
  modalBody
}: Props) {
  const [isScrollNotOnTop, setIsScrollNotOnTop] = useState(false);
  const [isScrollNotOnBottom, setIsScrollNotOnBottom] = useState(false);
  const handleScroll = (event) => {
    const { scrollTop, clientHeight } = event.target;
    if (scrollTop > 0) {
      setIsScrollNotOnBottom(false);
      setIsScrollNotOnTop(true);
      return;
    }
    if (scrollTop < clientHeight) {
      setIsScrollNotOnTop(false);
      setIsScrollNotOnBottom(true);
      return;
    }
  }
  return (
    <Overlay
      isOpen={isOpen}
      hasContentDownwards={isScrollNotOnBottom}
      hasContentUpdwards={isScrollNotOnTop}
    >
      <div className="modal">
        <section className="modal-header">
          <div className="header-text">
            {headerIcon && (
              <div>
                {headerIcon}
              </div>
            )}
            <p>
              {headerText}
            </p>
          </div>
          <button type="button" onClick={onClose}>
            <CloseIcon size={12} />
          </button>
        </section>
        <section className="modal-body" onScroll={handleScroll}>
          {modalBody}
        </section>
        <section className="modal-footer">
          {closeButtonText ? (
            <button type="button" onClick={onClose} >
              <p>
                {closeButtonText}
              </p>
            </button>
          ) : (<div></ div>)}
          {confirmButtonText && (
            <button
              className="confirm-button"
              type={asSubmitModal ? "submit" : "button"}
              onClick={onConfirm}
            >
              <p color="white">
                {confirmButtonText}
              </p>
            </button>
          )}
        </section>
      </div>
    </Overlay>
  );
}