import { Api } from "@/services";


export function useTransfers() {
  const baseUrl = "/sellers/transfers";
  const redeemsBaseUrl = "/redeems";

  const requestTransfer = (data: Object) => {
    return Api.post(`${baseUrl}`, data);
  };
  const requestAnticipationTransfer = () => {
    return Api.post(`${redeemsBaseUrl}/anticipation`)
  }
  const findSeller = () => {
    return Api.get(`${baseUrl}`);
  };

  const findSellerTransferList = ({params}) => {
    return Api.get(`${baseUrl}/approve`, {
      params: {
        size: params.size,
        sort: params.sort,
      },
    })};
      return {
        requestAnticipationTransfer,
        requestTransfer,
        findSeller,
        findSellerTransferList
      };
    }
