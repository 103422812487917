
import { IconProps } from "../../models/Icon";

export function CheckCircleIcon({ size = 20, color = "#389E0D", opacity = "1" }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.20156 2 1.5 6.70156 1.5 12.5C1.5 18.2984 6.20156 23 12 23C17.7984 23 22.5 18.2984 22.5 12.5C22.5 6.70156 17.7984 2 12 2ZM12 21.2188C7.18594 21.2188 3.28125 17.3141 3.28125 12.5C3.28125 7.68594 7.18594 3.78125 12 3.78125C16.8141 3.78125 20.7188 7.68594 20.7188 12.5C20.7188 17.3141 16.8141 21.2188 12 21.2188Z" fill={color}/>
        <path opacity="0.1" d="M12 3.78125C7.18594 3.78125 3.28125 7.68594 3.28125 12.5C3.28125 17.3141 7.18594 21.2188 12 21.2188C16.8141 21.2188 20.7188 17.3141 20.7188 12.5C20.7188 7.68594 16.8141 3.78125 12 3.78125ZM16.5328 9.07109L11.5969 15.9148C11.5279 16.0111 11.4369 16.0896 11.3316 16.1437C11.2262 16.1978 11.1095 16.2261 10.991 16.2261C10.8726 16.2261 10.7558 16.1978 10.6505 16.1437C10.5451 16.0896 10.4541 16.0111 10.3852 15.9148L7.46484 11.8648C7.37578 11.7406 7.46484 11.5672 7.61719 11.5672H8.71641C8.95781 11.5672 9.18281 11.6844 9.32344 11.8789L10.9922 14.1945L14.6766 9.08516C14.8172 8.88828 15.0445 8.77344 15.2836 8.77344H16.3828C16.5352 8.77344 16.6242 8.94688 16.5328 9.07109Z"  fill={color}/>
        <path d="M16.3812 8.77344H15.282C15.0429 8.77344 14.8156 8.88828 14.6749 9.08516L10.9905 14.1945L9.32179 11.8789C9.18116 11.6844 8.95616 11.5672 8.71475 11.5672H7.61553C7.46319 11.5672 7.37412 11.7406 7.46319 11.8648L10.3835 15.9148C10.4525 16.0111 10.5434 16.0896 10.6488 16.1437C10.7542 16.1978 10.8709 16.2261 10.9894 16.2261C11.1078 16.2261 11.2246 16.1978 11.3299 16.1437C11.4353 16.0896 11.5262 16.0111 11.5952 15.9148L16.5312 9.07109C16.6226 8.94688 16.5335 8.77344 16.3812 8.77344Z" fill={color}/>
    </svg>
  )
}













