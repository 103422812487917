





import { IconProps } from "@/models";

export function MoneyOut({
  size = 20,
  color = "#8E1E1E",
  opacity = "1",
}: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M18.8233 2.47803V9.56402H19.8096V2.50016L20.9201 3.56398L21.6024 2.85174L19.6576 0.988726L19.3212 0.666504L18.9804 0.983964L16.98 2.84698L17.6522 3.56874L18.8233 2.47803ZM1.09766 17.5641C1.09766 18.3207 1.71097 18.934 2.46752 18.934L21.536 18.934C22.2926 18.934 22.9059 18.3207 22.9059 17.5641L22.9059 5.29013H21.9196L21.9196 17.5641C21.9196 17.7759 21.7478 17.9477 21.536 17.9477L2.46752 17.9477C2.25569 17.9477 2.08396 17.7759 2.08396 17.5641L2.08396 6.61074C2.08396 6.39891 2.25568 6.22718 2.46752 6.22718L16.6045 6.22718V5.24088L2.46752 5.24088C1.71097 5.24088 1.09766 5.85419 1.09766 6.61074L1.09766 17.5641ZM19.7818 15.7011H16.6585V14.7148H19.7818V15.7011ZM4.22102 9.45447H7.28951V8.46817L4.22102 8.46817V9.45447ZM10.0769 13.1582C9.54191 12.6232 9.54191 11.7558 10.0769 11.2209L11.0334 10.2643C11.5684 9.72934 12.4357 9.72934 12.9707 10.2643L13.9273 11.2209C14.4622 11.7558 14.4622 12.6232 13.9273 13.1582L12.9707 14.1147C12.4357 14.6497 11.5684 14.6497 11.0334 14.1147L10.0769 13.1582ZM10.7743 11.9183C10.6245 12.0681 10.6245 12.3109 10.7743 12.4607L11.7309 13.4173C11.8806 13.5671 12.1235 13.5671 12.2733 13.4173L13.2299 12.4607C13.3796 12.3109 13.3796 12.0681 13.2299 11.9183L12.2733 10.9617C12.1235 10.8119 11.8806 10.8119 11.7309 10.9617L10.7743 11.9183Z" 
            fill={color}
        />
    </svg>
  );
}
