import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 450px;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #e6e9f0;
`;

export const TitleSection = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e9f0;
    padding: 1.5rem;
    h1 {
        font-weight: 700;
        font-size: 1.2rem;
    }
`;

export const BodySection = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 2.5rem;
    border-bottom: 1px solid #e6e9f0;
    h1 {
        font-weight: 700;
    }
    span {
        display: flex;
        align-items: center;
        gap: .5rem;
        p {
            font-size: .8rem;
        }
    }
`;

export const FooterSection = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2.5rem;
    div {
        display: flex;
        gap: 1rem;
    }
`;