import { useState } from "react";
import { useParams } from "react-router-dom";
import { WithAuth } from "@/hoc";
import { Center, Divider, Flex } from "@chakra-ui/react";
import { BackButton } from "@/components";
import { PunctualCharge } from "./punctual-charge";
import { CustomSignature } from "./custom-signature";
import { LeanCheckout } from "./lean-checkout";
import PreviewLinkPagamento from "@/components/PreviewLinkPagamento";
import { usePreviewContext } from "@/contexts/PreviewContext";




const SejaPago = () => {
  const { id } = useParams();
  const [punctualButton, setPunctualButton] = useState(true);
  const { previewData, setPreviewData } = usePreviewContext();
  const paymentLinkCreationState = window?.location?.pathname?.includes("criar");
  const leanCheckout = window?.location?.pathname?.includes("link-rapido");


  return (
    <Flex
      flexDir="column"
      align="start"
      pb="20px"
    >
      <Flex w="100%" align="center" justify="flex-start" marginBottom="2rem">
        <BackButton hasLink link="/seja-pago" />
      </Flex>

      {paymentLinkCreationState && (
        <Flex 
          flexDir="row" 
          w="100%" 
        >
          {leanCheckout && (
            <Flex 
              w="100%"
              justifyContent="center"
              alignItems="center"
            >
              <LeanCheckout />
            </Flex>
          )}
          {!leanCheckout && (
            <>
              <Flex w="60%">
                {punctualButton && <PunctualCharge punctualButton={punctualButton} setPreviewData={setPreviewData} previewData={previewData} setPunctualButton={setPunctualButton} />}
                {!punctualButton && <CustomSignature punctualButton={punctualButton} setPreviewData={setPreviewData} previewData={previewData} setPunctualButton={setPunctualButton} />}
                <Flex>
                  <Center w="1px" m=" -90px auto" height={'calc(100% + 17px)'}>
                    <Divider orientation='vertical' />
                  </Center>
                </Flex>
              </Flex>
              <PreviewLinkPagamento previewData={previewData} isPontual={punctualButton} />
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default WithAuth(SejaPago);
