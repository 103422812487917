import { Dispatch, ReactNode, SetStateAction } from "react";
import { Flex, Box, Text, Link } from "@chakra-ui/react";
import { Button } from "@/styles/components/Buttons";


interface termsProps {
    UploadTerms?: () => void;
    typeSeller?: string;
    setLogoff?: React.Dispatch<SetStateAction<boolean>>;
}

export default function AlertTerms({ UploadTerms, typeSeller,setLogoff }: termsProps) {

    return (
        <Flex
            position="absolute"
            top="0"
            left="0"
            height="100vh"
            width="100vw"
            backgroundColor="#262F4080"
            zIndex="9999"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
        >
            <Flex
                width="510px"
                bgColor="white"
                borderRadius="8px"
                flexDirection="column"
                justifyContent="space-between"
                p="56px"
                gap="56px"
                color="black"
            >
                <Text
                    fontSize="25px"
                    fontWeight="600"
                >
                    Novo repasse
                </Text>
                {typeSeller.includes("SELLER_ADMIN")?
                    <>
                        <Flex
                            flexDirection="column"
                            gap="24px"
                        >
                            <Box
                                lineHeight="32px"
                                fontWeight="400"
                            >
                                <Text lineHeight="32px" >
                                    Nossos <strong>Termos de Uso</strong> foram atualizados.
                                </Text>

                                <Text lineHeight="32px">
                                    Você pode conferir o texto completo <Link href="https://www.barte.com/termos-de-uso" target="_blank" color="blue.200">neste link.</Link>
                                </Text>
                            </Box>
                            <Text>
                                Ao prosseguir você concorda com os novos termos.
                            </Text>
                        </Flex>
                        <Flex>
                            <Button onClick={() => UploadTerms()}>Concordar e prosseguir</Button>
                        </Flex>
                    </> :
                    <>
                        <Flex
                            flexDirection="column"
                            gap="24px"
                        >
                            <Flex
                                flexDirection="column"
                                lineHeight="32px"
                                fontWeight="400"
                                gap="24px"
                            >
                                <Text lineHeight="32px" >
                                    Nossos <strong>Termos de Uso</strong> foram atualizados e é necessário aceite pelo usuário administrador.
                                </Text>
                                <Text as="strong">
                                    Contate o usuário administrador e peça para que seja feito o login e aceite dos termos.
                                </Text>
                                <Text lineHeight="32px">
                                    Você pode conferir o texto completo <Link href="https://www.barte.com/termos-de-uso" target="_blank" color="blue.200">neste link.</Link>
                                </Text>
                            </Flex>

                        </Flex>
                        <Flex>
                            <Button onClick={() => setLogoff(true)}>Voltar para tela de login</Button>
                        </Flex>
                    </>
                }
            </Flex>
        </Flex>
    )

}