import { useSubscription } from "@/hooks"
import { showToast } from "@/utils"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export function useInvoiceSendStatus(status: boolean) {
  const [componentStatus, setComponentStatus] = useState<boolean>(status)
  const [invoiceSendStatusModal, setInvoiceSendStatusModal] = useState<boolean>(false)
  const { patchToggleAutomaticInvoiceEmit } = useSubscription()
  const { id } = useParams()

  function getStatus(shownStatus: boolean) {
    return shownStatus ? 'Ativo' : 'Desativada'
  }

  function translateStatus(shownStatus: boolean): string {
    return shownStatus ? 'ACTIVE' : 'INACTIVE'
  }

  async function handleToggleAutomaticInvoiceEmit(status: boolean) {
    try {
      await patchToggleAutomaticInvoiceEmit(!status, id)
      setComponentStatus(!status)
      setInvoiceSendStatusModal(false)
      showToast({
        type: "success",
        message: `Envio de nota fiscal automática ${!status ? "ativada" : "desativada"} para essa recorrência`
      })
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  useEffect(() => {
    setComponentStatus(status)
  }, [status])

  useEffect(() => {
  }, [componentStatus])

  return {
    componentStatus,
    invoiceSendStatusModal,
    setInvoiceSendStatusModal,
    handleToggleAutomaticInvoiceEmit,
    translateStatus,
    getStatus
  }
}