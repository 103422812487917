import getAPIAirtable from "@/services/Api/airTableApiConfig";

export function useBillsToPay() {
   const baseURL =  String(import.meta.env.VITE_API_AIRTABLE)
const urlFile = 'https://airtable.com/appgcCA1WiWUpKMVc/tbl9TukvdgqUfl8JX/viwUU6i1z9jZ5KLXd/recWsA0gPsslkBOOh/fldeRcIsWt7eNQWk2?copyLinkToCellOrRecordOrigin=gridView'
    const newBills = (values: object) => {
        return getAPIAirtable(baseURL).post("", values);
    };

    const updateFile = (values: FormData) => {
        return getAPIAirtable(urlFile).patch("", values);
    };

    const updateBills = (values: object) => {
        return getAPIAirtable(baseURL).patch("", values);
    };

    const getBills = (values: string) => {
        return getAPIAirtable(values,).get("");
    };

    const deleteBills = (recordID: string) => {
        return getAPIAirtable(`${baseURL}${recordID}`).delete("",);
    };
    
    const putBills = (values: object) => {
        return getAPIAirtable().put("", values);
    };
    const getOneBills = (values: string) => {
        return getAPIAirtable(baseURL).get(values);
    };
   

    return {
        newBills,
        getBills,
        putBills,
        deleteBills,
        updateBills,
        updateFile,
        getOneBills
    };
}
