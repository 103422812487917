import { Flex, Divider, Button as ChakraButton } from "@chakra-ui/react";
import { useSettings } from "@/hooks";
import { useEffect, useState } from "react";
import { WithAuth } from "@/hoc";
import { GeneralInfo } from "./_GeneralInfo";
import { BankSlipConfig } from "./_BankSlipConfig";
import { PixConfig } from "./_PixConfig";
import { CreditCardConfig } from "./_CreditCardConfig";
import { showToast } from "@/utils";
import { ConfirmationModal } from "@/components/ConfirmationModal";
import { SellerNotifications } from "@/models";

const Notifications = () => {
  const [data, setData] = useState({} as SellerNotifications);
  const [userData, setUserData] = useState(
    {} as Pick<
      SellerNotifications,
      "name" | "email" | "hiddenEmail" | "activeAllNotifications"
    >
  );
  const [
    isEnableAllNotificationModalOpen,
    setIsEnableAllNotificationModalOpen,
  ] = useState(false);
  const [enableNotificationLoading, setEnableNotificationLoading] =
    useState(false);

  const {
    getSellerNotifications,
    handleBarChange,
    patchBasic,
    PIX,
    bank,
    info,
    credit,
  } = useSettings();

  const handleEnableAllNotifications = async () => {
    const sellerBasicData = {
      name: userData.name,
      email: userData.email,
      hiddenEmail: userData.hiddenEmail,
      activeAllNotifications: !data.activeAllNotifications,
    };
    try {
      setEnableNotificationLoading(true);
      await patchBasic(sellerBasicData);
      showToast({
        message: "Notificação desabilitada com sucesso",
        type: "success",
      });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    } finally {
      setEnableNotificationLoading(false);
      setIsEnableAllNotificationModalOpen(false);
    }
  };
  const getInitialData = async() => {
    try {
      const response = await getSellerNotifications();
      setData({ ...response.data });
      setUserData({
        name: response.data.name,
        email: response.data.email,
        hiddenEmail: response.data.hiddenEmail,
        activeAllNotifications: response.data.activeAllNotifications,
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possível carregar os dados básicos.",
      });
    }
  }
  useEffect(() => {
    getInitialData();
  }, [PIX, bank, info, credit, enableNotificationLoading]);

  return (
    <>
      <Flex flexDir="column">
        <Flex
          w="450px"
          justify="space-between"
          fontWeight="bold"
          color="gray.400"
          marginTop="1rem"
          gap={2}
        >
          <ChakraButton
            zIndex={1}
            color={info && "black"}
            borderBottom="2px solid transparent"
            borderBottomColor={info && "black"}
            transition="all .3s"
            name="generalInfo"
            bgColor="transparent"
            borderRadius="0"
            _hover={{
              transition: "all .3s",
              color: "black",
              cursor: "pointer",
            }}
            onClick={(e: any) =>
              handleBarChange(e.target.attributes.name.value)
            }
          >
            Informações gerais
          </ChakraButton>
          <ChakraButton
            zIndex={1}
            color={bank && "black"}
            borderBottom="2px solid transparent"
            borderBottomColor={bank && "black"}
            name="bankSlip"
            transition="all .3s"
            bgColor="transparent"
            borderRadius="0"
            _hover={{
              transition: "all .3s",
              color: "black",
              cursor: "pointer",
            }}
            onClick={(e: any) =>
              handleBarChange(e.target.attributes.name.value)
            }
          >
            Boleto
          </ChakraButton>
          <ChakraButton
            zIndex={1}
            color={PIX && "black"}
            borderBottom="2px solid transparent"
            borderBottomColor={PIX && "black"}
            name="PIX"
            transition="all .3s"
            bgColor="transparent"
            borderRadius="0"
            _hover={{
              transition: "all .3s",
              color: "black",
              cursor: "pointer",
            }}
            onClick={(e: any) =>
              handleBarChange(e.target.attributes.name.value)
            }
          >
            Pix
          </ChakraButton>
          <ChakraButton
            zIndex={1}
            color={credit && "black"}
            borderBottom="2px solid transparent"
            borderBottomColor={credit && "black"}
            name="creditCard"
            transition="all .3s"
            bgColor="transparent"
            borderRadius="0"
            _hover={{
              transition: "all .3s",
              color: "black",
              cursor: "pointer",
            }}
            onClick={(e: any) =>
              handleBarChange(e.target.attributes.name.value)
            }
          >
            Cartão de Crédito
          </ChakraButton>
        </Flex>

        <Divider marginTop="-.1rem" marginBottom="1.8rem" />
      </Flex>

      {info && (
        <>
          <ConfirmationModal
            isOpen={isEnableAllNotificationModalOpen}
            onClose={() =>
              setIsEnableAllNotificationModalOpen(
                !isEnableAllNotificationModalOpen
              )
            }
            handleConfirmModal={() => handleEnableAllNotifications()}
            title={
              userData.activeAllNotifications === false
                ? "Ativar todas as notificações de cobrança?"
                : "Desativar todas as notificações de cobrança?"
            }
            isSubmitting={enableNotificationLoading}
          >
            {userData.activeAllNotifications === false
              ? "Seus clientes começarão a receber notificações relativas a cobranças conforme suas configurações por métodos de pagamento."
              : "Seus clientes não irão receber mais nenhuma notificação relativa a cobranças. Essa opção é recomendada somente em casos onde você já possui outro sistema de notificação fora da barte."}
          </ConfirmationModal>
          <GeneralInfo
            data={userData}
            isDisable={userData.activeAllNotifications === false}
            refreshData={getInitialData}
          />
          <Divider my="2rem" />
          <ChakraButton
            variant={
              userData.activeAllNotifications === false ? "secondary" : "cancel"
            }
            maxW="18rem"
            onClick={() =>
              setIsEnableAllNotificationModalOpen(
                !isEnableAllNotificationModalOpen
              )
            }
          >
            {userData.activeAllNotifications === false
              ? "Ativar notificações"
              : "Desativar todas as notificações"}
          </ChakraButton>
        </>
      )}

      {bank && (
        <BankSlipConfig
          data={data}
          isDisable={userData.activeAllNotifications === false}
        />
      )}

      {PIX && (
        <PixConfig
          data={data}
          isDisable={userData.activeAllNotifications === false}
        />
      )}

      {credit && (
        <CreditCardConfig
          data={data}
          isDisable={userData.activeAllNotifications === false}
        />
      )}
    </>
  );
};

export default WithAuth(Notifications);
