import { ReactNode, KeyboardEvent, useState, useEffect } from "react";
import {
  Flex,
  Input as ChakraInput,
  Switch,
  Text,
  InputProps as ChakraInputProps,
  Tooltip,
  Icon,
  InputGroup,
  FormControl,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";


interface InputProps extends ChakraInputProps {
  name: string;
  switchActive?: boolean;
  periodicityName?: string;
  containerDisabled?: boolean;
  hasMonthlyValue?: boolean;
  valueData?: any;
  asSubscription?: boolean;
  tooltipText?: string;
  hasSwitch?: boolean;
  label?: string;
  labelColor?: string;
  register?: any;
  setValue?: any;
  errors?: any;
  mask?: string;
  valueLenght?: number;
  classInput?: string;
  before?: string;
  after?: string;
  variant?: string;
  switchFunction?: (any?: any) => void;
  color?: string;
  asDoubleEffect?: boolean;
  optionalText?: string;
  afterFontSize?: string;
  afterColor?: string;
  afterBackgroundColor?: string;
}

export function InputCurrency
  ({
    name,
    setValue,
    switchActive,
    containerDisabled = true,
    periodicityName,
    valueLenght,
    hasMonthlyValue,
    valueData,
    asSubscription,
    tooltipText,
    label,
    labelColor = "gray.600",
    register,
    errors,
    mask,
    hasSwitch = false,
    classInput,
    variant,
    switchFunction,
    color,
    asDoubleEffect,
    optionalText,
    afterFontSize = "2xl",
    afterColor = "gray.400",
    afterBackgroundColor = "gray.100",
    ...rest
  }: InputProps) {
  const [disabledState, setDisabledState] = useState(
    hasSwitch || containerDisabled
  );
  const [showCurrency, setShowCurrency] = useState(false);

  const handleMaskInputCurrency = (e: KeyboardEvent<HTMLInputElement>) => {
    let { value } = e.currentTarget;
    let cleanValue = value.replace(/\D/g, '');
    if (cleanValue.length > 2) {
      cleanValue = cleanValue.replace(/(\d{2})$/, ',$1');
    }
    cleanValue = cleanValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    e.currentTarget.value = cleanValue
    setShowCurrency(cleanValue !== '');
    setValue(name, e.currentTarget.value)
    return e.currentTarget.value
  };




  function handleSwitchChange(e: any) {
    if (asDoubleEffect) {
      switchFunction(e);
    }
    setDisabledState(!disabledState);
    setValue ? setValue(name, null) : null;
  }

  const Component: ReactNode = (
    <ChakraInput
      onKeyUp={handleMaskInputCurrency}
      disabled={(hasSwitch && disabledState) || !containerDisabled || (asDoubleEffect && !switchActive)}
      name={name}
      borderColor={errors ? "danger" : "newGray.200"}
      border={errors ? "1.5px solid" : "1px solid"}
      borderRadius="4px"
      p={showCurrency ? "0 30px" : "0 20px"}
      variant={variant}
      w="100%"
      color={color ? color : "black"}
      _focus={{
        border: "1px solid",
        borderColor: "blue.200",
      }}
      {...register}
      {...rest}
    />
  );

  useEffect(() => {
    setDisabledState(!switchActive);
  }, []);

  return (
    <FormControl isInvalid={!!errors?.message}>

      <Flex flexDir="column">
        <Flex align="center" justify="space-between">
          <Flex align="center" marginRight={tooltipText ? ".2rem" : ""}>
            {label && (
              <Text
                marginRight={tooltipText ? ".2rem" : ""}
                marginBottom=".2rem"
                fontSize=".8rem"
                color={
                  (hasSwitch && disabledState) || !containerDisabled
                    ? "newGray.200"
                    : labelColor}
                cursor={
                  ((hasSwitch && disabledState) || !containerDisabled) &&
                  "not-allowed"
                }
              >
                {label}
              </Text>
            )}
            {optionalText && (
              <Text marginLeft=".2rem" marginBottom=".2rem" fontSize=".8rem" color="#A5AEC0">
                {optionalText}
              </Text>
            )}
            {tooltipText && (
              <Tooltip placement="top" hasArrow label={tooltipText}>
                <span>
                  <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
                </span>
              </Tooltip>
            )}
          </Flex>
          {hasSwitch && !asDoubleEffect && (
            <Switch
              name={name}
              defaultChecked={switchActive}
              onChange={handleSwitchChange}
              size="sm"
            />
          )}

          {hasSwitch && asDoubleEffect && (
            <Switch
              name={name}
              isChecked={switchActive}
              defaultChecked={switchActive}
              onChange={handleSwitchChange}
              size="sm"
            />
          )}
        </Flex>
        <Flex flexDir="column" position="relative" >
          <InputGroup
            alignItems="center"
          >
            {showCurrency &&
              <Text position="absolute" p="2px 10px 0" color="black">
                R$
              </Text>
            }
            {Component}
          </InputGroup>
        </Flex>

        {errors && (
          <Text fontSize=".8rem" color="danger" mb={errors?.message && "4"} role="alert">
            {errors?.message}
          </Text>
        )}
      </Flex>
    </FormControl>
  );
}
