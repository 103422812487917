import { Button, ButtonProps } from "@chakra-ui/react";

export interface RadioElementProps extends ButtonProps {
  name: string;
  key: string;
}

export function RadioElement({name, key, ...rest}: RadioElementProps) {
  return (
    <Button
      h="100%"
      w="100%"
      key={key}
      {...rest}
    >
      {name}
    </Button>
  )
}