import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatISO } from "date-fns";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  BackButton,
  DatePickerInput,
  Input,
  InputSearch,
  Radio,
} from "@/components";
import { WithAuth } from "@/hoc";
import { useClient, usePlan, useSubscription } from "@/hooks";
import {
  Plan,
  SubscriptionImportRequestChanged,
  BasicValueProps,
  ClientData,
} from "@/models";
import { formatCurrency, formatCurrencyToApi, showToast } from "@/utils";
import { AddClient } from "@/pages/clientes/_adicionar_cliente";
import AddPlanModal from "@/pages/meu-checkout/meus-planos/_adionar-plano-modal";

const createPaymentSubscriptionSchema = yup.object().shape({
  idPlan: yup.string().required("Preenchimento obrigatório!"),
  valuePerMonth: yup.string().required("Preenchimento obrigatório!"),
  uuidBuyer: yup.string().required("Preenchimento obrigatório!"),
});

function SignatureImport() {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dropDown, setDropDown] = useState(false);
  const [clients, setClients] = useState<ClientData[]>([]);
  const [clientSelected, setClientSelected] = useState({} as ClientData);
  const [clientModal, setClientModal] = useState(false);
  const [planModal, setPlanModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({} as Plan);
  const [plansList, setPlansList] = useState<Plan[]>([]);
  const [basicValue, setBasicValue] = useState<BasicValueProps>(
    {} as BasicValueProps
  );

  const [periodicitButton, setPeriodicitButton] = useState("MONTHLY");
  const periodicits = [
    { name: "Mensal", value: "MONTHLY" },
    { name: "Semestral", value: "SEMESTER" },
    { name: "Anual", value: "YEARLY" },
  ];

  const [paymentMethodButton, setPaymentMethodButton] = useState("BANK_SLIP");
  const paymentMethods = [
    { name: "Boleto", value: "BANK_SLIP" },
    { name: "Pix", value: "PIX" },
  ];

  const { getClients } = useClient();
  const { getPlans } = usePlan();
  const { importSubscription } = useSubscription();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(createPaymentSubscriptionSchema),
  });

  function handleTogglePlan(e: number, plans?: Array<Plan>) {
    const plansListCurrent = plans || plansList;
    const planMatch = plansListCurrent.filter((plans) => {
      return plans?.id === e;
    });
    setSelectedPlan(planMatch[0]);
    setValue("idPlan", planMatch[0]?.id);
  }

  async function handleGetClient(client?: any) {
    try {
      const { data } = await getClients(
        {},
        {
          size: 10000,
          page: 0,
          sort: "name,ASC",
        }
      );
      setClients(data?.content);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  function getBasicValuesOfSelectedPlan() {
    if (selectedPlan) {
      const baseValues = selectedPlan?.basicValues?.filter(
        (data) => data.type === periodicitButton
      );
      if (baseValues?.length > 0) {
        setBasicValue(baseValues[0]);
        setValue(
          "valuePerMonth",
          formatCurrency({ value: Number(baseValues[0]?.valuePerMonth) })
        );
      }
    }
  }

  function getMonthlyPlan() {
    if (plansList.length > 0) {
      const item = plansList[0]?.basicValues.filter(
        (plan) => plan.type === "MONTHLY"
      );
      return item[0];
    }
  }

  async function getPlanList() {
    try {
      const response = await getPlans();
      setPlansList(response.data);
      const resMonthly = getMonthlyPlan();
      setBasicValue(resMonthly);
      handleTogglePlan(response?.data[0]?.id, response?.data);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  const handleSignatureImport: SubmitHandler<
    SubscriptionImportRequestChanged
  > = async (values) => {
    setLoading(true);

    const parsedDate = formatISO(new Date(values?.startDate), {
      representation: "date",
    });

    const signatureImportModel = {
      idPlan: values?.idPlan,
      basicValue: {
        value: formatCurrencyToApi(values?.valuePerMonth),
        type: basicValue?.type,
      },
      payment: {
        method: paymentMethodButton,
      },
      uuidBuyer: values?.uuidBuyer,
      startDate: parsedDate,
    };

    try {
      await importSubscription(signatureImportModel);

      showToast({
        message: "Assinatura importada com sucesso",
        type: "success",
      });

      setTimeout(() => {
        navigate("/recorrencias");
      }, 500);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getBasicValuesOfSelectedPlan();
  }, [selectedPlan]);

  useEffect(() => {
    getBasicValuesOfSelectedPlan();
  }, [periodicitButton]);

  useEffect(() => {
    setValue("uuidBuyer", clientSelected?.uuid);
  }, [clientSelected]);

  useEffect(() => {
    handleGetClient();
    getPlanList();
    setValue("startDate", selectedDate);
  }, []);

  return (
    <>
      {clientModal && (
        <Modal isOpen={clientModal} onClose={() => setClientModal(false)}>
          <ModalOverlay />
          <ModalContent maxW="520px">
            <ModalCloseButton />
            <AddClient />
          </ModalContent>
        </Modal>
      )}

      {planModal && (
        <Modal isOpen={planModal} onClose={() => setPlanModal(false)}>
          <ModalOverlay />
          <ModalContent maxW="640px">
            <ModalHeader>Adicionar plano</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <AddPlanModal
                getPlans={getPlanList}
                planModal={setPlanModal}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <BackButton />

      <Flex
        flexDir="column"
        justify="flex-start"
        align="center"
        h="100vh"
        w="100%"
        onMouseDownCapture={() => setDropDown(false)}
      >
        <Flex
          p="2rem"
          bgColor="white"
          flexDir="column"
          w="100%"
          maxW="524px"
          maxH="850px"
          gap="5"
          border-radius="8px"
          boxShadow="0px 4px 24px rgba(0,0,0, 0.04)"
        >
          <Flex flexDir="column" gap="4">
            <Flex align="flex-end">
              <Heading fontSize="1.8rem" marginRight=".5rem" color="gray.700">
                Importação de Recorrências
              </Heading>
              <Tooltip
                placement="top"
                hasArrow
                label="Você pode importar
                uma assinatura que já esteja
                vigente e trazê-la para a Barte. Seu cliente passará a receber
                a cobrança automaticamente."
              >
                <span>
                  <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
                </span>
              </Tooltip>
            </Flex>
            <Divider orientation="horizontal" />
          </Flex>
          <Flex
            as="form"
            flexDir="column"
            gap="9"
            onSubmit={handleSubmit(handleSignatureImport)}
          >
            <FormControl flexDir="column">
              <InputSearch
                name="clients"
                label="Clientes"
                placeholder="Nome do cliente"
                setClients={setClients}
                clients={clients}
                selectedClient={clientSelected}
                setSelectedClient={setClientSelected}
              />
            </FormControl>

            <FormControl>
              <FormLabel color="black">
                Plano
                <Tooltip
                  hasArrow
                  label="Para configurar seus planos,
                  vá até a opção no menu Meu Checkout > Meus Planos."
                >
                  <span>
                    <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
                  </span>
                </Tooltip>
              </FormLabel>

              <Flex flexDir="column" align="end">
                <Select
                  borderRadius="2"
                  name="idPlan"
                  {...register("idPlan")}
                  onChange={(e) => handleTogglePlan(Number(e.target.value))}
                >
                  {plansList.length === 0 ? (
                    <option>Sem planos cadastrados</option>
                  ) : (
                    plansList.map((plan) => {
                      return (
                        <option key={plan?.id} value={plan?.id}>
                          {plan?.title}
                        </option>
                      );
                    })
                  )}
                </Select>
                <Button
                  p="0"
                  m="0"
                  h="20px"
                  variant="ghost"
                  fontSize="14px"
                  transition="all .2s"
                  _hover={{
                    transition: "all .2s",
                    textDecoration: "underline"
                  }}
                  onClick={() => setPlanModal(!planModal)}
                >
                  Adicionar plano
                </Button>
              </Flex>
            </FormControl>

            <FormControl>
              <Flex flexDir="column">
                <Input
                  type="text"
                  labelColor="black"
                  color="black"
                  label="Valor"
                  border="1px solid"
                  borderColor="gray.200"
                  placeholder="R$ 100,00"
                  mask="currency"
                  name="valuePerMonth"
                  register={{ ...register("valuePerMonth") }}
                  errors={errors?.valuePerMonth}
                />
                <Text color="gray.300">
                  Valor original:{" "}
                  {formatCurrency({ value: basicValue?.valuePerMonth })}
                </Text>
              </Flex>
            </FormControl>

            <FormControl isInvalid={!!errors?.periodicity}>
              <FormLabel color="black">Periodicidade</FormLabel>
              <Radio
                arrayData={periodicits}
                singleData={periodicitButton}
                setSingleData={setPeriodicitButton}
                h="32px"
                maxW="280px"
              />
            </FormControl>

            <FormControl>
              <FormLabel color="black">Próximo vencimento</FormLabel>
              <DatePickerInput
                locale="ptBR"
                isCustomizableDate={true}
                selectedDate={selectedDate}
                control={control}
                register={{ ...register("startDate") }}
                name="startDate"
                rules={{ required: "Preenchimento obrigatório!" }}
              />
            </FormControl>

            <Divider orientation="horizontal" />

            <FormControl>
              <FormLabel color="black">Métodos de Pagamento</FormLabel>
              <Radio
                arrayData={paymentMethods}
                singleData={paymentMethodButton}
                setSingleData={setPaymentMethodButton}
                h="32px"
                maxW="150px"
              />
            </FormControl>

            <Flex position="relative" justify="center" align="center">
              <Flex
                flexDir="column"
                justify="space-between"
                align="center"
                position="absolute"
                marginTop="14rem"
              >
                <Button
                  isLoading={loading}
                  type="submit"
                  color="white"
                  bgColor="blue.200"
                  borderRadius="2"
                  transition="all .2s"
                  _hover={{
                    filter: "brightness(.9)",
                    transition: "all .2s",
                  }}
                >
                  Salvar
                </Button>
                <Button
                  color="black"
                  variant="ghost"
                  marginTop="1rem"
                  onClick={() => navigate("/recorrencias")}
                >
                  Cancelar
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default WithAuth(SignatureImport);
