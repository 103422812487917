import { flatRoutesList } from "@/Routes";
import styles from "./styles.module.scss";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Link, useLocation } from "react-router-dom";
import { Flex, Image, Text } from "@chakra-ui/react";

export function BreadCrumbs() {
  const breadcrumbs = useBreadcrumbs(flatRoutesList());
  const location = useLocation();
  return (
    <>
      <div className={styles.container}>
        {breadcrumbs.map(({ match, key }, index) => {
          return (
            <div className={styles.item} key={index}>
              {!!breadcrumbs[index - 1]?.match?.route?.breadcrumb &&
              breadcrumbs[index]?.match?.route?.breadcrumb ? (
                <div className={styles.divider} />
              ) : (
                ""
              )}
              <div
                className={`${styles.breadOption} ${
                  index == breadcrumbs.length - 1
                    ? styles.breadActive
                    : styles.breadPrevious
                }`}
              >
                {location.pathname === "/" ? (
                  <Link to={breadcrumbs[0]?.match?.pathname}>
                    <Flex align="center" gap="4">
                      <Image
                        w="24px"
                        h="24px"
                        src={
                          /* @ts-ignore */
                          `/images/icons/${breadcrumbs[0].match?.route?.icon}`
                        }
                      />
                      <Text fontSize="16px">
                        {breadcrumbs[0].match?.route?.breadcrumb}
                      </Text>
                    </Flex>
                  </Link>
                ) : (
                  index > 0 && (
                    <Link to={match.pathname}>
                      <Flex align="center" gap="2">
                        {/* @ts-ignore */}
                        {index === 1 && (
                          <Image
                            w="24px"
                            h="24px"
                            src={
                              /* @ts-ignore */
                              `/images/icons/${match?.route?.icon}`
                            }
                          />
                        )}
                        <Text fontSize="16px">{match?.route?.breadcrumb}</Text>
                      </Flex>
                    </Link>
                  )
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
