import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Select,
  Grid,
  GridItem,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, BackButton, Input, TextArea } from "@/components";
import { WithAuth } from "@/hoc";
import {
  UpdateSubscriptionProps,
  useAlert,
  usePlan,
  useSubscription,
} from "@/hooks";
import { BasicValue, Plan } from "@/models";
import {
  formatSubscription,
  getInitials,
  showToast,
  getColorBadgeStatus,
  getStatus,
  formatCurrency,
  formatCurrencyToApi,
} from "@/utils";
import { CancelSignature } from "@/layouts/cancelSignature";
import { InvoiceSendStatus } from "@/layouts/InvioceSendStatus";
import { AiOutlineInfoCircle } from "react-icons/ai";

export interface SubscriptionFields {
  basicValue: string;
  plan: string;
  periodicity: string;
  bankSlipDescription: string;
}

interface BasicValueProps {
  id?: number;
  type?: string;
  value?: number;
  valuePerMonth?: number;
}

const editSubscriptionSchema = yup.object().shape({
  plan: yup.string(),
  periodicity: yup.string(),
  basicValue: yup.string(),
  bankSlipDescription: yup.string(),
});

function AssinaturaEdit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const periodicits = [
    { id: 1, name: "Mensal", value: "MONTHLY" },
    { id: 2, name: "Semestral", value: "SEMESTER" },
    { id: 3, name: "Anual", value: "YEARLY" },
  ];
  const [subscription, setSubscription] = useState({} as any);
  const [selectedPlan, setSelectedPlan] = useState({} as Plan);
  const [basicValue, setBasicValue] = useState<BasicValueProps>(
    {} as BasicValueProps
  );
  const [selectedPeriodicity, setSelectedPeriodicity] = useState("MONTHLY");

  async function GetSubscriptionData() {
    try {
      const { data } = await getSubscription(String(id));
      const formattedData = formatSubscription(data);
      setValue("plan", data.idPlan);
      setValue("bankSlipDescription", data?.bankSlipDescription);
      setValue("periodicity", formattedData.basicValue.type);
      setValue("basicValue", formattedData.basicValue.formattedValuePerMonth);

      setSubscription(formattedData);
    } catch (error) {}
  }

  useEffect(() => {
    if (id === undefined) return;
    GetSubscriptionData();
  }, [id]);

  const { 
    onClose, 
    showAlert, 
    cancelRef, 
    setShowAlert 
  } = useAlert();

  const { getPlans } = usePlan();

  const { activateAccount, editSubscription, getSubscription } =
    useSubscription();

  const [plansList, setPlansList] = useState<Plan[]>();

  const [updateSubscriptionData, setUpdateSubscriptionData] =
    useState<UpdateSubscriptionProps>({} as UpdateSubscriptionProps);

  const [basicValuesList, setBasicValuesList] = useState<BasicValue[]>();
  const [basicValueActive, setBasicValueActive] = useState();

  const [isEditingPlan, setIsEditingPlan] = useState(false);

  const [isActiveSubscription, setIsActiveSubscription] = useState(false);
  const [isCancelableSubscription, setIsCancelableSubscription] =
    useState(false);
  const initials = getInitials(subscription?.customerName ?? "-");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ resolver: yupResolver(editSubscriptionSchema) });
  const bankSlipDescriptionValue = watch("bankSlipDescription");
  async function getSubscriptionData2() {
    const { data } = await getSubscription(subscription.uuid);

    setSubscription(formatSubscription(data));
    setUpdateSubscriptionData({
      idPlan: data.idPlan,
      bankSlipDescription: data?.bankSlipDescription,
      basicValueRequest: {
        type: data.basicValue.type,
        valuePerMonth: data.basicValue.valuePerMonth,
      },
    });
  }

  async function handleDeactivateSubscription() {
    try {
      await activateAccount(
        isCancelableSubscription ? false : true,
        subscription.uuid
      );

      await getSubscriptionData2();
      onClose();
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
    setIsEditingPlan(false);
  }
  const finishEditPlan: SubmitHandler<SubscriptionFields> = async (values) => {
    let periodicityValue: number;
    if (values.periodicity === "MONTHLY") periodicityValue = 1;
    if (values.periodicity === "SEMESTER") periodicityValue = 6;
    if (values.periodicity === "YEARLY") periodicityValue = 12;
    try {
      const editPlanData = {
        idPlan: Number(values.plan),
        basicValueRequest: {
          type: values.periodicity,
          value: formatCurrencyToApi(values.basicValue) * periodicityValue,
          valuePerMonth: formatCurrencyToApi(values.basicValue),
        },
        bankSlipDescription: values?.bankSlipDescription
      };
      await editSubscription(subscription.uuid, editPlanData);

      await getSubscriptionData2();

      showToast({
        message: "Assinatura atualizadaa com sucesso!",
        type: "success",
      });

      setIsEditingPlan(false);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  };
  async function handleEditSubscription() {
    try {
      setIsEditingPlan(true);
      const { data } = await getPlans();
      setPlansList(data);
      let idPlanActive = data.find((item) => item.active === true).id;
      handleChangeSelectedPlan(idPlanActive, data);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  function handleChangeSelectedPlan(idPlan: number, plans?: any) {
    setUpdateSubscriptionData({
      ...updateSubscriptionData,
      idPlan,
    });

    let plansList = plans;
    const plan = plansList?.find((plan) => plan.id === idPlan);

    setBasicValuesList(plan.basicValues);

    let basicValueActive = plan.basicValues.find(
      (item) => item.type === subscription.basicValue.type
    );
    setBasicValueActive(basicValueActive);
  }

  // async function getPlanList(id: number) {
  //   const response = await getPlans();
  //   setPlansList(response.data);
  //   handleTogglePlan(id, response.data);
  // }

  function handleTogglePlan(e: number, plans?: Array<Plan>) {
    const plansListCurrent = plans || plansList;
    const planMatch = plansListCurrent?.filter((plans) => {
      return plans?.id === e;
    });
    setSelectedPlan(planMatch[0]);
    setValue("idPlan", planMatch[0]?.id);
  }

  const closeEditMode = () => {
    return async (event: React.MouseEvent) => {
      await GetSubscriptionData();
      setIsEditingPlan(false);
      event.preventDefault();
    };
  };

  useEffect(() => {
    setIsActiveSubscription(
      subscription.formattedStatus === "Ativo" ? true : false
    );

    setIsCancelableSubscription(
      subscription.formattedStatus === "Ativo" ||
        subscription.formattedStatus === "Inadimplente" ||
        subscription.formattedStatus === "Pendente"
        ? true
        : false
    );
    setValue("plan", subscription.idPlan);
  }, [subscription]);

  function getBasicValuesOfSelectedPlan() {
    if (selectedPlan) {
      const baseValues = selectedPlan?.basicValues?.filter(
        (data) => data.type === selectedPeriodicity
      );
      if (baseValues?.length > 0) {
        setBasicValue(baseValues[0]);
        setValue(
          "basicValue",
          formatCurrency({ value: baseValues[0]?.valuePerMonth })
        );
      }
    }
  }

  useEffect(() => {
    getBasicValuesOfSelectedPlan();
  }, [selectedPlan]);

  useEffect(() => {
    getBasicValuesOfSelectedPlan();
    setValue("periodicity", selectedPeriodicity);
  }, [selectedPeriodicity]);

  return (
    <Box>
      <Box my={4}>
        <BackButton />
      </Box>

      <Divider />

      <Flex align="center" my="8" gap="10rem">
        <Flex align="center" gridGap="4">
          <Badge
            fontSize="1rem"
            minWidth="35px"
            minHeight="35px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            colorScheme="yellow"
            color="gray.800"
          >
            {initials}
          </Badge>

          <Text color="gray.800" fontWeight="600" fontSize="25px">
            {subscription.customerName}
          </Text>
        </Flex>

        <CancelSignature
          isCancelableSubscription={isCancelableSubscription}
          setShowAlert={setShowAlert}
        />
      </Flex>

      <Divider />

      <Text as="h2" fontSize="20" fontWeight="600" mt="8">
        Informações gerais
      </Text>

      <SimpleGrid columns={4} minChildWidth={116} gap={4} my="8">
        <Flex direction="column" gridGap="1">
          <Text color="gray.300" fontSize="14px">
            Código da recorrência
          </Text>

          <Text color="gray.800" fontSize="14px">
            {subscription.uuid}
          </Text>
        </Flex>

        <Flex direction="column" gridGap="1">
          <Text color="gray.300" fontSize="14px">
            Contratação
          </Text>

          <Text color="gray.800" fontSize="14px">
            {subscription.formattedStartDate}
          </Text>
        </Flex>

        <Flex direction="column" align="flex-start" gridGap="1">
          <Text color="gray.300" fontSize="14px">
            Status da recorrência
          </Text>

          <Badge
            variant={getColorBadgeStatus(subscription.status)}
            fontSize="14px"
          >
            {getStatus(subscription.status)}
          </Badge>
        </Flex>

        {subscription.activeInvoiceConfig && !subscription.cancelledDate ? (
          <InvoiceSendStatus status={subscription.invoiceEnabled} />
        ) : (
          !subscription.cancelledDate && <Text></Text>
        )}

        {subscription.cancelledDate && (
          <Flex
            direction="column"
            align="flex-start"
            gridGap="1"
            marginBottom="2rem"
          >
            <Text color="gray.300" fontSize="14px">
              Data do cancelamento
            </Text>
            <Text color="red.700">{subscription.cancelledDate}</Text>
          </Flex>
        )}
      </SimpleGrid>

      <Divider />

      <Grid templateColumns="repeat(4, 1fr)" gap={4} my="8">
        <GridItem colSpan={2}>
          <Text as="h2" fontSize="20" fontWeight="600">
            Detalhes da recorrência
          </Text>
        </GridItem>

        <GridItem>
          {isEditingPlan && (
            <Button
              variant="ghost"
              color={"gray.800"}
              onClick={closeEditMode()}
            >
              Descartar alterações
            </Button>
          )}
        </GridItem>

        <GridItem>
          {isActiveSubscription && (
            <Button
              variant="ghost"
              color={"gray.800"}
              onClick={
                isEditingPlan
                  ? handleSubmit(finishEditPlan)
                  : handleEditSubscription
              }
            >
              {isEditingPlan ? "Salvar alterações" : "Editar assinatura"}
            </Button>
          )}
        </GridItem>
      </Grid>

      <SimpleGrid columns={4} minChildWidth={116} gap={4} my="8">
        <Flex direction="column" gridGap="1">
          <Text color="gray.300" fontSize="14px">
            Plano
          </Text>
          {isEditingPlan ? (
            <Select
              name="plan"
              {...register("plan")}
              bg="gray.25"
              border="none"
              onChange={(e) => handleTogglePlan(Number(e.target.value))}
            >
              {plansList?.length === 0 ? (
                <option>Sem planos cadastrados</option>
              ) : (
                plansList?.map((plan) => {
                  return (
                    <option key={plan?.id} value={plan?.id}>
                      {plan?.title}
                    </option>
                  );
                })
              )}
            </Select>
          ) : (
            <Text color="gray.800" fontSize="14px">
              {subscription.titlePlan}
            </Text>
          )}
        </Flex>

        <Flex direction="column" gridGap="1">
          <Text color="gray.300" fontSize="14px">
            Periodicidade
          </Text>

          {isEditingPlan ? (
            <Select
              name="periodicity"
              onChange={(e) => setSelectedPeriodicity(e.target.value)}
              defaultValue={subscription.basicValue?.type}
              bg="gray.25"
              border="none"
            >
              {periodicits.map((periodicity) => (
                <option key={periodicity?.id} value={periodicity?.value}>
                  {periodicity?.name}
                </option>
              ))}
            </Select>
          ) : (
            <Text color="gray.800" fontSize="14px">
              {subscription.basicValue?.formattedType}
            </Text>
          )}
        </Flex>

        <Flex direction="column" gridGap="1">
          <Text color="gray.300" fontSize="14px">
            Valor/mês
          </Text>

          <Text color="gray.800" fontSize="14px">
            {isEditingPlan ? (
              <Input
                type="text"
                name="basicValue"
                bg="gray.25"
                border="none"
                mask="currency"
                defaultValue={subscription.basicValue?.formattedValuePerMonth}
                register={{ ...register("basicValue") }}
              />
            ) : (
              subscription.basicValue?.formattedValuePerMonth
            )}
          </Text>
        </Flex>

        <Text></Text>
      </SimpleGrid>

      <SimpleGrid columns={4} minChildWidth={116} gap={4} my="8">
        <Flex direction="column" gridGap="1">
          <Text color="gray.300" fontSize="14px">
            Método de pagamento
          </Text>

          <Text color="gray.800" fontSize="14px">
            {subscription.paymentMethod}
          </Text>
        </Flex>
        {subscription?.additionalValue && (
          <Flex direction="column" gridGap="1">
            <Text color="gray.300" fontSize="14px">
              Valor adicional
            </Text>
            <HStack>
              <Text color="gray.800" fontSize="14px">
                {formatCurrency({ value: subscription.additionalValue?.value })}
              </Text>
              <Text>
                (
                {subscription.additionalValue.installments > 1 &&
                  formatCurrency({
                    value:
                      subscription.additionalValue?.value /
                      subscription.additionalValue?.installments,
                  })}{" "}
                x {subscription.additionalValue?.installments})
              </Text>
            </HStack>
          </Flex>
        )}
        
        {subscription?.paymentMethod === "Boleto" && (
          <Flex direction="column" gridGap="1">
            <Flex 
              gap="2"
            >
              <Text color="gray.300" fontSize="14px">
                Descrição do boleto
              </Text>
              <Tooltip 
                placement="top" 
                hasArrow 
                label="Essa descrição parece nas informações do boleto emitido"
              >
                <span>
                  <Icon 
                    w=".8rem" 
                    color="gray.400" 
                    as={AiOutlineInfoCircle} 
                  />
                </span>
              </Tooltip>
            </Flex>
            
            <HStack>
              <TextArea 
                name="bankSlipDescription"
                color="gray.800" 
                fontSize="14px" 
                register={{ ...register("bankSlipDescription")}}
                maxLength={130}
                currentLength={bankSlipDescriptionValue?.length}
                isDisabled={!isEditingPlan}
                _disabled={{
                  border: "none",
                  resize: "none",
                  padding: "0",
                }}
              />
            </HStack>
          </Flex>
        )}
        <Flex direction="column" gridGap="1">
          {/* <Text color="gray.300" fontSize="14px">
            Voucher
          </Text>
          <Text color="gray.800" fontSize="14px">
            FRGX2021
          </Text> */}
        </Flex>

        <Text></Text>
      </SimpleGrid>

      <Divider />

      <Text as="h2" fontSize="20" fontWeight="600" mt="8">
        Últimas cobranças
      </Text>

      <Table>
        <Thead>
          <Tr>
            <Th
              w="295px"
              margin="0"
              padding="1rem 0"
              my="8"
              color="gray.300"
              fontSize="14px"
              fontWeight="normal"
            >
              Vencimento
            </Th>
            <Th
              w="295px"
              margin="0"
              padding="1rem 0"
              my="8"
              color="gray.300"
              fontSize="14px"
              fontWeight="normal"
            >
              Valor
            </Th>
            <Th
              w="295px"
              margin="0"
              padding="1rem 0"
              my="8"
              color="gray.300"
              fontSize="14px"
              fontWeight="normal"
            >
              Status
            </Th>
            <Th w="295px" />
          </Tr>
        </Thead>

        <Tbody>
          {subscription.charges?.map(
            ({ formattedExpirationDate, status, uuid, formattedValue }) => (
              <Tr
                key={uuid}
                cursor="pointer"
                onClick={() => navigate(`/cobrancas-historico/${uuid}`)}
              >
                <Td w="295px" margin="0" padding="1rem 0" my="8">
                  {formattedExpirationDate}
                </Td>
                <Td w="295px" margin="0" padding="1rem 0" my="8">
                  {formattedValue}
                </Td>
                <Td w="295px" margin="0" padding="1rem 0" my="8">
                  {status ? (
                    <Badge
                      fontSize="1rem"
                      variant={getColorBadgeStatus(status)}
                    >
                      {getStatus(status)}
                    </Badge>
                  ) : (
                    "-"
                  )}
                </Td>
                <Td w="295px" />
              </Tr>
            )
          )}
        </Tbody>
      </Table>

      <Alert
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        onConfirm={handleDeactivateSubscription}
        isOpen={showAlert}
        title="Confirmar ação?"
        messageBody="Tem certeza que deseja alterar o status da recorrência?"
      />
    </Box>
  );
}

export default WithAuth(AssinaturaEdit);
