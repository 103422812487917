

import { IconProps } from "@/models";


export function SunIcon({ size = 16 }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2808_2873)">
        <path d="M7.99935 1.16669V2.50002M7.99935 14.5V15.8334M2.81268 3.31335L3.75935 4.26002M12.2393 12.74L13.186 13.6867M0.666016 8.50002H1.99935M13.9993 8.50002H15.3327M2.81268 13.6867L3.75935 12.74M12.2393 4.26002L13.186 3.31335M11.3327 8.50002C11.3327 10.341 9.8403 11.8334 7.99935 11.8334C6.1584 11.8334 4.66602 10.341 4.66602 8.50002C4.66602 6.65907 6.1584 5.16669 7.99935 5.16669C9.8403 5.16669 11.3327 6.65907 11.3327 8.50002Z" stroke="#1E1E1E" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_2808_2873">
            <rect width={size} height={size} fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
    </svg>
  )
}