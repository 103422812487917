import { Container, TitleSection, BodySection, FooterSection } from "./styles";
import { FiX } from "react-icons/fi"
import { Button, CancelButton, GhostButton } from "@/styles/components/Buttons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Input, ActionInput } from "@/components";
import { SubmitHandler, useForm } from "react-hook-form";
import { useClient } from "@/hooks";
import { clearMask, showToast } from "@/utils";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { ClientsCreateData } from "@/models";
import { Switch, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@/styles/icons/InfoIcon";
import { PhoneInput } from "@/components/Forms/PhoneInput";
import { DocumentInput } from "@/components/Forms/DocumentInput";

interface AddClientProps {
    onClose?: () => void;
    isModal?: boolean;
    hasNewClients?: boolean;
    setHasNewClients?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AddClient({ hasNewClients, setHasNewClients, onClose, isModal = false }: AddClientProps) {
    const [isCnpjCpfValidationLoading, setIsCnpjCpfValidationLoading] = useState(false);
    const [isCnpjValidate, setIsCnpjValidate] = useState(false);
    const [isCpfValidate, setIsCpfValidate] = useState(false);
    const [isCreateNewClientLoading, setIsCreateNewClientLoading] = useState(false);
    const navigate = useNavigate()
    const {
        register,
        watch,
        setValue,
        setError,
        clearErrors,
        getValues,
        formState: { errors },
    } = useForm();
    const [section, setSection] = useState(1);
    const [isCepLoading, setIsCepLoading] = useState(false);
    const [isUseMyAddress, setIsUseMyAddress] = useState(false);
    const documentValue = watch("document");
    const documentType = watch("documentType");
    const documentNation = watch("documentNation");
    const {
        consultCep,
        consultCNPJ,
        postClient,
    } = useClient();
    const cep = watch("zipCode");
    const buyerFields = ["nameBuyer", "document", "email", "phone"];
    const addressFields = ["zipCode", "street", "number", "district", "city", "state", "country"];
    const validateRequiredFields = () => {
        const requiredFields = [...buyerFields, ...addressFields]
        const emptyFields = [];
        requiredFields.forEach(
            (item) => {
                if (isUseMyAddress && addressFields.includes(item)) return
                if (getValues(item) === "" || getValues(item) === undefined) {
                    setError(item, { type: "custom", message: "Peenchimento obrigatório!" });
                    emptyFields.push(item);
                    if (buyerFields.includes(item)) return setSection(1);
                    if (!buyerFields.includes(item) && addressFields.includes(item)) return
                }
            }
        )
        return emptyFields;
    }


    async function handleGetCep() {
        try {
            clearErrors("zipCode")
            setIsCepLoading(true);
            const { data } = await consultCep(clearMask(cep));
            setValue("street", data.logradouro);
            setValue("district", data.bairro);
            setValue("city", data.localidade);
            setValue("state", data.uf);
            setValue("complement", data.complemento);
            setValue("country", "BR");
        } catch {
            if (cep.length !== 10) return setError("zipCode", { type: "custom", message: "Cep inválido." })
        } finally {
            setIsCepLoading(false);
        }
    }

    async function handleGetCnpj() {
        try {
            const { data } = await consultCNPJ(clearMask(documentValue));
            setValue("nameBuyer", data?.companyName);
            setValue("fantasyName", data?.fantasyName);
            setValue("street", data?.address?.street);
            setValue("district", data?.address?.district);
            setValue("city", data?.address?.city);
            setValue("state", data?.address?.state);
            setValue("country", data?.address?.country);
            setValue("zipCode", data?.address?.zipCode);
        } catch {

        }
    }

    async function handleValidateCpfCnpj() {
        if(documentNation !== "BR") {
            clearErrors("document");
            return
        }
        try {
            clearErrors("document");
            setIsCnpjCpfValidationLoading(true);
            if (documentType === "cpf" && cpf.isValid(documentValue)) return setIsCpfValidate(true);
            if (documentType === "cnpj" && cnpj.isValid(documentValue)) {
                await handleGetCnpj();
                setIsCnpjValidate(true);
                return;
            }
            if (!cpf.isValid(documentValue) || !cnpj.isValid(documentValue)) return setError("document", { type: "custom", message: "Inclua um CNPJ ou CPF válido" })
        } catch {

        } finally {
            setIsCnpjCpfValidationLoading(false);
        }
    }

    const onSubmit: SubmitHandler<ClientsCreateData> = async (values) => {
        const requiredFields = validateRequiredFields();
        if (requiredFields.length !== 0) return
        setIsCreateNewClientLoading(true);
        const clientData = {
            document:{
                documentNumber: clearMask(values.document),
                documentType: values.documentType,
                documentNation: values.documentNation,
            },
            name: values.nameBuyer,
            email: values.email,
            countryCode: values.countryCode,
            phone: clearMask(values.phone),
            alternativeEmail: values.alternativeEmail,
            address: isUseMyAddress ? null : {
                zipCode: clearMask(values.zipCode),
                street: values.street,
                number: values.number,
                complement: values.complement,
                district: values.district,
                city: values.city,
                state: values.state,
                country: values.country,
            }
        };
        try {
            await postClient(clientData);
            showToast({
                message: "Cliente cadastrado com sucesso!",
                type: "success",
            });
            
            [...buyerFields, ...addressFields].forEach(
                (item) => {
                    setValue(item, "");
                }
                );
                setHasNewClients(!hasNewClients)

        } catch (err) {
            err.response.data.errors.forEach((item) => {
                showToast({
                    message: item.description,
                    type: "error",
                });
            });
        } finally {
            setIsCreateNewClientLoading(false);
            hasNewClients !== undefined ? onClose() : navigate("/clientes"), onClose();

        }

    }

    const sections = {
        BACK() {
            setSection(section - 1);
        },
        NEXT() {
            setSection(section + 1);
        },
        LAST() {
            const values = getValues();
            onSubmit(values);
        },
    };

    useEffect(() => {
        setIsCnpjValidate(false);
        setIsCpfValidate(false);
    }, [watch("document")]);

    return (
        <Container>
            <TitleSection>
                <h1>Adicionar cliente</h1>
                {isModal && (
                    <button onClick={onClose}>
                        <FiX size={20} />
                    </button>
                )}
            </TitleSection>
            {section === 1 && (
                <BodySection>
                    <h1>Identificação e contato</h1>
                    {/* <ActionInput
                        name="document"
                        label="CNPJ/CPF"
                        type="text"
                        register={{ ...register("document") }}
                        onSubmit={handleValidateCpfCnpj}
                        isSubmitting={isCnpjCpfValidationLoading}
                        errors={errors?.document}
                    /> */}
                    <DocumentInput
                        name="document"
                        fixedLabel="Documento "
                        register={{ ...register("document") }}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors?.document}
                        height="40px"
                        fontSize="1rem"
                        onBlurCapture={handleValidateCpfCnpj}
                        mask={documentType}
                    />
                    <>
                        <Input
                            name="nameBuyer"
                            label={documentType?.length > 11 ? "Razão Social" : "Nome do cliente"}
                            type="text"
                            register={{ ...register("nameBuyer") }}
                            errors={errors?.nameBuyer}
                        />
                        <PhoneInput
                            name="phone"
                            label="Telefone Celular"
                            type="cellphone"
                            register={{ ...register("phone") }}
                            setValue={setValue}
                            defaultCountryCodeValue="+55"
                            mask="cellphone"
                            errors={errors?.phone}
                        />
                        <Input
                            name="email"
                            label="Email"
                            type="text"
                            register={{ ...register("email") }}
                            errors={errors?.email}
                        />
                        <Input
                            name="alternativeEmail"
                            label="Email financeiro (opcional)"
                            type="text"
                            register={{ ...register("alternativeEmail") }}
                        />
                    </>
                </BodySection>
            )}
            {section === 2 && (
                <BodySection>
                    <h1>Endereço</h1>
                    <Input
                        name="zipCode"
                        label="CEP"
                        type="text"
                        register={{ ...register("zipCode") }}
                        onBlur={handleGetCep}
                        mask="cep"
                        errors={errors?.zipCode}
                        isDisabled={isUseMyAddress}
                        _disabled={{
                            bgColor: "neutral.3"
                        }}
                    />
                    <Input
                        name="street"
                        label="Rua"
                        type="text"
                        register={{ ...register("street") }}
                        isDisabled={isCepLoading || isUseMyAddress}
                        errors={errors?.street}
                        _disabled={{
                            bgColor: "neutral.3"
                        }}
                    />
                    <Input
                        name="number"
                        label="Número"
                        type="text"
                        register={{ ...register("number") }}
                        isDisabled={isCepLoading || isUseMyAddress}
                        errors={errors?.number}
                        _disabled={{
                            bgColor: "neutral.3"
                        }}
                    />
                    <Input
                        name="complement"
                        label="Complemento (opcional)"
                        type="text"
                        register={{ ...register("complement") }}
                        isDisabled={isCepLoading || isUseMyAddress}
                        _disabled={{
                            bgColor: "neutral.3"
                        }}
                    />
                    <Input
                        name="district"
                        label="Bairro"
                        type="text"
                        register={{ ...register("district") }}
                        isDisabled={isCepLoading || isUseMyAddress}
                        errors={errors?.district}
                        _disabled={{
                            bgColor: "neutral.3"
                        }}
                    />
                    <Input
                        name="city"
                        label="Cidade"
                        type="text"
                        register={{ ...register("city") }}
                        isDisabled={isCepLoading || isUseMyAddress}
                        errors={errors?.city}
                        _disabled={{
                            bgColor: "neutral.3"
                        }}
                    />
                    <Input
                        name="state"
                        label="Estado"
                        type="text"
                        register={{ ...register("state") }}
                        isDisabled={isCepLoading || isUseMyAddress}
                        errors={errors?.state}
                        _disabled={{
                            bgColor: "neutral.3"
                        }}
                    />
                    <Input
                        name="country"
                        label="País"
                        type="text"
                        register={{ ...register("country") }}
                        isDisabled={isCepLoading || isUseMyAddress}
                        errors={errors?.country}
                        _disabled={{
                            bgColor: "neutral.3"
                        }}
                    />
                    <span>
                        <p>Usar o meu endereço no lugar do endereço do cliente</p>
                        <Tooltip
                            label="Utilizaremos o seu endereço no lugar do endereço do cliente na emissão de boletos e Notas Fiscais"
                        >
                            <span>
                                <InfoIcon
                                    size={14}
                                    color="#000000"
                                    opacity="0.4"
                                />
                            </span>
                        </Tooltip>
                        <Switch
                            isChecked={isUseMyAddress}
                            onChange={() => {
                                clearErrors();
                                setIsUseMyAddress(!isUseMyAddress);
                            }}
                        />
                    </span>
                </BodySection>
            )}
            <FooterSection>

                <CancelButton
                    isHidden={!isModal}
                    onClick={onClose}
                >
                    Cancelar
                </CancelButton>
                <div>
                    {section > 1 && (
                        <GhostButton
                            onClick={() => sections.BACK()}
                        >
                            Voltar
                        </GhostButton>
                    )}
                    {(section === 1 && documentNation === "BR") && (
                        <Button
                            onClick={() => sections.NEXT()}
                            disabled={(documentType === "cpf") ? !isCpfValidate : !isCnpjValidate}
                        >
                            Proximo
                        </Button>
                    )}
                    {(section === 1 && documentNation !== "BR") && (
                        <Button
                            onClick={() => sections.NEXT()}
                        >
                            Proximo
                        </Button>
                    )}
                    {section === 2 && (
                        <Button
                            onClick={() => sections.LAST()}
                        >
                            Salvar
                        </Button>
                    )}
                </div>
            </FooterSection>
        </Container>
    )
}