import { useState } from "react";
import { Flex, Stack, Text, Button, Image } from "@chakra-ui/react";
import { ConfirmationModal } from "@/components";
import { useSettings } from "@/hooks";
import { showToast } from "@/utils";

const NotaFiscalActiveState = () => {
  const [isDesactiveModalOpen, setIsDesactiveModalOpen] = useState(false);
  const { desactivateSellerInvoice } = useSettings();
  const [loading, setLoading] = useState(false);
  async function desactivateInvoice() {
    setLoading(true);
    try {
      await desactivateSellerInvoice();
      showToast({
        message: "Nota fiscal desativado com sucesso!",
        type: "success",
      });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <ConfirmationModal
        title="Desativar nota fiscal?"
        isOpen={isDesactiveModalOpen}
        onClose={() => setIsDesactiveModalOpen(!isDesactiveModalOpen)}
        isSubmitting={loading}
        handleConfirmModal={desactivateInvoice}
        yesNoText
      >
        <Text>
          Para retomar o funcionamento da Nota Fiscal você deverá agendar uma
          configuração novamente com nosso time de suporte.
        </Text>
      </ConfirmationModal>

      <Flex w="100%" py="8" justifyContent="center">
        <Stack spacing={12}>
          <Flex align="center" direction="column">
            <Image
              maxW="135px"
              w="100%"
              src="/images/commons/invoiceActiveImage.svg"
            />
            <Text fontWeight="600">Sua nota está configurada e operando!</Text>
          </Flex>
          <Button
            variant="link"
            color="danger"
            onClick={() => setIsDesactiveModalOpen(!isDesactiveModalOpen)}
          >
            Desativar nota fiscal
          </Button>
        </Stack>
      </Flex>
    </>
  );
};

export default NotaFiscalActiveState;
