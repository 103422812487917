import { Image } from "@chakra-ui/react";

const PaidTotalIcon = () => {
  return(
    <Image
      maxW="20px"
      src="/images/icons/paidTotalIcon.svg"
      transition="filter .2s"
    />
  )
}

export { PaidTotalIcon }