import { useEffect, useState } from "react";
import { ClientData } from "@/models";
import styles from "./styles.module.scss";

interface subsellers {
    name?: string;
    id?: any;
    index?: any
    value?: any;
}


interface ClientSelectorProps {
    name: string;
    index?: any;
    value?: any;
    register?: any;
    label?: string;
    optional?: boolean;
    initialClient?: any;
    idClient?: boolean;
    // onChange?:React.ChangeEvent<HTMLInputElement>
    setSelectedClient?: React.Dispatch<React.SetStateAction<subsellers>>;
    clients?: Array<subsellers>;
    setClientList?: React.Dispatch<React.SetStateAction<Array<subsellers>>>;
    clientList?: Array<subsellers>;
}

export function InputSearchSubSeller({
    name,
    index,
    value,
    label,
    optional = false,
    clients,
    // onChange,
    setSelectedClient,
    initialClient,
    idClient = false,
}: ClientSelectorProps) {

    const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
    const [filteredClients, setFilteredClients] = useState([]);
    const [selected, setSelected] = useState<subsellers>({ name: "", value: '', id: '', index: "" },)

    useEffect(() => {
        if (!clients) return;
        setFilteredClients(clients);
    }, [clients]);

    function handleDropDownToggleState() {
        setTimeout(() => {
            setIsClientDropdownOpen(!isClientDropdownOpen);
        }, 200);
    }

    function selectClient(client: any) {
        setSelectedClient({ ...client, index });
        setSelected(client)
        setIsClientDropdownOpen(false);
    }

    function handleSearchBox(e: string) {
        if (clients?.length > 0) {
            if (clients[0]?.name) {
                setIsClientDropdownOpen(true);
                setFilteredClients(clients?.filter(({ name }: any) => name.toLowerCase().includes(e.toLowerCase())));
            }
        } else {
            setIsClientDropdownOpen(false);
        }

        if (!selected?.name) {
            setSelectedClient({} as ClientData);
        }
    }
    
    useEffect(() => {
        if (selected.name != "") {
            setSelectedClient(selected)
        }
    }, [selected])

    return (
        <>
            <div className={styles.container}>
                {label && <span>{`${label} ${optional ? "(Opcional)" : ""}`}</span>}
                <input
                    className={`${styles.clientInput}`}
                    onBlurCapture={handleDropDownToggleState}
                    onFocusCapture={handleDropDownToggleState}
                    autoComplete="off"
                    value={value ? value : selected.name}
                    onChange={(e) => {
                        handleSearchBox(e.target.value), setSelected({
                            name: e.target.value,
                        });
                    }}
                />
                {isClientDropdownOpen && (
                    <div className={`${styles.dropDown}`}>
                        <ul>

                            {filteredClients?.map((client) => (
                                <li
                                    key={client.id}
                                    onClick={() => selectClient(client)}
                                >
                                    {client.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </>
    )
}