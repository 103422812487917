import ApexCharts from "apexcharts";
import ChartApex from "react-apexcharts";
import { formatCurrency } from "@/utils";
import { Graph } from "@/models";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { GetChartModel } from "./_chart.model";

interface ChartProps extends Graph {
  isActualMonth: boolean;
}

export function Chart({ lines, period, isActualMonth }: ChartProps) {
  const [today, setToday] = useState<number>();
  const [todayLabelPositionX, setTodayLabelPositionX] = useState<number>();
  const [todayLabelPositionY, setTodayLabelPositionY] = useState<number>();
  const optionsChart = GetChartModel({
    period,
    todayLabelPositionX,
    todayLabelPositionY,
  });

  function getToday() {
    if (period.length > 7) {
      const getToday = new Date().getDate();
      setToday(getToday);
    } else {
      const getToday = format(new Date(), "yyyy-MM-dd");

      const findIndexToday = period.findIndex((item) => item === getToday);

      setToday(findIndexToday + 1);
    }
  }

  function getXPositionLabelToday() {
    if (isActualMonth) {
      const todayXPosition = new Date(`${period[today - 1]}`).getTime();
      setTodayLabelPositionX(todayXPosition);
    } else {
      setTodayLabelPositionX(null);
    }
  }

  function getYPositionLabelToday() {
    if (isActualMonth) {
      const sumValuesToday = lines
        .map((item) => item.data[today - 1])
        .filter((item2) => item2)
        .reduce((acc, total) => acc + total, 0);

      setTodayLabelPositionY(sumValuesToday);
    } else {
      setTodayLabelPositionY(null);
    }
  }

  useEffect(() => {
    getToday();
    getXPositionLabelToday();
    getYPositionLabelToday();
  });

  return (
    <ChartApex
      width="100%"
      height="100%"
      options={optionsChart}
      series={lines}
      type="bar"
    />
  );
}
