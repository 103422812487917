import { IconProps } from "@/models";

export function RandomIcon({
  size = 20,
  color = "#000",
  opacity = "1",
}: IconProps) {
  return (
    <svg 
        width={size} 
        height={size}
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M20.625 2.625H3.375C2.96016 2.625 2.625 2.96016 2.625 3.375V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V3.375C21.375 2.96016 21.0398 2.625 20.625 2.625ZM19.6875 19.6875H4.3125V4.3125H19.6875V19.6875Z" 
            fill={color} 
            fillOpacity={opacity}
        />
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M14.9473 11.5729L15.5029 11.0173L16.9737 9.54648L15.5029 8.0757L14.9473 7.52002V8.63137V8.74834H12.8629H12.3998L12.1691 9.14995L11.4535 10.3962L10.7378 9.15004L10.5072 8.74843H10.0441H7.43994V10.3484H9.58097L10.5309 12.0027L9.58097 13.6569H7.43994V15.2569H10.0441H10.5072L10.7378 14.8553L11.4535 13.6092L12.1691 14.8554L12.3998 15.257H12.8629H14.9473V15.3678V16.4792L15.5029 15.9235L16.9737 14.4527L15.5029 12.9819L14.9473 12.4263V13.5376V13.657H13.326L12.376 12.0027L13.326 10.3483H14.9473V10.4616V11.5729Z" 
            fill={color}
            fillOpacity={opacity}
        />
    </svg>
  );
}
