import { Input, Select, NewAlert } from "@/components";
import { formatCurrency, formatCurrencyToApi, getApplicationName, installments, showToast } from "@/utils";
import { PaymentMethod } from "@/layouts/SejaPago/PaymentMethod";
import { useState, useRef, useEffect } from "react";
import { PaymentMethodTest, PunctualChargeProps } from "@/models";
import { useCustomLink } from "@/hooks";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Flex, Icon, Radio, RadioGroup, Text, Tooltip } from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RadioInput } from "@/layouts/SejaPago/RadioInput";
import { useNavigate } from "react-router-dom";

const LeanCheckoutSchema = yup
  .object()
  .shape({
    title: yup.string().required("Preenchimento obrigatório!").max(60, 'O título deve ter no máximo 60 caracteres!'),
    value: yup.string().required("Preenchimento obrigatório!"),
    installments: yup.number()
  })

export function LeanCheckout({
  asEdition,
  initialData
}: PunctualChargeProps) {
  const [dateSwitchState, setDateSwitchState] = useState(false);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [creditCardType, setCreditCardType] = useState<string>(
    initialData?.allowedPaymentMethods?.creditCardMethod?.type && asEdition
      ? initialData?.allowedPaymentMethods?.creditCardMethod?.type
      : "EARLY_BUYER"
  );
  const [taxText, setTaxText] = useState("As taxas de parcelamento serão repassadas ao seu cliente");
  const [anchoringState, setAnchoringState] = useState(
    initialData?.enableAnchoring ? initialData?.enableAnchoring : false
  );
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [uuid, setUuid] = useState(
    window.location.search.split("?id=").join("")
  );
  const {
    postCustomLink,
    getCustomLinkData,
    putCustomLink,
  } = useCustomLink();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(LeanCheckoutSchema),
  });
  const applicationName = getApplicationName({});
  const cancelRef = useRef();
  const navigate = useNavigate();
  function handleChangeTax(value: string) {
    setCreditCardType(value);
    setTaxText(value === "EARLY_BUYER" ? "As taxas de parcelamento serão repassadas ao seu cliente" : "Você assume as taxas de parcelamento");
  }
  const handleCreateLeanCheckout: SubmitHandler<any> = async (values) => {
    const formattedValue = formatCurrencyToApi(values?.value);
    const leanCheckoutDTO = {
      type: "FAST_ORDER",
      paymentOrder: {
        title: values?.title,
        value: formattedValue,
        installments: Number(values?.installments),
        description: ""
      },
      allowedPaymentMethods: {
        creditCardMethod: {
          type: creditCardType
        }
      },
    }
    try {
      if (asEdition) {
        const { data } = await putCustomLink(
          initialData?.uuid,
          leanCheckoutDTO
        );
        setUrl(`${import.meta.env.VITE_CHECKOUT_URL}payment-link/${initialData?.uuid}`)
      } else {
        const { data } = await postCustomLink(leanCheckoutDTO);
        setUrl(`${import.meta.env.VITE_CHECKOUT_URL}payment-link/${data?.uuid}`)
      }
      setConfirmModalState(!confirmModalState);
    } catch (error) {
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }
  function handleSetOrderValues(data: any) {
    setValue("title", data?.paymentOrder?.title);
    setValue(
      "value",
      formatCurrency({ value: Number(data?.paymentOrder?.value) })
    );
    setValue("installments", data?.paymentOrder?.installments);
    setValue("enableCreditCard", data?.allowedPaymentMethods?.creditCardMethod);
    setUrl(data.url);
    setUuid(data.uuid);
  }
  async function handleGetDataToDuplicate(uuid: string) {
    try {
      const { data } = await getCustomLinkData(uuid);
      handleSetOrderValues(data);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }
  async function getInitialData() {
    if (!initialData) return setValue("installments", 10)
    if (initialData) return handleSetOrderValues(initialData);
    if (uuid) {
      try {
        handleGetDataToDuplicate(uuid);
        return;
      } catch (error) {
        error?.response?.data?.errors.map((error) => {
          showToast({
            type: "error",
            message: `${error?.code} - ${error?.description}`
          })
        });
      }
    }
  }
  useEffect(() => {
    getInitialData();
  }, []);
  return (
    <>
      <NewAlert
        isConfirmAlert={true}
        isOpen={confirmModalState}
        onConfirm={() => (
          setConfirmModalState(!confirmModalState), navigate("/seja-pago")
        )}
        onClose={() => setConfirmModalState(!confirmModalState)}
        title="Link Rápido criado com sucesso"
        urlBody={url}
        confirmButtonText="Voltar para a Home"
        leastDestructiveRef={cancelRef}
        generateQrCodeFromUrl
      />
      <Flex
        justify="center"
        height="100vh"
      >
        <Flex
          as="form"
          onSubmit={handleSubmit(handleCreateLeanCheckout)}
          flexDir="column"
          width="100%"
          maxWidth="440px"
          height="100%"
          maxHeight="361px"
          gap="24px"
          autoComplete="off"
        >
          <Text
            fontWeight="600"
            fontSize="20px"
            lineHeight="27.98px"
            color="black"
          >
            Crie seu link rápido
          </Text>
          <Flex
            flexDir="column"
            gap="16px"
            border="1px solid #E6E9F0"
            borderRadius="4px"
            padding="16px 24px"
          >
            <Input
              label="Título"
              type="text"
              name="title"
              register={{ ...register("title") }}
              errors={errors?.title}
            />
            <Input
              label="Valor"
              mask="currency"
              type="text"
              name="value"
              register={{ ...register("value") }}
              errors={errors?.value}
            />
            <Select
              label="Parcelamento máximo"
              name="installments"
              optionsList={installments}
              register={{ ...register("installments") }}
              setValue={setValue}
            />
            <RadioGroup
              onChange={(e) => handleChangeTax(e)}
              value={creditCardType}
            >
              <Flex flexDir="column" >
                <Flex flexDir="column">
                  <Flex align="center" gap=".3rem">
                    <Text lineHeight="1.5rem" fontSize="12px">
                      Taxas de Parcelamento
                    </Text>
                    <Tooltip
                      placement="top"
                      hasArrow
                      label="
                      Você pode optar por repassar as taxas de 
                      adiantamento ao seu cliente na forma de 
                      juros de parcelamento ou pagar você, 
                      mantendo o pagamento com valor fixo 
                      independente das parcelas."
                    >
                      <span>
                        <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
                      </span>
                    </Tooltip>
                  </Flex>
                  <Flex flexDir="row" justify="space-between">
                    <RadioInput active={true}>
                      <Radio value="EARLY_BUYER" borderColor="newGray.100" />
                      <Text fontSize="14px">Cliente</Text>
                    </RadioInput>
                    <RadioInput active={true}>
                      <Radio value="EARLY_SELLER" borderColor="newGray.100" />
                      <Text fontSize="14px">Você</Text>
                    </RadioInput>
                  </Flex>
                  <Text
                    color="#8A94A8"
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="400"
                    marginTop="5px"
                  >
                    {taxText}
                  </Text>
                </Flex>
              </Flex>
            </RadioGroup>
          </Flex>
          <Flex
            flexDir="column"
            align="center"
          >
            <Flex
              flexDir="column"
              bgColor="#FFFBE6"
              padding="16px 24px"
              border="1px solid #FFE58F"
              borderRadius="4px"
            >
              <Text
                color="#613400"
                fontSize="14px"
                lineHeight="22px"
              >
                Links rápidos não contém dados de cliente e não há emissão de Nota Fiscal.
              </Text>
            </Flex>
            <Flex
              flexDir="column"
            >
              <Button
                type="submit"
                alignSelf="center"
                variant="solid"
                bgColor="blue.200"
                color="white"
                borderRadius="3"
                marginTop="3rem"
                transition="filter .2s"
                _hover={{
                  transition: "filter .2s",
                  filter: "brightness(.9)",
                }}
                _active={{
                  filter: "brightness(.6)",
                }}
                _focus={{}}
              >
                Finalizar
              </Button>
              <Button
                alignSelf="center"
                variant="ghost"
                borderRadius="3"
                marginTop="3rem"
                transition="filter .2s"
                _hover={{
                  transition: "filter .2s",
                  filter: "brightness(.9)",
                }}
                _active={{
                  filter: "brightness(.6)",
                }}
                _focus={{}}
                onClick={() => navigate(-1)}
              >
                Cancelar
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}