import { FlexProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RadioElement } from "./RadioElement";
import { RadioGroup } from "./RadioGroup";

interface BaseValues {
  name: string;
  value: string;
  position?: string;
}

interface RadioProps extends FlexProps {
  arrayData: Array<BaseValues>;
  singleData: string;
  setSingleData: React.Dispatch<React.SetStateAction<string>>;
}

export function Radio({
  arrayData,
  singleData,
  setSingleData,
  ...rest
}: RadioProps) {
  const [data, setData] = useState<Array<BaseValues>>();

  function setStartAndEndPosition(handleArrayBorder: Array<BaseValues>) {
    const newDataWithBorderStyled = handleArrayBorder.map((data, index) => {
      if (index === 0) {
        return (data = {
          ...data,
          position: "start",
        });
      } else if (index === handleArrayBorder.length - 1) {
        return (data = {
          ...data,
          position: "end",
        });
      } else {
        return (data = {
          ...data,
          position: "middle",
        });
      }
    });
    setData(newDataWithBorderStyled);
  }

  function handleBorderRadius(position: string): string {
    if (position === "start") return ".1rem 0 0 .1rem";
    else if (position === "end") return "0 .1rem .1rem 0";

    return "none";
  }

  useEffect(() => {
    setStartAndEndPosition(arrayData);
  }, []);

  return (
    <RadioGroup {...rest}>
      {data?.map((item) => (
        <RadioElement
          fontWeight="normal"
          border="1px solid"
          borderColor={item.value === singleData ? "blue.200" : "#D9D9D9"}
          color={item.value === singleData ? "blue.200" : "black"}
          bgColor="white"
          p="0 .5rem"
          transition="all .2s"
          borderRadius={handleBorderRadius(item?.position)}
          name={item.name}
          key={item.value}
          _hover={{
            transition: "all .2s",
            borderColor: "blue.200",
            color: "blue.200",
          }}
          _focus={{}}
          onClick={() => setSingleData(item.value)}
        />
      ))}
    </RadioGroup>
  );
}
