 
import { Flex, Badge, Text,Tooltip } from "@chakra-ui/react";
import { getInitials } from "@/utils";

interface AvatarProps {
  customerName?: string;
}
const Avatar = ({ customerName }: AvatarProps) => {
  let initials = getInitials(customerName);
  return (
    <Flex align="center" gridGap="2">
      <Badge
        fontSize="1rem"
        minWidth="35px"
        minHeight="35px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        colorScheme="yellow"
        color="gray.800"
      >
        {initials ?? "-"}
      </Badge>
      <Tooltip label={customerName}>
        <Text  noOfLines={1} >{customerName}</Text>
      </Tooltip>

    </Flex>
  );
};

export { Avatar };
