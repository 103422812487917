import { IconProps } from "@/models/Icon";

export function ShareIcon({ size = 20, color = "#000", opacity="1"}: IconProps) {
    return(
        <svg width={size} height={size} viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M21.0346 19.0893C20.0804 19.0893 19.1998 19.4241 18.51 19.9833L11.5725 14.9643C11.6887 14.3267 11.6887 13.6733 11.5725 13.0357L18.51 8.01674C19.1998 8.57589 20.0804 8.91072 21.0346 8.91072C23.2511 8.91072 25.0525 7.10938 25.0525 4.89286C25.0525 2.67634 23.2511 0.875 21.0346 0.875C18.8181 0.875 17.0167 2.67634 17.0167 4.89286C17.0167 5.28125 17.0703 5.6529 17.1741 6.00781L10.5848 10.779C9.60714 9.48326 8.05357 8.64286 6.30246 8.64286C3.34263 8.64286 0.945312 11.0402 0.945312 14C0.945312 16.9598 3.34263 19.3571 6.30246 19.3571C8.05357 19.3571 9.60714 18.5167 10.5848 17.221L17.1741 21.9922C17.0703 22.3471 17.0167 22.7221 17.0167 23.1071C17.0167 25.3237 18.8181 27.125 21.0346 27.125C23.2511 27.125 25.0525 25.3237 25.0525 23.1071C25.0525 20.8906 23.2511 19.0893 21.0346 19.0893ZM21.0346 3.15179C21.9955 3.15179 22.7757 3.93192 22.7757 4.89286C22.7757 5.8538 21.9955 6.63393 21.0346 6.63393C20.0737 6.63393 19.2935 5.8538 19.2935 4.89286C19.2935 3.93192 20.0737 3.15179 21.0346 3.15179ZM6.30246 16.9464C4.67857 16.9464 3.35603 15.6239 3.35603 14C3.35603 12.3761 4.67857 11.0536 6.30246 11.0536C7.92634 11.0536 9.24888 12.3761 9.24888 14C9.24888 15.6239 7.92634 16.9464 6.30246 16.9464ZM21.0346 24.8482C20.0737 24.8482 19.2935 24.0681 19.2935 23.1071C19.2935 22.1462 20.0737 21.3661 21.0346 21.3661C21.9955 21.3661 22.7757 22.1462 22.7757 23.1071C22.7757 24.0681 21.9955 24.8482 21.0346 24.8482Z" 
                fill={color} 
                fillOpacity={opacity}
            />
        </svg>
    )
}