import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Checkbox,
    Icon,
    Spacer,
    Switch,
    Text,
    Tooltip
} from "@chakra-ui/react";
import React, { SetStateAction, useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { TextArea } from "../TextArea";

interface AccordionTextAreaProps {
    name: string;
    switchActive?: boolean;
    tooltipText?: string;
    hasSwitch?: boolean;
    label?: string;
    labelColor?: string;
    register?: any;
    setValue?: any;
    value?: any;
    errors?: any;
    switchFunction?: (any?: any) => void;
    color?: string;
    asDoubleEffect?: boolean;
    defaultIsOpen?: boolean;
}

export function AccordionTextArea({
    name,
    register,
    hasSwitch,
    asDoubleEffect,
    switchFunction,
    setValue,
    value,
    errors,
}: AccordionTextAreaProps) {
    const [disabledState, setDisabledState] = useState(false);
    const [accordionIndex, setAccordionIndex] = useState(-1);

    function handleSwitchChange(e: any) {
        if (asDoubleEffect) {
            switchFunction(e);
        }
        setDisabledState(!disabledState);
        setValue ? setValue(name, null) : null;
        setAccordionIndex(accordionIndex === 0 ? -1 : 0);
    }
    useEffect(() => {
        setDisabledState(hasSwitch)
        setAccordionIndex(hasSwitch ? 0 : -1);
    }, [hasSwitch])


    return (
        <Accordion
            allowToggle
            index={accordionIndex}
            backgroundColor={disabledState ? "rgba(247, 248, 251, 1)" : ""}
            m="12px 0"
            p={disabledState ? "16px" : "0 16px"}
        >
            <AccordionItem
                border="none"
            >
                <AccordionButton
                    display="flex"
                    borderTop="none"
                    p="0"
                    alignItems="center"
                    gap="2"
                    _hover={{
                        backgroundColor: "none",
                    }}
                >
                    <Checkbox
                        name="disabledState"
                        isChecked={disabledState}
                        onChange={handleSwitchChange}
                        size="md"
                    />
                    <Text
                        fontSize="1rem"
                        fontWeight={disabledState ? "600" : "400"}
                        color={disabledState ? "rgba(0, 0, 0, 0.85)" : "rgba(0, 0, 0, 1)"}
                    >
                        Incluir descrição no boleto
                    </Text>
                    <Tooltip
                        placement="top"
                        hasArrow
                        label="Você pode escolher um texto que aparecerá como descrição do boleto."
                    >
                        <span>
                            <Icon
                                w=".8rem"
                                color="gray.400"
                                as={AiOutlineInfoCircle}
                            />
                        </span>
                    </Tooltip>
                    <Spacer />
                    {/* <AccordionIcon /> */}
                </AccordionButton>
                <AccordionPanel px={0} py={2}>
                    <TextArea
                        label="Descrição do boleto"
                        name={name}
                        color="black"
                        labelFontSize=".8rem"
                        labelColor="gray.600"
                        maxLength={130}
                        currentLength={value?.length}
                        register={register}
                        errors={errors}
                    />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}