import { useRef, useState } from "react";
import {
    Button,
    Flex,
    Heading,
    Text,
} from "@chakra-ui/react";
import { WithAuth } from "@/hoc";
import { GenerateReportModal } from "./_GenerateReportModal";
import { ReportType } from "@/models";
import { SuccessAlert } from "@/components/SuccessAlert";

const Relatorios = () => {
    const ref = useRef();
    const user = JSON.parse(localStorage.getItem("barte-user-info"));
    const reportsType: ReportType[] = [
        {
            id: 1,
            title: "Vendas", 
            description: "Nesse relatório você encontra todos os pagamentos que recebeu pela Barte", 
            button: { title: "Gerar relatório" },
            query_file: "cobrancas-completo",
        },
        { 
            id: 2,
            title: "Financeiro", 
            description: "Nesse relatório você encontra todas as movimentações de entradas e saídas da sua conta", 
            button: { title: "Gerar relatório" },
            query_file: "extrato",
        },
        { 
            id: 3,
            title: "Repasses", 
            description: "Nesse relatório você encontra os saques feitos pela sua conta", 
            button: { title: "Gerar relatório" },
            query_file: "report-transfers-full",
        },
        {   
            id: 4,
            title: "Nota Fiscal", 
            description: "Nesse relatório você encontra todas as Notas Fiscais emitidas a partir das suas vendas", 
            button: { title: "Gerar relatório" },
            query_file: "notas-fiscais",
        },
        {   
            id: 5,
            title: "Links de Pagamento", 
            description: "Nesse relatório você encontra todos os Links de pagamento feitos pela sua conta", 
            button: { title: "Gerar relatório" },
            query_file: "links-de-pagamento",
        },
    ]
    const [isReportGenerateModalOpen, setIsReportGenerateModalOpen] = useState(false);
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
    const [selectedReportType, setSelectedReportType] = useState(reportsType[0])
    

  return (
    <>
        <SuccessAlert
            isOpen={isSuccessAlertOpen}
            onClose={() => setIsSuccessAlertOpen(false)}
            onConfirm={() => setIsSuccessAlertOpen(false)}
            title="Relatório gerado"
            leastDestructiveRef={ref}
            confirmButtonText="OK"
        >
            <Text>Dentro de alguns instantes o relatório será enviado para o email: <Text color="blue.200" textDecoration="underline">{user?.email}</Text></Text>
        </SuccessAlert>
        <GenerateReportModal 
            isOpen={isReportGenerateModalOpen}
            onClose={() => {
                setIsReportGenerateModalOpen(false)
            }}
            onCloseSuccess={() => {
                setIsReportGenerateModalOpen(false)
                setIsSuccessAlertOpen(true)
            }}
            reportType={selectedReportType}
        />
        <Flex 
            w="100%" 
            p="1rem" 
            direction="column" 
            gap="8px"
        >
            <Heading 
                fontSize={["1.2rem", "1.5rem"]}
            >
                Relatórios
            </Heading>
            <Text 
                fontSize={["0.8rem", "0.9rem"]}
            >
                Selecione os relatórios que deseja exportar
            </Text>
            <Flex
                flexDir="column"
                borderRadius="8px"
                border="1px solid #E6E9F0"
            >
                {reportsType?.map(rt => (
                    <Flex 
                        key={rt.id}
                        borderBottom="1px solid #E6E9F0"
                        padding="1rem"
                        _hover={{
                            button: {
                                visibility: "visible", 
                            }
                        }}
                    >
                        <Flex
                            w="100%"
                            gap="8px"
                            align="center"
                        >
                            <Heading 
                                fontSize="1rem" 
                                fontWeight={500}
                                color="#141A28"
                                maxWidth="120px"
                                w="100%"
                            >
                                {rt.title}
                            </Heading>
                            <Text
                                fontSize="1rem"
                                color="#8A94A8"
                                fontWeight={400}
                            >
                                {rt.description}
                            </Text>
                            <Button
                                ml="auto"
                                variant="primary"
                                borderRadius="8px"
                                fontWeight={400}
                                visibility="hidden"
                                onClick={() => {
                                    setSelectedReportType(rt);
                                    setIsReportGenerateModalOpen(true);
                                }}
                            >
                                {rt.button.title}
                            </Button>
                        </Flex>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    </>
  );
};

export default WithAuth(Relatorios);