import { IconProps } from "@/models";

export function DocumentIcon({
  size = 20,
  color = "#000",
  opacity = "1",
}: IconProps) {
  return (
    <svg 
        width={size}
        height={size} 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M21.75 3.75H2.25C1.83516 3.75 1.5 4.08516 1.5 4.5V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V4.5C22.5 4.08516 22.1648 3.75 21.75 3.75ZM20.8125 18.5625H3.1875V5.4375H20.8125V18.5625ZM6.30391 11.1562H11.1961C11.2266 11.1562 11.25 11.0719 11.25 10.9688V9.84375C11.25 9.74062 11.2266 9.65625 11.1961 9.65625H6.30391C6.27344 9.65625 6.25 9.74062 6.25 9.84375V10.9688C6.25 11.0719 6.27344 11.1562 6.30391 11.1562ZM6.41641 14.5312H14.7687C14.8602 14.5312 14.9352 14.4469 14.9352 14.3438V13.2188C14.9352 13.1156 14.8602 13.0312 14.7687 13.0312H6.41641C6.325 13.0312 6.25 13.1156 6.25 13.2188V14.3438C6.25 14.4469 6.325 14.5312 6.41641 14.5312Z" 
            fill={color} 
            fillOpacity={opacity}
        />
    </svg>
  );
}
