import { SubSellerDto } from "@/pages/configuracoes/subsellers/NewSubSeller";
import { Api } from "@/services";
import { filterAndPageableToRequest } from "@/utils";

export function useSubSeller() {
  const baseUrl = "/sellers/subseller";

  const createSubseller = (data: SubSellerDto) =>
    Api.post(baseUrl, data);

  const getSubsellers = (filter: object, pageable: object) => 
    Api.get(`${baseUrl}${filterAndPageableToRequest({ filter, pageable })}`);

  const getSubseller = (id: string) =>
    Api.get(`${baseUrl}/${id}`);

  const deleteSubseller = (id: string, status: boolean) =>
    Api.patch(`${baseUrl}/${id}/active?active=${status}`);
  const editSubsellerGeneralData = (id: string, data: any) =>
    Api.patch(`${baseUrl}/${id}`, data);
  const editSubsellerAddress = (id: string, data: any) =>
    Api.patch(`${baseUrl}/${id}/address`, data);

  const editSubsellerAccounts = (id: string, data: any) =>
    Api.patch(`${baseUrl}/${id}/accounts`, data);

  const editSubsellerContacts = (id: string, data: any) =>
    Api.patch(`${baseUrl}/${id}/contacts`, data);

  const activateSubseller = (id: string) => 
    Api.post(`${baseUrl}/${id}/split`)
  

  return {
    createSubseller,
    getSubsellers,
    getSubseller,
    editSubsellerAddress,
    editSubsellerAccounts,
    editSubsellerContacts,
    editSubsellerGeneralData,
    deleteSubseller,
    activateSubseller
  }
}