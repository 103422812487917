
import { Box, Flex, Heading, Image, Link, Stack, Text, useMediaQuery } from "@chakra-ui/react";
import styles from "./styles.module.scss";




const BannerLateral = () => {
    const [isLargeThan460] = useMediaQuery("min-width: 460px");
    return (
        <Box
            w={["100%", "100%", "100%", "50%"]}
            bg="gray.login"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color="white"
            position="relative"
            top="0"
            backgroundImage="../images/commons/backGroundBanner21x.png"
            bgSize="cover"
            bgPosition="center"
            bgRepeat="no-repeat"
            overflow="hidden"
            p={isLargeThan460 ? "0px" : "20px"}
        >

            <Flex
                 className={styles.borderGradient}
                 width="100%"
                 height='100%'
                 gap="12px"
                 direction="column"
                 justifyContent="center"
                 alignItems="center"
                 borderRadius="40px"
                 overflow="hidden"
            />
            <Flex
                width="100%"
                height='100%'
                gap="12px"
                direction="column"
                justifyContent="center"
                alignItems="center"
                borderRadius="40px"
                overflow="hidden"
                position="absolute"
         
            >
                <Flex
                    color="white"
                    lineHeight="28px"
                    direction="column"
                    fontWeight={600}
                    justifyContent="center"
                    gap="6px"
                // alignItems="center"
                >
                    <Flex
                        gap="11px"
                    >

                        <Text fontSize="31px" fontWeight="600">Venda em até 21x</Text>
                        <Image
                            src="../images/commons/flagNew.svg"
                            maxW="61px"
                            width="61px"
                            height="26px"
                        />
                    </Flex>

                    <Text fontSize="16px" w="300px" fontWeight="400">E comece a aumentar sua conversão de vendas agora mesmo</Text>
                </Flex>

                <Image
                    src="/images/commons/florid-crypto-wallet-and-online-banking.gif"
                    maxW="411px"
                    width="411px"
                    height="411px"
                    marginBottom="22px"
                />
                {/* <Text maxW="350px" fontWeight={500}>Responda algumas perguntas rápidas e entre na fila para testar em primeira mão!</Text>
                <Link
                    href="https://form.typeform.com/to/QmrW0dD7"
                    target="_blank"
                    maxW="135px"
                    py="10px"
                    px="20px"
                    backgroundColor="pink.100"
                    textAlign="center"
                    transition="filter ease .5s"
                    _hover={{
                        color: "white",
                        filter: "brightness(.9)",
                    }}
                    id="contas_a_pagar"
                >
                    Vamos lá
                </Link> */}
            </Flex>
        </Box >
    )
}

export { BannerLateral };