import { useSettings } from "@/hooks";
import { SellerNotifications } from "@/models/Settings";
import { showToast } from "@/utils";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { NotificationSwitch } from "./_NotificationSwitch";
import { WarningMessage } from "./_warningMessage";

interface CreditCardConfigProps {
  data: SellerNotifications;
  isDisable?: boolean;
}

const CreditCardConfig = ({
  data,
  isDisable = false,
}: CreditCardConfigProps) => {
  const { patchActive } = useSettings();

  const handleSwitchChange = async (type: string, active: boolean) => {
    const switchValues = {
      type,
      active,
    };
    try {
      await patchActive(switchValues);
      showToast({
        message: active ? "Notificação ativada!" : "Notificação desativada!",
        type: "success",
      });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  };
  return (
    <Flex w="100%" justify="space-between" flexDir="column">
      <Stack spacing="3rem" w="100%" justify="space-between">
        {isDisable && <WarningMessage isVariant />}
        <Text color="black" fontSize="2xl" fontWeight="bold">
          Pagamentos em cartão de crédito
        </Text>

        <Stack
          maxW="1100px"
          justify="space-between"
          direction="row"
          spacing="2rem"
        >
          <Box>
            <Flex flexDir="column">
              <Text color="black" fontWeight="bold" marginBottom="1rem">
                Notificação de pagamento efetuado
              </Text>
              <Stack marginTop="1rem" spacing="1rem">
                <Flex h="70px" w="480px" justify="space-between">
                  <Text color="black">Ao confirmar pagamento</Text>
                  <NotificationSwitch
                    emailDefaultChecked={data?.creditCardPayment}
                    emailName="CREDIT_CARD_PAYMENT"
                    handleSwitch={handleSwitchChange}
                    whatsappDefaultChecked={data?.creditCardPaymentWhatsapp}
                    whatsappName="CREDIT_CARD_WHATSAPP_PAYMENT"
                    isDisable={isDisable}
                  />
                </Flex>
              </Stack>
            </Flex>
          </Box>
          <Box w="600px">
            <Flex flexDir="column">
              <Stack spacing=".8rem">
                <Text color="gray.400">Preview da mensagem</Text>
                <Flex p="1rem" bgColor="gray.25" borderRadius="3">
                  <Stack spacing=".8rem" color="black">
                    <Text
                      lineHeight="3rem"
                      dangerouslySetInnerHTML={{
                        __html: data?.creditCardPaymentMessage?.replace(
                          /\n/gi,
                          "<br/>"
                        ),
                      }}
                    />
                  </Stack>
                </Flex>
              </Stack>
            </Flex>
          </Box>
        </Stack>

        <Stack
          maxW="1100px"
          justify="space-between"
          direction="row"
          spacing="2rem"
        >
          <Box>
            <Flex flexDir="column">
              <Text color="black" fontWeight="bold">
                Notificação pós vencimento
              </Text>
              <Text fontSize=".8rem" color="gray.400" w="350px">
                Notificações pós vencimento só serão enviadas em caso de não
                identificação do pagamento.
              </Text>
              <Stack marginTop="2rem" spacing="1rem">
                <Flex h="70px" w="480px" justify="space-between">
                  <Text color="black">
                    Notificar{" "}
                    <Text display="inline" fontWeight="bold">
                      2
                    </Text>{" "}
                    dias depois do vencimento
                  </Text>
                  <NotificationSwitch
                    emailDefaultChecked={data?.creditCardLatePaymentWithTwoDays}
                    emailName="CREDIT_CARD_LATE_PAYMENT_TWO_DAYS"
                    handleSwitch={handleSwitchChange}
                    whatsappDefaultChecked={
                      data?.creditCardLatePaymentWithTwoDaysWhatsApp
                    }
                    whatsappName="CREDIT_CARD_LATE_PAYMENT_WHATSAPP_TWO_DAYS"
                    isDisable={isDisable}
                  />
                </Flex>
                <Flex h="70px" w="480px" justify="space-between">
                  <Text color="black">
                    Notificar{" "}
                    <Text display="inline" fontWeight="bold">
                      7
                    </Text>{" "}
                    dias depois do vencimento
                  </Text>
                  <NotificationSwitch
                    emailDefaultChecked={
                      data?.creditCardLatePaymentWithSevenDays
                    }
                    emailName="CREDIT_CARD_LATE_PAYMENT_SEVEN_DAYS"
                    handleSwitch={handleSwitchChange}
                    whatsappDefaultChecked={
                      data?.creditCardLatePaymentWithSevenDaysWhatsApp
                    }
                    whatsappName="CREDIT_CARD_LATE_PAYMENT_WHATSAPP_SEVEN_DAYS"
                    isDisable={isDisable}
                  />
                </Flex>
                <Flex h="70px" w="480px" justify="space-between">
                  <Text color="black">
                    Notificar{" "}
                    <Text display="inline" fontWeight="bold">
                      20
                    </Text>{" "}
                    dias depois do vencimento
                  </Text>
                  <NotificationSwitch
                    emailDefaultChecked={
                      data?.creditCardLatePaymentWithTwentyDays
                    }
                    emailName="CREDIT_CARD_LATE_PAYMENT_TWENTY_DAYS"
                    handleSwitch={handleSwitchChange}
                    whatsappDefaultChecked={
                      data?.creditCardLatePaymentWithTwentyDaysWhatsApp
                    }
                    whatsappName="CREDIT_CARD_LATE_PAYMENT_WHATSAPP_TWENTY_DAYS"
                    isDisable={isDisable}
                  />
                </Flex>
              </Stack>
            </Flex>
          </Box>
          <Box w="600px">
            <Flex flexDir="column">
              <Stack spacing=".8rem">
                <Text color="gray.400">Preview da mensagem</Text>
                <Flex p="1rem" bgColor="gray.25" borderRadius="3">
                  <Stack spacing=".8rem" color="black">
                    <Text
                      lineHeight="3rem"
                      dangerouslySetInnerHTML={{
                        __html: data?.creditCardLatePaymentMessage?.replace(
                          /\n/gi,
                          "<br/>"
                        ),
                      }}
                    />
                  </Stack>
                </Flex>
                <Text fontSize=".8rem" color="gray.400">
                  *Enviaremos o link da fatura por email
                </Text>
              </Stack>
            </Flex>
          </Box>
        </Stack>
      </Stack>
    </Flex>
  );
};

export { CreditCardConfig };
