import { useRef, useState } from "react"

type BarteUserInfo = {
  email: string
  roles: Array<string>
}

export function useAnticipationStatus() {
  const [alertModalState, setAlertModalState] = useState<boolean>(false)
  const barteUserInfo:BarteUserInfo = JSON.parse( localStorage.getItem('barte-user-info') )
  // const hasAntecipationActive = !barteUserInfo.roles.includes("SELLER_OP_CANCEL")
  const hasAntecipationActive = false
  const ref = useRef()
  return {
    hasAntecipationActive,
    setAlertModalState,
    alertModalState,
    ref
  }
}