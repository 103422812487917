import { FormControl, FormLabel, Select, SelectProps, Text } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";

interface ListSelectorProps extends SelectProps {
  label?: string;
  options: any[];
  name: string;
  defaultValue?: any;
  dataUserCard?:any;
  placeholder: string;
  register?: any;
  errors?: any
  setDataUserSelectCard?: Dispatch<SetStateAction<string>>;
  dataUserSelectCard?: string;
  showValueText?: boolean;
}

export const ListSelector = ({
  label,
  options,
  name,
  placeholder = "",
  register,
  defaultValue,
  dataUserCard,
  errors,
  dataUserSelectCard,
  setDataUserSelectCard,
  showValueText = false,
  ...rest
}: ListSelectorProps) => {
  return (
    <FormControl isInvalid={!!errors?.message}>

      <FormLabel fontSize=".8rem" marginBottom=".2rem">
        {label}
      </FormLabel>

      <Select
        placeholder={placeholder}
        name={name}
        borderRadius="4px"
        fontSize="14px"
        onChange={(e) => setDataUserSelectCard(e.target.value)}
        defaultValue={dataUserSelectCard}
        borderColor={errors ? "danger" : "newGray.200"}
        border={errors ? "1.5px solid" : "1px solid"}
        w="100%"
        _focus={{
          border: "1px solid",
          borderColor: "blue.200",
        }}
        {...rest}
        {...register}
      >
        {options.map((data, index) => (
          <option key={index} value={data?.value}>
            {showValueText ? data?.value : data?.label}
          </option>
        ))}
      </Select>

      {errors && (
        <Text fontSize=".8rem" color="danger" mb="4" role="alert">
          {errors?.message}
        </Text>
      )}
    </FormControl>
  );
};
