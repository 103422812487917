import { createContext, ReactNode, useContext, useState } from "react";
import { ManualCharge, ManualChargePaginator, Pageable } from "@/models";
import { showToast, filterAndPageableToRequest } from "@/utils";
import { Api } from "@/services";

interface ManualChargesProps {
  children: ReactNode;
}

interface ManualChargesContextData {
  manualCharges: ManualChargePaginator;
  setManualCharges: (value: ManualChargePaginator) => void;
  deleteManualCharge: (idCharge: string) => void;
  selectedManualCharge: ManualCharge;
  //MODALS
  isDeleteModalOpen: boolean;
  isFormModalOpen: boolean;
  handleOpenModal: (modal: string, chargeId?: string) => void;
  handleCloseModal: (modal: string) => void;

  createManualCharge: (data) => void;
  updateManualCharge: (data, chargeId) => void;

  selectedId: string;
  loading: boolean;
  setLoading: (value: boolean) => void;
  getManualCharges: (filter: Pageable | {}, pageable: Pageable | {}) => any;
}

const ManualChargeContext = createContext<ManualChargesContextData>(
  {} as ManualChargesContextData
);

const ManualChargeProvider = ({ children }: ManualChargesProps) => {
  //MODALS
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const [selectedId, setSelectedId] = useState("");
  const [selectedManualCharge, setSelectedManualCharge] = useState(
    {} as ManualCharge
  );
  const [loading, setLoading] = useState(false);

  const [manualCharges, setManualCharges] = useState(
    {} as ManualChargePaginator
  );
  const baseUrl = "/manual-charges";

  const handleOpenModal = (modal: string, chargeId: string) => {
    setSelectedId(chargeId);
    if (modal === "delete") return setIsDeleteModalOpen(true);
    if (modal === "new") return setIsFormModalOpen(true);
    if (modal === "edit") {
      let selectManualCharge = manualCharges.content.find(
        (manualCharge) => manualCharge.uuid === chargeId
      );
      setSelectedManualCharge(selectManualCharge ?? ({} as ManualCharge));
      setIsFormModalOpen(true);
      return;
    }
  };

  const handleCloseModal = (modal: string) => {
    setSelectedId("");
    setSelectedManualCharge({} as ManualCharge);
    setLoading(false);
    if (modal === "delete") return setIsDeleteModalOpen(false);
    if (modal === `${"edit" || "new"}`) return setIsFormModalOpen(false);
  };

  const deleteManualCharge = async (chargeId: string) => {
    try {
      setLoading(true);
      await Api.delete(`${baseUrl}/${chargeId}`);
      const updatedManualCharges = (await Api.get("/manual-charges")).data;
      setManualCharges(updatedManualCharges);
      showToast({
        type: "success",
        message: "Cobrança manual excluída com sucesso.",
      });
    } catch (err) {
      handleCloseModal("delete");
      showToast({
        type: "error",
        message: "Erro ao deletar a cobrança manual, tente novamente",
      });
    }
    handleCloseModal("delete");
  };

  const createManualCharge = async (data) => {
    setLoading(true);
    try {
      const response = await Api.post(baseUrl, data);
      if (response.status !== 201) throw new Error();
      const updatedManualCharges = (await Api.get("/manual-charges")).data;
      setManualCharges(updatedManualCharges);
      showToast({
        type: "success",
        message: "Cobrança manual criada com sucesso.",
      });
    } catch (err) {
      handleCloseModal("edit");
      showToast({
        type: "error",
        message: "Erro ao criar a cobrança manual, tente novamente",
      });
    }
    handleCloseModal("edit");
  };

  const updateManualCharge = async (data, chargeId) => {
    setLoading(true);
    try {
      const response = await Api.put(`${baseUrl}/${chargeId}`, data);
      if (response.status !== 204) throw new Error();
      const updatedManualCharges = (await Api.get("/manual-charges")).data;
      setManualCharges(updatedManualCharges);
      showToast({
        type: "success",
        message: "Cobrança manual alterada com sucesso.",
      });
    } catch (err) {
      handleCloseModal("edit");
      showToast({
        type: "error",
        message: "Erro ao editar a cobrança manual, tente novamente",
      });
    }
    handleCloseModal("edit");
  };

  const getManualCharges = (
    filter?: Pageable | {},
    pageable?: Pageable | {}
  ) => {
    return Api.get<ManualChargePaginator>(
      `${baseUrl}${filterAndPageableToRequest({ filter, pageable })}`
    );
  };

  return (
    <ManualChargeContext.Provider
      value={{
        createManualCharge,
        updateManualCharge,
        selectedManualCharge,
        manualCharges,
        deleteManualCharge,
        setManualCharges,
        isDeleteModalOpen,
        isFormModalOpen,
        selectedId,
        loading,
        setLoading,
        handleOpenModal,
        handleCloseModal,
        getManualCharges,
      }}
    >
      {children}
    </ManualChargeContext.Provider>
  );
};

export function useManualCharges(): ManualChargesContextData {
  return useContext(ManualChargeContext);
}

export { ManualChargeProvider };
