import { background, Button, Flex, Img, Text } from "@chakra-ui/react";
import {
    formatDate,
    formatCurrency,
} from "@/utils";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import "./modalSelectedBills.scss"
import { useBillsToPay } from "../../hooks/useBillsToPay"
import { useState } from "react";
import { addDays, isMonday, isWednesday, isFriday, format, differenceInDays, parseISO } from 'date-fns';


interface modalBillsToPay {
    content?: any;
    setOpenModalAprove?: any;
    openModalAprove?: boolean;
    modalMethod?: string;
    selectedActionRead ?:  React.Dispatch<React.SetStateAction<any[]>>;
    dayOfPayment?:Date;
}


export interface BillsToPay {
    Cliente?: [string];
    Valor?: string;
    Status?: string;
    id?: string;
    Vencimento?: string;
}

export interface Fields {
    fields?: BillsToPay[];
    Status: string;
    Valor?: string;

}



function ModalSelectedBills({ content, setOpenModalAprove, openModalAprove, modalMethod,dayOfPayment,selectedActionRead }: modalBillsToPay) {

    const { deleteBills, updateBills } = useBillsToPay()
    const baseURL = String(import.meta.env.VITE_API_AIRTABLE)
    const [loading, setLoading] = useState(false);

    const columns: ColumnsType<BillsToPay> = [

        {
            title: "Conta",
            dataIndex: "Nome da Conta",
            key: "Nome da Conta",
            render: (customerName) => customerName,
        },
        {
            title: "Vencimento",
            dataIndex: "Vencimento",
            key: "Vencimento",
            render: (paymentDate) => paymentDate && (
                <>
                    <Text>
                        {formatDate(paymentDate)}
                    </Text>
                </>
            )

        },

        {
            title: "Valor",
            dataIndex: "Valor",
            key: "Valor",
            render: (value) => value > 0 ? formatCurrency({ value }) : "",

        },

    ];
    const valuesEachMethod = {
        "Aprove": {
            title: "Aprovar contas selecionadas?",
            subTitle: "Essas contas serão agendadas para pagamento.",
            btAction: "Aprovar",
            btColor: "rgba(24, 144, 255, 1)",
            btColorHover: 'rgba(24, 144, 255, 1)',
            icon: "/images/icons/CheckCircleTwoTone.svg"
        }, "Reprove": {
            title: "Reprovar contas selecionadas?",
            subTitle: "Essas contas serão encaminhadas para revisão.",
            btAction: "Reprovar",
            btColor: "rgba(24, 144, 255, 1)",
            btColorHover: 'rgba(24, 144, 255, 1)',
            icon: "/images/icons/InteractionTwoTone.svg"

        }, "Delete": {
            title: "Excluir contas selecionadas?",
            subTitle: "Essas contas serão excluídas e não serão pagas.",
            btAction: "Excluir",
            btColor: "rgba(255, 77, 79, 1)",
            btColorHover: 'rgba(255, 77, 79, 1)',
            icon: "/images/icons/CloseCircleTwoTone.svg"
        }
    }

    const { TabPane } = Tabs;
    const actionMethod = 
      {  
        Aprove :"Aprovado",
        Reprove: "Reprovado",
        Delete : "Cancelada"
    }

    async function ActionModal(data: any) {
        const dataObject = data.map((item) => {
            return {
                'id': item.id, 'fields': {
                    'Status': actionMethod[modalMethod]
                }
            }
        });
        if (modalMethod === "Aprove") {
            setLoading(true);
            try {
                const reprovePromisses = await updateBills({ records: dataObject })
                if (reprovePromisses.status === 200) {
                    selectedActionRead([]);
                    setLoading(false);
                    setOpenModalAprove(false);
                }
            } catch (error) {
                console.log(error)
                setLoading(false);
            }
        }
        if (modalMethod === "Reprove") {
            setLoading(true);
            try {
                const reprovePromisses = await updateBills({ records: dataObject })
                if (reprovePromisses.status === 200) {
                    selectedActionRead([]);
                    setLoading(false);
                    setOpenModalAprove(false);
                }
            } catch (error) {
                console.log(error)
                setLoading(false);
            }
        }

        if (modalMethod === "Delete") {
            setLoading(true);
            try {
                const reprovePromisses = await updateBills({ records: dataObject })
                if (reprovePromisses.status === 200) {
                    selectedActionRead([]);
                    setLoading(false);
                    setOpenModalAprove(false);
                }
            } catch (error) {
                console.log(error)
                setLoading(false);
            }

        }

    }


    return (

        <Flex position="absolute" top="0" left="0" zIndex={999} h="100%" w="100%"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            bg="white"

        >
            <Flex h="427px" w="520px" flexDirection="column"
                border="1px solid gray"
                alignContent="center"
                justifyContent="space-between"
                alignItems="center"
            >
                <Flex
                    flexDirection="column"
                    p=" 0 16px"

                >
                    <Flex
                        flexDirection="column"
                        textAlign="start"
                        justifyContent="flex-start"
                        w="100%"
                        gap="24px"
                        marginBottom="16px"
                        p=" 0 16px"

                    >


                        <Flex justifyContent="space-between"
                            w="100%"
                            borderBottom="1px solid rgba(0, 0, 0, 0.08)"
                            h="57px"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Flex gap="10px"
                                justifyContent="center"
                                alignContent="center"
                                alignItems="center"

                            >
                                <Img w="24px" h="24px" src={valuesEachMethod[modalMethod].icon} />
                                <Text
                                    fontWeight="600"
                                >
                                    {valuesEachMethod[modalMethod]?.title}
                                </Text>
                            </Flex>
                            <Img w="24px" h="24px"
                                src="/images/icons/CloseOutlined.svg"
                                onClick={() => setOpenModalAprove(false)}
                            />
                        </Flex>
                        <Text
                            textAlign="start"
                            w="100%"
                        >
                            {valuesEachMethod[modalMethod]?.subTitle}

                        </Text>
                    </Flex>
                    <Flex
                        w="100%"
                        maxH="242px"
                        className="teste"
                    >
                        <Tabs
                            style={{ overflowY: "auto" }}
                        >
                            {content?.map(({ key, tab }) => (
                                <TabPane tab={tab} key={key}>

                                    <Table<BillsToPay>

                                        columns={columns}
                                        dataSource={content || []}
                                        // onRow={(record) => {
                                        //     return {
                                        //         onClick: () => console.log(`${record.id}`),
                                        //     };
                                        // }}
                                        rowKey={(record) => record.id}
                                        locale={{ emptyText: "Sem dados de recorrências" }}
                                        pagination={false}
                                    />
                                </TabPane>
                            ))}
                        </Tabs>
                    </Flex>

                </Flex>

                <Flex
                    alignContent="center"
                    justifyContent="end"
                    alignItems="center"
                    w="100%"
                    gap="8px"
                    borderTop="1px solid rgba(0, 0, 0, 0.08)"
                    p=" 8px 24px"

                >



                    <Button
                        backgroundColor="white"
                        border="1px solid rgba(0, 0, 0, 0.15)"
                        _hover={{ backgroundColor: "white" }}
                        onClick={() => setOpenModalAprove(false)}
                    >Cancelar</Button>
                    <Button
                        isLoading={loading}

                        backgroundColor={valuesEachMethod[modalMethod]?.btColor}
                        color="white"
                        border="1px solid rgba(0, 0, 0, 0.15)"
                        onClick={() => ActionModal(content)}

                        _hover={{ backgroundColor: valuesEachMethod[modalMethod]?.btColorHover }}
                    >{valuesEachMethod[modalMethod]?.btAction}</Button>
                </Flex>

            </Flex>

        </Flex>
    )

}
export default ModalSelectedBills;

