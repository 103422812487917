import { IconProps } from "@/models/Icon";

export function Wallet2Icon({ 
    size = 24, 
    color = "#06795D", 
}: IconProps) {
    return(
        <svg 
        width={size} 
        height={size} 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        >
            <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M2.87223 4.27256C2.87223 4.07432 3.03294 3.91361 3.23118 3.91361H17.919C18.1173 3.91361 18.278 4.07432 18.278 4.27256V6.93583H3.23118C3.10658 6.93583 2.98613 6.95361 2.87223 6.98676V4.27256ZM2.87223 8.21779V9.15074V19.7276C2.87223 19.9258 3.03293 20.0865 3.23118 20.0865H20.7684C20.9666 20.0865 21.1273 19.9258 21.1273 19.7276V16.5036H14.8353C14.1273 16.5036 13.5534 15.9297 13.5534 15.2217V12.9947C13.5534 12.2867 14.1273 11.7128 14.8353 11.7128H21.1273V8.21779C21.1273 8.01955 20.9666 7.85884 20.7684 7.85884H3.23118C3.03293 7.85884 2.87223 8.01955 2.87223 8.21779ZM1.94922 8.21779V4.27256C1.94922 3.56455 2.52317 2.9906 3.23118 2.9906H17.919C18.627 2.9906 19.201 3.56455 19.201 4.27256V6.93583H20.7684C21.4764 6.93583 22.0503 7.50978 22.0503 8.21779V19.7276C22.0503 20.4356 21.4764 21.0095 20.7684 21.0095H3.23118C2.52317 21.0095 1.94922 20.4356 1.94922 19.7276V9.15074V8.21779ZM21.1273 12.6358V15.5806H14.8353C14.6371 15.5806 14.4764 15.4199 14.4764 15.2217V12.9947C14.4764 12.7965 14.6371 12.6358 14.8353 12.6358H21.1273Z" 
            fill="#06795D"
            />
        </svg>
    )
}



