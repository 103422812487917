import {
  Box,
  Button,
  Flex,
  ListItem,
  Text,
  Tooltip,
  Icon,
  UnorderedList,
} from "@chakra-ui/react";
import { Input } from "@/components";
import { Item } from "@/layouts";
import { Bullet } from "@/models";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineInfoCircle } from "react-icons/ai";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface ItemsList {
  tooltip?: string;
  optional?: boolean;
  label?: string;
  name?: string;
  id?: string;
  list: Bullet[];
  updateList: (values: Bullet[]) => void;
  buttonText: string;
}

type FormValues = {
  description: string;
};

const addBulletSchema = yup.object().shape({
  description: yup.string().required("Preenchimento obrigatório!"),
});

const ItemsList = ({
  tooltip,
  optional,
  name,
  label,
  list,
  updateList,
  buttonText,
}: ItemsList) => {
  const [inputAdd, setInputAdd] = useState(false);
  const [editBulletValue, setEditBulletValue] = useState("");
  const [bulletSelectedId, setBulletSelectedId] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addBulletSchema),
  });

  function handleDeleteItem(indexList: number) {
    updateList(list.filter((bullet, index) => index !== indexList));
  }

  const onSubmit: SubmitHandler<FormValues> = (values, event) => {
    event.preventDefault();

    if (list) {
      updateList([
        ...list,
        { description: values.description, id: String(list.length + 1) },
      ]);
    } else {
      updateList([{ description: values.description, id: "1" }]);
    }

    reset({ description: "" });
    setInputAdd(false);
  };

  function handleChangeBulletValue() {
    if (editBulletValue !== "") {
      list.map((item) => {
        if (item.id === bulletSelectedId) {
          (item.description = editBulletValue), (item.id = bulletSelectedId);
        }
      });
    }

    setEditBulletValue("");
    setBulletSelectedId(null);
  }

  return (
    <Box w="520px">
      <Flex flexDir="row" mb="2">
        <Text
          marginRight=".5rem"
          color="#000000"
          fontSize="1xl"
          fontWeight="500"
        >
          {label}
        </Text>
        {optional && (
          <Text marginRight=".4rem" color="gray.300">
            (opcional)
          </Text>
        )}
        {tooltip && (
          <Tooltip placement="right-end" hasArrow label={tooltip}>
            <span>
              <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
            </span>
          </Tooltip>
        )}
      </Flex>

      <UnorderedList
        w="520px"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="3"
        padding=".5rem"
        margin="0"
      >
        {list?.map((item, index) => (
          <Flex
            h="40px"
            _hover={{
              bgColor: "gray.25",
            }}
          >
            <ListItem
              w="100%"
              marginLeft="2rem"
              onClick={() => setBulletSelectedId(item?.id)}
              cursor="pointer"
            >
              <Item
                key={index}
                text={item.description}
                index={index}
                handleDelete={handleDeleteItem}
              />
            </ListItem>
            {item?.id === bulletSelectedId && (
              <Flex
                zIndex={1}
                position="absolute"
                w="500px"
                justify="space-between"
                align="center"
                bgColor="gray.25"
              >
                <Input
                  type="text"
                  name="input-text"
                  maxLength={50}
                  color="#000000"
                  border="none"
                  placeholder="Digite aqui"
                  classInput="input-bg-transparent"
                  aria-label="Digite aqui"
                  aria-invalid={errors.description ? "true" : "false"}
                  onChange={(e) => setEditBulletValue(e.target.value)}
                  _focus={{}}
                />
                <Button
                  type="button"
                  onClick={handleChangeBulletValue}
                  marginRight="1rem"
                  border="1px solid"
                  borderColor="blue.200"
                  bgColor="blue.200"
                  borderRadius="3"
                  w="15"
                  h="7"
                  color="white"
                  transition="filter .2s"
                  _hover={{
                    transition: "filter .2s",
                    filter: "brightness(.9)",
                  }}
                >
                  Ok
                </Button>
              </Flex>
            )}
          </Flex>
        ))}
        {inputAdd === false ? (
          <Button
            color="blue.200"
            variant="ghost"
            _hover={{ bg: "transparent" }}
            onClick={() => setInputAdd(true)}
            fontSize="14"
          >
            Adicionar item
          </Button>
        ) : (
          <Flex w="100%" justify="space-between" align="flex-start">
            <Input
              type="text"
              name="description"
              color="#000000"
              border="none"
              placeholder="Digite aqui"
              classInput="input-bg-transparent"
              register={{ ...register("description") }}
              errors={errors?.description}
              aria-label="Digite aqui"
              aria-invalid={errors.description ? "true" : "false"}
              maxLength={120}
            />

            <Button
              marginRight="1rem"
              border="1px solid"
              borderColor="blue.200"
              bgColor="blue.200"
              borderRadius="3"
              w="120"
              ml="16"
              color="white"
              transition="filter .2s"
              _hover={{
                transition: "filter .2s",
                filter: "brightness(.9)",
              }}
              onClick={handleSubmit(onSubmit)}
            >
              {buttonText}
            </Button>
          </Flex>
        )}
      </UnorderedList>
    </Box>
  );
};

export { ItemsList };
