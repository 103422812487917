import { useEffect, useState } from "react";
import { Button, Flex, Heading, Stack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "@/components";
import { useResetPassword } from "@/hooks";
import { getApplicationName, showToast } from "@/utils";
import { BannerLateral } from "@/layouts";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("O campo senha é obrigatório!"),
  confirm_password: Yup.string()
    .oneOf([null, Yup.ref("password")], "As senhas precisam ser iguais")
    .required("O campo confirme sua senha é obrigatório!"),
});

type FormValues = {
  password: string;
  confirm_password: string;
};

const ResetPasswordDetails: React.FC = () => {
  const { sendNewPassword } = useResetPassword();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
  }, [id]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const applicationName = getApplicationName({});
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async (data: FormValues) => {
    setLoading(true);

    const newPassword = {
      newPassword: data.password,
    };

    try {
      const response = await sendNewPassword(String(id), newPassword);
      showToast({
        message: "Nova senha cadastrada com sucesso!",
        type: "success",
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }

    setLoading(false);
  });

  return (
    <Flex flexDir={["column", "column", "column", "row"]}>
      <Flex align="center" w={["100%", "100%", "100%", "50%"]} justify="center">
        <Flex
          as="form"
          direction="column"
          align="flex-start"
          justify="center"
          h="100vh"
          w="380px"
          onSubmit={onSubmit}
          gap="6"
        >
          <img src={`/images/commons/logo-${import.meta.env.VITE_APPLICATION_NAME}.svg`} width="180" height="60" />
          <Flex w="100%" flexDirection="column" mb="40px">
            <Heading as="h3" mb="10px">
              Trocar senha
            </Heading>
          </Flex>
          <Stack w="100%">
            <Input
              label="Senha"
              type="password"
              name="password"
              placeholder="Sua senha"
              className="mb-3"
              register={{ ...register("password") }}
              errors={errors?.password}
              aria-label={"Insira sua senha"}
              aria-invalid={errors.password ? "true" : "false"}
              fontSize="1rem"
              labelColor="black"
              mb="30px"
            />

            <Input
              label="Confirme sua senha"
              type="password"
              name="confirm_password"
              placeholder="Sua senha"
              className="mb-3"
              register={{ ...register("confirm_password") }}
              errors={errors?.confirm_password}
              aria-label={"Confirme sua senha"}
              aria-invalid={errors.confirm_password ? "true" : "false"}
              fontSize="1rem"
              labelColor="black"
              mb="30px"
            />

            <Button
              isLoading={loading}
              borderRadius="3"
              type="submit"
              variant="outline"
              fontSize="1rem"
              py="1rem"
              w="170px"
              mt="40px!important"
            >
              Definir nova senha
            </Button>
          </Stack>
        </Flex>
      </Flex>
      <BannerLateral />
    </Flex>
  );
};

export default ResetPasswordDetails;
