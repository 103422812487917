import { DialogIcon } from "@/styles/icons/DialogIcon"
import { Flex, Heading, Text } from "@chakra-ui/react"

interface WarningMessageProps {
    isVariant?: boolean;
}
const WarningMessage = ({ isVariant = false }: WarningMessageProps) => {
    return(
        <Flex 
            w="100%" 
            p="1rem" 
            bgColor="orange.sunset"
            alignItems="center"
            justifyContent="center"
            borderRadius="4px"
            flexDirection="column"
            gap="2"
        >
            <Flex gap="2">
                <DialogIcon />
                <Heading fontSize={[".6rem", ".8rem", "1rem"]}> Suas notificações de cobrança estão desativadas</Heading>
            </Flex>
            <Flex gap="1">
                {isVariant ? 
                (
                    <Text fontSize={[".6rem", ".8rem", "1rem"]}>{`Para ativá-las, vá em Notificações -> Configurações Gerais`}</Text>
                ) :
                (
                <>
                    <Text fontSize={[".6rem", ".8rem", "1rem"]}>Você pode ativar abaixo pelo botão</Text>
                    <Text fontSize={[".6rem", ".8rem", "1rem"]} fontWeight="700">Ativar Notificações</Text>
                </>
                )}
            </Flex>
        </Flex>
    )
}

export { WarningMessage }