import { signOut } from "@/contexts"
import { Api } from "@/services"

export function useRefreshToken() {
  const refreshTokenStorage = localStorage.getItem("barte-refresh-token")
  async function sendRefreshToken() {
    try {
      const { data } = await Api.post("public/auth/refresh-token", {
        refreshToken: String(refreshTokenStorage)
      })
      const { email, roles, token, idSeller, document, refreshToken } = data;
      localStorage.setItem("barte-auth-token", token);
      localStorage.setItem("barte-user-info", JSON.stringify({ email, roles, idSeller, document }));
      localStorage.setItem("barte-refresh-token", refreshToken);
      document.location.reload();
    } catch (error) {
      if (error?.response?.status === 401) {
        signOut();
      }
    }
  }
  return {
    sendRefreshToken
  }
}