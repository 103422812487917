import ApexCharts from "apexcharts";
import { formatCurrency } from "@/utils";

export function GetChartModel({
  period,
  todayLabelPositionX,
  todayLabelPositionY,
}) {
  const options: ApexCharts.ApexOptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      defaultLocale: "pt",
      locales: [
        {
          name: "pt",
          options: {
            months: [
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Agosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Dezembro",
            ],
            shortMonths: [
              "Jan",
              "Fev",
              "Mar",
              "Abr",
              "Mai",
              "Jun",
              "Jul",
              "Ago",
              "Set",
              "Out",
              "Nov",
              "Dez",
            ],
            days: [
              "Domingo",
              "Segunda",
              "Terça",
              "Quarta",
              "Quinta",
              "Sexta",
              "Sábado",
            ],
            shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
          },
        },
      ],
      offsetX: -5,
    },
    colors: ["#138EFF", "#FF908A", "#E6E9F0"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "35",
        rangeBarOverlap: true,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      y: {
        formatter: function (value) {
          return formatCurrency({ value: value });
        },
      },
      marker: {
        show: true,
      },
    },
    xaxis: {
      type: "datetime",
      categories: period,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          colors: "#8A94A8",
          fontSize: "12px",
          fontFamily: "Hero New, Arial, sans-serif",
        },
        datetimeFormatter: {
          month: period.length > 7 ? "dd" : "ddd",
          day: period.length > 7 ? "dd" : "ddd",
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      fontSize: "14px",
      offsetY: 20,
      offsetX: -7,
      labels: {
        colors: "gray",
      },
      markers: {
        radius: 4,
        offsetY: 1,
      },
      itemMargin: {
        vertical: 20,
        horizontal: 15,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    annotations: {
      points: [
        {
          x: todayLabelPositionX,
          y: todayLabelPositionY,
          marker: {
            size: 0,
          },
          label: {
            borderColor: "transparent",
            text: "hoje",
            style: {
              fontSize: "16px",
              color: "gray",
              background: "transparent",
              fontWeight: "bold",
            },
          },
        },
      ],
    },
  };

  return options;
}
