import { ChartMetrics, Graph } from "@/models";
import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Chart } from "./chart";
import { DashboardChartMetrics } from "./chart-metrics";

interface DashboardChartProps {
  graph: Graph;
  chartMetrics: ChartMetrics;
}

export function DashboardChart({ graph, chartMetrics }: DashboardChartProps) {
  const [thisMonth, setThisMonth] = useState((new Date().getUTCMonth()+1).toString().padStart(2, "0"));
  const [isActualMonth, setIsActualMonth] = useState(false);

  function handleIsActualMonth() {
    const actualMonth = graph.period[0].split("-")[1];
    setIsActualMonth(actualMonth === thisMonth);
  }

  useEffect(() => {
    handleIsActualMonth();
  }, [graph]);

  return (
    <Flex
      h="100%"
      w="100%"
      flexDir="column"
    >
      <DashboardChartMetrics 
        paidAmount={chartMetrics?.paidAmount}
        lateAmount={chartMetrics?.lateAmount}
        scheduledAmount={chartMetrics?.scheduledAmount}
      />
      <Flex
        h="80%"
        w="100%"
        flexDir="column"
      >
        <Chart 
          lines={graph.lines}
          period={graph.period}
          isActualMonth={isActualMonth}
        />
      </Flex>
    </Flex>
  );
}