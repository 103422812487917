export const States = [ 
  {label: 'Acre', value: 'AC'}, 
  {label: 'Alagoa', value: 'AL'}, 
  {label: 'Amapá', value: 'AP'}, 
  {label: 'Amazonas', value: 'AM'}, 
  {label: 'Bahia', value: 'BA'}, 
  {label: 'Ceará', value: 'CE'}, 
  {label: 'Espírito Santo', value: 'ES'},
  {label: 'Goiás', value: 'GO'}, 
  {label: 'Maranhã', value: 'MA'}, 
  {label: 'Mato Grosso', value: 'MT'}, 
  {label: 'Mato Grosso do Sul', value: 'MS'}, 
  {label: 'Minas Gerais', value: 'MG'}, 
  {label: 'Pará', value: 'PA'}, 
  {label: 'Paraíba', value: 'PB'},
  {label: 'Pernambuco', value: 'PE'}, 
  {label: 'Piauí', value: 'PI'}, 
  {label: 'Rio de janeiro', value: 'RJ'}, 
  {label: 'Rio Grande do Norte', value: 'RN'}, 
  {label: 'Rio Grande do Sul', value: 'RS'}, 
  {label: 'Rondônia', value: 'RO'}, 
  {label: 'Roraima', value: 'RR'},
  {label: 'Santa Catarina', value: 'SC'}, 
  {label: 'São Paulo', value: 'SP'}, 
  {label: 'Sergipe', value: 'SE'}, 
  {label: 'Tocantins', value: 'TO'}, 
  {label: 'Distrito Federal', value: 'DF'}
]