import { Flex, FlexProps, Icon, Switch, Text, Tooltip } from "@chakra-ui/react";
import { useEffect } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

type LengthUnit = "px" | "em" | "rem" | "vw" | "vh" | "%";
type Size = `${number}${LengthUnit}`;

interface TimerSwitchProps extends FlexProps {
  text: string;
  switchSize?: "sm" | "md" | "lg";
  tooltipText?: string;
  tooltipTextSwitch?: string;
  fontSize?: Size;
  color?: `#${number}` | string;
  timerState: boolean;
  setTimerState: (value: boolean) => void;
  expirationDate: boolean;
}

export function TimerSwitch({
  timerState,
  setTimerState,
  tooltipText,
  text,
  color = "black",
  fontSize = ".8rem",
  switchSize = "sm",
  tooltipTextSwitch,
  expirationDate,
  ...rest
}: TimerSwitchProps) {
  function handleSwitchChange() {
    setTimerState(!timerState);
  }
  function verifyExpirationDate() {
    if(!expirationDate) setTimerState(false);
  }
  useEffect(() => {
    verifyExpirationDate();
  }, [expirationDate]);
  return (
    <Flex {...rest} >
      <Flex align="center">
        <Text fontSize={fontSize} color={color} marginRight={tooltipText ? ".2rem" : ""}>
          {text}
        </Text>
        {tooltipText && (
          <Tooltip placement="top" hasArrow label={tooltipText}>
            <span>
              <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
            </span>
          </Tooltip>
        )}
      </Flex>
      <Tooltip placement="top" hasArrow label={tooltipTextSwitch}>
        <span>
          <Switch
            disabled={!expirationDate}
            size={switchSize}
            isChecked={expirationDate && timerState}
            onChange={handleSwitchChange}
          />
        </span>
      </Tooltip>
    </Flex>
  );
}