import { Flex, Text, Divider } from "@chakra-ui/react";
import { WithAuth } from "@/hoc";
import { usePaymentMethods } from "./usePaymentMethods";
import { Input, Select } from "@/components";

const PaymentMethods = () => {
  const {
    initialData,
    handleEnablePaymentMethod,
    register,
    paymentMethodBase,
    handleDefaultTaxInputChange,
    defaultTax,
    interestError,
    fineError,
    handleDefaultTaxSwitchChange
  } = usePaymentMethods();
  return (
    <>
      {initialData && defaultTax && (
        <Flex w="100%" flexDir="column" gap="3rem">
          <Text
            fontSize="1.6rem"
            fontWeight="600"
            color="black"
            aria-label="Page header"
          >
            Métodos de pagamento
          </Text>

          <Flex flexDir="column" gap="2rem">
            <Flex flexDir="column" gap="1rem">
              <Text
                fontSize="1rem"
                fontWeight="600"
                color="black"
                aria-label="Payment method setting"
              >
                Cartão de crédito
              </Text>
              <Select
                w="320px"
                h="40px"
                label="Ao criar nova cobrança ou assinatura"
                tooltipText="
                  Defina como quer que o método de pagamento
                  esteja ao criar uma nova cobrança. Você ainda
                  poderá ativar e desativar cada método de
                  pagamento durante a criação de uma cobrança
                  ou assinatura."
                name="defaultEnableCreditCard"
                register={{...register("defaultEnableCreditCard")}}
                onChange={(e) => handleEnablePaymentMethod(e)}
                optionsList={paymentMethodBase}
              />
            </Flex>

            <Divider orientation="horizontal" />

            <Flex w="100%" flexDir="row">
              <Flex w="100%" flexDir="column" gap="1rem">
                <Text
                  fontSize="1rem"
                  fontWeight="600"
                  color="black"
                  aria-label="Payment method setting"
                >
                  Boleto
                </Text>

                <Flex gap="2rem" >
                  <Select
                    w="320px"
                    h="40px"
                    label="Ao criar nova cobrança ou assinatura"
                    tooltipText="
                    Defina como quer que o método de pagamento
                    esteja ao criar uma nova cobrança. Você ainda
                    poderá ativar e desativar cada método de
                    pagamento durante a criação de uma cobrança
                    ou assinatura."
                    name="defaultEnableBankSlip"
                    register={{...register("defaultEnableBankSlip")}}
                    optionsList={paymentMethodBase}
                    onChange={(e) => handleEnablePaymentMethod(e)}
                  />

                  <Flex w="320px" flexDir="column">
                    <Input
                      h="40px"
                      type="text"
                      maxLength={4}
                      mask="percentageRacional"
                      name="interest"
                      label="Juros por atraso"
                      tooltipText="O juros por atraso é calculado utilizando o percentual
                      escolhido e conforme o tempo de atraso (em dias)
                      do pagamento de uma cobrança."
                      defaultValue={defaultTax?.bankSlipTax?.interest?.percentage}
                      switchActive={defaultTax?.bankSlipTax?.interest?.enable}
                      switchFunction={handleDefaultTaxSwitchChange}
                      onBlur={handleDefaultTaxInputChange}
                      hasSwitch
                      asDoubleEffect
                      errors={interestError}
                    />

                    <Text
                      fontSize=".8rem"
                      fontWeight="400"
                      color="newGray.300"
                      aria-label="Max interest percentage per month"
                    >
                      Máximo de 1% a.m. conforme Código de Defesa do Consumidor. 
                      <strong style={{fontSize: ".8rem"}}> Qualquer valor maior que esse é de responsabilidade do vendedor</strong>
                    </Text>
                  </Flex>

                  <Flex w="320px" flexDir="column">
                    <Input
                      maxLength={4}
                      h="40px"
                      type="text"
                      name="fine"
                      mask="percentageRacional"
                      label="Multa por atraso"
                      tooltipText="A multa por atraso é um valor único automaticamente
                      adicionado a uma cobrança caso tenha atrasado o
                      pagamento. O calculo é feito utilizando a porcentagem
                      que você definir sobre o valor da cobrança."
                      defaultValue={defaultTax?.bankSlipTax?.fine?.percentage}
                      switchActive={defaultTax?.bankSlipTax?.fine?.enable}
                      switchFunction={handleDefaultTaxSwitchChange}
                      onBlur={handleDefaultTaxInputChange}
                      hasSwitch
                      asDoubleEffect
                      errors={fineError}
                    />

                    <Text
                      fontSize=".8rem"
                      fontWeight="400"
                      color="newGray.300"
                      aria-label="Max fine percentage per month"
                    >
                      Máximo de 2% conforme Código de Defesa do
                      Consumidor.
                      <strong style={{fontSize: ".8rem"}}> Qualquer valor maior que esse é de responsabilidade do vendedor</strong>
                    </Text>
                    
                  </Flex>
                  
                </Flex>
              </Flex>
            </Flex>

            <Divider orientation="horizontal" />

            <Flex flexDir="column" gap="1rem">
              <Text
                fontSize="1rem"
                fontWeight="600"
                color="black"
                aria-label="Payment method setting"
              >
                Pix
              </Text>
              <Select
                w="320px"
                h="40px"
                label="Ao criar nova cobrança ou assinatura"
                tooltipText="
                Defina como quer que o método de pagamento
                esteja ao criar uma nova cobrança. Você ainda
                poderá ativar e desativar cada método de
                pagamento durante a criação de uma cobrança
                ou assinatura."
                register={{...register("defaultEnablePixPayment")}}
                name="defaultEnablePixPayment"
                optionsList={paymentMethodBase}
                onChange={(e) => handleEnablePaymentMethod(e)}
              />
            </Flex>

            <Divider orientation="horizontal" />

          </Flex>
        </Flex>
      )}
    </>
  );
};
export default WithAuth(PaymentMethods);