import { useCallback, useEffect, useRef } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { BellIcon } from "@/layouts";
import { UseInfiniteScroll } from "@/hooks";
import { MessageNotification } from "./MessageNotification";

export function NotificationDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const fourMinutes = 60000 * 4;

  const {
    data,
    handleGetNotifications,
    handleGetUnreadNotifications,
    unreadNotifications,
    hasNoMoreNotifications,
  } = UseInfiniteScroll();

  const observer = useRef<IntersectionObserver>();
  const lastNotificationElement = useCallback(
    (node: Element) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !hasNoMoreNotifications) {
          handleGetNotifications();
        }
      });
      if (node) observer.current.observe(node);
    },
    [data]
  );

  function handleOpenNotificationsBell() {
    onOpen();
    handleGetNotifications();
  }

  useEffect(() => {
    handleGetUnreadNotifications();
  }, []);

  return (
    <>
      <Flex position="relative">
        <IconButton
          ref={btnRef}
          icon={<BellIcon />}
          aria-label="notification button"
          bgColor="transparent"
          onClick={handleOpenNotificationsBell}
          _hover={{}}
        />
        {unreadNotifications > 0 && (
          <Flex
            w="16px"
            h="16px"
            borderRadius="full"
            bgColor="magenta"
            top=".2rem"
            right=".4rem"
            color="white"
            align="center"
            justify="center"
            fontSize="10px"
            position="absolute"
          >
            {unreadNotifications > 9 ? "9+" : unreadNotifications}
          </Flex>
        )}
      </Flex>
      <Drawer
        size="sm"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerContent width="600px">
          <DrawerCloseButton />
          <DrawerHeader>Notificações</DrawerHeader>
          <DrawerBody>
            {data &&
              data.map((item, index) => {
                if (data.length === index + 1) {
                  return (
                    <div ref={lastNotificationElement}>
                      <MessageNotification data={item} />
                    </div>
                  );
                }
                return <MessageNotification data={item} />;
              })}
          </DrawerBody>
          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
