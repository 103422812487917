import { Checkbox, Flex, Text } from "@chakra-ui/react";
import { ReactNode, useState } from "react";


interface AditionalValue {
    name: string;
    switchActive?: boolean;
    tooltipText?: string;
    hasSwitch?: boolean;
    label?: string;
    labelColor?: string;
    register?: any;
    setValue?: any;
    children: ReactNode;
    value?: any;
    errors?: any;
    switchFunction?: (any?: any) => void;
    color?: string;
    asDoubleEffect?: boolean;
    defaultIsOpen?: boolean;
}

export function AditionalValue({
    name,
    register,
    hasSwitch,
    asDoubleEffect,
    switchFunction,
    setValue,
    value,
    children,
    errors,
    defaultIsOpen,
    label
}: AditionalValue) {
    const [disabledState, setDisabledState] = useState(defaultIsOpen);

    function handleSwitchChange(e: any) {
        if (asDoubleEffect) {
            switchFunction(e);
        }
        setDisabledState(!disabledState);
        setValue ? setValue(name, null) : null;
    }


    return (
        <Flex flexDirection='column'
            gap="16px"
            m="12px 0"
            p={disabledState ? "16px" : "0 16px"}
            backgroundColor={disabledState ? "rgba(247, 248, 251, 1)" : ""}>
            <Flex gap="12px">
                {hasSwitch &&
                    <Checkbox
                        isChecked={disabledState}
                        defaultChecked={true}
                        onChange={() => setDisabledState(!disabledState)}
                        size="md"
                    />
                }
                <Text color="black" fontWeight={disabledState ? "600" : "200"}>
                    {label}
                </Text>
            </Flex>
            {disabledState &&
                <Flex flexDirection='column' gap="16px">
                    {children}
                </Flex>
            }
        </Flex>
    )
}