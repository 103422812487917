import { Input } from "@/components";
import { ListSelector } from "@/components/Forms/Selector";
import { Countries } from "@/models/Countries";
import { States } from "@/models/States";
import { formatCurrency, formatDate, phone_format } from "@/utils";
import { formatPercentage } from "@/utils/formatPercentage";
import { Divider, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { SubSellerEditDto } from "../id";

interface AddressSectionProps {
  initialData?: SubSellerEditDto;
  register?: any;
  errors?: any;
  control?: any;
  onEditMode?: boolean;
}

export function AddressSection({
  initialData,
  register,
  errors,
  control,
  onEditMode
}: AddressSectionProps) {
  return (
    <>
      <Divider />
      <Flex flexDir="column" w="100%" h="100%">
        <Text
          fontWeight="600"
          fontSize="25px"
          color="#262F40"
          marginBottom="15px"
        >
          Endereço
        </Text>
        <SimpleGrid
          w="100%"
          h="100%"
          columns={3}
          rowGap={8}
          columnGap={8}
        >

          <Input
            maxW="250px"
            label="CEP"
            type="text"
            name="zipCode"
            register={{ ...register("zipCode") }}
            isDisabled={!onEditMode}
            p={!onEditMode ? "0" : ""}
            _disabled={{
              bg: "transparent",
              border: "none",
              cursor: "default"
            }}
            errors={errors?.zipCode}
          />

          <Input
            maxW="250px"
            label="Rua"
            type="text"
            name="street"
            register={{ ...register("street") }}
            isDisabled={!onEditMode}
            p={!onEditMode ? "0" : ""}
            _disabled={{
              bg: "transparent",
              border: "none",
              cursor: "default"
            }}
            errors={errors?.street}
          />

          <Input
            maxW="250px"
            label="Número"
            type="text"
            name="number"
            register={{ ...register("number") }}
            isDisabled={!onEditMode}
            p={!onEditMode ? "0" : ""}
            _disabled={{
              bg: "transparent",
              border: "none",
              cursor: "default"
            }}
            errors={errors?.number}
          />

          <Input
            maxW="250px"
            label="Complemento"
            type="text"
            name="complement"
            register={{ ...register("complement") }}
            isDisabled={!onEditMode}
            p={!onEditMode ? "0" : ""}
            _disabled={{
              bg: "transparent",
              border: "none",
              cursor: "default"
            }}
            errors={errors?.complement}
          />

          <Input
            maxW="250px"
            label="Bairro"
            type="text"
            name="district"
            register={{ ...register("district") }}
            isDisabled={!onEditMode}
            p={!onEditMode ? "0" : ""}
            _disabled={{
              bg: "transparent",
              border: "none",
              cursor: "default"
            }}
            errors={errors?.district}
          />

          <Input
            maxW="250px"
            label="Cidade"
            type="text"
            name="city"
            register={{ ...register("city") }}
            isDisabled={!onEditMode}
            p={!onEditMode ? "0" : ""}
            _disabled={{
              bg: "transparent",
              border: "none",
              cursor: "default"
            }}
            errors={errors?.city}
          />

          {!onEditMode && (
            <Flex
              flexDir="column"
            >
              <Text fontSize=".8rem" marginBottom=".8rem">
                Estado
              </Text>
              <Text
                fontSize=".9rem"
                color="black"
              >
                {initialData?.address?.state}
              </Text>
            </Flex>
          )}

          {onEditMode && (
            <ListSelector
              maxW="250px"
              label="Estado"
              placeholder="Escolha seu estado"
              options={States}
              showValueText
              name="state"
              register={{ ...register("state") }}
              isDisabled={!onEditMode}
              p={!onEditMode ? "0" : ""}
              _disabled={{
                bg: "transparent",
                border: "none",
                cursor: "default"
              }}
              color="black"
              errors={errors?.state}
            />
          )}

          {!onEditMode && (
            <Flex
              flexDir="column"
            >
              <Text fontSize=".8rem" marginBottom=".8rem">
                País
              </Text>
              <Text
                fontSize=".9rem"
                color="black"
              >
                {initialData?.address?.country}
              </Text>
            </Flex>
          )}

          {onEditMode && (
            <ListSelector
              maxW="250px"
              label="País"
              placeholder="Escolha seu país"
              options={Countries}
              name="country"
              register={{ ...register("country") }}
              isDisabled={!onEditMode}
              _disabled={{
                bg: "transparent",
                border: "none",
                cursor: "default"
              }}
              color="black"
              errors={errors?.country}
            />
          )}

        </SimpleGrid>
      </Flex>
    </>
  )
}