import styled, { css } from "styled-components";

interface OverlayProps {
  isOpen?: boolean;
  hasContentUpdwards?: boolean;
  hasContentDownwards?: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  height: 100vh;
  overflow-y: hidden;
  background-color: var(--white-025);
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 5% 30%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isOpen }) => !isOpen && css`
    display: none;
  `}
h1{
  font-size: 14px;
}
  .modal {
    display: flex;
    flex-direction: column;
    height: 395px;
    width: 520px;
    z-index: 200;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05), 0px 4px 20px rgba(0, 0, 0, 0.04);
    .modal-header {
      box-shadow: ${props => props.hasContentUpdwards ? "0px 12px 20px rgba(0, 0, 0, 0.03)" : ""};
      border-bottom: 1px solid var(--gray-100);
      height: 57px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;

      div.header-text {
        display: flex;
        align-items: center;
        gap: .8rem;
      }

      p {
        color: black;
        font-size: 1.1rem;
      }

      button {
        padding: 0;
        display: flex;
        border-radius: 2px;
        border: 1px solid #D9D9D9;
        background-color: white;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
      }
    }

    .modal-body {
      padding: 1.5rem 2rem;
      height: 80%;
      width: 100%;
      .modal-alert {
        padding: 8px 12px 8px 12px;
        border: 1px solid #FFCCC7;
        border-radius: 2px;
        background-color: #FFF1F0;
        p {
          color: #820014;
          font-size: 0.9rem;
        }
      }
      .title{
        display : flex;
        justify-content : space-between;
        padding: 8px 16px 8px 0px;
        .title-name {
          width : 150%;
          gap: 15px;
          display : flex;
        }
        .title-value {
          width : 50%;
          display : flex;
          font-weight : 600;
        }
      }
      .splits{
        color: #6E7A91;
        font-weight : 400;
      } 
      .box-subellers {
        border: 1px solid rgba(230, 233, 240, 1);
        border-radius : 4px;
        padding: 8px 16px 8px 16px;
        .box-list {
          width : 100%;
          display : flex;
          justify-content : space-between;
          margin-bottom : 5px;
          .name-box {
            width : 150%;
            gap: 15px;
            display : flex;
            .box-taxas{
              display: flex;
              justify-content : center;
              align-content : center;
              align-items: center;
              background-color:#E6E9F0;
              padding: 2px 4px;
              border-radius: 4px;
              font-size: 10px;
              color:#6E7A91;
            }
          }
          .value-box {
            width : 50%;
            display : flex;
            font-weight : 600;
          }
        }
      }
    }

    .modal-footer {
      box-shadow: ${props => props.hasContentDownwards ? "0px -12px 20px rgba(0, 0, 0, 0.03);" : ""};
      border-top: 1px solid var(--gray-100);
      height: 52px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;

      .modal-buttons {

        width : 100%;
        gap: 8px;
        display : flex;
        justify-content : end;


        button {
          padding: 0;
          background-color: white;
        }
        
        .back-button {
          border :  1.5px solid #E6E9F0;
          padding: 2px 8px;
          border-radius: 2px;
        }
        .confirm-button {
          background-color: var(--blue-200);
          padding: 5px 16px;
          border-radius: 2px;
          color: white;
          transition: all .2s ease-in-out;
          &:disabled {
            background-color: #F5F5F5;
            color: rgba(0, 0, 0, 0.25);
            border-radius: 2px;
            border: 1px solid #D9D9D9;
            &:hover: {
              background-color: #F5F5F5;
              cursor: not-allowed;
            }
          }
          &:hover {
            transition: all .2s ease-in-out;
            filter: brightness(.9);
          }
        }
      }

      p {
        font-size: .8rem;
        color: color="#000000D9";
      }
    }
  }
`