import { TimerSwitch } from "@/layouts/SejaPago/TimerSwitch";
import { Button, Checkbox, Flex, forwardRef, Icon, Switch, Text, Tooltip } from "@chakra-ui/react";
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useController } from "react-hook-form";

interface TimeDatePickerInputProps {
  label?: string;
  name: string;
  control: any;
  tooltipText?: string;
  hasSwitch?: boolean;
  disabledState?: boolean;
  register?: any;
  setDisabledState?: (data: boolean) => void;
  startEnabled?: boolean;
  dateSwitchState?: boolean;
  errors?: any;
  setValue?: any;
  timerState: boolean;
  setTimerState: (value: boolean) => void;
  format?: string;
}

export function TimeDatePickerInput({
  label,
  control,
  tooltipText,
  name,
  hasSwitch,
  disabledState,
  startEnabled,
  setDisabledState,
  register,
  dateSwitchState,
  errors,
  setValue,
  timerState,
  setTimerState,
  format,
  ...rest
}: TimeDatePickerInputProps) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      onClick={onClick}
      ref={ref}
      {...register}
      name={name}
      p="5px 12px"
      w="100%"
      borderRadius="4px"
      bgColor="transparent"
      border="1px solid"
      borderColor={disabledState ? "newGray.100" : "newGray.200"}
      fontSize="16px"
      disabled={hasSwitch && !disabledState}
      _focus={{
        border: "1px solid",
        borderColor: "blue.200",
      }}
      _hover={{
      }}
      _disabled={{
        cursor: "not-allowed",
      }}
      position="relative"
      color={hasSwitch && !disabledState ? "newGray.200" : "black"}
      fontWeight="500"
    >
      <Text position="absolute" left="10px">
        {value}
      </Text>
    </Button>
  ));

  useEffect(() => {
    setValue("timer", selectedDate ?? new Date());
  }, []);

  return (
    <Flex flexDir="column" gap="4px" justifyContent="center"
      m="12px 0"
      p={disabledState ? "16px" : "0 16px"}
      backgroundColor={disabledState ? "rgba(247, 248, 251, 1)" : ""}>
      <Flex align="center" alignContent="center" alignItems="center" gap="12px">
        {hasSwitch && (
          <Flex h="100%">

            <Checkbox
              isChecked={disabledState}
              defaultChecked={true}
              onChange={() => setDisabledState(!disabledState)}
              size="md"
            />
          </Flex>

        )}
        <Flex align="center" marginRight={tooltipText ? ".2rem" : ""}>
          {label && (
            <Text
              marginRight={tooltipText ? ".2rem" : ""}
              fontSize="16px"
              color={hasSwitch && !disabledState ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.85)"}
              fontWeight={hasSwitch && !disabledState ? "200" : "600"}
            >
              {label}
            </Text>
          )}
        </Flex>
      </Flex>

      {disabledState && (


        <Flex flexDir="column" alignItems="flex-start" w="100%" align="center" position="relative" justify="space-between">
          <Controller
            name={name}
            control={control}
            defaultValue={selectedDate ?? new Date()}
            render={({ field }: any) => (
              <DatePicker
                {...rest}
                locale={ptBR}
                onBlur={onBlur}
                selected={field.value}
                wrapperClassName="datePicker"
                onChange={(date) => { field.onChange(date) }}
                minDate={new Date()}
                disabled={hasSwitch && !disabledState}
                showTimeSelect={format !== "dd/MM/yyyy"}
                timeFormat={!format ? "HH:mm" : ""}
                timeIntervals={15}
                filterTime={filterPassedTime}
                dateFormat={format || "dd/MM/yyyy HH:mm"}
                timeCaption="Time"
                customInput={<CustomInput />}
              />
            )}
          />
          {format !== "dd/MM/yyyy" &&

            <TimerSwitch
              expirationDate={disabledState}
              timerState={timerState}
              setTimerState={setTimerState}
              text="Contagem regressiva"
              marginTop="1rem"
              justify="space-between"
              align="center"
              w="100%"
            />
          }
          {errors && (
            <Text fontSize=".8rem" color="danger" role="alert">
              {errors?.message}
            </Text>
          )}
        </Flex>

      )}

    </ Flex>
  )
}