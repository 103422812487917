import { createContext, useState, useEffect } from "react";
import { Api } from "@/services";
import { showToast } from "@/utils";
import { useNavigate } from "react-router-dom";
import { useFeatureToggle } from "@/hooks";
import { v4 as uuidv1 } from 'uuid';
import { stringify } from "querystring";
import { Api_Banking } from "@/services/Api/bankingApiConfig";

type signInDataType = {
  email: string;
  password: string;
};

type UserType = {
  email: string;
  roles: object;
  idSeller: number;
  document: string;
};

type AuthContextType = {
  isAuthenticated: boolean;
  user: UserType;
  signIn: (data: signInDataType) => Promise<void>;
  featureTogglesUser: string[];
  getFeatureTogglesList: () => void;
};

export const AuthContext = createContext({} as AuthContextType);

export function signOut() {
  localStorage.removeItem("barte-auth-token");
  localStorage.removeItem("barte-user-info");
  localStorage.removeItem("barte-features-list-user");
  window.location.replace(String(import.meta.env.VITE_FRONT_URL) + "login");
}
const getUser = () => {
  if (typeof window !== "undefined") {
    const user = JSON.parse(localStorage.getItem("barte-user-info"));
    if (!user) return null;
    return user;
  }
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState<UserType | null>(getUser);
  const [featureTogglesUser, setFeatureTogglesUser] = useState(null);
  const isAuthenticated = false;
  const navigate = useNavigate();
  const { getFeatureToggles } = useFeatureToggle();
  const [attemptReference, setAttemptReference] = useState(uuidv1());

  async function getFeatureTogglesList() {
    try {
      const featureToggleResponse = await getFeatureToggles();
      setFeatureTogglesUser(featureToggleResponse.data);
      localStorage.removeItem("barte-features-list-user");
      localStorage.setItem(
        "barte-features-list-user",
        JSON.stringify(featureToggleResponse.data)
      );
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    if (attemptReference) {
      const nethoneScript = document.createElement("script");
      nethoneScript.type = "text/javascript";
      nethoneScript.id = "nethone-script";
      nethoneScript.crossOrigin = "use-credentials";
      nethoneScript.src = "https://d354c9v5bptm0r.cloudfront.net/s/68741/dQItJr.js";
      nethoneScript.async = true
      document.body.appendChild(nethoneScript);
      nethoneScript.onload = () => {
        localStorage.setItem("attempt_reference", attemptReference)
        const nethoneOptions = {
          attemptReference: attemptReference,
          sensitiveFields: ["cardHolderName", "cardCvv", "cardExpirationDate", "cardNumber"],
        };

        if ((window as any).dftp) {
          (window as any).dftp.init(nethoneOptions);
        } else {
          nethoneScript.addEventListener("load", () => {
            if ((window as any).dftp) {
              (window as any).dftp.init(nethoneOptions);
            }
          });
        }
      }
    };



  }, [attemptReference]);

  async function signIn(data: signInDataType) {
    localStorage.removeItem("barte-auth-token");
    const newData = { ...data, attemptReference }
    try {
      const login = await Api.post("public/auth/sign-in", newData);
      const { email, roles, token, refreshToken, idSeller, mixedInstallment, document } = login.data;
      localStorage.setItem("barte-auth-token", token);
      localStorage.setItem("barte-refresh-token", refreshToken);
      localStorage.setItem("barte-user-info", JSON.stringify({ email, roles, idSeller, document }));
      localStorage.setItem("barte-user-document", document);
      localStorage.setItem("hasMixInstallments", String(mixedInstallment))
      Api.defaults.headers["Authorization"] = `Bearer ${token}`;
      Api_Banking.defaults.headers["Authorization"] = `Bearer ${token}`;
      setUser({ email, roles, idSeller, document });
      await getFeatureTogglesList();

      // /* @ts-ignore */
      // if (octadesk) {
      //   /* @ts-ignore */
      //   octadesk?.chat?.login({
      //     // name: user.name,
      //     email: user?.email,
      //   });
      // }

      /* @ts-ignore */
      if (window?.userGuiding) {
        //@ts-ignore
        window?.userGuiding?.identify(email, {
          name: email,
          email: email,
          created_at: new Date(),
        })
      }

      if (roles?.includes("SELLER_BUSINESS")) {
        navigate("/seja-pago", { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        if (error?.code === "BAR-9999") return showToast({ message: "Erro ao realizar login", type: "error" });
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  useEffect(() => {
    let featuresList = JSON.parse(
      localStorage.getItem("barte-features-list-user")
    );
    if (featuresList && featuresList.length > 0) {
      setFeatureTogglesUser(featuresList);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        signIn,
        featureTogglesUser,
        getFeatureTogglesList,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
