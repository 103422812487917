import {
  Button,
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Heading,
  Box,
} from "@chakra-ui/react";
import { RiErrorWarningLine } from "react-icons/ri";

interface ModalProps extends ChakraModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmModal: () => void;
  title: string;
  yesNoText?: boolean;
  isSubmitting?: boolean;
}

const ConfirmationModal = ({
  isOpen,
  onClose,
  handleConfirmModal,
  children,
  isCentered = true,
  title,
  yesNoText = false,
  isSubmitting = false,
}: ModalProps) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent borderRadius="2px" p="32px">
        <ModalHeader position="relative">
          <Box position="absolute" left="-5px">
            <RiErrorWarningLine size={24} color="#FAAD14" />
          </Box>
          <Heading fontSize="22px">{title}</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <HStack>
            <Button variant="outline" onClick={onClose} borderRadius="2px">
              {yesNoText ? "Não" : "Cancelar"}
            </Button>
            <Button
              id={
                title.toLowerCase().includes("excluir")
                  ? "excluir_cobrança"
                  : ""
              }
              onClick={handleConfirmModal}
              variant="primary"
              borderRadius="2px"
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            >
              {yesNoText ? "Sim" : "Confirmar"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export { ConfirmationModal };
