import { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  Link,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModal, ConfirmationModal } from "@/components";
import { useIntegration, useSettings } from "@/hooks";
import { CreateSellerToken } from "@/models";
import { showToast } from "@/utils";
import { IntegrationEmptyState } from "@/pages/configuracoes/integracao/_EmptyState";
import { TokenItem } from "./_token-item";

const tokenCreateScheama = yup.object().shape({
  title: yup.string().required("Preenchimento obrigatório!"),
});

export const ApiKeys = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { postSellerApiToken } = useSettings();
  const {
    tokens,
    isAlertActiveOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    setIsAlertActiveOpen,
    deleteToken,
    getTokensInitialData,
    changeActive,
    chooseToken,
    isLoading,
    setIsLoading,
  } = useIntegration();

  useEffect(() => {
    getTokensInitialData();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(tokenCreateScheama),
  });
  const getTitleValue = watch("title");
  const onSubmit = handleSubmit(async (data: CreateSellerToken) => {
    try {
      setIsLoading(true);
      await postSellerApiToken(data);
      getTokensInitialData();
      showToast({ message: "Token criado com sucesso!", type: "success" });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    } finally {
      setIsLoading(false);
      setIsCreateModalOpen(!isCreateModalOpen);
    }
  });

  useEffect(() => {
    setValue("title", `Chave ${tokens?.length + 1}`);
  }, [tokens?.length]);
  return (
    <>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        handleConfirmModal={deleteToken}
        title="Tem certeza que deseja excluir essa chave?"
        isSubmitting={isLoading}
        yesNoText
      >
        <Text>Essa ação não poderá ser desfeita.</Text>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={isAlertActiveOpen}
        onClose={() => setIsAlertActiveOpen(!isAlertActiveOpen)}
        handleConfirmModal={changeActive}
        isSubmitting={isLoading}
        title={
          chooseToken.active
            ? "Tem certeza que deseja desativar essa chave?"
            : "Tem certeza que deseja reativar essa chave?"
        }
        yesNoText
      >
        {chooseToken.active ? (
          <Text>
            As integrações relativas a essa chave irão parar de funcionar
            imediatamente.
          </Text>
        ) : (
          <Text>
            As integrações relativas a essa chave começarão a funcionar
            imediatamente.
          </Text>
        )}
      </ConfirmationModal>
      <FormModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(!isCreateModalOpen);
        }}
        title="Nova chave API"
        handleConfirmModal={onSubmit}
        isSubmitting={isLoading}
        disableWhenIsFormEmpty={getTitleValue === ""}
        confirmationButtonText="Salvar"
      >
        <Input name="title" {...register("title")} borderRadius="2px" />
      </FormModal>
      <Flex w="100%" direction="column" gap={4}>
        <Flex align="center">
          <Heading fontSize="20px">Chaves API</Heading>
          <Flex gap={8} ml="auto">
            <Button
              variant="link"
              as={Link}
              href="https://app.swaggerhub.com/apis-docs/b6782/barte-api/1.0.0#/"
              target="_blank"
            >
              ver documentação
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setIsCreateModalOpen(!isCreateModalOpen);
              }}
            >
              Nova chave API
            </Button>
          </Flex>
        </Flex>
        {tokens.length > 0 ? (
          <Stack>
            {tokens?.map((token) => (
              <TokenItem key={token.uuid} token={token} />
            ))}
          </Stack>
        ) : (
          <IntegrationEmptyState message="Nenhuma chave cadastrada ainda" />
        )}
      </Flex>
    </>
  );
};
