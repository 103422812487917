import styled from "styled-components";
export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .5rem;
    background-color: #FFF7E6;
    padding: 1rem 0;
    align-items: center;
    border-radius: .25rem;
    section {
        display: flex;
        gap: .5rem;
        align-items: center;
        h1 {
            font-size: 1rem;
            font-weight: 600;
        }
    }
    span {
        max-width: 600px;
    }
`