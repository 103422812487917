import { IconProps } from "@/models";

export function OkayIcon({
  color = "#000",
  opacity = "1",
}: IconProps) {
  return (
    <svg 
      width="10"
      height="8"
      viewBox="0 0 10 8" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.68769 0.226562H8.86855C8.75371 0.226562 8.64472 0.279297 8.57441 0.369531L3.74277 6.49023L1.42597 3.55469C1.39092 3.51019 1.34625 3.47421 1.29531 3.44945C1.24436 3.42469 1.18847 3.41179 1.13183 3.41172H0.31269C0.234174 3.41172 0.190815 3.50195 0.238861 3.56289L3.44863 7.6293C3.59863 7.81914 3.88691 7.81914 4.03808 7.6293L9.76152 0.376563C9.80957 0.316797 9.76621 0.226562 9.68769 0.226562Z" fill={color} />
    </svg>
  );
}