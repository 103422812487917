import { Api } from "@/services";

export function useFeatureToggle() {
  const baseUrl = "/users";

  const getFeatureToggles = () => {
    return Api.get(`${baseUrl}/feature-toggles`);
  };

  return {
    getFeatureToggles,
  };
}
