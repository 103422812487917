import { Text, TextProps } from "@chakra-ui/react";

interface TitleSectionProps extends TextProps {
  text: string;
}

const TitleSection = ({ text, ...rest }: TitleSectionProps) => (
  <Text as="h2" color={"gray.400"} fontSize={"1rem"} {...rest}>
    {text}
  </Text>
);

export { TitleSection };
