import {
  Flex,
  Stack,
  Text,
  Button,
} from "@chakra-ui/react";

const NotaFiscalConfig = () => {
  return (
    <>
      <Flex w="100%" py="8" direction="column">
        <Stack maxW="550px" gap={4}>
          <Text>
            Sua Nota Fiscal ainda não está configurada. Para configurar, acesse o formulário abaixo.
          </Text>
        </Stack>
        <Button
          p="5px 16px"
          bgColor="blue.200"
          color="white"
          maxW="200px"
          borderRadius="2px"
          mt="8"
          onClick={() => window.open("https://form.typeform.com/to/FcE3JDxh")}
        >
          Configurar Nota Fiscal
        </Button>
      </Flex>
    </>
  );
};

export default NotaFiscalConfig;
