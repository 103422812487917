import { Button, Flex, FlexProps, Icon, Image, Switch, Text } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
interface ContainerProps extends FlexProps {
  setContainerValue?: (prop: boolean) => void;
  containerValue?: boolean;
  active?: boolean;
  startsOpen?: boolean;
  title: string;
  hasPadding?: boolean;
  children: ReactNode;
  isOk?: boolean;
  type?: "check" | "retract";
  button?: boolean;
  buttonTitle?: string;
  buttonAction?: () => void;
}
export function NewContainer({ startsOpen, isOk, containerValue, type, setContainerValue, active, title, children, hasPadding, button, buttonTitle, buttonAction, ...rest }: ContainerProps) {
  const [containerState, setContainerState] = useState(true);
  return (
    <Flex
      p={hasPadding ? "1.5rem" : ""}
      borderRadius=".2rem"
      bgColor="white"
      gap="1rem"
      flexDirection="row"
      w="100%"

      {...rest}
    >
      {type === "check" && (
        <>
          <Flex
            w="100%"
            gap="10px"
            alignItems={button ? "center" : ""}
          >

            <Image fontSize="1.2rem" w="24px" h="24px" src={isOk ? "/images/icons/checked.svg" : "/images/icons/noChecked.svg"} />

            {title && (
              <Text
                fontSize="1.2rem"
                color="black"
                fontWeight="600"
              >
                {title}
              </Text>
            )}

          </Flex>
          <Flex
            flexDir="column"
            display={containerState ? "flex" : "none"}
            gap="1.5rem"
            w="100%"
          >
            {active && (
              <Flex align="center" justify="space-between">
                <Text fontSize="16px" color="black" fontWeight="600">Ativar {title.toLowerCase()}</Text>
                <Switch
                  defaultChecked={containerValue}
                  onChange={(e) => setContainerValue(e.target.checked)}
                />
              </Flex>
            )}
            {children}
          </Flex>
        </>
      )}
      {type === "retract" && (
        <Flex
          w="100%"
          flexDirection="column"
          justifyContent="end"
          gap="12px"
        >
          <Flex
            w="100%"
            justify="space-between"
            gap="10px"
            cursor="pointer"
            onClick={() => setContainerState(!containerState)}
            p="0 45px"
          >
            {title && (
              <Text
                fontSize="1.2rem"
                color="black"
                fontWeight="600"
              >
                {title}
              </Text>
            )}
            <Icon fontSize="1.6rem" as={containerState ? MdKeyboardArrowUp : MdKeyboardArrowDown} />

          </Flex>
          <Flex
            flexDir="column"
            display={containerState ? "flex" : "none"}
            gap="1.5rem"
            marginLeft="auto"
            w="50%"
          >
            {active && (
              <Flex align="center" 
          

              >
                <Text fontSize="16px" color="black" fontWeight="600">Ativar {title.toLowerCase()}</Text>
                <Switch
                  defaultChecked={containerValue}
                  onChange={(e) => setContainerValue(e.target.checked)}
                />
              </Flex>
            )}
            {children}
          </Flex>
        </Flex>
      )}
      {button && (
        <Button
          type="button"
          onClick={buttonAction}
          variant="cancel"
          ml="auto"
          w="360px"
        >
          {buttonTitle}
        </Button>
      )}
    </Flex>
  )
}