import { useEffect, useState } from "react";
import { paginationProps } from "./pagination.model";
import { StyledReactPaginate } from "./pagination.styled";

export function Pagination({ totalPages, setCurrentPage }: paginationProps) {
  const [pageCount, setPageCount] = useState(totalPages);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  useEffect(() => {
    setPageCount(totalPages);
  }, [totalPages]);

  return (
    <>
      <StyledReactPaginate
        initialPage={0}
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        onPageChange={handlePageClick}
        pageCount={pageCount}
      />
    </>
  );
}
