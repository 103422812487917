import { useEffect, useState } from "react";
import { Button, Checkbox, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { SellerWebhooks } from "@/models";
import { showToast } from "@/utils";
import { useSettings, useIntegration } from "@/hooks";
import { Input, FormModal, ConfirmationModal } from "@/components";
import { IntegrationEmptyState } from "../_EmptyState";
import { WebhookItem } from "./_webhook-item";

const webhookCreateScheama = yup.object().shape({
  title: yup.string().required("Preenchimento obrigatório!"),
  url: yup
    .string()
    .required("Preenchimento obrigatório!")
    .matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      "Entre com uma url correta"
    )
    .required("Preenchimento obrigatório!"),
  subscriptions: yup.boolean(),
  order: yup.boolean(),
});

export const WebHooks = () => {
  const {
    webhooks,
    getWebhooksInitialData,
    isLoading,
    setIsLoading,
    isWebhookDeleteModalOpen,
    isWebhookAlertActiveOpen,
    setIsWebhookAlertActiveOpen,
    setIsWebhookDeleteModalOpen,
    changeWebhookActive,
    chooseWebhook,
    deleteWebhook,
  } = useIntegration();

  const { postSellerApiWebhook } = useSettings();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  useEffect(() => {
    getWebhooksInitialData();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(webhookCreateScheama) });

  const getTitleValue = watch("title");
  const getUrlValue = watch("url");
  const getSubscriptionsValue = watch("subscriptions");
  const getOrderValue = watch("order");
  const onSubmit = handleSubmit(
    async (
      data: Pick<SellerWebhooks, "title" | "url" | "subscriptions" | "order">
    ) => {
      try {
        let domains = [
          data.subscriptions ? "SUBSCRIPTION" : null,
          data.order ? "ORDER" : null,
        ];
        const formData = {
          title: data.title,
          domains: domains.filter((domain) => domain !== null),
          url: data.url,
        };
        setIsLoading(true);
        await postSellerApiWebhook(formData);
        getWebhooksInitialData();
        showToast({ message: "Webhook criado com sucesso!", type: "success" });
      } catch (error) {
        error?.response?.data?.errors.map((error) => {
          showToast({
            type: "error",
            message: `${error?.code} - ${error?.description}`
          })
        });
      } finally {
        setIsLoading(false);
        setIsCreateModalOpen(!isCreateModalOpen);
      }
    }
  );
  return (
    <>
      <ConfirmationModal
        isOpen={isWebhookDeleteModalOpen}
        onClose={() => setIsWebhookDeleteModalOpen(!isWebhookDeleteModalOpen)}
        handleConfirmModal={deleteWebhook}
        title="Tem certeza que deseja excluir esse webhook?"
        isSubmitting={isLoading}
        yesNoText
      >
        <Text>Essa ação não poderá ser desfeita.</Text>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={isWebhookAlertActiveOpen}
        onClose={() => setIsWebhookAlertActiveOpen(!isWebhookAlertActiveOpen)}
        handleConfirmModal={changeWebhookActive}
        isSubmitting={isLoading}
        title={
          chooseWebhook.active
            ? "Tem certeza que deseja desativar esse webhook?"
            : "Tem certeza que deseja reativar esse webhook?"
        }
        yesNoText
      >
        {chooseWebhook.active ? (
          <Text>
            As integrações relativas a esse webhook irão parar de funcionar
            imediatamente.
          </Text>
        ) : (
          <Text>
            As integrações relativas a esse webhook começarão a funcionar
            imediatamente.
          </Text>
        )}
      </ConfirmationModal>
      <FormModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(!isCreateModalOpen);
        }}
        title="Novo Webhook"
        handleConfirmModal={onSubmit}
        isSubmitting={isLoading}
        disableWhenIsFormEmpty={
          !getTitleValue ||
          !getUrlValue ||
          (!getSubscriptionsValue && !getOrderValue)
        }
        confirmationButtonText="Salvar"
      >
        <Stack spacing={4}>
          <Input
            type="text"
            label="Nome"
            name="title"
            register={{ ...register("title") }}
            borderRadius="2px"
            errors={errors?.title}
          />
          <Input
            type="text"
            label="Sua URL"
            name="url"
            register={{ ...register("url") }}
            borderRadius="2px"
            errors={errors?.url}
          />
          <Text color="gray.400" fontSize=".8rem">
            Domínios
          </Text>
          <Checkbox
            maxW="80px"
            name="subscriptions"
            {...register("subscriptions")}
          >
            Assinaturas
          </Checkbox>
          <Checkbox maxW="180px" name="orders" {...register("order")}>
            Cobrança Pontual
          </Checkbox>
        </Stack>
      </FormModal>
      <Flex w="100%" direction="column" gap={4}>
        <Flex align="center">
          <Heading fontSize="20px">Webhooks</Heading>
          <Flex gap={8} ml="auto">
            {/* <Button variant="link">ver documentação</Button> */}
            <Button
              variant="secondary"
              onClick={() => setIsCreateModalOpen(!isCreateModalOpen)}
            >
              Novo Webhook
            </Button>
          </Flex>
        </Flex>
        {webhooks.length > 0 ? (
          <Stack>
            {webhooks?.map((webhook) => (
              <WebhookItem key={webhook.uuid} webhook={webhook} />
            ))}
          </Stack>
        ) : (
          <IntegrationEmptyState message="Nenhum webhook cadastrado ainda" />
        )}
      </Flex>
    </>
  );
};
