import { IconProps } from "@/models";

export function InfoCircleOutlined({
  size = 20,
  color = "#000",
  opacity = "1",
}: IconProps) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C6.20156 2 1.5 6.70156 1.5 12.5C1.5 18.2984 6.20156 23 12 23C17.7984 23 22.5 18.2984 22.5 12.5C22.5 6.70156 17.7984 2 12 2ZM12 21.2188C7.18594 21.2188 3.28125 17.3141 3.28125 12.5C3.28125 7.68594 7.18594 3.78125 12 3.78125C16.8141 3.78125 20.7188 7.68594 20.7188 12.5C20.7188 17.3141 16.8141 21.2188 12 21.2188Z" fill="black" fillOpacity="0.85"/>
    <path d="M10.875 8.375C10.875 8.67337 10.9935 8.95952 11.2045 9.1705C11.4155 9.38147 11.7016 9.5 12 9.5C12.2984 9.5 12.5845 9.38147 12.7955 9.1705C13.0065 8.95952 13.125 8.67337 13.125 8.375C13.125 8.07663 13.0065 7.79048 12.7955 7.5795C12.5845 7.36853 12.2984 7.25 12 7.25C11.7016 7.25 11.4155 7.36853 11.2045 7.5795C10.9935 7.79048 10.875 8.07663 10.875 8.375ZM12.5625 11H11.4375C11.3344 11 11.25 11.0844 11.25 11.1875V17.5625C11.25 17.6656 11.3344 17.75 11.4375 17.75H12.5625C12.6656 17.75 12.75 17.6656 12.75 17.5625V11.1875C12.75 11.0844 12.6656 11 12.5625 11Z" fill="black" fillOpacity="0.85"/>
    </svg>
  );
}
