import { Filter, Pagination } from "@/components";
import { WithAuth } from "@/hoc";
import { useSubSeller } from "@/hooks/useSubSeller";
import { ApiFakeRequestFunction } from "@/services/Apifake";
import { RightOutlined } from "@/styles/icons/RightOutlined";
import { clearMask, formatCurrency, hasOnlyNumbers, showToast } from "@/utils";
import { FormatDocument } from "@/utils/formatDocument";
import { formatPercentage } from "@/utils/formatPercentage";
import { SubSellerStatus, SubSellerStatusBg, SubSellerStatusBorder, SubSellerStatusColor } from "@/utils/subSellerStatus";
import { Box, Button, Flex, Link, Text, Tooltip } from "@chakra-ui/react";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface SubSellersDTO {
  id: number;
  sellerId: number;
  account: {
    id: number;
    bank: number;
    issuer: string;
    number: string;
    pixKey: string;
  };
  address: {
    id: number;
    city: string;
    country: string;
    district: string;
    number: string;
    state: string;
    street: string;
    zipCode: string;
  };
  contact: {
    id: number;
    countryCode: string;
    email: string;
    name: string;
    phone: string;
  };
  owner: {
    birthdate: string;
    ownerDocument: string;
    ownerName: string;
  }
  document: string;
  documentType: string;
  isActive: true;
  name: string;
  paymentValue: number;
  paymentType: string;
}

const SubSellers = () => {
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  const [subSellers, setSubSellers] = useState<Array<SubSellersDTO>>(null);
  const [page, setPage] = useState(0);
  const [totalOfPages, setTotalOfPages] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [filter, setFilter] = useState({
    name: "",
    document: "",
  })
  const { TabPane } = Tabs;
  const navigate = useNavigate();

  const {
    getSubsellers
  } = useSubSeller();

  function handleCreateNewSubSeller() {
    navigate("novo");
  }

  function handleSearchSubSeller(value) {
    if (hasOnlyNumbers(clearMask(value))) {
      setFilter({
        name: "",
        document: clearMask(value)
      })
    } else {
      setFilter({
        name: value,
        document: ""
      })

    }
  }
  async function handleGetData() {
    setLoading(true)
    try {
      const { data } = await getSubsellers(filter, { page: page });
      setSubSellers(data?.content);
      setTotalOfPages(data?.totalPages);
    } catch (error) {
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    } finally {
      setLoading(false)

    }
  }

  useEffect(() => {
    handleGetData();
  }, [page, filter]);

  const columns: ColumnsType<SubSellersDTO> = [
    {
      key: "name",
      title: "Nome",
      dataIndex: "name",
      render: (_, render) => (render?.name)
    },
    {
      key: "document",
      title: "Documento",
      dataIndex: "document",
      render: (_, render) => (FormatDocument(render?.document))
    },
    {
      key: "value",
      title: "Taxa",
      dataIndex: "value",
      render: (_, render) => (
        <Box p="0">
          {
            render.paymentType.includes("FIXED") ?
              formatCurrency({ value: render?.paymentValue }) :
              formatPercentage({ value: render?.paymentValue })
          }
        </Box>
      )
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (_, render) => (
        render?.isActive ?
          <Flex
            p="0 10px"
            w="fit-content"
            justify="center"
            align="center"
            height="24px"
            bgColor={SubSellerStatusBg("ACTIVE")}
            border={`1px solid ${SubSellerStatusBorder("ACTIVE")}`}
            borderRadius="36px"
          >
            <Text color={SubSellerStatusColor("ACTIVE")}>
              {SubSellerStatus("ACTIVE")}
            </Text>
          </Flex>
          :
          <Tooltip
            placement="top"
            hasArrow
            arrowSize={5}
            label={"Por favor, verifique os dados e realize o reenvio do cadastro."}
          >
            <Flex
              p="0 10px"
              w="fit-content"
              justify="center"
              align="center"
              height="24px"
              bgColor={SubSellerStatusBg("INACTIVE")}
              border={`1px solid ${SubSellerStatusBorder("INACTIVE")}`}
              borderRadius="36px"
            >
              <Text color={SubSellerStatusColor("INACTIVE")}>
                Inativo
              </Text>
            </Flex>
          </Tooltip>
      )
    },
    {
      key: "viewSubSellerDetails",
      render: (_, render) => (
        <Box p="0" w="100px" h="30px">
          <Flex
            h="30px"
            w="190px"
            gap="10px"
            align="center"
            justify="flex-end"
            className="rowAction"
          >
            <Link
              href={`/sub-vendedores/${render?.id}`}
              onClick={(event) => event.stopPropagation()}
              _hover={{
                cursor: "pointer",
                filter: "brightness(.98)",
                transition: "all .2s"
              }}
            >
              <Flex
                borderRadius="8px"
                padding="4px 8px"
                w="110px"
                height="30px"
                bgColor="#FFFFFF"
                align="center"
                gap="6px"
              >
                <Text
                  fontSize="12px"
                  fontWeight="400"
                  color="#556077"
                >
                  Ver detalhes
                </Text>
                <RightOutlined />
              </Flex>
            </Link>
          </Flex>
        </Box>
      )
    }
  ]

  return (
    <Flex w="100%" flexDir="column" gap="1rem">
      <Flex w="100%" align="center" justify="space-between">
        <Text
          fontSize="1.6rem"
          fontWeight="600"
          color="black"
          aria-label="Page header"
          marginLeft="0.5rem"
        >
          Sub-vendedores
        </Text>

        <Button
          type="button"
          bgColor="#1890FF"
          borderRadius="2px"
          p="0 15px"
          transition="filter .2s"
          _hover={{
            transition: "filter .2s",
            filter: "brightness(.9)",
          }}
          onClick={handleCreateNewSubSeller}
          marginRight="2rem"
        >
          <Text
            color="white"
            fontSize="14px"
            fontWeight="400"
          >
            + Novo sub-vendedor
          </Text>
        </Button>
      </Flex>

      <Box letterSpacing="tighter">

        <Filter
          placeholder="Buscar por nome, CPF ou CNPJ"
          searchFunction={handleSearchSubSeller}
          loading={loading}
        />

        <Tabs
          defaultActiveKey=""
          className="tabs-custom"
        >
          <TabPane>
            <Flex
              w="100%"
              gap="12px"
              flexDirection="column"
            >
              <Table<any>
                columns={
                  (user?.roles?.includes("SELLER_CONFIG_NF") ||
                    user?.roles?.includes("SELLER_MASTER") ||
                    user?.roles?.includes("SELLER_ADMIN") ||
                    user?.roles?.includes("SELLER_BACKOFFICE")) && columns
                }
                dataSource={subSellers as any}
                rowKey={(record) => record?.id}
                locale={{ emptyText: "Sem dados de sub-vendedores" }}
                pagination={false}
              />
              <Pagination
                totalPages={totalOfPages}
                setCurrentPage={setPage}
              />
            </Flex>
          </TabPane>
        </Tabs>
      </Box>
    </Flex>
  )
}

export default WithAuth(SubSellers);