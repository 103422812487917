import { ReactNode } from "react";
import {
  AlertDialog,
  AlertProps as AlertPropsChakra,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Flex,
  Text,
  ModalCloseButton,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@/styles/icons/CheckCircleIcon";

interface AlertProps extends AlertPropsChakra {
  isConfirmAlert?: boolean;
  closeButtonText?: string;
  confirmButtonText?: string;
  isOpen: boolean;
  leastDestructiveRef: any;
  onClose?: () => void;
  onConfirm: () => void;
  title: string;
  messageBody?: string | ReactNode;
  children?: ReactNode;
  isLoading?: boolean;
}

function SuccessAlert({
  isConfirmAlert,
  children,
  closeButtonText,
  confirmButtonText,
  isOpen,
  onClose,
  onConfirm,
  leastDestructiveRef,
  title,
  isLoading = false,
  ...rest
}: AlertProps) {
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={leastDestructiveRef}
      motionPreset="slideInBottom"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          display="flex"
          flexDir="column"
          borderRadius="2"
          width="380px"
          {...rest}
        >
          <AlertDialogHeader 
            fontSize="1rem" 
            fontWeight="bold"
            borderBottom="1px solid"
            borderColor="gray.50"
          >
            <Flex 
                gap="12px" 
                alignItems="center"
            >
              <CheckCircleIcon size={25}/>
              <Text
                color="#000000"
                fontWeight="600"
                fontSize="16px"
                lineHeight="24px"
              >
                {title}
              </Text>
            </Flex>
            <ModalCloseButton />
          </AlertDialogHeader>

          <AlertDialogBody
            display="flex"
            w="100%"
            gap="2rem"
            borderBottom="1px solid"
            borderColor="gray.50"
          >
            {children}
          </AlertDialogBody>
          <AlertDialogFooter
            w="100%"
          >
            <Button
                ml="auto"
                variant="cancel"
                onClick={onConfirm}
                isLoading={isLoading}
              >
                {confirmButtonText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export { SuccessAlert };
