import styled, { css } from "styled-components";
interface ButtonProps {
    isHidden: boolean;
}

export const CancelButton = styled.button<ButtonProps>`
    background-color: white;
    transition: all ease .4s;
    border-radius: 2px;
    border: rgba(0, 0, 0, 0.15);
    padding: .5rem 1rem;
    ${(props) => props.isHidden && css`
        opacity: 0;
        cursor: default;
    `}
    &:hover {
        filter: brightness(.9);
    }
`;

export const Button = styled.button`
    background-color: #1890FF;
    color: white;
    transition: all ease .4s;
    border-radius: 2px;
    padding: .5rem 1rem;
    &:hover {
        filter: brightness(.9);
    }
    &:disabled {
        cursor: not-allowed;
        background-color: #F5F5F5;
        border: 1px solid #D9D9D9;
        color:  rgba(0, 0, 0, 0.25);
    }
`;

export const GhostButton = styled.button`
    background-color: white;
    color: black;
    transition: all ease .4s;
    border-radius: 2px;
    padding: .5rem 1rem;
    border: 1px solid #D9D9D9;
    &:hover {
        filter: brightness(.9);
    }
    &:disabled {
        cursor: not-allowed;
        background-color: #F5F5F5;
        color:  rgba(0, 0, 0, 0.25);

    }
`;
