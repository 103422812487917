import { useEffect, useRef, useState, KeyboardEvent } from "react";
import {
  Flex,
  Input as ChakraInput,
  Text,
  InputProps as ChakraInputProps,
  FormControl,
  Box,
  Image,
  FormLabel,
} from "@chakra-ui/react";
import useOutsideClick from "@/utils/outsideClick";
import { RiArrowDropDownLine } from "react-icons/ri";
import { stdnum } from 'stdnum';
import { countries as ListCountries } from "countries-list";
import { cnpj, cpf } from "@/utils";

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  labelColor?: string;
  register?: any;
  defaultDocumentType?: string;
  defaultCountry?: string;
  setValue?: any;
  getValues?: any;
  errors?: any;
  mask?: string;
  isDisabled?: boolean;
  labelPlaceholder?: string;
  secondaryLabel?: string;
  fixedLabel?: string;
  borderRadius?: string;
  height?: string;
  fontSize?: string;
}

export function DocumentInput({
  name,
  setValue,
  getValues,
  label,
  labelColor,
  register,
  errors,
  mask,
  variant,
  color,
  isDisabled,
  defaultDocumentType = "cpf",
  defaultCountry = "BR",
  secondaryLabel,
  labelPlaceholder,
  borderRadius,
  height,
  fixedLabel,
  fontSize,
  ...rest
}: InputProps) {
  const arrayCountries = Object.keys(stdnum);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDocumentDropdownOpen, setIsDocumentDropdownOpen] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [documentValue, setDocumentValue] = useState("");
  const [showLabel, setShowLabel] = useState(false);
  const dropdownRef = useRef(null);
  const documentDropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [documentCountryList, setDocumentCountryList] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(defaultDocumentType)
  const elementRef = useRef(null);
  const filteredArrrayCountries = arrayCountries.filter(ac => ListCountries[ac]?.name?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase()));
  function getBorderStatus(): string {
    if (isDisabled) return "none"
    if (errors) return "2.5px solid #d33939"
    if (variant === "FAST" && onFocus) return "1px solid #F89C00"
    if (variant === "FAST") return "1px solid #F89C004D"
    if (onFocus) return "1px solid #1890FF"
    return "1px solid #C3CAD7"
  }
  useOutsideClick(dropdownRef, () => {
    setIsDropdownOpen(!isDropdownOpen);
  });
  useOutsideClick(documentDropdownRef, () => {
    setIsDocumentDropdownOpen(!isDocumentDropdownOpen);
  });

  const handleMaskInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (mask === "cpf") {
      cpf(e);
    } else if (mask === "cnpj") {
      cnpj(e);
    }
  };

  function handleSelectCountry(country: any) {
    setSelectedCountry(country);
    setIsDropdownOpen(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  useEffect(() => {
    if (selectedCountry === undefined) return;
  }, [selectedCountry])

  useEffect(() => {
    function handleClickOutside(event) {
      if (elementRef.current && !elementRef.current.contains(event.target) && documentValue === "") {
        setIsFocused(false)
        setOnFocus(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const documentList = Object.keys(stdnum[selectedCountry]);
    setDocumentCountryList(documentList)
    if(selectedCountry === "BR") {
        setSelectedDocument("cpf")
    } else {
        setSelectedDocument(documentList[0])
    }
    setValue("documentNation", selectedCountry)
  }, [selectedCountry])

  useEffect(() => {
    setValue("documentType", selectedDocument);
  }, [selectedDocument])

  useEffect(() => {
    setDocumentValue(getValues("document") || "")
    if (documentValue !== "" || isFocused) {
      setShowLabel(true)
    }
    if (documentValue === "" && isFocused === false) {
      setShowLabel(false)
    }
  }, [isFocused, documentValue, showLabel])

  return (
    <FormControl>
      <Flex
        backgroundColor={variant === "FAST" ? "#F89C001A" : "rgba(255, 255, 255, 0.3)"}
        flexDir="column" onClick={() => {
          inputRef?.current?.focus()
          setIsFocused(true)
        }}
        ref={elementRef}
      >
        <Flex align="center">
          {label && (
            <FormLabel
              htmlFor={name}
              position="absolute"
              top={showLabel ? "-18px" : "10px"}
              left={!showLabel ? "75px" : ""}
              fontSize={showLabel ? ".8rem" : "16px"}
              color={variant === "FAST" ? "white" : "black"}
              transition="0.2s"
              pointerEvents="none"
              zIndex="99"
            >
              {label}
            </FormLabel>
          )}

          {fixedLabel && (
            <Text
              marginBottom=".2rem"
              fontSize=".8rem"
              color={variant === "FAST" ? "white" : "black"}
            >
              {fixedLabel}
            </Text>

          )}

        </Flex>
        <Flex
          border={getBorderStatus()}
          onFocus={() => {
            setOnFocus(true)
            setIsFocused(true)
          }}
          onBlurCapture={() => {
            setOnFocus(false)
            setIsFocused(false)
          }}
          gap="10px"
          borderRadius={borderRadius || "0.2rem"}
          _focus={{
            border: "1px solid",
            borderColor: variant === "FAST" ? "#F89C00" : "blue.200",
          }}
          position="relative"
          align="center"
          zIndex="99"
        >
          <button
            onClick={() => setIsDropdownOpen(true)}
            disabled={isDisabled}
            style={{
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              maxWidth: '100px',
              height: height || '25px'
            }}
            type="button"
          >
            <Flex
              alignItems="center"
              gap="2"
              px=".8rem"
              h="100%"
              borderRight={variant === "FAST" ? "1px solid #F89C004D" :"1px solid rgba(184, 198, 225, 1)"}
            >
                <Image
                    w={19}
                    h={13}
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedCountry}.svg`}
                />
                <RiArrowDropDownLine size={30} />
            </Flex>
          </button>
          {selectedCountry && (
            <button
                onClick={() => setIsDocumentDropdownOpen(true)}
                disabled={isDisabled}
                style={{
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    maxWidth: '100px',
                    height: height || '25px'
                }}
                type="button"
            >
                <Flex
                    alignItems="center"
                    gap="2"
                    px=".8rem"
                    h="100%"
                    borderRight={variant === "FAST" ? "1px solid #F89C004D" :"1px solid rgba(184, 198, 225, 1)"}
                >
                    <Text fontSize=".8rem">{`${selectedDocument}`.toLocaleUpperCase()}</Text>
                    <RiArrowDropDownLine size={11} />
                </Flex>
            </button>
          )}
          <ChakraInput
            ref={inputRef}
            _placeholder={{ paddingLeft: "10px", fontSize: '16px', color: "rgba(0, 0, 0, 1)", fontWeight: 500 }}
            name={name}
            setValue={(e) => e.target.value}
            borderColor={errors ? "danger" : "#D9D9D9"}
            backgroundColor={variant === "FAST" ? "transparent" : "white"}
            border="0px solid transparent"
            outline="none"
            height={height || '25px'}
            variant={variant}
            w="100%"
            fontSize={fontSize ? fontSize : '1.4rem'}
            p="0"
            color={color ? color : "black"}
            _focus={{
              outline: "none",
              border: "none"
            }}
            _disabled={{
              color: color ? color : "black"
            }}
            isDisabled={isDisabled}
            onKeyUp={handleMaskInput}
            size="lg"
            {...rest}
            {...register}
          />
          {isDropdownOpen && (
            <Flex
              position="absolute"
              h={360}
              bgColor="#F5F5F5"
              border={variant === "FAST" ? "1px solid #F89C004D" : "1px solid #C3CAD7"}
              boxShadow="2px 10px 15px 3px rgba(0,0,0,0.4)"
              overflowY="auto"
              zIndex="99"
              borderRadius="2px"
              top="calc(100% + .4rem)"
              ref={dropdownRef}
            >
              <Box
                w="100%"
              >
                <ChakraInput
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.currentTarget.value)}
                  border="0px solid transparent"
                  outline="none"
                  w="100%"
                  color={color ? color : "black"}
                  _focus={{
                    outline: "none",
                    border: "none"
                  }}
                  _disabled={{
                    color: color ? color : "black"
                  }}
                  placeholder="Pesquisar país"
                  fontSize="16px"
                  isDisabled={isDisabled}
                />
                {filteredArrrayCountries?.map(country => {
                  return (
                    <Flex
                      key={ListCountries[country].name}
                      align="center"
                      gap="2"
                      p="2"
                      _hover={{
                        bgColor: "#C3CAD7",
                        transition: "all .2s ease-in-out",
                        cursor: "pointer"
                      }}
                      onClick={() => handleSelectCountry(country)}
                    >
                      <Image w={6} h={4} src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`} />
                      <Text>{ListCountries[country].name}</Text>
                    </Flex>
                  )
                })}
              </Box>
            </Flex>
          )}

        {isDocumentDropdownOpen && (
            <Flex
              position="absolute"
              maxW={180}
              w="100%"
              bgColor="#F5F5F5"
              border={variant === "FAST" ? "1px solid #F89C004D" : "1px solid #C3CAD7"}
              boxShadow="2px 10px 15px 3px rgba(0,0,0,0.4)"
              overflowY="auto"
              zIndex="99"
              borderRadius="2px"
              top="calc(100% + .4rem)"
              ref={documentDropdownRef}
            >
              <Box
                w="100%"
              >
                {documentCountryList?.map((document, index) => {
                  return (
                    <Flex
                      key={index}
                      align="center"
                      gap="2"
                      p="2"
                      _hover={{
                        bgColor: "#C3CAD7",
                        transition: "all .2s ease-in-out",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setSelectedDocument(document);
                        setIsDocumentDropdownOpen(false);
                      }}
                    >
                      <Text>{`${document}`.toLocaleUpperCase()}</Text>
                    </Flex>
                  )
                })}
              </Box>
            </Flex>
          )}
        </Flex>

        {errors && (
          <Text
            fontSize=".8rem"
            color="danger"
            mb={errors?.message && "4"}
            role="alert"
          >
            {errors?.message}
          </Text>
        )}
        {secondaryLabel && (
          <Text
            fontSize=".8rem"
            color="gray.300"
          >
            {`${" "}${secondaryLabel}`}
          </Text>
        )}
      </Flex>
    </FormControl>
  );
}
