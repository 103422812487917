import {
  Flex,
  Icon,
  Button as ChakraButton,
  Link as ChakraLink,
  Switch,
  Text,
} from "@chakra-ui/react";
import { ColumnsType } from "antd/lib/table";
import { WithAuth } from "@/hoc";
import { usePlan } from "@/hooks";
import { useEffect, useState } from "react";
import { Tabs, Table } from "antd";
import { Filter } from "@/components/Filter";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "@/utils";
import { boolean } from "yup";

interface Plan {
  title: string;
  id: number;
  active: boolean;
}

interface MyPlansProps {
  plansList: Plan[];
}

const MyPlans = () => {
  const [plans, setPlans] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false)
  async function getPlans() {
    const { getPlans } = usePlan();
    try {
      const { data } = await getPlans();
      setPlans(data);
      setFilteredData(data);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
      setPlans([]);
      setFilteredData([]);
    }
  }

  useEffect(() => {
    getPlans();
  }, []);

  const navigate = useNavigate();

  const { TabPane } = Tabs;

  const { activatePlan } = usePlan();

  async function switchPlan(active: boolean, id: number) {
    try {
      await activatePlan(id, active);

      setPlans(
        plans.map((plan) => {
          if (plan.id === id) {
            return {
              ...plan,
              active,
            };
          }
          return plan;
        })
      );
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  function handlePlan(active: any, id: any): void {
    try {
      switchPlan(!active, id);
      showToast({ message: "Plano atualizado com sucesso!", type: "success" });

    } catch (error) {
      showToast({ message: "Plano não pode ser atualizado!", type: "error" });

    }
  }

  const columns: ColumnsType<Plan> = [
    {
      key: "title",
      title: "Nome do plano",
      render: (text, record) => (
        <Flex justify="space-between" w="500px">
          <Text>{record.title}</Text>
        </Flex>
      ),
    },
    {
      key: "active",
      title: "Plano ativo",
      render: (text, record) => (
        <Flex w="10px">
          <Switch
            id={record.id.toString()}
            defaultChecked={record.active}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              handlePlan(record.active, Number(record.id))
            }
          // onChange={() => handlePlan({ active, id: Number(id) })}
          />
        </Flex>
      ),
    },
    {
      key: "",
      title: "",
      render: (text, record) => (
        <Flex justify="flex-end" w="10px" h="30px">
          <Flex
            className="rowAction"
            align="center"
            justify="center"
            h="40px"
            w="40px"
            transition="all .2s"
            _hover={{
              borderRadius: "5px",
              transition: "all .2s",
              bgColor: "white",
            }}
          >
            <ChakraLink h="20px" p="0 10px">
              <Link to={`/meus-planos/${record?.id}`}>
                <Icon as={FaLongArrowAltRight} boxSize="20px" />
              </Link>
            </ChakraLink>
          </Flex>
        </Flex>
      ),
    },
  ];

  async function filterTable(searchParam?: string) {
    setLoading(true)
    if (!searchParam) {
      try {
        await getPlans();

      } catch (error) {

      } finally {
        setLoading(false)
      }
    }
    if (searchParam && plans.length > 0) {
      const filteredPlans = plans.filter(({ title }: any) => title.toLowerCase().includes(searchParam.toLowerCase()))
      setFilteredData(filteredPlans);
      setLoading(false)
    }
  }
  return (
    <>
      <Flex
        w="290px"
        justify="flex-end"
        position="absolute"
        top="3.6rem"
        right="1.3rem"
        zIndex={1}
      >
        <ChakraButton
          size="sm"
          bgColor="blue.200"
          borderRadius="3"
          color="white"
          transition="filter .2s"
          _hover={{
            transition: "filter .2s",
            filter: "brightness(.9)",
          }}
          _focus={{}}
          onClick={() => navigate("/meus-planos/0")}
        >
          Criar plano
        </ChakraButton>
      </Flex>
      <Tabs defaultActiveKey="" className="tabs-custom">
        <TabPane>
          <Filter placeholder="Buscar planos"

            loading={loading}
            searchFunction={filterTable} />
          <Table<Plan>
            columns={columns}
            // onRow={(record) => {
            //   return {
            //     onClick: () => navigate(`/meus-planos/${record?.id}`),
            //   };
            // }}
            dataSource={filteredData}
            rowKey={(record) => record.id}
            pagination={{ pageSize: 20 }}
            locale={{ emptyText: "Sem Planos cadastrados" }}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default WithAuth(MyPlans);
