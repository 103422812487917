import React from 'react';
import { Alert, Input, Select } from "@/components";
import { WithAuth } from "@/hoc";
import { Container } from "@/layouts/SejaPago/Container";
import { useBillsToPay } from "@/hooks/useBillsToPay";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { formatCurrencyToApi, installmentsContasAPagar, showToast } from "@/utils";
import { Flex, Text, Button, Stack, forwardRef } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import RadioButtons from "./_RadioButtons";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import { v4 as uuidv1 } from 'uuid';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { nextDayOfPaymentWeek } from '@/utils/smallerNextDay';
import { paymentForecast } from '@/utils/paymentForecast';

registerLocale('pt-BR', ptBR);

const schema = yup.object().shape({
    title: yup.string().required('Este campo é obrigatório').max(50, 'O nome da conta não pode ter mais de 50 caracteres'),
    beneficiary: yup.string().required('Este campo é obrigatório'),
    bankSlipCode: yup.string().when('selectedPaymentMethod', {
        is: 'Boleto',
        then: yup.string().required('Este campo é obrigatório'),
        otherwise: yup.string().nullable(),
    }),
    pixCode: yup.string().when(['selectedPaymentMethod', 'selectedPixMethod'], {
        is: (selectedPaymentMethod, selectedPixMethod) => selectedPaymentMethod === 'Pix' && selectedPixMethod === 'pix_code',
        then: yup.string().required('Este campo é obrigatório'),
        otherwise: yup.string().nullable(),
    }),
    pixKey: yup.string().when(['selectedPaymentMethod', 'selectedPixMethod'], {
        is: (selectedPaymentMethod, selectedPixMethod) => selectedPaymentMethod === 'Pix' && selectedPixMethod === 'pix_key',
        then: yup.string().required('Este campo é obrigatório'),
        otherwise: yup.string().nullable(),
    }),
});


interface Form {
    setNewForm: React.Dispatch<React.SetStateAction<boolean>>
    setHasNewBills: React.Dispatch<React.SetStateAction<boolean>>
}




interface newOrderbills {
    fields: {
        "Nome da Conta"?: any;
        Método?: string;
        "Boleto - Código"?: any;
        "Pix - Copia e Cola"?: any;
        "Pix - Chave Pix"?: any;
        Status?: string;
        "Tipo do valor"?: string;
        "Tipo de conta"?: string;
        Recorrência?: number;
        Beneficiário?: any;
        Valor?: any;
        Vencimento?: string;
        idSeller_input?: any;
        parcela?: number;
        order_id?: string;
    };

}

const FormContasPagar = ({ setNewForm, setHasNewBills }: Form) => {
    const [selectedRecurrentRadio, setSelectedRecurrentRadio] = useState("Recorrente");
    const [selectedValueRadio, setSelectedValueRadio] = useState("Valor variável");
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Pix");
    const [selectedPixMethod, setSelectedPixMethod] = useState("pix_key");
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null)
    const user = JSON.parse(localStorage.getItem("barte-user-info"));
    const { newBills, getBills } = useBillsToPay();
    const cancelRef = useRef();
    const [order_id, setOrder_id] = useState(uuidv1);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema)
    });

    const isAllowedDate = (date) => {
        const dayOfWeek = date.getDay();
        return [1, 3, 5].includes(dayOfWeek);
    };

    const isAllowedTime = (date) => {
        const now = new Date();
        return date >= now;
    };

    const isValidDate = (date) => {
        return isAllowedDate(date) && isAllowedTime(date);
    };

    const CustomInput = forwardRef(({ value }: any, ref) => (
        <Flex
            w="100%"
            flexDir="column"
        >
            <Text
                marginBottom=".2rem"
                fontSize=".8rem"
                color={"gray.600"}
            >
                Vencimento
            </Text>
            <Button
                onClick={() => setIsDatePickerOpen(true)}
                ref={ref}
                p="5px 12px 5px 20px"
                w="100%"
                fontSize="14px"
                _hover={{}}
                fontWeight="500"
                border="1px solid"
                borderColor="newGray.200"
                borderRadius="4px"
                bgColor="transparent"
            >
                {value}
            </Button>
        </Flex>
    ));


    function getLastDayOfMonth(month, year) {
        return new Date(year, month + 1, 0).getDate();
    }

    function getStatus(values: any, index: number) {
        selectedValueRadio === "Valor fixo" && (index >= 1 || formatCurrencyToApi(values?.amount) <= 0) ? "Atualização necessária" : "Aprovar"
        selectedValueRadio === "Valor variável" && (index >= 1 || formatCurrencyToApi(values?.amount) <= 0) ? "Atualização necessária" : "Aprovar"

    }


    const onSubmit = async (values) => {
        const today = new Date();
        const expireDate = selectedDate;
        let nextDayOfPayment = nextDayOfPaymentWeek(expireDate)

        if (
            today.getDate() === expireDate.getDate() &&
            today.getMonth() === expireDate.getMonth() &&
            today.getFullYear() === expireDate.getFullYear() &&
            today.getHours() >= 12
        ) {
            nextDayOfPayment.setDate(nextDayOfPayment.getDate() + 2)
        }

        let objectContasAPagar = {
            fields: {
                "Nome da Conta": values?.title,
                "Método": selectedPaymentMethod,
                "Boleto - Código": values?.bankSlipCode,
                "Pix - Copia e Cola": selectedPixMethod === "pix_code" ? values?.pixCode : null,
                "Pix - Chave Pix": selectedPixMethod === "pix_key" ? values?.pixKey : null,
                "Status": "Aprovar",
                "Tipo do valor": selectedValueRadio,
                "Tipo de conta": selectedRecurrentRadio,
                "Recorrência": selectedRecurrentRadio === "Recorrente" ? Number(values?.installments) : 1,
                "Beneficiário": values?.beneficiary,
                "Valor": formatCurrencyToApi(values?.amount),
                "Vencimento": format(selectedDate, "yyyy-MM-dd"),
                "idSeller_input": user?.idSeller,
                "parcela": 1,
                "order_ID": order_id,
                "Agendado": paymentForecast({ dataBills: selectedDate, newBills: true })

            },
        };


        if (selectedRecurrentRadio === "Recorrente") {
            let newArrayObjectsBillsToPay = []
            if (selectedValueRadio === "Valor variável") {
                newArrayObjectsBillsToPay = Array.from({ length: Number(values?.installments) }, (_, index) => {
                    const nextDatePayment = new Date(nextDayOfPayment);
                    nextDatePayment.setMonth(nextDatePayment.getMonth() + (index));
                    nextDatePayment.setDate(Math.min(selectedDate.getDate(), getLastDayOfMonth(nextDatePayment.getMonth(), nextDatePayment.getFullYear())));
                    return {
                        fields: {
                            ...objectContasAPagar.fields,
                            parcela: index + 1,
                            order_ID: order_id,
                            "Pix - Copia e Cola": selectedPixMethod === "pix_code" ? values?.pixCode : "",
                            "Pix - Chave Pix": selectedPixMethod === "pix_key" ? values?.pixKey : "",
                            Vencimento: format(selectedDate, "yyyy-MM-dd"),
                            Agendado: paymentForecast({ dataBills: nextDatePayment, newBills: true }),
                            Valor: selectedValueRadio === "Valor variável" && index >= 1 ? 0 : formatCurrencyToApi(values?.amount),
                            Status: selectedValueRadio === "Valor variável" && (index >= 1 || formatCurrencyToApi(values?.amount) <= 0) ? "Atualização necessária" : "Aprovar"
                        }
                    };
                });
            }
            if (selectedValueRadio === "Valor fixo") {
                newArrayObjectsBillsToPay = Array.from({ length: Number(values?.installments) }, (_, index) => {
                    const nextDatePayment = new Date(nextDayOfPayment);
                    nextDatePayment.setMonth(nextDatePayment.getMonth() + (index));
                    nextDatePayment.setDate(Math.min(selectedDate.getDate(), getLastDayOfMonth(nextDatePayment.getMonth(), nextDatePayment.getFullYear())));
                    return {
                        fields: {
                            ...objectContasAPagar.fields,
                            parcela: index + 1,
                            order_ID: order_id,
                            Vencimento: format(selectedDate, "yyyy-MM-dd"),
                            Agendado: paymentForecast({ dataBills: nextDatePayment, newBills: true }),
                            Valor: formatCurrencyToApi(values?.amount),
                            "Pix - Copia e Cola": selectedPixMethod === "pix_code" ? values?.pixCode : "",
                            "Pix - Chave Pix": selectedPixMethod === "pix_key" ? values?.pixKey : "",
                            "Boleto - Código": values?.bankSlipCode && index === 0 ? values?.bankSlipCode : "",
                            Status: selectedValueRadio === "Valor fixo" && (index >= 1 || formatCurrencyToApi(values?.amount) <= 0) ? "Atualização necessária" : "Aprovar"
                        }
                    };
                });
            }

            postNewBillsRecurrence(newArrayObjectsBillsToPay)
        }

        if (selectedRecurrentRadio === "Pontual") {

            try {
                await newBills({ "records": [objectContasAPagar] });
                setIsAlertOpen(true);
                setNewForm(false);
                setHasNewBills(true);
                showToast({
                    type: "success",
                    message: "conta criada com sucesso.",
                });
            } catch {
                showToast({
                    type: "error",
                    message: "Falha ao cadastrar nova conta.",
                });
            }
        }
    }

    async function postNewBillsRecurrence(newArrayObjectsBillsToPay) {
        if (newArrayObjectsBillsToPay) {
            const firstObject = { records: newArrayObjectsBillsToPay.slice(0, 10) };
            const secondObject = newArrayObjectsBillsToPay.length > 10 ? { records: newArrayObjectsBillsToPay.slice(10) } : null;

            try {
                const promises = [newBills(firstObject)];

                if (secondObject) {
                    promises.push(newBills(secondObject));
                }

                const results = await Promise.all(promises);

                setIsAlertOpen(true);
                setNewForm(false);
                setHasNewBills(true);
                showToast({
                    type: "success",
                    message: "Contas criadas com sucesso.",
                });
            } catch (error) {
                showToast({
                    type: "error",
                    message: "Falha ao cadastrar novas contas.",
                });
            }
        }

    }
    useEffect(() => {
        if (selectedRecurrentRadio === "Pontual") {
            setSelectedValueRadio("Valor fixo")
        }
    }, [selectedRecurrentRadio])

    useEffect(() => {
        const now = new Date();
        let initialDate = new Date(now);
        while (!isValidDate(initialDate)) {
            initialDate.setDate(initialDate.getDate() + 1);
        }
        setSelectedDate(initialDate);
    }, []);

    return (
        <>
            <Alert
                isConfirmAlert={true}
                isOpen={isAlertOpen}
                onConfirm={() => {
                    setIsAlertOpen(!isAlertOpen)
                    reset()
                }}
                onClose={() => setIsAlertOpen(false)}
                title="Conta criada"
                messageBody={`Sua nova conta foi criada com sucesso!`}
                leastDestructiveRef={cancelRef}
            />
            <Flex
                w="100%"
                justify="center"
                align="center"
                flexDir="column"
                as="form"
                mb="60px"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Stack
                    maxW="520px"
                    w="100%"
                >
                    <Flex
                        w="100%"
                        justifyContent="space-between"
                    >
                        <Text fontSize="24px">Nova conta</Text>
                        <Button
                            onClick={() => setNewForm(false)}

                            variant="link">Cancelar</Button>
                    </Flex>
                    <Container
                        title="Identificação"
                        flexDir="column"
                        startsOpen
                        hasPadding
                    >
                        <Input
                            label="Nome da Conta"
                            type="text"
                            name="title"
                            register={{ ...register("title") }}
                            errors={errors?.title}
                        />
                        <Input
                            label="Beneficiário"
                            type="text"
                            name="beneficiary"
                            register={{ ...register("beneficiary") }}
                            errors={errors?.beneficiary}
                        />
                        <DatePicker
                            locale="pt-BR"
                            selected={selectedDate}
                            onChange={(date) => {
                                setSelectedDate(date)
                                setIsDatePickerOpen(false)
                            }}
                            onInputClick={() => setIsDatePickerOpen(true)}
                            onClickOutside={() => setIsDatePickerOpen(false)}
                            open={isDatePickerOpen}
                            customInput={<CustomInput />}
                            dateFormat="dd/MM/yyyy"
                        // filterDate={isValidDate}
                        />
                    </Container>
                    <Container
                        title="Recorrência"
                        flexDir="column"
                        startsOpen
                        hasPadding
                    >
                        <RadioButtons
                            label="Tipo de conta"
                            options={[{ name: "Recorrente", value: "Recorrente" }, { name: "Pontual", value: "Pontual" }]}
                            selected={selectedRecurrentRadio}
                            onChange={setSelectedRecurrentRadio}
                        />
                        <Select
                            isDisabled={selectedRecurrentRadio === "Pontual"}
                            name="recurrentTime"
                            label="Tempo de recorrência"
                            optionsList={installmentsContasAPagar}
                            register={{ ...register("installments") }}
                        />
                    </Container>
                    <Container
                        title="Valor"
                        flexDir="column"
                        startsOpen
                        hasPadding
                    >
                        <Input
                            label="Valor"
                            type="text"
                            name="amount"
                            register={{ ...register("amount") }}
                            errors={errors?.amount}
                            mask="currency"
                        />
                        <RadioButtons
                            label="Tipo de valor"
                            options={[{ name: "Valor Fixo", value: "Valor fixo" }, { name: "Valor Variável", value: "Valor variável" }]}
                            selected={selectedValueRadio}
                            onChange={setSelectedValueRadio}
                            disabled={selectedRecurrentRadio === "Pontual"}
                        />
                    </Container>
                    <Container
                        title="Método de Pagamento"
                        flexDir="column"
                        startsOpen
                        hasPadding
                    >
                        <RadioButtons
                            label="Tipo de valor"
                            options={[{ name: "Pix", value: "Pix" }, { name: "Boleto", value: "Boleto" }]}
                            selected={selectedPaymentMethod}
                            onChange={setSelectedPaymentMethod}
                        />
                        {selectedPaymentMethod === "Pix" && (
                            <>
                                <RadioButtons
                                    label="Tipo de valor"
                                    options={[{ name: "Chave Pix", value: "pix_key" }, { name: "Pix Copia e Cola", value: "pix_code" }]}
                                    selected={selectedPixMethod}
                                    onChange={setSelectedPixMethod}
                                />
                                {selectedPixMethod === "pix_key" && (
                                    <Input
                                        label="Chave Pix"
                                        type="text"
                                        name="pixKey"
                                        register={{ ...register("pixKey") }}
                                        errors={errors?.pixKey}
                                    />
                                )}
                                {selectedPixMethod === "pix_code" && (
                                    <Input
                                        label="Pix Copia e Cola"
                                        type="text"
                                        name="pixCode"
                                        register={{ ...register("pixCode") }}
                                        errors={errors?.pixCode}
                                    />
                                )}
                            </>
                        )}
                        {selectedPaymentMethod === "Boleto" && (
                            <>
                                <Input
                                    label="Código do Boleto"
                                    type="text"
                                    name="bankSlipCode"
                                    register={{ ...register("bankSlipCode") }}
                                    errors={errors?.bankSlipCode}
                                />
                            </>
                        )}
                    </Container>

                    <Button type="submit" variant="primary">Finalizar</Button>
                    <Button
                        variant="link"
                        onClick={() => setNewForm(false)}


                    >Cancelar</Button>
                </Stack>
            </Flex>
        </>
    )
}

export default FormContasPagar;