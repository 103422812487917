import { Api_Banking } from "@/services/Api/bankingApiConfig";

export function usePix() {
  const baseUrl = "key";

  const getPixKeys = (account_id: string) => Api_Banking.get(`${baseUrl}/${account_id}`);
  const deletePixKey = (key_id: string, key_type: string) => Api_Banking.delete(`${baseUrl}/${key_id}?keyType=${key_type}`);
  const createPixKey = (data: any) => Api_Banking.post(baseUrl, data)

  return { getPixKeys, deletePixKey, createPixKey };
}
