import { Button, ButtonProps, Flex, forwardRef, Icon, Switch, Text, Tooltip } from "@chakra-ui/react";
import ptBR from 'date-fns/locale/pt-BR';
import { useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useController } from "react-hook-form";

interface DatePickerComponentProps extends ButtonProps {
  label?: string;
  name: string;
  control: any;
  register?: any;
  errors?: any;
  editMode?: boolean;
}

export function DatePickerComponent({
  label,
  control,
  name,
  register,
  errors,
  editMode = false,
  ...rest
}: DatePickerComponentProps) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      onClick={onClick}
      ref={ref}
      name={name}
      p="5px 12px"
      w="100%"
      borderRadius="4px"
      bgColor="transparent"
      border="1px solid"
      borderColor={"newGray.200"}
      fontSize="16px"
      _focus={{
        border: "1px solid",
        borderColor: "blue.200",
      }}
      position="relative"
      color={"black"}
      fontWeight="500"
      {...rest}
      {...register}
    >
      <Text position="absolute" left={editMode ? "0" : "10px"}>
        {value}
      </Text>
    </Button>
  ));

  return (
    <Flex flexDir="column" gap="4px">
      <Flex justify="space-between" align="center">
        <Flex align="center">
          {label && (
            <Text
              marginBottom=".2rem"
              fontSize=".8rem"
              color={"newGray.600"}
            >
              {label}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex w="100%" align="center" position="relative" justify="space-between">
        <Controller
          name={name}
          control={control}
          render={({ field }: any) => (
            <DatePicker
              locale={ptBR}
              onBlur={onBlur}
              selected={field.value}
              wrapperClassName="datePicker"
              onChange={(date) => { field.onChange(date) }}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
              showMonthDropdown
              showYearDropdown
            />
          )}
        />
      </Flex>
      {errors && (
        <Text fontSize=".8rem" color="danger" mb={errors?.message && "4"} role="alert">
          {errors?.message}
        </Text>
      )}
    </ Flex>
  )
}