import { Plan, PlanSelectProps } from "@/models";
import { Box, Select,Text } from "@chakra-ui/react";

export function PlanSelect({
  label,
  register,
  name,
  plansList,
  setValue,
  setSelectedPlanData,
  ...rest
}: PlanSelectProps) {
  function handleTogglePlan(e: number, plans?: Array<Plan>) {
    const plansListCurrent = plans || plansList;
    const planMatch = plansListCurrent.filter((plans) => {
      return plans?.id === e;
    });
    setSelectedPlanData(planMatch[0]);
    setValue("idPlan", planMatch[0]?.id);
  }

  return (

    <Box>
    <Text mb="0" fontSize="12px">
      {label}
    </Text>
    <Select
      {...rest}
      {...register}
      onChange={(e) => handleTogglePlan(Number(e.target.value))}
      _focus={{
        border: "1px solid",
        borderColor: "blue.200",
      }}
    >
      {plansList?.length === 0 ? (
        <option>Sem planos cadastrados</option>
      ) : (
        plansList?.map((plan) => {
          return (
            <option key={plan?.id} value={plan?.id}>
              {plan?.title}
            </option>
          );
        })
      )}
    </Select>
    </Box>
  );
}
