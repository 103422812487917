import { useAnticipationStatus } from "@/hooks/useAnticipationStatus.ts";
import { Button, Flex } from "@chakra-ui/react";

interface ExcludeChargeProps {
  handleToggleDeleteChargeModal: () => void
}

export function ExcludeCharge({ handleToggleDeleteChargeModal }: ExcludeChargeProps) {
  const { hasAntecipationActive } = useAnticipationStatus()
  return (
    <>
      <Flex flexDir="column" align="flex-start" >
        <Button
          p="0"
          m="0"
          color={hasAntecipationActive ? "newGray.400" : "red.danger"}
          fontWeight={hasAntecipationActive ? "normal" : "bold"}
          fontSize={hasAntecipationActive ? "14px" : "1rem"}
          disabled={hasAntecipationActive}
          id="excluir_cobrança"
          variant="ghost"
          h="24px"
          maxW="145px"
          transition="filter .2s"
          cursor="pointer"
          _hover={!hasAntecipationActive ? {
            transition: "filter .2s",
            filter: "brightness(.8)",
            textDecoration: "underline",
            color: "red.danger",
          } : {}}
          onClick={handleToggleDeleteChargeModal}
        >
          Excluir cobrança
        </Button>
      </Flex>
    </>
  )
}