import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  forwardRef,
  Heading,
  Link,
  Spacer,
  Text,
} from "@chakra-ui/react";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker, { registerLocale } from "react-datepicker";
import { format, sub, addDays } from "date-fns";
import { WithAuth } from "@/hoc";
import { useRedeem } from "@/hooks/useRedeem";
import { useTransfers } from "@/hooks/useTransfer";
import { RedeemHistory } from "@/layouts";
import { CalendarIcon } from "@/styles/icons/CalendarIcon";
import { ArrowDownIcon } from "@/styles/icons/ArrowDownIcon";
import { Pagination } from "@/components";
import { SaldoComponent } from "./_SaldoComponent";
import { Tabs } from "antd";
import NewTransfer from "@/components/NewTransferModal";
import TransferList from "@/components/TransferListModal";

const Resgates = () => {
  registerLocale("ptBR", ptBR);
  const { initialData, getInitialData, getRedeemCSV, getReedemAmount } = useRedeem();
  const { findSeller } = useTransfers();
  const csvDownloadRef = useRef();
  const [startDate, setStartDate] = useState<Date>(sub(new Date(), { days: 30 }));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const { TabPane } = Tabs;
  const [currentPage, setCurrentPage] = useState(0);
  const [transfeRequested, setTransferRequested] = useState<string>("0");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalList, setOpenModalList] = useState<boolean>(false);
  const [tabStatus, setTabStatus] = useState<string>("REDEEMS");
  const featureToggle = JSON.parse(localStorage.getItem("barte-features-list-user"));
  const tabs = [
    {
      tab: "Extrato",
      key: "REDEEMS",
      status: "REDEEMS",
    },
    {
      tab: "Lançamentos futuros",
      key: "FUTURE_REDEEMS",
      status: "FUTURE_REDEEMS",
    }
  ];
  const CustomInput = forwardRef(({ value }: any, ref) => (
    <Button
      onClick={() => setIsDatePickerOpen(true)}
      ref={ref}
      variant="secondary"
      maxH="32px"
      borderColor="#E6E9F0"
      px="2"
      borderRadius="4px"
      _hover={{}}
    >
      <Flex align="center" gap="4">
        <CalendarIcon size={16} />
        <Text>{value}</Text>
        <ArrowDownIcon color="#3D485C" />
      </Flex>
    </Button>
  ));
  const totalValue = () => {
    if (
      initialData?.amount &&
      initialData?.amount.length === 2 &&
      initialData?.provisionedAmount &&
      initialData?.provisionedAmount.length === 2 &&
      transfeRequested !== undefined
    ) {
      const parseNumber = (value: string) => parseFloat(value.replace(/\D/g, '').replace(',', '.')) || 0;

      const numberValue1 = parseNumber(initialData.amount[1].trim());
      const numberValue2 = parseNumber(initialData.provisionedAmount[1].trim());

      const transfeRequestedValue = parseFloat(transfeRequested) || 0;
      const finalValue = numberValue1 + numberValue2 + transfeRequestedValue;
      const formatter = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(finalValue / 100)
    } else {
      return '0,00';
    }
  };

  const repasses = [
    {
      title: "Saldo total", icon: "tooltip", value: totalValue(), label: "É o somatório do saldo disponível, saldo a liberar e das solicitações de resgate em processamento", button: { title: "Solicitar repasse" }
    },
    { title: "Disponível", icon: "button", value: initialData?.amount, label: "", button: { title: "Solicitar repasse" } },
    { title: "A liberar", icon: "tooltip", value: initialData?.provisionedAmount, label: "Somatório das suas cobranças pagas e ainda não compensadas.Saiba mais sobre o seu prazo de repasse.", button: { title: "" } },
    { title: "Repasse solicitado", icon: "outlineButton", value: Number(transfeRequested)?.toLocaleString('pt-BR', { minimumFractionDigits: 2 }), label: "", button: { title: "Ver repasse" } },
  ]
  const onDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) return setIsDatePickerOpen(false);
  };
  
  const onTabChange = (activeKey) => {
    if (activeKey === "FUTURE_REDEEMS") {
      setTabStatus(activeKey)
      const initialDate = addDays(new Date(), 1);
      const finalDate = addDays(initialDate, 30);
      setStartDate(initialDate);
      setEndDate(finalDate);

    }
    if (activeKey === "REDEEMS") {
      setTabStatus(activeKey)
      const initialDate = sub(new Date(), { days: 30});
      const finalDate = new Date();
      setStartDate(initialDate);
      setEndDate(finalDate);
    }
  }

  const getCsvContent = () => {
    return getRedeemCSV(
      {
        executionDateInitial: format(startDate, "yyyy-MM-dd"),
        executionDateFinal: format(endDate, "yyyy-MM-dd"),
        sort: "executionDate,desc",
      },
      {
        size: 20,
        page: currentPage,
      }
    );
  };

  async function findSellers() {
    try {
      const findSellerData = await findSeller()
      setTransferRequested(findSellerData?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    findSellers();
  }, [openModal])

  useEffect(() => {
    getInitialData(
      {
        executionDateInitial: format(startDate, "yyyy-MM-dd"),
        executionDateFinal: format(endDate, "yyyy-MM-dd"),
        sort: "executionDate,desc",
      },
      {
        size: 20,
        page: currentPage,
      }
    );
    getReedemAmount()
  }, [isDatePickerOpen, currentPage, openModal, tabStatus]);



  return (
    <>


      {initialData && Object.keys(initialData).length > 0 && (

        <>
          {openModal && <NewTransfer data={initialData} open={setOpenModal} />}
          {openModalList && <TransferList open={setOpenModalList} />}
          <Flex w="100%" p={["1rem", "2rem"]} direction="column" gap="8">
            <Flex
              align="center"
            >
              <Heading fontSize={["1.2rem", "1.5rem"]}>Financeiro</Heading>
              <Spacer />
              <Flex
                direction="column"
                gap="20px"
                marginLeft="auto"
                w="100%"
                maxW="550px"
              >
                {featureToggle?.includes("view_seller_balance") && (
                  <Flex
                    width="100%"
                  >
                    <SaldoComponent
                      openTransferList={setOpenModalList}
                      open={setOpenModal}
                      data={initialData}
                    />
                  </Flex>
                )}
                <DatePicker
                  locale="ptBR"
                  selected={startDate}
                  onChange={(date) => onDateChange(date)}
                  startDate={startDate}
                  endDate={endDate}
                  onInputClick={() => setIsDatePickerOpen(true)}
                  onClickOutside={() => {
                    if (endDate) return setIsDatePickerOpen(false);
                  }}
                  open={isDatePickerOpen}
                  customInput={<CustomInput />}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  minDate={tabStatus === "FUTURE_REDEEMS" ? addDays(new Date(), 1) : 
                    sub(new Date(), {
                      days: 30,
                    })
                  }
                  maxDate={tabStatus === "FUTURE_REDEEMS" ? addDays(new Date(), 30) : new Date()}

                />
              </Flex>
              
            </Flex>
            
            <Flex w="100%" align="center">
              <Spacer />
              {/* <CsvDownloadAlert
                onConfirm={getCsvContent}
                leastDestructiveRef={csvDownloadRef}
                closeButtonText="Cancelar"
                confirmButtonText="Sim"
                textTitle="Baixar Planilha (.csv)"
                textModal="Deseja baixar o extrato no período selecionado em CSV?"
                csvFileName={`Extrato ${startDate && format(startDate, "dd-MM-yyyy")
                  } - ${endDate && format(endDate, "dd-MM-yyyy")}.csv`}
              /> */}

              
            </Flex>
            <Tabs
              defaultActiveKey=""
              className="tabs-custom"
              onChange={onTabChange}
            >
              {tabs.map(({ key, tab }) => (
                <TabPane tab={tab} key={key}>
                  {key === "REDEEMS" && (
                    <RedeemHistory redeems={initialData?.content} />
                  )}
                  {key === "FUTURE_REDEEMS" && (
                    <RedeemHistory redeems={initialData?.content} />
                  )}
                </TabPane>
              ))}
            </Tabs>

            <Pagination
              totalPages={initialData?.totalPages}
              setCurrentPage={setCurrentPage}
            />
          </Flex>
        </>
      )}
    </>
  );
};

export default WithAuth(Resgates);