import { Button, ButtonProps as ChakraButtonProps } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface RowProps extends ChakraButtonProps {
  link?: string;
  deleteFunction?: any;
}

const RowOptionLink = ({ children, className, link, ...rest }: RowProps) => {
  const navigate = useNavigate();

  return (
    <Button
      bg="bgRow"
      w="100%"
      p="30px 25px"
      fontSize="1.5rem"
      color="gray.800"
      display="flex"
      justifyContent="space-between"
      {...rest}
      onClick={() => navigate(link)}
      _hover={{
        bg: "gray.100",
      }}
      borderRadius={8}
    >
      {children}
    </Button>
  );
};

export { RowOptionLink };
