import { Button, Flex, Text } from "@chakra-ui/react"
interface OptionProps {
    name: string;
    value: string;
}
interface RadioButtonsProps {
    label?: string;
    options: OptionProps[];
    selected: string;
    onChange?: (value: string) => void; 
    disabled?: boolean;
}
const RadioButtons = ({ label, options, selected, disabled = false, onChange }: RadioButtonsProps) => {
    return(
        <Flex
            w="100%"
            flexDir="column"
        >
            {label && (
                <Text
                    marginBottom=".2rem"
                    fontSize=".8rem"
                    color={disabled ? "newGray.200" : "gray.600"}
                    cursor={disabled && "not-allowed"}
                >
                    {label}
                </Text>
            )}
            <Flex 
                w="100%"
                borderRadius="8px"
                border="1px solid"
                borderColor="#E6E9F0"
            >
                {options?.map(option => (
                    <Button 
                        key={option?.value}
                        w="100%"
                        borderRadius="8px"
                        backgroundColor={selected === option.value ? "#10afee2f" : "transparent"}
                        border={selected === option.value ? "1px solid" : "none"}
                        borderColor={selected === option.value ? "#10AFEE" : "none"}
                        _hover={{
                            border: "1px solid",
                            borderColor: "#10AFEE",
                            backgroundColor: "#10afee2f",
                        }}
                        disabled={disabled}
                        onClick={() => onChange(option.value)}
                    >
                        {option?.name}
                    </Button>
                ))}
            </Flex>
        </Flex>
    )
}

export default RadioButtons