import { Button, Flex, ListItem, Text } from "@chakra-ui/react";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";

interface ItemProps {
  text: string;
  handleDelete: (index: number) => void;
  index: number;
}

const Item = ({ text, handleDelete, index }: ItemProps) => {
  const [isOnHover, setIsOnHover] = useState(false);
  return (
    <ListItem 
      mb={4} 
      onMouseEnter={() => setIsOnHover(true)}
      onMouseLeave={() => setIsOnHover(false)}
    >
      <Flex 
        align="center" 
        justify="space-between" 
        h="40px"
      >
        <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxW="420px">{text}</Text>
        {isOnHover && (
          <Button 
            variant="ghost" 
            _hover={{
              color:"#000000"
            }}
            onClick={() => handleDelete(index)}
          >
            <IoMdClose size={16} />
          </Button>
        )}
      </Flex>
    </ListItem>
  );
};

export { Item };
