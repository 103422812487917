import { Input } from "@/components";
import { SearchDropDown } from "@/components/Forms/SearchDropDown";
import { ListSelector } from "@/components/Forms/Selector";
import { Tooltip } from "@/components/Tooltip";
import { useSubSeller } from "@/hooks/useSubSeller";
import { AccountTypes } from "@/models/AccountType";
import { Banks } from "@/models/Banks";
import { ListInterface } from "@/models/interfaces";
import { PixKeyTypes } from "@/models/PixKeyTypes";
import { phone_format, showToast } from "@/utils";
import { FormatDocument } from "@/utils/formatDocument";
import { SubSellerStatus, SubSellerStatusBg, SubSellerStatusBorder, SubSellerStatusColor } from "@/utils/subSellerStatus";
import { Button, Divider, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { BsCheckCircle } from "react-icons/bs";
import { SubSellerEditDto } from "../id";

interface BankSectionProps {
  initialData?: SubSellerEditDto;
  register?: any;
  errors?: any;
  watch?: any;
  setValue?: any;
  control?: any;
  onEditMode?: boolean;
}

export function BankSection({
  initialData,
  register,
  errors,
  watch,
  control,
  setValue,
  onEditMode
}: BankSectionProps) {
  const {
    activateSubseller
  } = useSubSeller();
  function handleFormatPix(value: string) {
    if (watch("pixKeyType")?.includes("EMAIL") || watch("pixKeyType")?.includes("ALLEATORY_KEY")) return value;
    const pixKeyFormater = {
      "DOCUMENT": FormatDocument(value),
      "PHONE": phone_format(value)
    }
    return pixKeyFormater[watch("pixKeyType")];
  }

  function handlePixMask(pixKeyType: string) {
    if (pixKeyType === "PHONE" || pixKeyType === "DOCUMENT") {
      const pixType = {
        "DOCUMENT": "cpf/cnpj",
        "PHONE": "cellphone",
      }
      return pixType[pixKeyType];
    } else {
      return "";
    }
  }
  // async function handleActivateSplit() {
  //   try {
  //     await activateSubseller(initialData?.id);
  //     showToast({
  //       type: "success",
  //       message: `Split para subseller ativo com sucesso!`
  //     })
  //   } catch (error) {
  //     error?.response?.data?.errors?.map((error) => {
  //       showToast({
  //         type: "error",
  //         message: `${error?.code} - ${error?.description}`
  //       })
  //     });
  //   }
  // }

  return (
    <>
      <Divider />
      <Flex flexDir="column" w="100%" h="100%">
        <Flex 
          align="center" 
          gap="12px"
        >
          <Text
            fontWeight="600"
            fontSize="25px"
            color="#262F40"
            marginBottom="15px"
          >
            Dados Bancários
          </Text>
          <Flex
            marginBottom=".7rem"
            gap=".5rem"
            p="0 10px"
            w="fit-content"
            justify="center"
            align="center"
            height="24px"
            bgColor={SubSellerStatusBg(initialData?.splitStatus)}
            border={`1px solid ${SubSellerStatusBorder(initialData?.splitStatus)}`}
            borderRadius="2px"
          >
            {!initialData?.splitStatus?.includes("INACTIVE") && (
              <BsCheckCircle size={12} />
            )}
            <Text color={SubSellerStatusColor(initialData?.splitStatus)}>
              {
                initialData?.splitStatus?.includes("ACTIVE") ?
                  SubSellerStatus(initialData?.splitStatus, true) :
                  SubSellerStatus(initialData?.splitStatus)
              }
            </Text>
            {initialData?.splitStatus?.includes("INACTIVE") && (
              <Flex
                align="center"
                gap="8px"
              >
                <Tooltip
                  color={SubSellerStatusColor(initialData?.splitStatus)}
                  label={
                    `Revise as informações e atualize o cadastro 
                    para realizar nova tentativa de cadastramento 
                    da conta. 
                    `
                  }
                >
                  
                </Tooltip>
              </Flex>
            )}
          </Flex>
          {/* {initialData?.splitStatus?.includes("INACTIVE") && (
            <Button
              variant="secondary"
              maxW="180px"
              maxH="24px"
              fontSize="14px"
              borderRadius="3"
              marginBottom=".7rem"
              onClick={handleActivateSplit}
            >
              Tentar Novamente
            </Button>
          )} */}
        </Flex>
        <SimpleGrid
          w="100%"
          h="100%"
          columns={3}
          rowGap={8}
          columnGap={8}
          marginBottom={!onEditMode ? "1rem" : ""}
        >
          <Input
            maxW="250px"
            label="Nome do titular da conta"
            type="text"
            name="name"
            value={watch("name")}
            isDisabled
            p={!onEditMode ? "0" : ""}
            _disabled={{
              color: onEditMode && "#A5AEC0",
              bg: onEditMode ? "#F4F7FB" : "transparent",
              border: onEditMode ? "1px solid #C3CAD7" : "none",
              cursor: "default"
            }}
          />

          <Input
            maxW="250px"
            label="Documento do titular"
            type="text"
            name="ownerDocument"
            value={FormatDocument(watch("ownerDocument"))}
            isDisabled
            p={!onEditMode ? "0" : ""}
            _disabled={{
              color: onEditMode && "#A5AEC0",
              bg: onEditMode ? "#F4F7FB" : "transparent",
              border: onEditMode ? "1px solid #C3CAD7" : "none",
              cursor: "default"
            }}
          />

          {!onEditMode && (
            <Flex
              flexDir="column"
            >
              <Text fontSize=".8rem" marginBottom=".8rem">
                Número do banco
              </Text>
              <Text
                fontSize=".9rem"
                color="black"
              >
                {Banks.filter(item => item.value === String(initialData?.account?.bank))[0]?.label}
              </Text>
            </Flex>
          )}
          {onEditMode && (
            <SearchDropDown<ListInterface>
              label="Número do banco"
              maxW="250px"
              name="bank"
              options={Banks}
              register={{ ...register("bank") }}
              setValue={setValue}
              errors={errors?.bank}
              initialValue={watch("bank")}
            />
          )}

          <Flex w="250px" align="center" gap="4px">
            <Input
              w="190px"
              label="Agência"
              type="text"
              name="agency"
              register={{ ...register("agency") }}
              isDisabled={!onEditMode}
              p={!onEditMode ? "0" : ""}
              _disabled={{
                bg: "transparent",
                border: "none",
                cursor: "default"
              }}
              errors={errors?.agency}
              mask="onlyNumbers"
            />
            {onEditMode && (
              <Text marginTop="1.3rem">
                -
              </Text>
            )}
            <Input
              maxW="50px"
              label="Dígito"
              type="text"
              name="agencyDigit"
              register={{ ...register("agencyDigit") }}
              maxLength={1}
              isDisabled={!onEditMode}
              p={!onEditMode ? "0" : ""}
              _disabled={{
                bg: "transparent",
                border: "none",
                cursor: "default"
              }}
              errors={errors?.agencyDigit}
              mask="onlyNumbers"
            />
          </Flex>

          <Flex w="250px" align="center" gap="4px">
            <Input
              w="190px"
              label="Conta"
              type="text"
              name="account"
              register={{ ...register("account") }}
              isDisabled={!onEditMode}
              p={!onEditMode ? "0" : ""}
              _disabled={{
                bg: "transparent",
                border: "none",
                cursor: "default"
              }}
              errors={errors?.account}
              mask="onlyNumbers"
            />
            {onEditMode && (
              <Text marginTop="1.3rem">
                -
              </Text>
            )}
            <Input
              maxW="50px"
              label="Dígito"
              type="text"
              name="accountDigit"
              register={{ ...register("accountDigit") }}
              maxLength={1}
              isDisabled={!onEditMode}
              p={!onEditMode ? "0" : ""}
              _disabled={{
                bg: "transparent",
                border: "none",
                cursor: "default"
              }}
              errors={errors?.accountDigit}
              mask="onlyNumbers"
            />
          </Flex>

          {!onEditMode && (
            <Flex
              flexDir="column"
            >
              <Text fontSize=".8rem" marginBottom=".8rem">
                Tipo de conta
              </Text>
              <Text
                fontSize=".9rem"
                color="black"
              >
                {AccountTypes.filter(item => item.value === initialData?.account?.accountType)?.at(0)?.label}
              </Text>
            </Flex>
          )}

          {onEditMode && (
            <ListSelector
              bgColor="#FFFFFF"
              color="#000000"
              maxW="250px"
              label="Tipo de conta"
              placeholder=""
              options={AccountTypes}
              name="accountType"
              register={{ ...register("accountType") }}
              errors={errors?.accountType}
            />
          )}

          {!onEditMode && (
            <Flex
              flexDir="column"
            >
              <Text fontSize=".8rem" marginBottom=".8rem">
                Tipo de chave PIX
              </Text>
              <Text
                fontSize=".9rem"
                color="black"
              >
                {PixKeyTypes.filter(item => item.value === initialData?.account?.pixKeyType)?.at(0)?.label}
              </Text>
            </Flex>
          )}

          {onEditMode && (
            <ListSelector
              bgColor="#FFFFFF"
              color="#000000"
              maxW="250px"
              label="Tipo de chave PIX"
              placeholder=""
              options={PixKeyTypes}
              name="pixKeyType"
              register={{ ...register("pixKeyType") }}
              errors={errors?.pixKeyType}
            />
          )}

          {!onEditMode && (
            <Flex
              flexDir="column"
            >
              <Text fontSize=".8rem" marginBottom=".8rem">
                Chave PIX
              </Text>
              <Text
                fontSize=".9rem"
                color="black"
              >
                {handleFormatPix(initialData?.account?.pixKey)}
              </Text>
            </Flex>
          )}

          {onEditMode && (
            <Input
              maxW="250px"
              label="Chave PIX"
              type="text"
              name="pixKey"
              mask={handlePixMask(watch("pixKeyType"))}
              register={{ ...register("pixKey") }}
              isDisabled={!onEditMode}
              p={!onEditMode ? "0" : ""}
              _disabled={{
                bg: "transparent",
                border: "none",
                cursor: "default"
              }}
              errors={errors?.pixKey}
            />
          )}

        </SimpleGrid>
        {onEditMode && (
          <Divider marginTop="1.5rem" />
        )}
      </Flex>
    </>
  )
}