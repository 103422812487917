import ReactPaginate from "react-paginate";
import styled from "styled-components";

export const StyledReactPaginate = styled(ReactPaginate)`
  display: flex;
  list-style: none;
  gap: 6px;
  // margin-top: 25px;
  li {
    a {
      display: inline-block;
      min-width: 30px;
      padding: 2px 4px;
      border: 1px solid var(--gray-200);
      text-align: center;
      color: black;
    }

    &.disabled {
      a,
      a:hover {
        border-color: var(--gray-100);
        color: var(--gray-100);
        cursor: not-allowed;
      }
    }

    &.selected,
    &:hover {
      a {
        border-color: var(--blue);
        color: var(--blue);
      }
    }
  }
`;
