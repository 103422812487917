


import { IconProps } from "@/models";

export function MoonIcon({ size = 16 }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9999 9.02667C13.8951 10.1615 13.4692 11.2429 12.7721 12.1445C12.075 13.0461 11.1356 13.7305 10.0637 14.1177C8.99188 14.5049 7.83192 14.5787 6.7196 14.3307C5.60728 14.0827 4.5886 13.523 3.78275 12.7172C2.97691 11.9113 2.41723 10.8927 2.16921 9.78033C1.92118 8.66801 1.99508 7.50806 2.38224 6.4362C2.7694 5.36434 3.45382 4.42491 4.35541 3.72784C5.257 3.03076 6.33847 2.60487 7.47327 2.5C6.80888 3.39884 6.48917 4.5063 6.57229 5.62094C6.65541 6.73559 7.13584 7.78337 7.9262 8.57373C8.71656 9.36409 9.76435 9.84452 10.879 9.92765C11.9936 10.0108 13.1011 9.69106 13.9999 9.02667Z" stroke="#1E1E1E" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}