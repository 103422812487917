import { Text, TextProps } from "@chakra-ui/react";
import { FeatureToggleHoc } from "../../FeatureToggleHoc";

interface ChargeInstallmentsProps extends TextProps {
  installments: any;
}

function ChargeInstallments({
  installments,
  ...rest
}: ChargeInstallmentsProps) {
  return (
    <>
      {installments &&
        <Text {...rest}>
          ({installments}x)
        </Text>

      }

    </>
  )
}

export default FeatureToggleHoc(ChargeInstallments, "view_charge_installments", false);