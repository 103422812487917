import { Sidebar } from "@/layouts/Sidebar";
import { Box, Grid } from "@chakra-ui/react";
import { Navbar } from "@/components/Navbar";
import { flatRoutesList } from "@/Routes";
import { isExactFeatureToggle, isExactRole } from "@/utils";

import AlertTerms from "@/components/AlertTerms";
import { useEffect, useState } from "react";
import { useTerms } from "@/hooks/useTerms";
import { signOut } from "@/contexts";

const WithAuth = (WrappedComponent) => {
  function formatCurrentUrlToPathRoute(url, path) {
    let pathReturn = url;
    if (path.includes(":id")) {
      let urlToArray = url.split("/");
      urlToArray.pop();
      pathReturn = urlToArray.join("/") + "/:id";
    }
    return pathReturn;
  }

  return (props) => {
    const pathnameUrl = window.location.pathname;
    const featureTogglesUser = localStorage.getItem("barte-features-list-user");
    const roleSeller = JSON.parse(localStorage.getItem("barte-user-info"));
    const [updateTerms, setUpdateTerms] = useState<boolean>(false);
    const [logoff, setLogoff] = useState<boolean>(false);
    const [newTerms, setNewTerms] = useState<boolean>(false);
    const [typeSeller, setTypeSeller] = useState<string>("");
    const featureToShow = flatRoutesList().find((item) => {
      return item.path === formatCurrentUrlToPathRoute(pathnameUrl, item.path);
    });
    const { currentTerms, isValidTerms } = useTerms();

    // if (!accessToken) {
    //   window.location.replace("/login");
    //   return <></>;
    // }

    async function IsvalidTerms() {
      try {
        const response = await isValidTerms();
        response?.data === true ? setUpdateTerms(false) : setUpdateTerms(true)
        return response;
      } catch (error) {
        console.log(error);
      }
    }

    async function UploadTerms() {
      try {
        const response = await currentTerms();
        setNewTerms(true)
        return response;
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
      const roleSeller = JSON.parse(localStorage.getItem("barte-user-info"));
      if (roleSeller) {
        setTypeSeller(roleSeller?.roles)
      }
    }, [])

    useEffect(() => {
      IsvalidTerms();
    }, [newTerms]);

    useEffect(() => {
      if (logoff === true) {
        signOut();
        setLogoff(false);
      }
    }, [logoff])

    return (
      <>
        {updateTerms && <AlertTerms setLogoff={setLogoff} typeSeller={typeSeller} UploadTerms={UploadTerms} />}
        <Grid
          height="100vh"
          gridTemplateColumns="auto 1fr"
          gridTemplateRows="52px 1fr"
          gridTemplateAreas={`"S N" "S B" "S B"`}
          bgColor="gray.10"
          backgroundColor="rgba(251, 252, 254, 1)"

        >
          <Box gridArea={"S"} bgColor="gray.24" zIndex={1}>
            <Sidebar />
          </Box>
          <Box gridArea={"N"} bgColor="gray.10">
            <Navbar />
          </Box>
          <Box p="20px 20px 80px" gridArea={"B"} h="100%" bgColor="gray.10">
            {/**@ts-ignore */}
            {(isExactRole(roleSeller?.roles, featureToShow?.role) && isExactFeatureToggle(featureTogglesUser, featureToShow?.feature)) ? (
              <WrappedComponent {...props} />
            ) : (
              <span>Você não tem permissão para visualizar essa página!</span>
            )}
          </Box>
        </Grid>
      </>

    );
  }
};

export { WithAuth };
