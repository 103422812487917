import { useNavigate, Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  Image,
  Flex,
  Button as ChakraButton,
  Box,
  Icon,
  Badge,
  Text,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
} from "@chakra-ui/react";
import { copyTextToClipboard } from "@/utils/copyToClipboard";
import { IoIosArrowDown } from "react-icons/io";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { Alert, Pagination } from "@/components";
import { WithAuth } from "@/hoc";
import { useCustomLink } from "@/hooks";
import { PaymentLinkData, CustomLinkTabs, CustomLinkPaginator } from "@/models";
import { showToast, formatCurrency, getInitials, formatDate } from "@/utils";

const CustomLinks = () => {
  const { getCustomLink, handleDeleteCustomLink } = useCustomLink();
  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const [data, setData] = useState({} as CustomLinkPaginator);
  const cancelRef = useRef();
  const [actualTab, setActualTab] = useState("orders");
  const [userUuid, setUserUuid] = useState<PaymentLinkData>();
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [duplicateModalState, setDuplicateModalState] = useState(false);
  const [clientButtonState, setClientButtonState] = useState(false);
  const [filterPlaceholder, setFilterPlaceholder] = useState(
    "Buscar cobranças pontuais"
  );
  const [currentPage, setCurrentPage] = useState(0);
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  const featureToggle = JSON.parse(localStorage.getItem("barte-features-list-user"));
  const [tabs, setTabs] = useState([])
  async function getCustomLinkData(filter?: string) {
    
    let isDefaultApp = location?.hostname?.includes('barte.com');
    let filter_params = { size: 20, page: currentPage, sort: "createdAt,DESC", whiteLabel: !isDefaultApp};

    try {
      const response = await getCustomLink(
        actualTab,
        {
          [actualTab.includes("orders") ? "title" : "planTitle"]: filter
            ? filter
            : "",
        },
        filter_params
      );
      // setFilteredData(response.data);
      setData(response.data);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  let initialTabs = [];

  const newColums: ColumnsType<CustomLinkTabs> = [
    {
      key: actualTab.includes("orders") ? "title" : "planTitle",
      title: actualTab.includes("orders") ? "Título" : "Plano",
      dataIndex: actualTab.includes("orders") ? "title" : "planTitle",
    },

    {
      key: "createdAt",
      title: "Criado em",
      dataIndex: "createdAt",
      render: (createDate) => formatDate(createDate),
    },

    {
      key: actualTab.includes("orders") ? "value" : "valuePerMonth",
      title: "Valor",
      dataIndex: actualTab.includes("orders") ? "value" : "valuePerMonth",
      render: (value) => formatCurrency({ value }),
    },
    {
      key: actualTab.includes("fast-orders") ? "" : "name",
      title: actualTab.includes("fast-orders") ? "" : "Cliente",
      dataIndex: actualTab.includes("fast-orders") ? "" : "sellerClient",
      render: (text, record) => (
        <>
          {!actualTab.includes("fast-orders") && (
            <Flex align="center" gridGap="2">
              {/* {record?.sellerClient?.name && (
                <Badge
                  fontSize="1rem"
                  minWidth="35px"
                  minHeight="35px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  colorScheme="yellow"
                  color="gray.800"
                >
                  {getInitials(record?.sellerClient?.name) ?? "-"}
                </Badge>
              )} */}
              <Text color={record?.sellerClient?.name ? "black" : "gray.300"}>
                {record?.sellerClient?.name
                  ? record?.sellerClient?.name
                  : "Sem cliente"}
              </Text>
            </Flex>
          )}
        </>
      ),
    },
    // {
    //   key: "url",
    //   title: "",
    //   dataIndex: "url",
    //   render: (url) => (
    //     <Text
    //       color="gray.300"
    //       cursor="pointer"
    //       transition="all .2s"
    //       _hover={{
    //         transition: "all .2s",
    //         textDecoration: "underline",
    //         color: "blue.200",
    //       }}
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         copyTextToClipboard(url);
    //       }}
    //     >
    //       Copiar Link
          
    //     </Text>
    //   ),
    // },
    {
      key: "buttonOptions",
      title: "",
      render: (text, record) => (
        <Box p="0" w="175px" h="30px">
        
          <Flex
            h="30px"
            minW="280px"
            gap="10px"
            align="center"
            justify="space-between"
            className="rowAction"
          >
          <Flex
            h="40px"
            w="40px"
            align="center"
            justify="center"
            bgColor="white"
            borderRadius="8px"
            transition="all .2s"
            _hover={{
              transition: "all .2s",
              bgColor: "white",
            }}
          >
            <Image
              h="20px"
              w="20px"
              onClick={(event) => {
                event.stopPropagation();
                setUserUuid({
                  ...userUuid,
                  uuid: record.uuid,
                });
                setDuplicateModalState(!duplicateModalState);
              }}
              cursor="pointer"
              src="/images/icons/Duplicate.svg"
              transition="filter .2s"
              _hover={{
                transition: "filter .2s",
                filter: "brightness(.3)",
              }}
            />
          </Flex>
       
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bgColor="white"
              borderRadius="8px"

              transition="all .2s"
              _hover={{
                transition: "all .2s",
                bgColor: "white",
              }}
            >
              <Image
                onClick={(event) => {
                  event.stopPropagation();
                  setUserUuid({
                    ...userUuid,
                    uuid: record.uuid,
                  });
                  setDeleteModalState(!deleteModalState);
                }}
                cursor="pointer"
                src="/images/icons/Trash.svg"
                transition="filter .2s"
                h="20px"
                w="20px"
                _hover={{
                  transition: "filter .2s",
                  filter: "brightness(.3)",
                }}
              />
            </Flex>
            <Flex
              transition="all .2s"
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bgColor="white"
              borderRadius="8px"
              _hover={{
                transition: "all .2s",
                bgColor: "white",
              }}
            >
              <Image
                onClick={(event) => {
                  event.stopPropagation();
                  copyTextToClipboard(`${import.meta.env.VITE_CHECKOUT_URL}payment-link/${record.uuid}`)
                }}
                h="20px"
                w="20px"
                cursor="pointer"
                src="/images/icons/Link.svg"
                transition="filter .2s"
                _hover={{
                  transition: "filter .2s",
                  filter: "brightness(.3)",
                }}
              />
            </Flex>
            <Flex               
            align="center"
            justify="center"
            h="40px"
            w="170px"
            bgColor="white"
            borderRadius="8px"
            transition="all .2s"
            _hover={{
              transition: "all .2s",
              bgColor: "white",
            }}
            
            >
              <ChakraLink
                onClick={(event)=> event.stopPropagation()}
                href={`/seja-pago/editar/${record?.uuid}`}
                target="_blank"
                _hover={{ cursor: "pointer" }}
                display="flex"
                alignItems="center"
                gap="10px"
              >

                <Image
                  id="excluir_cobranca"
                  h="20px"
                  w="20px"
                  src="/images/icons/Export.svg"
                  transition="filter .2s"
                  _hover={{
                    transition: "filter .2s",
                    filter: "brightness(.3)",
                  }}
                />
                <Text fontSize="12px" color="gray_600">
                Abrir em nova aba
                </Text>
              </ChakraLink>
            </Flex>
          </Flex>
        </Box>
      ),
    },
  ];

  // function filterTable(searchParam: string) {
  //   if (filteredData.content.length > 0) {
  //     if (filteredData.content[0].title) {
  //       setFilteredData({
  //         ...filteredData,
  //         content: filteredData.content.filter(
  //           ({ title, sellerClient }: any) =>
  //             title.toLowerCase().includes(searchParam.toLowerCase()) &&
  //             (clientButtonState ? sellerClient : true)
  //         ),
  //       });
  //     }

  //     if (filteredData.content[0].planTitle) {
  //       setFilteredData({
  //         ...filteredData,
  //         content: filteredData.content.filter(
  //           ({ planTitle, sellerClient }: any) =>
  //             planTitle.toLowerCase().includes(searchParam.toLowerCase()) &&
  //             (clientButtonState ? sellerClient : true)
  //         ),
  //       });
  //     }
  //   } else {
  //     setFilteredData({} as CustomLinkPaginator);
  //   }
  // }

  async function handleChangeTab(tabName: string) {
    setFilterPlaceholder(
      tabName === "orders"
        ? "Buscar cobranças pontuais"
        : "Buscar assinaturas customizadas"
    );
    setActualTab(tabName);

    // try {
    //   const { data } = await getCustomLink(tabName);

    //   setData(data);
    //   setFilteredData(data);
    // } catch (error) {
    //   setData(data);
    //   error?.response?.data?.errors.map((error) => {
    //     showToast({
    //       type: "error",
    //       message: `${error?.code} - ${error?.description}`,
    //     });
    //   });
    // }
  }

  async function handleDelete() {
    try {
      await handleDeleteCustomLink(userUuid.uuid);
      await getCustomLinkData();

      showToast({
        message: "Link customizado excluído com sucesso!",
        type: "success",
      });
      setDeleteModalState(!deleteModalState);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  function handleDuplicateLink() {
    if(actualTab.includes("fast-orders")) {
      navigate(`/seja-pago/link-rapido?id=${userUuid?.uuid}`)
    } else {
      userUuid.planTitle
      ? navigate(`/seja-pago/criar/assinatura-customizada?id=${userUuid.uuid}`)
      : navigate(`/seja-pago/criar/cobranca-pontual?id=${userUuid.uuid}`);
    }
    setDuplicateModalState(!duplicateModalState);
  }
  useEffect(() => {
    initialTabs.push({
      tab: "Cobranças pontuais",
      key: "orders",
      status: "",
    },
    {
      tab: "Cobranças recorrentes",
      key: "subscriptions",
      status: "",
    },)
    if(featureToggle?.includes("view_fast_payment_link")) {
      initialTabs.push({
        tab: "Links Rápidos",
        key: "fast-orders",
        status: "",
      })
    }
    setTabs(initialTabs)
  }, [])

  useEffect(() => {
    if (clientButtonState) {
      // if (filteredData?.content) {
      //   const result = filteredData?.content?.filter(
      //     ({ sellerClient }: any) => sellerClient?.name
      //   );

      //   setFilteredData({
      //     ...filteredData,
      //     content: result,
      //   });
      // } else {
      // }
      const newResult = data?.content?.filter(
        ({ sellerClient }: any) => sellerClient?.name
      );

      if (!newResult) {
        setData({} as CustomLinkPaginator);
        // setFilteredData({} as CustomLinkPaginator);
      } else {
        setData({
          ...data,
          content: newResult,
        });
      }
    } else {
      handleChangeTab(actualTab);
    }
  }, [clientButtonState]);

  useEffect(() => {
    getCustomLinkData();
  }, [actualTab, currentPage]);

  return (
    <Box letterSpacing="tighter">
      <Alert
        closeButtonText={"Não"}
        confirmButtonText="Sim"
        isConfirmAlert={false}
        isOpen={deleteModalState}
        onConfirm={() => handleDelete()}
        onClose={() => setDeleteModalState(!deleteModalState)}
        title={`Tem certeza que deseja excluir esse link?`}
        messageBody="Excluí-lo desativará o link para futuras contratações."
        leastDestructiveRef={cancelRef}
      />

      <Alert
        closeButtonText={"Não"}
        confirmButtonText="Sim"
        isConfirmAlert={false}
        isOpen={duplicateModalState}
        onConfirm={() => handleDuplicateLink()}
        onClose={() => setDuplicateModalState(!duplicateModalState)}
        title={`Deseja duplicar esse link customizado?`}
        messageBody="O novo link replicará todas as informações do original,
        com exceção do cliente, que virá em branco."
        leastDestructiveRef={cancelRef}
      />
      <Flex top="4.5rem" right="1.3rem" justifyContent="flex-end" zIndex={1}>
        {(user?.roles?.includes("SELLER_SUBSCRIPTION") ||
          user?.roles?.includes("SELLER_MASTER") ||
          user?.roles?.includes("SELLER_ADMIN") ||
          user?.roles?.includes("SELLER_BACKOFFICE")) && (
            <ChakraLink
              href="/importacao-de-recorrencia"
              border="1px solid"
              borderColor="neutral.200"
              color="black"
              borderRadius="4"
              h="40px"
              p="0 .5rem"
              marginRight="1rem"
              transition="filter .2s"
              _hover={{
                transition: "filter .2s",
                filter: "brightness(.7)",
              }}
              _active={{}}
            >
              <Text marginTop=".5rem">Importar Recorrências</Text>
            </ChakraLink>
          )}
        <Menu>
          <MenuButton
            color="white"
            bgColor="blue.200"
            h="40px"
            borderRadius="4"
            as={ChakraButton}
            rightIcon={<IoIosArrowDown />}
            _hover={{}}
            _expanded={{
              bgColor: "blue.200",
              filter: "brightness(.9)",
            }}
          >
            Criar
          </MenuButton>
          <MenuList
            zIndex={100}
            p="5px"
            border="none"
            boxShadow="5px 5px 20px 5px rgba(0,0,0,.09)"
          >
            <MenuItem
              borderRadius="4"
              onClick={() => navigate("/seja-pago/criar/cobranca-pontual")}
            >
             Link de Pagamento
            </MenuItem>

            { featureToggle?.includes("view_fast_payment_link") &&
           
            <MenuItem
              borderRadius="4"
              onClick={() =>
                navigate("/seja-pago/criar/link-rapido")
              }
            >
              Link Rápido
            </MenuItem>
            }
          </MenuList>
        </Menu>
      </Flex>
      <Box marginTop="2rem" position="relative">
        {/* <Filter
          placeholder={filterPlaceholder}
          searchFunction={getCustomLinkData}
          isVisibleDatepicker={false}
        /> */}
        <Tabs
          defaultActiveKey="orders"
          className="tabs-custom"
          onChange={handleChangeTab}
        >
          {tabs.map(({ key, tab }) => (
            <TabPane
              className="test"
              tab={tab}
              key={key}
              style={{ position: "relative" }}
            >
              <Flex position="absolute" top="1rem" right="2rem">
                <Checkbox
                  isChecked={clientButtonState}
                  onChange={() => setClientButtonState(!clientButtonState)}
                  marginRight=".3rem"
                />
                <Text color="black">
                  Ver somente links dedicados a clientes
                </Text>
              </Flex>
              <Table<PaymentLinkData>
                columns={newColums as ColumnsType}
                dataSource={data?.content?.length > 0 ? data.content : []}
                rowKey={(record) => record.uuid}
                onRow={(record) => ({
                  onClick: () => {
                    setUserUuid(record);
                    navigate(`/seja-pago/editar/${record?.uuid}`);
                  },
                  onMouseEnter: () => setUserUuid(record),
                })}
                locale={{ emptyText: "Sem dados de links customizados" }}
                pagination={false}
              />
              <Pagination
                totalPages={data?.totalPages}

                setCurrentPage={setCurrentPage}
              />
            </TabPane>
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default WithAuth(CustomLinks);
