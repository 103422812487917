import { Alert, Input, TextArea } from "@/components";
import { DatePickerComponent } from "@/components/Forms/DatePicker/DatePicker";
import { InputLabelTag } from "@/components/Forms/InputLabelTag";
import { SearchDropDown } from "@/components/Forms/SearchDropDown";
import { ListSelector } from "@/components/Forms/Selector";
import { WithAuth } from "@/hoc";
import { useClient } from "@/hooks";
import { useSubSeller } from "@/hooks/useSubSeller";
import { Container } from "@/layouts/SejaPago/Container";
import { TaxTypeGroup } from "@/layouts/SubSellers/TaxTypeGroup";
import { AccountTypes } from "@/models/AccountType";
import { Banks } from "@/models/Banks";
import { Countries } from "@/models/Countries";
import { ListInterface } from "@/models/interfaces";
import { PixKeyTypes } from "@/models/PixKeyTypes";
import { States } from "@/models/States";
import { clearMask, formatCurrencyToApi, showToast } from "@/utils";
import { FormatDocument } from "@/utils/formatDocument";
import { formatPercentageToApi } from "@/utils/formatPercentageToApi";
import { validateAlleatoryPixKey } from "@/utils/validateAlleatoryPixKey";
import { Button, Flex, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { formatISO } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

export type DocumentType = "CPF" | "CNPJ" | "";

interface SubSeller {
  document: string;
  mail: string;
  name: string;
  cellphone: string;
  openedDate: Date;
  birthDate: Date;
  description: string;
  zipCode: string;
  street: string;
  number: number;
  complement: string;
  district: string;
  city: string;
  state: string;
  country: string;
  value: number;
  bank: string;
  agency: number;
  agencyDigit: number;
  account: number;
  accountDigit: number;
  accountType: string;
  pixKey: string;
  pixKeyType: string;
  ownerDocument?: string;
}

type SubSellerStatus = {
  type: string;
  errorMessage?: string;
}

export interface SubSellers {
  id: number;
  status: SubSellerStatus;
  generalInfo: {
    document: string;
    mail: string;
    name: string;
    cellphone: string;
    openedDate: Date;
    birthDate: Date;
    description: string;
  },
  bankData: {
    bank: string;
    agency: number;
    agencyDigit: number;
    account: number;
    accountDigit: number;
    accountType: string;
  },
  address: {
    zipCode: string;
    street: string;
    number: number;
    complement: string;
    district: string;
    city: string;
    state: string;
    country: string;
  }
  valueDivison: {
    taxType: string;
    value: number;
  }
}

type TaxType = "FIXED" | "PERCENTAGE";

const valueType = [
  {
    name: "Valor fixo",
    key: "FIXED",
    value: "FIXED",
    position: "start"
  },
  {
    name: "Valor percentual",
    key: "PERCENTAGE",
    value: "PERCENTAGE",
    position: "end"
  },
]

export interface SubSellerDto {
  name: string;
  documentType: string;
  document: string;
  alternativeEmail?: String;
  address: {
    country: string;
    state: string;
    city: string;
    district: string;
    street: string;
    zipCode: string;
    number: string;
    complement: string;
  };
  account: {
    account: {
      bank: string;
      issuer: string;
      number: string;
      bankDigit: string;
      issuerDigit: string;
      accountType: string;
    };
    bank?: number;
    id?: number;
    issuer?: string;
    number?: string;
    pixKey: string;
    pixKeyType: string;
  };
  contact: {
    name: string;
    email: string;
    phone: string;
  };
  owner: {
    ownerName: string;
    ownerDocument: string;
    birthdate?: string;
  };
  openingDate?: string;
  paymentType: string;
  paymentValue: number;
  description?: string;
}

const NewSubSeller = () => {
  const [documentType, setDocumentType] = useState<DocumentType>("");
  const [isCepLoading, setIsCepLoading] = useState<boolean>(false);
  const [documentIsValid, setDocumentIsValid] = useState<boolean>(false);
  const [taxType, setTaxType] = useState<TaxType>("FIXED");
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmModalState, setConfirmModalState] = useState<boolean>(false);
  const [pixKeyValidation, setPixKeyValidation] = useState<boolean>(null);

  const {
    createSubseller,
    activateSubseller
  } = useSubSeller();

  const { consultCep } = useClient();
  const navigate = useNavigate();
  const cancelRef = useRef();

  function validatePixKeyMessage(pixKeyType: any) {
    const validationMessage = {
      "DOCUMENT": "Formato de documento incorreto",
      "ALLEATORY_KEY": "Chave aleatória incorreta",
      "EMAIL": "Formato de email inválido",
      "PHONE": "Formato de telefone inválido",
    };
    return validationMessage[pixKeyType];
  }

  function handlePixMask(pixKeyType: string) {
      const pixType = {
        "DOCUMENT": "cpf/cnpj",
        "PHONE": "cellphone",
        "ALLEATORY_KEY": "",
        "EMAIL": ""
      }
      return pixType[pixKeyType];
  }

  const createSubSellerSchema = yup.object().shape({
    document: yup.string()
      .required("Preenchimento obrigatório")
      .test(
        documentType === "CPF" ? "cpf-validation" : "cnpj-validation",
        documentType === "CNPJ" ? "CPF inválido" : "CNPJ inválido",
        (value) =>
          clearMask(value)?.length <= 11
            ? cpf.isValid(clearMask(value))
            : cnpj.isValid(clearMask(value))
      ),
    ownerDocument: yup.string().test(
      documentType === "CPF" ? "cpf-validation" : "cnpj-validation",
      documentType === "CNPJ" ? "CPF inválido" : "CNPJ inválido",
      (value) =>
        clearMask(value)?.length <= 11
          ? cpf.isValid(clearMask(value))
          : cnpj.isValid(clearMask(value))
    ),
    mail: yup.string().email("formato de email inválido").required("Preenchimento obrigatório"),
    name: yup.string().required("Preenchimento obrigatório"),
    ownerName: yup.string().when('document', {
      is: () => documentType === 'CNPJ',
      then: yup.string().required("Preenchimento obrigatório"),
      otherwise: yup.string(),
    }),
    cellphone: yup.string().required("Preenchimento obrigatório"),
    openedDate: yup.string().when('document', {
      is: () => documentType === 'CNPJ',
      then: yup.string().required("Preenchimento obrigatório"),
      otherwise: yup.string(),
    }),
    birthDate: yup.string().required("Preenchimento obrigatório"),
    description: yup.string().when('document', {
      is: () => documentType === 'CNPJ',
      then: yup.string().required("Preenchimento obrigatório"),
      otherwise: yup.string(),
    }),
    zipCode: yup.string().required("Preenchimento obrigatório"),
    street: yup.string().required("Preenchimento obrigatório"),
    number: yup.string().required("Preenchimento obrigatório"),
    complement: yup.string(),
    district: yup.string().required("Preenchimento obrigatório"),
    city: yup.string().required("Preenchimento obrigatório"),
    state: yup.string().required("Preenchimento obrigatório"),
    country: yup.string().required("Preenchimento obrigatório"),
    value: yup.string().required("Preenchimento obrigatório"),
    bank: yup.string().required("Preenchimento obrigatório"),
    agency: yup.string().min(3, "").required(""),
    agencyDigit: yup.string().required(""),
    account: yup.string().min(3, "").required(""),
    accountDigit: yup.string().required(""),
    accountType: yup.string().required("Preenchimento obrigatório"),
    pixKeyType: yup.string().required("Preenchimento obrigatório"),
    pixKey: yup.string().when('pixKeyType', (pixKeyType, schema) => {
      return schema.test("pix-key-validation", validatePixKeyMessage(pixKeyType), (value) => {
        if(value === "" || value === undefined){ 
          yup.string().required("Preenchimento obrigatório").isValidSync(value)
          return
        }
        if (pixKeyType === "DOCUMENT") {
          return clearMask(value)?.length <= 11 ? cpf.isValid(clearMask(value)) : cnpj.isValid(clearMask(value));
        }
        if (pixKeyType === "ALLEATORY_KEY") {
          return validateAlleatoryPixKey(value);
        }
        if (pixKeyType?.includes("EMAIL")) {
          return yup.string().email("Formato de email inválido").isValidSync(value)
        }
        return clearMask(value)?.length <= 11
      });
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(createSubSellerSchema),
  });
  const descriptionValue = watch("description");

  async function consultCepData() {
    try {
      clearErrors("zipCode");
      setIsCepLoading(true);
      const { data } = await consultCep(clearMask(watch("zipCode")));
      setValue("street", data.logradouro);
      setValue("district", data.bairro);
      setValue("city", data.localidade);
      setValue("state", data.uf);
      setValue("complement", data.complemento);
      setValue("country", "BR");
    } catch (error) {
      if (watch("zipCode").length !== 10) return setError("zipCode", { type: "custom", message: "Cep inválido." })
    } finally {
      setIsCepLoading(false);
    }
  }

  const handleCreateSubSeller: SubmitHandler<any> = async (values) => {
    const parsedBirthDate =  formatISO(new Date(values?.birthDate), { representation: "date" })
    const parsedOpenedDate = documentType?.includes("CNPJ") ? formatISO(new Date(values?.openedDate), { representation: "date" }) : null;
    try {

      const newSubSellerDto: SubSellerDto = {
        name: values?.name,
        documentType: documentType,
        document: clearMask(values?.document),
        address: {
          country: values?.country,
          state: values?.state,
          city: values?.city,
          district: values?.district,
          street: values?.state,
          zipCode: values?.zipCode,
          number: values?.number.toString(),
          complement: values?.complement
        },
        account: {
          account: {
            bank: values?.bank,
            issuer: values?.agency?.toString(),
            number: values?.account?.toString(),
            bankDigit: values?.accountDigit?.toString(),
            issuerDigit: values?.agencyDigit?.toString(),
            accountType: values?.accountType,
          },
          pixKey: values?.pixKey,
          pixKeyType: values?.pixKeyType,
        },
        contact: {
          name: values?.name,
          email: values?.mail,
          phone: clearMask(values?.cellphone),
        },
        owner: {
          ownerName: documentType?.includes("CPF") ? values?.name : values?.ownerName,
          ownerDocument: documentType?.includes("CPF") ? clearMask(values?.document) : clearMask(values?.ownerDocument),
          birthdate: parsedBirthDate ?? null
        },
        openingDate:  parsedOpenedDate ?? null,
        paymentType: taxType,
        paymentValue: taxType?.includes("FIXED") ? formatCurrencyToApi(values?.value) : formatPercentageToApi(values?.value),
        description: values?.description
      }

      const response = await createSubseller(newSubSellerDto);
      await activateSubseller(response?.data?.id);
      setConfirmModalState(!confirmModalState);
    } catch (error) {
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  useEffect(() => {
      if(documentType === "CPF") {
        setValue("ownerDocument", watch("document"));
      }
  }, [watch("document"), documentType]);
  useEffect(() => {
    setValue("value", "");
  }, [taxType])

  return (
    <>
      <Alert
        isConfirmAlert
        isProcessing
        isOpen={confirmModalState}
        onConfirm={() => (
          setConfirmModalState(!confirmModalState), navigate("/sub-vendedores")
        )}
        onClose={() => setConfirmModalState(!confirmModalState)}
        title="Cadastro de sub-vendedor em processamento!"
        confirmButtonText="Ok"
        messageBody="Normalmente o processamento leva em torno de 5 minutos.
        Você pode conferir o status do cadastramento na listagem ou nos detalhes do sub-vendedor."
        leastDestructiveRef={cancelRef}
      />
      <Flex
        flexDir="column"
        align="center"
      >
        <Flex
          as="form"
          autoComplete="off"
          maxW="520px"
          w="520px"
          flexDir="column"
          onSubmit={handleSubmit(handleCreateSubSeller)}
        >
          <Container
            title="Informações gerais"
            flexDir="column"
            startsOpen
            hasPadding
          >
            <InputLabelTag
              type="text"
              mask="cpf/cnpj"
              maxLength={14}
              name="document"
              register={{ ...register("document") }}
              errors={errors?.document}
              documentType={documentType}
              documentTypeFunc={setDocumentType}
              setDocumentIsValid={setDocumentIsValid}
              documentIsValid={documentIsValid}
              hasSelectedLabel
            />

            {documentIsValid && (
              <>
                {documentType.includes("CPF") && (
                  <>
                    <Input
                      label="Nome"
                      type="text"
                      name="name"
                      register={{ ...register("name") }}
                      errors={errors?.name}
                    />
                    <Input
                      label="Email"
                      type="mail"
                      name="mail"
                      register={{ ...register("mail") }}
                      errors={errors?.mail}
                    />
                    <Input
                      label="Telefone"
                      type="text"
                      name="cellphone"
                      mask="cellphone"
                      register={{ ...register("cellphone") }}
                      errors={errors?.cellphone}
                    />
                    <DatePickerComponent
                      label="Data de nascimento"
                      control={control}
                      name="birthDate"
                      register={{ ...register("birthDate") }}
                      errors={errors?.birthDate}
                    />
                  </>
                )}
                {documentType.includes("CNPJ") && (
                  <>
                    <Input
                      label="Email"
                      type="mail"
                      name="mail"
                      register={{ ...register("mail") }}
                      errors={errors?.mail}
                    />
                    <Input
                      label="Nome"
                      type="text"
                      name="name"
                      register={{ ...register("name") }}
                      errors={errors?.name}
                    />
                    <Input
                      label="CPF do proprietário"
                      type="text"
                      mask="cpf/cnpj"
                      maxLength={14}
                      name="ownerDocument"
                      register={{ ...register("ownerDocument") }}
                      errors={errors?.ownerDocument}
                    />
                    <Input
                      label="Nome do proprietário"
                      type="text"
                      name="ownerName"
                      register={{ ...register("ownerName") }}
                      errors={errors?.ownerName}
                    />
                    <DatePickerComponent
                      label="Nascimento"
                      control={control}
                      name="birthDate"
                      register={{ ...register("birthDate") }}
                      errors={errors?.birthDate}
                    />
                    <Input
                      label="Telefone"
                      type="text"
                      name="phone"
                      mask="cellphone"
                      register={{ ...register("cellphone") }}
                      errors={errors?.cellphone}
                    />
                    <DatePickerComponent
                      label="Data de abertura da empresa"
                      control={control}
                      name="openedDate"
                      register={{ ...register("openedDate") }}
                      errors={errors?.openedDate}
                    />
                    <DatePickerComponent
                      label="Nascimento"
                      control={control}
                      name="birthDate"
                      register={{ ...register("birthDate") }}
                      errors={errors?.birthDate}
                    />
                    <TextArea
                      label="Descrição do negócio da empresa"
                      name="description"
                      color="black"
                      labelFontSize=".8rem"
                      labelColor="gray.600"
                      maxLength={300}
                      maxLengthBlocks
                      register={{ ...register("description") }}
                      errors={errors?.description}
                      currentLength={descriptionValue?.length}
                    />
                  </>
                )}
              </>
            )}
          </Container>

          {documentIsValid && (
            <>
              <Container
                title="Endereço"
                flexDir="column"
                hasPadding
              >
                <Input
                  label="CEP"
                  type="text"
                  name="zipCode"
                  mask="cep"
                  onBlur={consultCepData}
                  register={{ ...register("zipCode") }}
                  errors={errors?.cep}
                />
                <Input
                  label="Rua"
                  type="text"
                  name="street"
                  isDisabled={isCepLoading}
                  register={{ ...register("street") }}
                  errors={errors?.street}
                />
                <Flex gap="24px">
                  <Input
                    label="Número"
                    type="number"
                    name="number"
                    isDisabled={isCepLoading}
                    register={{ ...register("number") }}
                    errors={errors?.number}
                  />
                  <Input
                    label="Complemento"
                    type="text"
                    name="complement"
                    optionalText="(opcional)"
                    isDisabled={isCepLoading}
                    register={{ ...register("complement") }}
                    errors={errors?.complement}
                  />
                </Flex>
                <Input
                  label="Bairro"
                  type="text"
                  name="district"
                  isDisabled={isCepLoading}
                  register={{ ...register("district") }}
                  errors={errors?.district}
                />
                <Input
                  label="Cidade"
                  type="text"
                  name="city"
                  isDisabled={isCepLoading}
                  register={{ ...register("city") }}
                  errors={errors?.city}
                />
                <Flex gap="24px">
                  <ListSelector
                    label="Estado"
                    placeholder="Escolha seu estado"
                    options={States}
                    showValueText
                    name="state"
                    register={{ ...register("state") }}
                    errors={errors?.state}
                  />
                  <ListSelector
                    label="País"
                    placeholder="Escolha seu país"
                    options={Countries}
                    name="country"
                    register={{ ...register("country") }}
                    errors={errors?.country}
                  />
                </Flex>
              </Container>

              <Container
                title="Divisão de valores padrão"
                flexDir="column"
                hasPadding
              >
                <TaxTypeGroup
                  options={valueType}
                  selectedOption={taxType}
                  setSelectedOption={setTaxType}
                />
                {taxType.includes("FIXED") && (
                  <Input
                    label="Valor"
                    type="text"
                    mask={"currency"}
                    name="value"
                    register={{ ...register("value") }}
                    errors={errors?.value}
                  />
                )}
                {taxType.includes("PERCENTAGE") && (
                  <Input
                    label="Valor"
                    type="text"
                    name="value"
                    value={watch("value")}
                    onChange={(e) => {
                      let inputValue = e.target.value;
                      if (/^\d*([,.]?\d*)$/.test(inputValue)) {
                        if(Number(inputValue?.replace(",", ".")) > 100) {
                          inputValue = "100"
                        }
                        setValue("value", inputValue);
                      }
                    }}
                    onBlur={(e: any) => {
                      let inputValue = e.target.value?.replace("%", "");
                      setValue("value", `${inputValue}%`)
                    }}
                    errors={errors?.value}
                  />
                )}
                
              </Container>

              <Container
                title="Dados bancários"
                flexDir="column"
                hasPadding
              >
                <Input
                  label="Titular da conta"
                  type="text"
                  isDisabled
                  name="name"
                  value={watch("name")}
                />

                <Input
                  label="Documento do titular"
                  type="text"
                  name="ownerDocument"
                  value={FormatDocument(documentType.includes("CNPJ") ? watch("ownerDocument") : watch("document"))}
                  isDisabled
                />

                <SearchDropDown<ListInterface>
                  label="Banco"
                  name="bank"
                  options={Banks}
                  defaultValue="Selecione um banco"
                  register={{ ...register("bank") }}
                  setValue={setValue}
                  errors={errors?.bank}
                />

                <Flex gap="24px">
                  <Flex align="center" gap="4px">
                    <Input
                      label="Agência"
                      type="text"
                      mask="onlyNumbers"
                      name="agency"
                      register={{ ...register("agency") }}
                      errors={errors?.agency}
                    />
                    <Text marginTop="1.3rem">
                      -
                    </Text>
                    <Input
                      label="Dígito"
                      type="text"
                      mask="onlyNumbers"
                      name="agencyDigit"
                      maxLength={1}
                      register={{ ...register("agencyDigit") }}
                      errors={errors?.agencyDigit}
                    />
                  </Flex>

                  <Flex align="center" gap="4px">
                    <Input
                      label="Conta"
                      type="text"
                      mask="onlyNumbers"
                      name="account"
                      register={{ ...register("account") }}
                      errors={errors?.account}
                    />
                    <Text marginTop="1.3rem">
                      -
                    </Text>
                    <Input
                      label="Dígito"
                      type="text"
                      mask="onlyNumbers"
                      name="accountDigit"
                      maxLength={1}
                      register={{ ...register("accountDigit") }}
                      errors={errors?.accountDigit}
                    />
                  </Flex>
                </Flex>

                <ListSelector
                  label="Tipo de conta"
                  placeholder=""
                  options={AccountTypes}
                  name="accountType"
                  register={{ ...register("accountType") }}
                  errors={errors?.accountType}
                />

                <ListSelector
                  label="Tipo de chave pix"
                  placeholder=""
                  options={PixKeyTypes}
                  name="pixKeyType"
                  register={{ ...register("pixKeyType") }}
                  errors={errors?.pixKeyType}
                />
                <Input
                  label="Chave PIX"
                  type="text"
                  name="pixKey"
                  mask={handlePixMask(watch("pixKeyType"))}
                  register={{ ...register("pixKey") }}
                  errors={errors?.pixKey}
                />

              </Container>
            </>
          )}
          {documentIsValid && (
            <Button
              isLoading={loading}
              type="submit"
              alignSelf="center"
              variant="solid"
              bgColor="blue.200"
              color="white"
              borderRadius="3"
              marginTop="3rem"
              transition="filter .2s"
              _hover={{
                transition: "filter .2s",
                filter: "brightness(.9)",
              }}
              _active={{
                filter: "brightness(.6)",
              }}
              _focus={{}}
            >
              Criar sub-vendedor
            </Button>
          )}
          {!documentIsValid && (
            <Button
              isLoading={loading}
              type="button"
              alignSelf="center"
              variant="solid"
              bgColor="blue.200"
              color="white"
              borderRadius="3"
              marginTop="3rem"
              transition="filter .2s"
              _hover={{
                transition: "filter .2s",
                filter: "brightness(.9)",
              }}
              _active={{
                filter: "brightness(.6)",
              }}
              _focus={{}}
            >
              Criar sub-vendedor
            </Button>
          )}
          
        </Flex>
      </Flex>
    </>
  )
}

export default WithAuth(NewSubSeller);