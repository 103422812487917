import { NotificationMessageProps } from "@/models/NotificationPanel";
import { formatCurrency, formatDate, getInitials } from "@/utils";
import { randomColor } from "@/utils/randomColors";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";

interface ObjectProps {
  buyer: string;
  amount: string;
  date: string;
  whatsApp: string;
  uuid: string;
}

export function LateNotification({
  data,
  timeDiff,
  notifiedAt = null,
}: NotificationMessageProps) {
  const object: ObjectProps = data.reduce(
    (obj: any, item: any) => Object.assign(obj, { [item.name]: item.value }),
    {}
  );

  const formattedData = {
    ...object,
    amount: formatCurrency({ value: Number(object.amount) }),
    date: formatDate(object.date, true),
  };

  return (
    <Flex flexDir="row">
      <Flex
        h="32px"
        align="center"
        marginLeft="-.5rem"
        marginRight=".5rem"
        marginTop="1rem"
      >
        <Box
          borderRadius="full"
          bgColor={notifiedAt === null ? "magenta" : "transparent"}
          h="6px"
          w="6px"
        />
      </Flex>
      <Flex flexDir="column" h="180px" marginTop="1rem">
        <Flex flexDir="row" h="180px">
          <Badge
            fontSize="1rem"
            width="32px"
            height="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            colorScheme="red"
            color="gray.800"
            marginRight="1rem"
          >
            {getInitials(formattedData.buyer) ?? "-"}
          </Badge>
          <Flex flexDir="column" w="100%" h="160px" justify="space-between">
            <Link
              href={`/cobrancas-historico/${formattedData.uuid}`}
              _hover={{}}
            >
              <Text w="100%">
                <Text fontWeight="bold" display="inline">
                  {formattedData.buyer}{" "}
                </Text>
                <Text display="inline">possui uma cobrança de </Text>
                <Text fontWeight="bold" display="inline">
                  {formattedData.amount}
                </Text>
                <Text display="inline"> que venceu em </Text>
                <Text fontWeight="bold" display="inline">
                  {formattedData.date}{" "}
                </Text>
                <Text display="inline">e</Text>
                <Text color="red.800" display="inline">
                  {" "}
                  não identificamos{" "}
                </Text>
                <Text display="inline"> o pagamento. </Text>
              </Text>
            </Link>
            <Link
              href={`https://Api.whatsapp.com/send?phone=55${formattedData.whatsApp}`}
              target="_blank"
              _hover={{
                textDecoration: "none",
              }}
            >
              <Button
                id="clique_em_whatsapp"
                w="190px"
                h="25px"
                bgColor="transparent"
                borderRadius="3"
                border="1px"
                borderColor="gray.100"
                fontSize=".9rem"
                textDecoration="none"
                _hover={{}}
              >
                Contatar por Whatsapp
              </Button>
            </Link>
            <Text fontSize=".8rem">{timeDiff}</Text>
          </Flex>
        </Flex>
        <Divider />
      </Flex>
    </Flex>
  );
}
