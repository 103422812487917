import { Alert, Input, TextArea } from "@/components";
import { DatePickerComponent } from "@/components/Forms/DatePicker/DatePicker";
import { InputLabelTag } from "@/components/Forms/InputLabelTag";
import { ListSelector } from "@/components/Forms/Selector";
import { percentage } from "@/utils";
import { Divider, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { SubSellerEditDto } from "../id";

interface GeneralSectionProps {
  initialData?: SubSellerEditDto;
  register?: any;
  errors?: any;
  control?: any;
  watch?: any;
  onEditMode?: boolean;
  documentType?: string;
  documentIsValid?: boolean;
  documentTypeFunc?: (item: string) => void;
  setDocumentIsValid?: (item: boolean) => void;
  setDescriptionModal?: (item: boolean) => void;
  setValue?: any;
}

const valueType = [
  {
    label: "Valor fixo",
    value: "FIXED"
  },
  {
    label: "Valor percentual",
    value: "PERCENTAGE"
  },
]


export function GeneralSection({
  initialData,
  register,
  errors,
  onEditMode,
  control,
  watch,
  documentType,
  documentIsValid,
  documentTypeFunc,
  setDocumentIsValid,
  setDescriptionModal,
  setValue,
}: GeneralSectionProps) {
  const maxChars = 150;
  function handleValidationFields(isDocValid: boolean, editModeState: boolean): boolean {
    if (!editModeState) return true;
    if (editModeState && !isDocValid) return true;
    if (!isDocValid || !editModeState) return true;
    return false;
  }

  return (
    <>

      <Divider />
      <SimpleGrid
        w="100%"
        h="100%"
        columns={3}
        rowGap={8}
        columnGap={8}
      >
        <InputLabelTag
          maxW="250px"
          type="text"
          mask="cpf/cnpj"
          maxLength={14}
          name="document"
          register={{ ...register("document") }}
          documentType={documentType}
          documentTypeFunc={documentTypeFunc}
          setDocumentIsValid={setDocumentIsValid}
          documentIsValid={documentIsValid}
          isDisabled
          p={!onEditMode ? "0" : ""}
          _disabled={
            onEditMode ?
              {
                bg: "#F4F7FB",
                border: "1px solid #C3CAD7",
                cursor: "default",
                color: "#A5AEC0"
              } :
              {
                bg: "transparent",
                border: "none",
                cursor: "default"
              }
          }
          errors={errors?.document}
        />

        <DatePickerComponent
          maxW="250px"
          label={documentType?.includes("CPF") ? "Nascimento" : "Data de abertura"}
          control={control}
          name={documentType?.includes("CPF") ? "birthDate" : "openedDate"}
          register={{ ...register(documentType?.includes("CPF") ? "birthDate" : "openedDate") }}
          isDisabled={handleValidationFields(documentIsValid, onEditMode)}
          editMode={!onEditMode}
          p={!onEditMode ? "0" : ""}
          _disabled={
            onEditMode ?
              {
                bg: "#F4F7FB",
                border: "1px solid #C3CAD7",
                cursor: "default",
                color: "#A5AEC0"
              } :
              {
                bg: "transparent",
                border: "none",
                cursor: "default"
              }
          }
          _active={{
            bg: "transparent",
          }}
          _hover={!onEditMode ? {} : {}}
          errors={errors?.birthDate}
        />

        {!onEditMode && (
          <Flex
            flexDir="column"
          >
            <Text fontSize=".8rem" marginBottom=".8rem">
              Tipo de taxa
            </Text>
            <Text
              fontSize=".9rem"
              color="black"
            >
              {valueType.filter(item => item.value === initialData?.paymentType)?.at(0)?.label}
            </Text>
          </Flex>
        )}

        {onEditMode && (
          <ListSelector
            bgColor="#FFFFFF"
            color="#000000"
            maxW="250px"
            label="Tipo de taxa"
            placeholder=""
            options={valueType}
            name="taxType"
            register={{ ...register("taxType") }}
            errors={errors?.taxType}
            isDisabled={handleValidationFields(documentIsValid, onEditMode)}
            p={!onEditMode ? "0" : ""}
            _disabled={
              onEditMode ?
                {
                  bg: "#F4F7FB",
                  border: "1px solid #C3CAD7",
                  cursor: "default",
                  color: "#A5AEC0"
                } :
                {
                  bg: "transparent",
                  border: "none",
                  cursor: "default"
                }
            }
          />
        )}

        <Input
          maxW="250px"
          label="Email"
          type="text"
          name="mail"
          register={{ ...register("mail") }}
          isDisabled={handleValidationFields(documentIsValid, onEditMode)}
          p={!onEditMode ? "0" : ""}
          _disabled={
            onEditMode ?
              {
                bg: "#F4F7FB",
                border: "1px solid #C3CAD7",
                cursor: "default",
                color: "#A5AEC0"
              } :
              {
                bg: "transparent",
                border: "none",
                cursor: "default"
              }
          }
          errors={errors?.mail}
        />

        <Input
          maxW="250px"
          label="Telefone"
          type="text"
          name="cellphone"
          register={{ ...register("cellphone") }}
          isDisabled={handleValidationFields(documentIsValid, onEditMode)}
          p={!onEditMode ? "0" : ""}
          _disabled={
            onEditMode ?
              {
                bg: "#F4F7FB",
                border: "1px solid #C3CAD7",
                cursor: "default",
                color: "#A5AEC0"
              } :
              {
                bg: "transparent",
                border: "none",
                cursor: "default"
              }
          }
          errors={errors?.cellphone}
        />

        <Input
          maxW="250px"
          label="Taxa"
          type="text"
          name="value"
          value={watch("value")}
          mask={watch("taxType")?.includes("PERCENTAGE") ? "" : "currency"}
          onChange={(e) => {
            let inputValue = e.target.value;
            if(watch("taxType")?.includes("PERCENTAGE")) {
              if (/^\d*([,.]?\d*)$/.test(inputValue)) {
                if(Number(inputValue?.replace(",", ".")) > 100) {
                  inputValue = "100"
                }
                setValue("value", inputValue);
              }
              return
            }
            setValue("value", inputValue);
          }}
          onBlur={(e: any) => {
            if(watch("taxType")?.includes("PERCENTAGE")) {
              let inputValue = e.target.value;
              setValue("value", `${inputValue}%`)
            }
          }}
          isDisabled={handleValidationFields(documentIsValid, onEditMode)}
          p={!onEditMode ? "0" : ""}
          _disabled={
            onEditMode ?
              {
                bg: "#F4F7FB",
                border: "1px solid #C3CAD7",
                cursor: "default",
                color: "#A5AEC0"
              } :
              {
                bg: "transparent",
                border: "none",
                cursor: "default"
              }
          }
          errors={errors?.value}
        />
      </SimpleGrid>
      {documentType?.includes("CNPJ") && (
        <>
          {!onEditMode && (
            <Flex
              w="100%"
              flexDir="column"
            >
              <Text
                fontSize=".8rem"
                color="gray.600"
                marginBottom=".2rem"
              >
                Descrição do negócio
              </Text>
              <Text
                w="700px"
                overflow="hidden"
                textOverflow="ellipsis"
                color="black"
                fontSize="14px"
              >
                {watch("description")?.slice(0, maxChars) + "..."}
                <Text
                  display="inline-block"
                  fontWeight="400"
                  color="#1890FF"
                  fontSize="14px"
                  transition="all .3s"
                  marginLeft="1.4rem"
                  _hover={{
                    transition: "all .3s",
                    textDecor: "underline",
                    cursor: "pointer"
                  }}
                  onClick={() => setDescriptionModal(true)}
                >
                  Ver mais
                </Text>
              </Text>
            </Flex>
          )}

          {onEditMode && (
            <TextArea
              w="50%"
              label="Descrição do negócio"
              name="description"
              color="black"
              labelFontSize=".8rem"
              labelColor="gray.600"
              maxLength={300}
              maxLengthBlocks
              register={{ ...register("description") }}
              isDisabled={handleValidationFields(documentIsValid, onEditMode)}
              p={!onEditMode ? "0" : ""}
              _disabled={
                onEditMode ?
                  {
                    bg: "#F4F7FB",
                    border: "1px solid #C3CAD7",
                    cursor: "default",
                    color: "#A5AEC0"
                  } :
                  {
                    bg: "transparent",
                    border: "none",
                    cursor: "default"
                  }
              }
              errors={errors?.description}
            // currentLength={descriptionValue?.length}
            />
          )}
        </>
      )}
    </>
  );
}