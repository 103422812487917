import { useAnticipationStatus } from "@/hooks/useAnticipationStatus.ts";
import { Button, Flex, Text } from "@chakra-ui/react";



interface ChangeToPaidProps {
  status: string;
  setShowAlert: (param: boolean) => void;
}

export function ChangeToPaid({ status, setShowAlert }: ChangeToPaidProps) {
  const { hasAntecipationActive } = useAnticipationStatus()
  return (
    <>
      {status !== "CANCELED" && (
        <Flex direction="column" minW="300px">
          <Text color="gray.300" fontSize="14px">
            Cobrança já foi paga?
          </Text>

          <Flex flexDir="column">
            <Text color="gray.800" fontSize="14px">
              {status != "PAID" && status != "PAID_MANUALLY" && (
                <Button
                  id="alterar_pago"
                  h="20px"
                  p="0"
                  m="0"
                  color="newGray.500"
                  fontWeight="400"
                  textDecoration="underline"
                  variant="ghost"
                  transition="all .2s"
                  _hover={{
                    transition: "all .2s",
                    filter: "brightness(.8)"
                  }}
                  onClick={() => setShowAlert(true)}
                  disabled={hasAntecipationActive}
                >
                  Alterar para paga
                </Button>
              )}
              {(status == "PAID" || status == "PAID_MANUALLY") && <Text> Sim </Text>}
            </Text>
          </Flex>
        </Flex>
      )}

    </>
  )
}