import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  Flex,
  Tooltip,
  Text,
  Icon,
  Switch,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

interface SelectProps extends ChakraSelectProps {
  name: string;
  label: string;
  register?: any;
  containerDisabled?: boolean;
  optionsList: any;
  defaultValue?:any;
  tooltipText?: string;
  errors?: any;
  hasSwitch?: boolean;
  switchActive?: boolean;
  setValue?: any;
  isDisabled?: boolean;
  hasInstallmentMix?: boolean;
}

export function Select({
  name,
  label,
  register,
  containerDisabled = true,
  hasSwitch = false,
  setValue,
  switchActive,
  optionsList,
  tooltipText,
  errors,
  defaultValue,
  isDisabled,
  hasInstallmentMix,
  ...rest
}: SelectProps) {
  const [disabledState, setDisabledState] = useState(hasSwitch || containerDisabled);
  function handleSwitchChange() {
    setDisabledState(!disabledState);
  }
  function handleSetDefaultInstallmentValue() {
    const defaultInstallment = {
      "installments": 10,
      "customInstallmentsValuesCreditCard": defaultValue || 10,
      "customInstallmentsValuesBankSlip": defaultValue || 1,
      "customInstallmentsValuesPix": defaultValue || 1
    }
    return defaultInstallment[name];
  }
  useEffect(() => {
    setDisabledState(!switchActive);
  }, []);

  useEffect(() => {
    if(disabledState && setValue) {
      setValue(name, handleSetDefaultInstallmentValue())
    }
  },[disabledState])
  return (
    <Flex flexDir="column" w="100%">
      <Flex align="center" justify="space-between">
        <Flex align="center" marginRight={tooltipText ? ".2rem" : ""}>
          {label && (
            <Text
              marginRight={tooltipText ? ".2rem" : ""}
              marginBottom=".2rem"
              fontSize=".8rem"
              color={!containerDisabled ? "newGray.200" : "gray.600"}
              cursor={!containerDisabled && "not-allowed"}
            >
              {label}
            </Text>
          )}
          {tooltipText && (
            <Tooltip placement="top" hasArrow label={tooltipText}>
              <span>
                <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
              </span>
            </Tooltip>
          )}
        </Flex>
        {hasSwitch && (
          <Switch
            name={name}
            isChecked={switchActive}
            defaultChecked={switchActive}
            onChange={handleSwitchChange}
            size="sm"
          />
        )}
      </Flex>
      <Flex flexDir="column" position="relative">
        <ChakraSelect
          name={name}
          {...register}
          {...rest}
          disabled={!containerDisabled || (hasSwitch && disabledState) || isDisabled}
          borderRadius=".2rem"
          _focus={{
            border: "1px solid",
            borderColor: "blue.200",
          }}
          defaultValue={defaultValue}
          fontSize="14px"
        >
          {optionsList?.map((data,index) => {
            return (
              <option
                key={index}
                value={data?.value}
              >
               { hasInstallmentMix ? "até" : ""} {data?.title}
              </option>
            );
          })}
        </ChakraSelect>
      </Flex>
      {errors && (
        <Text fontSize=".8rem" color="danger" mb="4" role="alert">
          {errors.message}
        </Text>
      )}
    </Flex>
  );
}
