import { Image } from "@chakra-ui/react";

export function BellIcon() {
  return (
    <Image
      src="/images/icons/bell.svg"
      transition="filter .2s"
      _hover={{
        transition: "filter .2s",
        filter: "brightness(.3)",
      }}
    />
  );
}