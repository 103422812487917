import { SubSellerTaxResponse } from "@/models";
import { InfoCircleOutlined } from "@/styles/icons";
import { formatCurrency, formatCurrencyToApi } from "@/utils";
import { formatPercentage } from "@/utils/formatPercentage";
import { Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Overlay } from "./subSellerModal";

interface Props {
  isOpen?: boolean;
  closeButtonText?: string;
  confirmButtonText?: string;
  asSubmitModal?: boolean;
  headerText?: string;
  headerIcon?: any;
  modalBody?: any;
  totalValue?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  subSellerList?: any;
  subSellerTaxResponse?: SubSellerTaxResponse;
  paymentLinkValue?: string;
  barteTaxValue?: string;
}

export function SubSellerModal({
  isOpen,
  closeButtonText,
  confirmButtonText,
  asSubmitModal,
  headerText,
  totalValue,
  onClose,
  onConfirm,
  subSellerTaxResponse,
  paymentLinkValue,
  barteTaxValue
}: Props) {
  const [isScrollNotOnTop, setIsScrollNotOnTop] = useState(false);
  const [isScrollNotOnBottom, setIsScrollNotOnBottom] = useState(false);
  const [sellerAmount, setSellerAmount] = useState<number>(0);
  function translateTaxTypeName(taxType: string) {
    const taxTypeOptions = {
      "FIXED": "Valor fixo",
      "PERCENTAGE": "Percentual",
    }
    return taxTypeOptions[taxType];
  }

  function setValues() {
    const paymentLinkTotal = formatCurrencyToApi(paymentLinkValue) - subSellerTaxResponse?.amountForSubSellers - formatCurrencyToApi(barteTaxValue);
    setSellerAmount(paymentLinkTotal);
  }

  useEffect(() => {
    setValues();
  }, [subSellerTaxResponse]);
  return (
    <Overlay
      isOpen={isOpen}
      hasContentDownwards={isScrollNotOnBottom}
      hasContentUpdwards={isScrollNotOnTop}
    >
      <div className="modal">
        <section className="modal-header">
          <div className="header-text">
            <div>
              {sellerAmount < 0 ? <IoCloseCircleOutline color="#8E1E1E" size={25} /> : <InfoCircleOutlined  size={19} />}
            </div>
            <p
              style={{
                color: sellerAmount < 0 ? "#8E1E1E" : "" 
              }}
            >
              {headerText}
            </p>
          </div>

        </section>

        <section className="modal-body">
          <div className="title">
            <h1 className="title-name"> Valor da cobrança </h1>
            <h2 className="title-value"> {paymentLinkValue} </h2>
          </div>
          <div>
            <h1 className="splits"> Splits </h1>
            <div className="box-subellers">
              {
                subSellerTaxResponse?.subSellerPaymentResponse?.map((item) => {
                  return (
                    <div className="box-list">
                      <div className="name-box">
                        <h1>{item.name} </h1>
                        <div className="box-taxas">
                          {item?.paymentType?.includes("FIXED") ? translateTaxTypeName(item?.paymentType) : formatPercentage({value: item?.paymentValue})}
                        </div>
                      </div>
                      <div className="value-box">
                        <h1>
                          {
                            formatCurrency({value: item?.amountOnPaymentLink})
                          }
                        </h1>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
            {sellerAmount < 0 && (
              <div className="title">
                <h1 className="title-name">
                  Taxas
                </h1>
                <h1 className="title-value">
                  {barteTaxValue}
                </h1>
              </div>
            )}
            <Divider />
            <div className="title">
              <h1 className="title-name">
                Você
              </h1>
              <h1 
                className="title-value" 
                style={{
                  color: sellerAmount < 0 ? "#8E1E1E" : "" 
                }}
              >
                {formatCurrency({value: sellerAmount})}
              </h1>
            </div>
            {sellerAmount < 0 && (
              <div className="modal-alert">
                <p>O valor do split é superior ao valor disponível. Revise os valores para criar o link.</p>
              </div>
            )}
        </section>
        <section className="modal-footer">
          <div className="modal-buttons">
            {closeButtonText ? (
              <button type="button" className="back-button" onClick={onClose} >
                <p>
                  {sellerAmount > 0 ? closeButtonText : "Revisar valores"}
                </p>
              </button>
            ) : (<div></ div>)}
            {confirmButtonText && sellerAmount > 0 && (
              <button
                className="confirm-button"
                type={asSubmitModal ? "submit" : "button"}
                onClick={onConfirm}
                disabled={sellerAmount < 0}
              >
                <p color="white">
                  {confirmButtonText}
                </p>
              </button>
            )}
          </div>
        </section>
      </div>
    </Overlay>
  );
}