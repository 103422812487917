
import { Badge, Flex, Link, Text } from "@chakra-ui/react";

export function CapitalGiroComponent() {
    return (
        <Flex
            borderRadius="8px"
            border="1px solid"
            borderColor="gray.100"
            width="100%"
            padding='16px 24px 16px 24px'
            align="center"
            justify="space-between"
        >
            <Flex
                flexDir="column"  
            >
                <Flex gap="12px">
                    <Text fontSize=".8rem">Capital de giro</Text>
                    <Flex
                        flexDirection="column"
                        align="center"
                        color="blue.200"
                    >
                        <Badge 
                            variant="yellowBills" 
                            borderRadius="10px" 
                            color="#CDAD0A"
                        >
                            Em breve
                        </Badge>

                        <Link 
                            href="https://whatsa.me/5511966701879/?t=Ol%C3%A1,%20Gostaria%20de%20saber%20mais%20sobre%20o%20Capital%20de%20Giro%20da%20Barte"
                            target="_blank"
                            fontSize="10px"
                        >
                            saiba mais
                        </Link>
                    </Flex>
                </Flex>
                <Flex
                    position="relative"
                >
                    <Text 
                        fontSize="16px"
                        position="absolute"
                        bottom="15%"
                        fontWeight={400}
                    >
                        R$
                    </Text>
                    <Text 
                        fontWeight={600} 
                        fontSize="2rem"
                        marginLeft="1.5rem"
                    >
                        0,00
                    </Text>
                </Flex>
            </Flex>
            
        </Flex>
    )
}