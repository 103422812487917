import { ReactNode } from "react";
import {
  AlertDialog,
  AlertProps as AlertPropsChakra,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Icon,
  Flex,
  Text,
  Box,
  Link,
  Checkbox,
} from "@chakra-ui/react";
import {
  IoAlertCircleOutline,
  IoCheckmarkCircleOutline,
  IoSyncCircleOutline
} from "react-icons/io5";
import { copyTextToClipboard } from "@/utils";

interface AlertProps extends AlertPropsChakra {
  isConfirmAlert?: boolean;
  closeButtonText?: string;
  confirmButtonText?: string;
  isOpen: boolean;
  leastDestructiveRef: any;
  onClose?: () => void;
  onConfirm: () => void;
  title: string;
  messageBody?: string | ReactNode;
  urlBody?: string;
  children?: ReactNode;
  isLoading?: boolean;
  checkBox?: boolean;
  checkBoxValue?: boolean;
  checkBoxText?: string;
  checkBoxFunction?: (param?: boolean) => void;
  isProcessing?: boolean;
  asDeleteAlert?: string;
}

function Alert({
  urlBody,
  isConfirmAlert,
  children,
  closeButtonText,
  confirmButtonText = "Pronto",
  isOpen,
  onClose,
  onConfirm,
  leastDestructiveRef,
  title,
  messageBody,
  isLoading = false,
  checkBox,
  checkBoxFunction,
  checkBoxText,
  checkBoxValue,
  isProcessing = false,
  asDeleteAlert,
  ...rest
}: AlertProps) {
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={leastDestructiveRef}
      motionPreset="slideInBottom"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          display="flex"
          flexDir="column"
          borderRadius="2"
          {...rest}
        >
          <AlertDialogHeader margin="0" p="0 32px 0 16px" fontSize="1rem" fontWeight="bold">
            <Flex marginTop="1rem" ml={4}>
              {!isProcessing && (
                <Icon
                  as={
                    isConfirmAlert
                      ? IoCheckmarkCircleOutline
                      : IoAlertCircleOutline
                  }
                  w={7}
                  h={7}
                  color={isConfirmAlert ? "#52C41A" : "#FAAD14"}
                  mr={3}
                />
              )}
              {isProcessing && (
                <Icon
                  as={IoSyncCircleOutline}
                  w={7}
                  h={7}
                  color="#556077"
                  mr={3}
                />
              )}
              <Text fontSize="1.1rem">{title}</Text>
            </Flex>
          </AlertDialogHeader>

          <AlertDialogBody
            margin="0"
            p="0"
            h="1rem"
            marginTop="1rem"
            display="flex"
            w="100%"
            alignItems="center"
            justifyContent="center"
          >
            {messageBody && (
              <Box ml={14} mr={6}>
                <Text textAlign="start">{messageBody}</Text>
                <Link
                  onClick={() => copyTextToClipboard(urlBody)}
                  color="blue.200"
                >
                  {urlBody}
                </Link>
              </Box>
            )}
            {children}
          </AlertDialogBody>

          <AlertDialogFooter
            h="5rem"
            w="100%"
            display="flex"
            justifyContent={checkBox ? "space-between" : "flex-end"}
          >
            {checkBox && (
              <Checkbox
                marginLeft="1rem"
                isChecked={checkBoxValue}
                onChange={(e) => checkBoxFunction(e?.target?.checked)}
              >
                {checkBoxText}
              </Checkbox>
            )}
            <Flex>
              {closeButtonText && (
                <Button
                  ref={leastDestructiveRef}
                  variant="outline"
                  m="0 1rem 3rem 0"
                  color="black"
                  borderRadius="3"
                  marginTop="3rem"
                  _active={{
                    filter: "brightness(.6)",
                  }}
                  _focus={{}}
                  onClick={onClose}
                >
                  {closeButtonText}
                </Button>
              )}
              <Button
                id={
                  title.toLowerCase().includes("excluir") ||
                    title.toLowerCase().includes("deletar")
                    ? "excluir_cobrança"
                    : ""
                }
                m="0 0 3rem 0"
                bgColor={asDeleteAlert ? asDeleteAlert : "blue.200"}
                color="white"
                borderRadius="3"
                marginTop="3rem"
                transition="filter .2s"
                _hover={{
                  transition: "filter .2s",
                  filter: "brightness(.9)",
                }}
                _active={{
                  filter: "brightness(.6)",
                }}
                _focus={{}}
                onClick={onConfirm}
                isLoading={isLoading}
              >
                {confirmButtonText}
              </Button>
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export { Alert };
