import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface RadioInputProps {
  children?: Array<ReactNode> | ReactNode;
  active?: boolean;
}

export function RadioInput({children, active}: RadioInputProps) {
  return (
    <Flex
      w="49%"
      h="32px"
      border="1px solid"
      borderColor={active ? "blue.200" : "newGray.100"}
      borderRadius="4"
      align="center"
      paddingLeft="2"
      transition="all .2s"
      gap="2"
    >
      {children}
    </Flex>
  )
}