import styled, { css } from "styled-components";

interface InputSectionProps {
    isErrored: boolean;
    isFocused: boolean;
    isFilled: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const LabelSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        font-size: .8rem;
    }
    div {
        display: flex;
        align-items: center;
        gap: .2rem;
    }
`;

export const ErrorsSection = styled.section`
    display: flex;
    p {
        font-size: .8rem;
    }
`;

export const InputSection = styled.section<InputSectionProps>`
    display: flex;
    position: relative;
    border-radius: .25rem;
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #C3CAD7;
    ${props => props.isErrored && css `
        border-color: #FF4D4F;
    `}
    ${props => props.isFocused && css `
        border-color: #1890FF;
    `}
    ${props => props.isFilled && css `
        color: #ff9000;
    `}
    input {
        width: 100%;
        border: 0;
        background-color: transparent;
        color: black;
        &:focus {
            outline: none !important;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            background-color: transparent !important;
        }
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .8rem;
        max-width: 25px;
        max-height: 25px;
    }
`;