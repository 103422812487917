import { WithAuth } from "@/hoc";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Flex, Text } from "@chakra-ui/react";
import { clearMask, formatCurrency, formatCurrencyToApi, formatDate, phone_format, showToast } from "@/utils";
import { GeneralSection } from "./sections/_GeneralSection";
import { AddressSection } from "./sections/_AddressSection";
import { BankSection } from "./sections/_BankSection";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { FormatDocument } from "@/utils/formatDocument";
import { formatPercentage } from "@/utils/formatPercentage";
import { formatISO } from "date-fns";
import { Alert } from "@/components";
import { CustomModal } from "@/components/CustomModal";
import { useSubSeller } from "@/hooks/useSubSeller";
import { formatPercentageToApi } from "@/utils/formatPercentageToApi";

interface SubSellerData {
  document: string;
  mail: string;
  name: string;
  cellphone: string;
  openedDate: Date;
  birthDate: Date;
  description: string;
  zipCode: string;
  street: string;
  number: number;
  complement: string;
  district: string;
  city: string;
  state: string;
  country: string;
  value: number;
  bank: string;
  agency: number;
  agencyDigit: number;
  account: number;
  accountDigit: number;
  accountType: string;
  pixKey: string;
  pixKeyType: string;
  ownerDocument: string;
  taxType: string;
}

export interface SubSellerEditDto {
  id: string;
  name: string;
  documentType: string;
  document: string;
  alternativeEmail?: String;
  address: {
    country: string;
    state: string;
    city: string;
    district: string;
    street: string;
    zipCode: string;
    number: string;
    complement: string;
  };
  account: {
    accountType: string;
    bank?: number;
    bankDigit: string;
    id?: number;
    issuer?: string;
    issuerDigit: string;
    number?: string;
    pixKey: string;
    pixKeyType: string;

  };
  contact: {
    name: string;
    email: string;
    phone: string;
  };
  owner: {
    ownerName: string;
    ownerDocument: string;
    birthdate?: string;
  };
  openingDate?: string;
  paymentType: string;
  paymentValue: number;
  description?: string;
  splitStatus?: string;
  isActive?: boolean;
}

const SubSeller = () => {
  const [initialData, setInitialData] = useState<SubSellerEditDto>(null);
  const [editionMode, setEditionMode] = useState<boolean>(false);
  const [documentIsValid, setDocumentIsValid] = useState<boolean>(true);
  const [documentType, setDocumentType] = useState<string>("");
  const [deleteSubSeller, setDeleteSubSeller] = useState<boolean>(false);
  const [confirmEdition, setConfirmEdition] = useState<boolean>(false);
  const [failedEdition, setFailedEdition] = useState<boolean>(false);
  const [failedSplitActivate, setFailedSplitActivate] = useState<boolean>(false);
  const [isChangeTaxTypeAlertOpen, setIsChangeTaxTypeAlertOpen] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState<boolean>(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const cancelRef = useRef();

  const {
    getSubseller,
    deleteSubseller,
    editSubsellerGeneralData,
    editSubsellerAddress,
    editSubsellerAccounts,
    editSubsellerContacts,
    activateSubseller
  } = useSubSeller();

  const editSubSellerSchema = yup.object().shape({
    document: yup.string()
      .required("Preenchimento obrigatório")
      .test(
        documentType === "CPF" ? "cpf-validation" : "cnpj-validation",
        documentType === "CNPJ" ? "CPF inválido" : "CNPJ inválido",
        (value) =>
          clearMask(value)?.length <= 11
            ? cpf.isValid(clearMask(value))
            : cnpj.isValid(clearMask(value))
      ),
    mail: yup.string().email("formato de email inválido").required("Preenchimento obrigatório"),
    name: yup.string().required("Preenchimento obrigatório"),
    cellphone: yup.string().required("Preenchimento obrigatório"),
    openedDate: yup.string().when('document', {
      is: () => documentType === 'CNPJ',
      then: yup.string().required("Preenchimento obrigatório"),
      otherwise: yup.string(),
    }),
    birthDate: yup.string().when('document', {
      is: () => documentType === 'CPF',
      then: yup.string().required("Preenchimento obrigatório"),
      otherwise: yup.string().nullable(),
    }),
    description: yup.string().when('document', {
      is: () => documentType === 'CNPJ',
      then: yup.string().required("Preenchimento obrigatório"),
      otherwise: yup.string(),
    }),
    zipCode: yup.string().required("Preenchimento obrigatório"),
    street: yup.string().required("Preenchimento obrigatório"),
    number: yup.string().required("Preenchimento obrigatório"),
    complement: yup.string(),
    district: yup.string().required("Preenchimento obrigatório"),
    city: yup.string().required("Preenchimento obrigatório"),
    state: yup.string().required("Preenchimento obrigatório"),
    country: yup.string().required("Preenchimento obrigatório"),
    value: yup.string().required("Preenchimento obrigatório"),
    bank: yup.string().required("Preenchimento obrigatório"),
    agency: yup.string().required(""),
    agencyDigit: yup.string().required(""),
    account: yup.string().required(""),
    accountDigit: yup.string().required(""),
    accountType: yup.string().required("Preenchimento obrigatório")
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(editSubSellerSchema)
  });

  function setInitialValues(data: SubSellerEditDto) {
    //generalInfo
    setDocumentType(data?.documentType);
    setValue("document", FormatDocument(data?.document));
    setValue("ownerDocument", FormatDocument(data?.owner?.ownerDocument));
    setValue("mail", data?.contact?.email);
    setValue("name", data?.name);
    setValue("cellphone", phone_format(data?.contact?.phone));
    setValue("openedDate", data?.openingDate ? new Date(data?.openingDate) : new Date());
    setValue("birthDate", data?.owner?.birthdate ? new Date(data?.owner?.birthdate) : null);
    setValue("description", data?.description ? data?.description : "");
    //addressInfo
    setValue("zipCode", data?.address?.zipCode);
    setValue("street", data?.address?.street);
    setValue("number", data?.address?.number);
    setValue("complement", data?.address?.complement);
    setValue("district", data?.address?.district);
    setValue("city", data?.address?.city);
    setValue("state", data?.address?.state);
    setValue("country", data?.address?.country);
    //valueInfo
    setValue("value", data?.paymentType?.includes("PERCENTAGE") ?  formatPercentage({ value: data?.paymentValue }) : formatCurrency({ value: data?.paymentValue }));
    setValue("taxType", data?.paymentType);
    //bankInfo
    setValue("bank", data?.account?.bank);
    setValue("agency", data?.account?.issuer);
    setValue("agencyDigit", data?.account?.issuerDigit);
    setValue("account", data?.account?.number);
    setValue("accountDigit", data?.account?.bankDigit);
    setValue("accountType", data?.account?.accountType);
    setValue("pixKey", data?.account?.pixKey);
    setValue("pixKeyType", data?.account?.pixKeyType);
  }

  async function getInitialData() {
    try {
      const { data } = await getSubseller(id);
      setInitialValues(data);
      setInitialData(data);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }
  useEffect(() => {
    if(watch("taxType") !== initialData?.paymentType && initialData?.paymentType !== undefined) {
      setIsChangeTaxTypeAlertOpen(true);
    }
  } ,[watch("taxType")])
  const handleEditSubSeller: SubmitHandler<SubSellerData> = async (values) => {
    try {
      const editGeneralSectionDTO = {
            name: values?.name,
            documentType: documentType,
            document: clearMask(values?.document),
            alternativeEmail: "altemail@email.com",
            birthdate: formatISO(new Date(values?.birthDate), { representation: "date" }),
            openingDate:  formatISO(new Date(values?.openedDate), { representation: "date" }),
            paymentType: values?.taxType,
            paymentValue: values?.taxType === "FIXED" ? formatCurrencyToApi(values?.value) : formatPercentageToApi(values?.value),
            description: values?.description,
            ownerDocument: values?.ownerDocument,
            ownerName: documentType?.includes("CPF") ? values?.name : initialData?.owner?.ownerName, 
      }
      const editAccountsSectionDTO = {
        account: {
          bank: values?.bank,
          issuer: values?.agency?.toString(),
          number: values?.account?.toString(),
          bankDigit: values?.accountDigit?.toString(),
          issuerDigit: values?.agencyDigit?.toString(),
          accountType: values?.accountType,
        },
        pixKey: values?.pixKey,
        pixKeyType: values?.pixKeyType,
        paymentValue: Number(clearMask(values?.value)),
        paymentType: values?.taxType
      }

      const editSubsellerAddressDTO = {
        country: values?.country,
        state: values?.state,
        city: values?.city,
        district: values?.district,
        street: values?.state,
        zipCode: values?.zipCode,
        number: values?.number.toString(),
        complement: values?.complement
      }

      const editContactsSectionDTO = {
        name: values?.name,
        email: values?.mail,
        phone: clearMask(values?.cellphone),
      }
      await editSubsellerGeneralData(id, editGeneralSectionDTO)
      await editSubsellerAddress(id, editSubsellerAddressDTO);
      await editSubsellerAccounts(id, editAccountsSectionDTO);
      await editSubsellerContacts(id, editContactsSectionDTO);
      if(initialData?.splitStatus?.includes("INACTIVE")) {
        await activateSubseller(id);
      }
      setConfirmEdition(true);
    } catch (error) {
      if(error?.response?.config?.url?.includes("split")) {
        setFailedSplitActivate(true);
      } else {
        setFailedEdition(true);
      }
    }
  }
  function handleResetTaxType() {
    setIsChangeTaxTypeAlertOpen(false);
    setValue("taxType", initialData?.paymentType);
  }
  async function handleDeleteSubSeller() {
    try {
      await deleteSubseller(id, !initialData?.isActive);
      navigate("/sub-vendedores")
    } catch (error) {
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  function handleEditSection() {
    setEditionMode(!editionMode);
  }

  useEffect(() => {
    if (id === undefined) return;
    getInitialData();
  }, []);

  return (
    <>
      <CustomModal
        isOpen={descriptionModal}
        onClose={() => setDescriptionModal(false)}
        onConfirm={() => setDescriptionModal(false)}
        headerText="Descrição do negócio"
        modalBody={initialData?.description}
        confirmButtonText="Ok"
      />  

      <Alert
        isOpen={deleteSubSeller}
        onConfirm={() => (handleDeleteSubSeller())}
        onClose={() => setDeleteSubSeller(!deleteSubSeller)}
        title={`Você tem certeza que deseja ${initialData?.isActive ? "desativar" : "ativar"} esse sub-vendedor?`}
        closeButtonText="Cancelar"
        confirmButtonText={initialData?.isActive ? "Desativar" : "Ativar"}
        messageBody={`O subseller será ${initialData?.isActive ? "desativado" : "ativado"}.`}
        leastDestructiveRef={cancelRef}
        asDeleteAlert={initialData?.isActive ? "#FF4D4F" : "blue.200"}
      />
      <Alert
        isOpen={isChangeTaxTypeAlertOpen}
        onClose={handleResetTaxType}
        onConfirm={() => {
          setIsChangeTaxTypeAlertOpen(false);
          setValue("value", "");
        }}
        title="Atenção"
        confirmButtonText="Confirmar"
        closeButtonText="Cancelar"
        messageBody="Deseja realmente alterar o tipo de tax desse sub-vendedor? Isso afetará links de pagamento futuros."
        leastDestructiveRef={cancelRef}
      />
      <Alert
        isConfirmAlert
        isOpen={confirmEdition}
        onConfirm={() => (navigate("/sub-vendedores"))}
        title="Dados alterados com sucesso!"
        confirmButtonText="Ok"
        messageBody="Você já pode vincular esse sub-vendedor aos seus links de pagamento."
        leastDestructiveRef={cancelRef}
      />
      <Alert
        isOpen={failedEdition}
        onConfirm={() => (navigate("/sub-vendedores"))}
        title="Erro ao atualizar cadastro do sub-vendedor"
        confirmButtonText="Ok"
        messageBody="Houve um erro no cadastramento da conta do Sub-vendedor. Por favor, verifique os dados e realize o reenvio do cadastro."
        leastDestructiveRef={cancelRef}
      />
      <Alert
        isOpen={failedSplitActivate}
        onConfirm={() => (navigate("/sub-vendedores"))}
        title="Erro ao validar a conta bancária do sub-vendedor"
        confirmButtonText="Ok"
        messageBody="Houve um erro ao validar da conta bancária do Sub-vendedor. Por favor, verifique os dados e realize o reenvio do cadastro."
        leastDestructiveRef={cancelRef}
      />

        <Flex
          as="form"
          w="100%"
          flexDir="column"
          align="flex-start"
          boxShadow="0px 4px 24px 0px #0000000A"
          gap="16px"
          p="16px"
          borderRadius="8px"
          onSubmit={handleSubmit(handleEditSubSeller)}
        >
          <Flex
            w="100%"
            align="center"
            justify="space-between"
          >
            <Text
              fontWeight="600"
              fontSize="25px"
              color="#262F40"
            >
              {initialData?.name}
            </Text>

            {!editionMode && (
              <>
                <Button
                  h="40px"
                  padding="8px 16px"
                  borderRadius="2px"
                  bgColor="#FFFFFF"
                  border="1px solid #D9D9D9"
                  onClick={handleEditSection}
                >
                  <Text
                    color="#000000"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    Editar
                  </Text>
                </Button>
              </>
            )}

            {editionMode && (
              <Flex gap="12px">
                <Button
                  h="40px"
                  padding="8px 16px"
                  borderRadius="2px"
                  bgColor="#FFFFFF"
                  border="1px solid #D9D9D9"
                  transition="filter .2s"
                  _hover={{
                    transition: "filter .2s",
                    filter: "brightness(.9)",
                  }}
                  onClick={handleEditSection}
                >
                  <Text
                    color="#000000"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    Cancelar
                  </Text>
                </Button>
                <Button
                  type="submit"
                  h="40px"
                  padding="8px 16px"
                  borderRadius="2px"
                  bgColor="#1890FF"
                  border="1px solid #1890FF"
                  transition="filter .2s"
                  _hover={{
                    transition: "filter .2s",
                    filter: "brightness(.9)",
                  }}
                >
                  <Text
                    color="#FFFFFF"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    Concluir
                  </Text>
                </Button>
              </Flex>
            )}
          </Flex>

          <GeneralSection
            initialData={initialData}
            onEditMode={editionMode}
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            documentIsValid={documentIsValid}
            documentType={documentType}
            documentTypeFunc={setDocumentType}
            setDocumentIsValid={setDocumentIsValid}
            setDescriptionModal={setDescriptionModal}
            setValue={setValue}
          />

          <AddressSection
            initialData={initialData}
            onEditMode={editionMode}
            control={control}
            register={register}
            errors={errors}
          />

          <BankSection
            initialData={initialData}
            onEditMode={editionMode}
            watch={watch}
            control={control}
            register={register}
            errors={errors}
            setValue={setValue}
          />

          {editionMode && (
            <Button
              h="40px"
              padding="8px 16px"
              borderRadius="2px"
              bgColor={initialData?.isActive ? "#FFFFFF" : "blue.200"}
              border={initialData?.isActive ? "1px solid #FF4D4F" : "1px solid blue.200"}
              onClick={() => setDeleteSubSeller(!deleteSubSeller)}
              transition="all .2s"
              _hover={{
                transition: "all .2s",
                filter: "brightness(.9)"
              }}
            >
              <Text
                color={initialData?.isActive ? "#FF4D4F" : "white"}
                fontSize="16px"
                fontWeight="400"
              >
                {initialData?.isActive ? "Desativar sub-seller" : "Ativar sub-seller"}
              </Text>
            </Button>
          )}
        </Flex>
    </>
  );
};

export default WithAuth(SubSeller);