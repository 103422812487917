import { Box, Flex, Text, Button, Checkbox } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Input, Select } from "@/components";
import { InputSearchSubSeller } from "@/components/Forms/inputSearchSubSeller";
import { useSubSellers } from "@/hooks/useSubSellers";
import { subSellerData } from "@/pages/seja-pago/punctual-charge";
import { } from "react-icons"
import { TrashIcon2 } from "@/styles/icons/TrashIcon2";
import { FiPercent } from "react-icons/fi";
import { watch } from "fs";

interface subsellers {
  name: string;
  id?: number;
  value?: string,
  index?: string;
}

interface PaymentSplitProps {
  reg?: string;
  title?: string;
  id?: string;
  hasSwitch?: boolean;
  setSplit?: React.Dispatch<React.SetStateAction<boolean>>;
  split?: boolean;
  subSellersData: subSellerData[];
  setSubSellersData: any;
  newClient: subSellerData;
}

export function PaymentSplit({
  title,
  setSplit,
  subSellersData,
  setSubSellersData,
  newClient,
  split,
}: PaymentSplitProps) {
  const [methodState, setMethodState] = useState(false);
  const { subSellers } = useSubSellers();
  const [selectedClient, setSelectedClient] = useState<subsellers>();
  const [listFilter, setListFilter] = useState([]);
  const [subSellersInitialData, setSubSellersInitialData] = useState([]);

  const TypeValues = [
    { name: "Percentual", value: "PERCENTAGE" },
    { name: "Valor fixo", value: "FIXED" },
  ];

  const initialSelectedPaymentTypes = subSellersData?.map((subSeller) => subSeller.paymentType);
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState(initialSelectedPaymentTypes);

  const handleAddSubSeller = () => {
    setSubSellersData([...subSellersData, newClient]);
    setSelectedPaymentTypes([...selectedPaymentTypes, TypeValues[0].value]); 
  };

  const handleRemoveSubSeller = (indexToRemove) => {
    const updatedSubSellersData = [...subSellersData ?? []];
    updatedSubSellersData.splice(indexToRemove, 1);
    setSubSellersData([...updatedSubSellersData ?? []]);

    const updatedSelectedPaymentTypes = [...selectedPaymentTypes];
    updatedSelectedPaymentTypes.splice(indexToRemove, 1);
    setSelectedPaymentTypes(updatedSelectedPaymentTypes);
  };

  const handleSubSellerChange = (index, fieldName, fieldValue) => {
    const updatedSubSellersData = [...subSellersData ?? []];
    updatedSubSellersData[index][fieldName] = fieldValue;
    setSubSellersData(updatedSubSellersData);


    const updatedSelectedPaymentTypes = [...selectedPaymentTypes];
    updatedSelectedPaymentTypes[index] = fieldValue;
    setSelectedPaymentTypes(updatedSelectedPaymentTypes);
  };
  const handleResetValues = (index) => {
    const updatedSubSellersData = [...subSellersData ?? []];
    updatedSubSellersData[index] = {
      ...updatedSubSellersData[index],
      paymentType: updatedSubSellersData[index]?.defaultPaymentType,
      paymentValue:  updatedSubSellersData[index]?.defaultPaymentValue
    };
    setSubSellersData(updatedSubSellersData);

    const updatedSelectedPaymentTypes = [...selectedPaymentTypes];
    updatedSelectedPaymentTypes[index] = updatedSubSellersData[index]?.defaultPaymentType;
    setSelectedPaymentTypes(updatedSelectedPaymentTypes);
  };

  function newClients(client: any, index: any) {
    const getClient = subSellersInitialData?.filter((item) => item?.id === client?.id)[0];
    const updatedSubSellersData = [...subSellersData ?? []];
    if (index >= 0 && index < updatedSubSellersData.length) {
      updatedSubSellersData[index] = getClient;
      setSubSellersData(updatedSubSellersData);

      const updatedSelectedPaymentTypes = [...selectedPaymentTypes];
      updatedSelectedPaymentTypes[index] = getClient.paymentType;
      setSelectedPaymentTypes(updatedSelectedPaymentTypes);
    }
  }

  const subSellersList = async () => {
    try {
      const response = await subSellers();
      const filteredArray = response?.data?.content?.map((subSeller) => {
        return {
          ...subSeller,
          id: subSeller?.id,
          name: subSeller?.name,
          paymentValue:subSeller?.paymentValue,
          defaultPaymentValue: subSeller?.paymentValue,
          paymentType: subSeller?.paymentType,
          defaultPaymentType: subSeller?.paymentType
        };
      });
      setSubSellersInitialData(filteredArray);
      setListFilter(filteredArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    subSellersList();
  }, []);


  useEffect(() => {
    newClients(selectedClient, selectedClient?.index);
  }, [selectedClient]);

  useEffect(() => {
    setSplit(methodState);
  }, [methodState]);

  useEffect(() => {
    if(subSellersData?.length === 0) {
      setSubSellersData([{
        paymentType: 'PERCENTAGE'
      }]);
    }
  }, [])

  useEffect(()=>{
    setMethodState(split)
  },[split])
  return (
    <Flex flexDir="column" gap="4px" justifyContent="center"
      m="12px 0"
      p={methodState ? "16px" : "0 16px"}
      backgroundColor={methodState ? "rgba(247, 248, 251, 1)" : ""}>

      <Flex align="center" gap="12px" >
        <Checkbox isChecked={methodState} onChange={() => setMethodState(!methodState)} />
        <Flex align="center" justify="space-between" gap="11px">
          <Text fontSize="1rem" color="black" fontWeight={!methodState ? "200" : "600"}>
            {title}
          </Text>
        </Flex>
      </Flex>
      {methodState && (
        <Flex flexDir="column" gap="1.5rem">
          {subSellersData?.map((subSeller, index) => (
            <Box key={index}>
              <Flex justifyContent="space-between"
                color="black"
                fontWeight="400"
                fontSize="14px"
              >
                <Flex>
                  <Text>Subvendedor {index + 1}</Text>
                </Flex>
                {index > 0 && (
                  <Flex
                    alignItems="center"
                    gap="8px"
                  >
                    <TrashIcon2 size={16} />
                    <Text
                      cursor="pointer"
                      onClick={() => handleRemoveSubSeller(index)}>
                      Remover subvendedor
                    </Text>
                  </Flex>
                )}
              </Flex>
              <Flex
                flexDir="column"
                gap="1.5rem"
                border="1px solid #E6E9F0"
                padding="16px"
              >
                <InputSearchSubSeller
                  key={index}
                  index={index}
                  value={subSeller?.name}
                  name="Sub-vendedor"
                  label="Sub-vendedor"
                  clients={listFilter}
                  //@ts-ignore
                  setSelectedClient={setSelectedClient}
                />
                <Flex
                  justifyContent="center"
                  position="relative"
                  alignItems="end"
                  w="100%"
                >
                  {subSeller?.paymentType !== "PERCENTAGE" && (
                    <Input
                      w="100%"
                      h="40px"
                      type="text"
                      name="fine"
                      label="Valor"
                      px="2.2rem"
                      fontSize="1rem"
                      value={subSeller?.paymentValue}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (/^\d*([,.]?\d*)$/.test(inputValue)) {
                            handleSubSellerChange(index, "paymentValue", inputValue);
                        }
                      }}
                    />
                  )}
                  {subSeller?.paymentType === "FIXED" && (
                    <Text
                      position="absolute"
                      top="32px"
                      left="12px"
                    >
                      R$
                    </Text>
                  )}
                  {(subSeller?.paymentType === "PERCENTAGE") && (
                    <Input
                      w="100%"
                      h="40px"
                      type="text"
                      name="fine"
                      label="Valor"
                      maxLength={5}
                      value={subSeller?.paymentValue}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (/^\d*([,.]?\d*)$/.test(inputValue)) {
                            if(Number(inputValue?.replace(",", ".")) > 100) {
                              inputValue = "100"
                            }
                            handleSubSellerChange(index, "paymentValue", inputValue);
                        }
                      }}
                    />
                  )}
                  {subSeller?.paymentType === "PERCENTAGE" && (
                    <Text
                      position="absolute"
                      top="32px"
                    >
                      %
                    </Text>
                  )}
                <Flex w="65%">
                  <Select
                    value={subSeller?.paymentType}
                    backgroundColor="white"
                    label=""
                    name="Sub-vendedor"
                    optionsList={TypeValues?.map((item) => ({
                      title: item.name,
                      value: item.value,
                    }))}
                    onChange={(e) => {
                      handleSubSellerChange(index, "paymentValue", "")
                      handleSubSellerChange(index, "paymentType", e.target.value)
                    }}
                  />
                </Flex>
                  {subSeller?.id !== undefined && (subSeller?.paymentValue !== subSeller?.defaultPaymentValue || subSeller?.paymentType !== subSeller?.defaultPaymentType) && (
                    <Button
                      variant="link"
                      fontWeight={400}
                      color="blue.200"
                      fontSize="12px"
                      position="absolute"
                      top="0"
                      right="0"
                      onClick={() => handleResetValues(index)}
                    >
                      Retornar padrão
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Box>
          ))}
          <Button
            backgroundColor="white"
            border="1px solid #E6E9F0"
            fontSize="14px"
            fontWeight="400"
            lineHeight="22px"
            color="black"
            onClick={handleAddSubSeller}
          >
            + Adicionar outro split
          </Button>
        </Flex>
      )}
    </Flex>
  );
}