
import { useEffect, useState } from "react";
import { ClientData } from "@/models";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    Flex,
    Box,
} from "@chakra-ui/react";
import { useClient } from "@/hooks";
import { showToast } from "@/utils";
import { AddClient } from "@/pages/clientes/_adicionar_cliente";
import { PlusCircle } from "@/styles/icons/PlusCircle";
import styles from "./styles.module.scss";
interface ClientSelectorProps {
    name: string;
    label?: string;
    optional?: boolean;
    initialClient?: any;
    placeholder?: string;
    idClient?: boolean;
    clients: Array<ClientData>;
    selectedClient: ClientData;
    setClients: React.Dispatch<React.SetStateAction<Array<ClientData>>>;
    setSelectedClient: React.Dispatch<React.SetStateAction<ClientData>>;
}

export function InputSearch({
    name,
    label,
    setClients,
    optional = false,
    clients,
    selectedClient,
    setSelectedClient,
    initialClient,
    placeholder,
    idClient = false,
}: ClientSelectorProps) {
    const [isClientCreatorModalOpen, setIsClientCreatorModalOpen] = useState(false);
    const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
    const [filteredClients, setFilteredClients] = useState([]);
    const [hasNewClient, setHasNewClient] = useState<boolean>(false)
    const { getClients } = useClient();
    let timeoutId: NodeJS.Timeout | null = null;
    useEffect(() => {
        if (!clients) return;
        setFilteredClients(clients);
    }, [clients]);

    function handleDropDownToggleState() {
        setTimeout(() => {
            setIsClientDropdownOpen(!isClientDropdownOpen);
        }, 200);
    }

    function selectClient(client: ClientData) {
        setSelectedClient(client);
        setIsClientDropdownOpen(false);
    }

    function setActiveClient(data: Array<ClientData>) {
        if (idClient) {
            setSelectedClient(
                data?.filter((item) => item.id === initialClient)[0]
            );
            return
        }
        setSelectedClient(
            data?.filter((item) => item.document === initialClient.document)[0]
        );
    }


    async function handleGetClient(client?: any) {
        try {
            const { data } = await getClients(
                {},
                {
                    size: 100,
                    page: 0,
                    sort: "name,ASC",
                }
            );
            setClients(data?.content);
            selectClient(client);
            if (initialClient) return setActiveClient(data?.content);
        } catch (error) {
            showToast({
                type: "error",
                message: "Não foi possível deletar o cliente.",
            });
        }
    }

    async function handleGetClientSearch(client?: any) {
        try {
            const { data } = await getClients(
                {
                    name: client ? client : "",
                },
                {
                    size: 100,
                    page: 0,
                    sort: "name,ASC",
                }
            );
            setClients(data?.content);
            selectClient(client);
            setIsClientDropdownOpen(true);

            if (initialClient) return setActiveClient(data?.content);
        } catch (error) {
            showToast({
                type: "error",
                message: "Não foi possível deletar o cliente.",
            });
        }
    }


    function handleSearchBox(e: string) {
        handleGetClientSearch(e)
        if (!selectedClient?.name) {
            setSelectedClient({} as ClientData);
        }


    }

    function selectClientWithMaxId() {
        const maxIdObject = clients.reduce((maxObj, obj) => {
            if (obj.id > maxObj.id) {
                return obj;
            } else {
                return maxObj;
            }
        });
        setSelectedClient(maxIdObject);
    }
    useEffect(() => {
        handleGetClient()
    }, []);

    useEffect(() => {
        if (hasNewClient === true) {
            handleGetClient()
        }
    }, [hasNewClient]);

    useEffect(() => {
        if (hasNewClient === true) {
            selectClientWithMaxId();
            setHasNewClient(false)
        }
    }, [clients]);


    return (
        <>
            {isClientCreatorModalOpen && (
                <Modal
                    isOpen={isClientCreatorModalOpen}
                    onClose={() => setIsClientCreatorModalOpen(!isClientCreatorModalOpen)}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <AddClient setHasNewClients={setHasNewClient} hasNewClients={hasNewClient} onClose={() => setIsClientCreatorModalOpen(!isClientCreatorModalOpen)} />
                    </ModalContent>
                </Modal>
            )}
            <div className={styles.container}>
                {label && <span>{`${label} ${optional ? "(Opcional)" : ""}`}</span>}

                <Flex >

                    {(selectedClient?.name === undefined || selectedClient?.name === "") &&

                        <Box position="absolute" top="50%" pl="16px" >

                            <img src={`/images/icons/client.svg`} width="16px" height="16px" />
                        </Box>
                    }
                    <input
                        className={`${styles.clientInput}`}
                        onBlurCapture={handleDropDownToggleState}
                        onFocusCapture={handleDropDownToggleState}
                        placeholder={placeholder}

                        value={selectedClient?.name}
                        autoComplete="off"
                        disabled={!!initialClient}
                        onChange={(e) => {
                            handleSearchBox(e.target.value),
                                setSelectedClient({
                                    ...selectedClient,
                                    name: e.target.value,
                                });
                        }}
                        style={{ paddingLeft: (selectedClient?.name === undefined || selectedClient?.name === "") ? '40px' : "16px"}}
                    />
                </Flex>

                {isClientDropdownOpen && (
                    <div className={`${styles.dropDown}`}>
                        <ul>
                            <li
                                className={styles.addClientLinkButton}
                                onClick={() => setIsClientCreatorModalOpen(true)}
                            >
                                <PlusCircle color="#1890FF" />
                                Adicionar cliente
                            </li>
                            {filteredClients?.map(client => (
                                <li
                                    key={client.id}
                                    onClick={() => selectClient(client)}
                                >
                                    {client.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </>
    )
}