
import { IconProps } from "@/models/Icon";

export function TrashIcon2({ size = 20, color = "#000", opacity="1"}: IconProps) {
    return(
        <svg 
            width={size} 
            height={size} 
            viewBox="0 0 14 14" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M4.1875 1.87305H4.0625C4.13125 1.87305 4.1875 1.8168 4.1875 1.74805V1.87305H8.9375V1.74805C8.9375 1.8168 8.99375 1.87305 9.0625 1.87305H8.9375V2.99805H10.0625V1.74805C10.0625 1.19648 9.61406 0.748047 9.0625 0.748047H4.0625C3.51094 0.748047 3.0625 1.19648 3.0625 1.74805V2.99805H4.1875V1.87305ZM12.0625 2.99805H1.0625C0.785938 2.99805 0.5625 3.22148 0.5625 3.49805V3.99805C0.5625 4.0668 0.61875 4.12305 0.6875 4.12305H1.63125L2.01719 12.2949C2.04219 12.8277 2.48281 13.248 3.01563 13.248H10.1094C10.6438 13.248 11.0828 12.8293 11.1078 12.2949L11.4938 4.12305H12.4375C12.5063 4.12305 12.5625 4.0668 12.5625 3.99805V3.49805C12.5625 3.22148 12.3391 2.99805 12.0625 2.99805ZM9.98906 12.123H3.13594L2.75781 4.12305H10.3672L9.98906 12.123Z" 
                fill={color}
                fillOpacity={opacity}
            />
        </svg>
    )
}