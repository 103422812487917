import {
  SubscriptionPaginator,
  Subscription,
  SubscriptionImportRequest,
} from "@/models";
import { Api } from "@/services";
import { filterAndPageableToRequest } from "@/utils";

type StatusTypes = "PENDING" | "ACTIVE" | "INACTIVE" | "LATE";

interface FilterOptions {
  status?: StatusTypes | string;
  customerDocument?: string;
}

export interface UpdateSubscriptionProps {
  idPlan: number;
  bankSlipDescription?: string;
  basicValueRequest: {
    type: string;
    valuePerMonth: number;
  };
}

export function useSubscription() {
  const baseUrl = "/subscriptions";

  const activateAccount = (value: boolean, uuid: string) =>
    Api.patch(`${baseUrl}/${uuid}/status`, { active: value });

  const editSubscription = (uuid: string, data: UpdateSubscriptionProps) =>
    Api.patch(`${baseUrl}/${uuid}`, data);

  const getSubscription = (uuid: string) =>
    Api.get<Subscription>(`${baseUrl}/${uuid}`);

  const getSubscriptions = (filter: object, pageable: object) => {
    return Api.get<SubscriptionPaginator>(
      `${baseUrl}/${filterAndPageableToRequest({ filter, pageable })}`
    );
  };

  const importSubscription = (data: SubscriptionImportRequest) => {
    return Api.post(`${baseUrl}`, data);
  };

  const patchToggleAutomaticInvoiceEmit = (status: boolean, uuid: string): Promise<void> => {
    return Api.patch(`${baseUrl}/${uuid}/invoice`, {
      enabled: status
    })
  }

  return {
    activateAccount,
    getSubscriptions,
    getSubscription,
    editSubscription,
    importSubscription,
    patchToggleAutomaticInvoiceEmit
  };
}
