import { ReactNode, useState, useRef } from "react";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  IconButton,
  forwardRef,
  Link,
  InputRightElement,
  Spinner,
  Select,
  Text
} from "@chakra-ui/react";
import { IoSearchOutline, IoCalendarOutline } from "react-icons/io5";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

interface FilterProps {
  placeholder: string;
  loading?: boolean;
  filterTable?: (searchParam: string) => void;
  children?: ReactNode;
  searchFunction?: (filter: string) => void;
  setStartDate?: (start: Date) => void;
  setEndDate?: (end: Date) => void;
  startDate?: Date;
  endDate?: Date;
  isVisibleDatepicker?: boolean;
  isVisibleSearchButton?: boolean;
  setFilterParams?: (filter: string) => void;
  getListFilter?: any;
  filterParams?: string;
}

function Filter({
  filterTable,
  searchFunction,
  placeholder,
  loading,
  children,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  isVisibleDatepicker = false,
  isVisibleSearchButton = true,
  setFilterParams,
  getListFilter,
  filterParams,

}: FilterProps) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const inputRef = useRef(null);
  registerLocale("ptBR", ptBR);

  const onMonthChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const CustomInput = forwardRef(({ value }: any, ref) => (
    <Button
      onClick={() => setIsDatePickerOpen(true)}
      ref={ref}
      p="5px 12px 5px 20px"
      maxW="220px"
      fontSize="14px"
      borderRadius="4"
      _hover={{ bgColor: "gray.200" }}
      bgColor="white"
      fontWeight="500"
      leftIcon={
        !startDate &&
        !endDate && <IoCalendarOutline color="gray.300" size={20} />
      }
    >
      {value}
    </Button>
  ));

  const handleSearch = () => {
    let searchText = inputRef.current.value;
    if (filterTable) {
      filterTable(searchText);
      return;
    }
    if (searchFunction) {
      searchFunction(searchText);
      return;
    }
  };

  const clearFilters = (e: any) => {
    e.preventDefault();
    inputRef.current.value = "";
    if (setStartDate) {
      setStartDate(null);
    }
    if (setEndDate) {
      setEndDate(null);
    }
    handleSearch();
  };

  return (


    <Flex
      bg="gray.25"
      p={2}
      borderRadius={8}
      w="100%"
      gap="0.3px"
      align="center"
    >
      {getListFilter &&

        <Select
          fontSize="14px"
          w="130px"
          zIndex="3"
          height={35}
          defaultValue="name"
          backgroundColor="rgba(230, 233, 240, 1)"
          border="none"
          borderRadius="6px 0 0 6px"
          onChange={(e) => setFilterParams(e.target.value)}
        >
          {getListFilter?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>

      }


      <InputGroup maxW={500} bg="white">
        <InputLeftElement
          pointerEvents="none"
          children={<IoSearchOutline color="gray.300" size={20} />}
          height={35}
        />
        <Input
          placeholder={placeholder}
          type="search"
          fontSize="1rem"
          borderColor="rgba(230, 233, 240, 1)"
          borderRadius="0 4px 4px 0"
          height={35}
          ref={inputRef}
        />
        <InputRightElement maxW="70px" w="100%" mr="2">
          {loading &&
            <Spinner size="sm" />
          }
        </InputRightElement>
        {/* <InputRightElement children={<Button>Buscar</Button>} /> */}
      </InputGroup>
      {children}
      {/* busca */}

      <Flex gap="10px" ml="10px" align="center">
        <Flex
          as="button"
          align="center"
          gap="2"
          bgColor="gray.49"
          borderRadius={8}
          px="4"
          onClick={() => handleSearch()}
          cursor="pointer"
          _hover={{ bgColor: "gray.200" }}
        >

          {isVisibleSearchButton && (
            <Flex
              h="35px"
              align="center"
              gap="10px"
            >
              <IoSearchOutline color="gray.300" size={20} />
              {/* <IconButton
                aria-label="Search"
                cursor="none"
                _hover={{ bgColor: "gray.200" }}

                w="20px"
                bgColor="transparent"
                icon={
                  <IoSearchOutline color="gray.300" size={20} />
                }
              /> */}
              <Text >
                Buscar
              </Text>
            </Flex>
          )}
        </Flex>
        {isVisibleDatepicker && (
          <DatePicker
            locale="ptBR"
            _hover={{ bgColor: "gray.200" }}
            selected={startDate}
            onChange={(date) => onMonthChange(date)}
            startDate={startDate}
            endDate={endDate}
            onInputClick={() => setIsDatePickerOpen(true)}
            onClickOutside={() => setIsDatePickerOpen(false)}
            open={isDatePickerOpen}
            customInput={<CustomInput />}
            selectsRange
            dateFormat="dd/MM/yyyy"
          />
        )}

        <a href="#" onClick={clearFilters}>
          <Link color="blue.50"   >Limpar</Link>
        </a>

      </Flex>
    </Flex >
  );
}

export { Filter };
