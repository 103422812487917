import { formatCurrency } from "@/utils";
import { Flex, Text } from "@chakra-ui/react";

interface ValueProps {
    isChargeback?: boolean
    creditOperation?: boolean;
    isRefund?: boolean;
    isTax?: boolean;
    amount?: number;
    statementType?: string;
}
export function Value({creditOperation = true, amount = 0, isChargeback = false, isRefund = false, isTax = false, statementType = ""}: ValueProps) {
    return (
        <Flex position="relative">
            {creditOperation ? 
                (<Text 
                    fontWeight={ isTax ? 500 : 700}  
                    color="gray.700"
                    position="absolute"
                    left="-14px"
                    display={isTax ? "" : "none"}
                >
                    +
                </Text>
                ) :
                (
                    <Text 
                        fontWeight={isTax ? 500 : 700}  
                        color="gray.700"
                        position="absolute"
                        left="-6px"

                    >
                        -
                    </Text>
                )
            }
            {(creditOperation && statementType !== "DEBIT_TRANSFER") && (
                <Text
                    textDecor={(isChargeback || isRefund || statementType === "CREDIT_ANTICIPATED" || statementType === "DEBIT_ANTICIPATED") && "line-through"}
                    fontWeight={isTax ? 500 : 700}
                    color="gray.700"
                >
                    {formatCurrency({value: amount})}
                </Text>
            )}
            {(!creditOperation && statementType !== "DEBIT_TRANSFER") && (
                <Text
                    textDecor={(isChargeback || isRefund || statementType === "CREDIT_ANTICIPATED" || statementType === "DEBIT_ANTICIPATED") && "line-through"}
                    fontWeight={isTax ? 500 : 700}
                    color="orange.10"
                >
                    {formatCurrency({value: amount})}
                </Text>
            )}
            {statementType === "DEBIT_TRANSFER" && (
                <Text
                    textDecor={(isChargeback || isRefund) && "line-through"}
                    fontWeight={isTax ? 500 : 700}
                    color="#389E0D"
                >
                    {formatCurrency({value: amount})}
                </Text>
            )}
        </Flex>
    )
}