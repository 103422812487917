export const PixKeyTypes = [
  { label: "CPF/CNPJ", value: "DOCUMENT" },
  { label: "Telefone", value: "PHONE" },
  { label: "Email", value: "EMAIL" },
  { label: "Chave aleatória", value: "ALLEATORY_KEY"}
]

export const PixKeyBankingTypes = [
  { label: "CNPJ", value: "cnpj" },
  // { label: "Celular", value: "phone" },
  { label: "Email", value: "email" },
  { label: "Aleatória", value: "evp"}
]