export const Banks = [
  { label: "001 - Banco do Brasil", value: "001" },
  { label: "237 - Bradesco S.A", value: "237" },
  { label: "335 - Banco Digio S.A", value: "335" },
  { label: "260 - Nu Pagamentos S.A (Nubank)", value: "260" },
  { label: "290 - Pagseguro Internet S.A (PagBank)", value: "290" },
  { label: "380 - PicPay Servicos S.A.", value: "380" },
  { label: "323 - Mercado Pago - conta do Mercado Livre", value: "323" },
  { label: "637 - Banco Sofisa S.A (Sofisa Direto)", value: "637" },
  { label: "077 - Banco Inter S.A", value: "077" },
  { label: "341 - Itaú Unibanco S.A", value: "341" },
  { label: "104 - Caixa Econômica Federal (CEF)", value: "104" },
  { label: "033 - Banco Santander Brasil S.A", value: "033" },
  { label: "212 - Banco Original S.A", value: "212" },
  { label: "756 - Bancoob (Banco Cooperativo do Brasil)", value: "756" },
  { label: "655 - Banco Votorantim S.A", value: "655" },
  { label: "041 - Banrisul – Banco do Estado do Rio Grande do Sul S.A", value: "041" },
  { label: "389 - Banco Mercantil do Brasil S.A", value: "389" },
  { label: "422 - Banco Safra S.A", value: "422" },
  { label: "070 - Banco de Brasília (BRB)", value: "070" },
  { label: "136 - Unicred Cooperativa", value: "136" },
  { label: "741 - Banco Ribeirão Preto", value: "741" },
  { label: "739 - Banco Cetelem S.A", value: "739" },
  { label: "743 - Banco Semear S.A", value: "743" },
  { label: "100 - Planner Corretora de Valores S.A", value: "100" },
  { label: "096 - Banco B3 S.A", value: "096" },
  { label: "747 - Banco Rabobank Internacional do Brasil S.A", value: "747" },
  { label: "748 - Sicredi S.A", value: "748" },
  { label: "752 - BNP Paribas Brasil S.A", value: "752" },
  { label: "091 - Unicred Central RS", value: "091" },
  { label: "399 - Kirton Bank", value: "399" },
  { label: "108 - Portocred S.A", value: "108" },
  { label: "757 - Banco KEB Hana do Brasil S.A", value: "757" },
  { label: "102 - XP Investimentos S.A", value: "102" },
  { label: "348 - Banco XP S/A", value: "348" },
  { label: "340 - Super Pagamentos S/A (Superdigital)", value: "340" },
  { label: "364 - Gerencianet Pagamentos do Brasil", value: "364" },
  { label: "084 - Uniprime Norte do Paraná", value: "084" },
  { label: "180 - CM Capital Markets CCTVM Ltda", value: "180" },
  { label: "066 - Banco Morgan Stanley S.A", value: "066" },
  { label: "015 - UBS Brasil CCTVM S.A", value: "015" },
  { label: "143 - Treviso CC S.A", value: "143" },
  { label: "062 - Hipercard BM S.A", value: "062" },
  { label: "074 - Bco. J.Safra S.A", value: "074" },
  { label: "099 - Uniprime Central CCC Ltda", value: "099" },
  { label: "025 - Banco Alfa S.A", value: "025" },
  { label: "075 - Bco ABN AMRO S.A", value: "075" },
  { label: "040 - Banco Cargill S.A", value: "040" },
  { label: "190 - Servicoop", value: "190" },
  { label: "063 - Banco Bradescard", value: "063" },
  { label: "191 - Nova Futura CTVM Ltda", value: "191" },
  { label: "064 - Goldman Sachs do Brasil BM S.A", value: "064" },
  { label: "097 - CCC Noroeste Brasileiro Ltda", value: "097" },
  { label: "016 - CCM Desp Trâns SC e RS", value: "016" },
  { label: "012 - Banco Inbursa", value: "012" },
  { label: "003 - Banco da Amazônia S.A", value: "003" },
  { label: "060 - Confidence CC S.A", value: "060" },
  { label: "037 - Banco do Estado do Pará S.A", value: "037" },
  { label: "159 - Casa Crédito S.A", value: "159" },
  { label: "172 - Albatross CCV S.A", value: "172" },
  { label: "085 - Coop Central Ailos", value: "085" },
  { label: "114 - Central Cooperativa de Crédito no Estado do Espírito Santo", value: "114" },
  { label: "036 - Banco BBI S.A", value: "036" },
  { label: "394 - Banco Bradesco Financiamentos S.A", value: "394" },
  { label: "004 - Banco do Nordeste do Brasil S.A", value: "004" },
  { label: "320 - Banco CCB Brasil S.A", value: "320" },
  { label: "189 - HS Financeira", value: "189" },
  { label: "105 - Lecca CFI S.A", value: "105" },
  { label: "076 - Banco KDB Brasil S.A", value: "076" },
  { label: "082 - Banco Topázio S.A", value: "082" },
  { label: "286 - CCR de Ouro", value: "286" },
  { label: "093 - Pólocred SCMEPP Ltda", value: "093" },
  { label: "273 - CCR de São Miguel do Oeste", value: "273" },
  { label: "157 - ICAP do Brasil CTVM Ltda", value: "157" },
  { label: "183 - Socred S.A", value: "183" },
  { label: "014 - Natixis Brasil S.A", value: "014" },
  { label: "130 - Caruana SCFI", value: "130" },
  { label: "127 - Codepe CVC S.A", value: "127" },
  { label: "079 - Banco Original do Agronegócio S.A", value: "079" },
  { label: "081 - BBN Banco Brasileiro de Negócios S.A", value: "081" },
  { label: "118 - Standard Chartered BI S.A", value: "118" },
  { label: "133 - Cresol Confederação", value: "133" },
  { label: "121 - Banco Agibank S.A", value: "121" },
  { label: "083 - Banco da China Brasil S.A", value: "083" },
  { label: "138 - Get Money CC Ltda", value: "138" },
  { label: "024 - BCO Bandepe S.A", value: "024" },
  { label: "095 - Banco Confidence de Câmbio S.A", value: "095" },
  { label: "094 - Banco Finaxis", value: "094" },
  { label: "276 - Senff S.A", value: "276" },
  { label: "137 - Multimoney CC Ltda", value: "137" },
  { label: "092 - BRK S.A", value: "092" },
  { label: "047 - Banco Bco do Estado de Sergipe S.A", value: "047" },
  { label: "144 - Bexs Banco de Câmbio S.A", value: "144" },
  { label: "126 - BR Partners BI", value: "126" },
  { label: "301 - BPP Instituição de Pagamentos S.A", value: "301" },
  { label: "173 - BRL Trust DTVM SA", value: "173" },
  { label: "119 - Banco Western Union", value: "119" },
  { label: "254 - Paraná Banco S.A", value: "254" },
  { label: "268 - Barigui CH", value: "268" },
  { label: "107 - Banco Bocom BBM S.A", value: "107" },
  { label: "412 - Banco Capital S.A", value: "412" },
  { label: "124 - Banco Woori Bank do Brasil S.A", value: "124" },
  { label: "149 - Facta S.A. CFI", value: "149" },
  { label: "197 - Stone Pagamentos S.A", value: "197" },
  { label: "142 - Broker Brasil CC Ltda", value: "142" },
  { label: "184 - Banco Itaú BBA S.A", value: "184" },
  { label: "634 - Banco Triângulo S.A (Banco Triângulo)", value: "634" },
  { label: "545 - Senso CCVM S.A", value: "545" },
  { label: "132 - ICBC do Brasil BM S.A", value: "132" },
  { label: "298 - Vips CC Ltda", value: "298" },
  { label: "129 - UBS Brasil BI S.A", value: "129" },
  { label: "128 - MS Bank S.A Banco de Câmbio", value: "128" },
  { label: "194 - Parmetal DTVM Ltda", value: "194" },
  { label: "310 - Vortx DTVM Ltda", value: "310" },
  { label: "163 - Commerzbank Brasil S.A Banco Múltiplo", value: "163" },
  { label: "280 - Avista S.A", value: "280" },
  { label: "146 - Guitta CC Ltda", value: "146" },
  { label: "279 - CCR de Primavera do Leste", value: "279" },
  { label: "182 - Dacasa Financeira S/A", value: "182" },
  { label: "278 - Genial Investimentos CVM S.A", value: "278" },
  { label: "271 - IB CCTVM Ltda", value: "271" },
  { label: "021 - Banco Banestes S.A", value: "021" },
  { label: "246 - Banco ABC Brasil S.A", value: "246" },
  { label: "751 - Scotiabank Brasil", value: "751" },
  { label: "208 - Banco BTG Pactual S.A", value: "208" },
  { label: "746 - Banco Modal S.A", value: "746" },
  { label: "241 - Banco Clássico S.A", value: "241" },
  { label: "612 - Banco Guanabara S.A", value: "612" },
  { label: "604 - Banco Industrial do Brasil S.A", value: "604" },
  { label: "505 - Banco Credit Suisse (BRL) S.A", value: "505" },
  { label: "196 - Banco Fair CC S.A", value: "196" },
  { label: "300 - Banco La Nacion Argentina", value: "300" },
  { label: "477 - Citibank N.A", value: "477" },
  { label: "266 - Banco Cédula S.A", value: "266" },
  { label: "122 - Banco Bradesco Berj S.A", value: "122" },
  { label: "376 - Banco J.P. Morgan S.A", value: "376" },
  { label: "473 - Banco Caixa Geral Brasil S.A", value: "473" },
  { label: "745 - Banco Citibank S.A", value: "745" },
  { label: "120 - Banco Rodobens S.A", value: "120" },
  { label: "265 - Banco Fator S.A", value: "265" },
  { label: "007 - BNDES (Banco Nacional do Desenvolvimento Social)", value: "007" },
  { label: "188 - Ativa S.A Investimentos", value: "188" },
  { label: "134 - BGC Liquidez DTVM Ltda", value: "134" },
  { label: "641 - Banco Alvorada S.A", value: "641" },
  { label: "029 - Banco Itaú Consignado S.A", value: "029" },
  { label: "243 - Banco Máxima S.A", value: "243" },
  { label: "078 - Haitong BI do Brasil S.A", value: "078" },
  { label: "111 - Banco Oliveira Trust DTVM S.A", value: "111" },
  { label: "017 - BNY Mellon Banco S.A", value: "017" },
  { label: "174 - Pernambucanas Financ S.A", value: "174" },
  { label: "495 - La Provincia Buenos Aires Banco", value: "495" },
  { label: "125 - Brasil Plural S.A Banco", value: "125" },
  { label: "488 - JPMorgan Chase Bank", value: "488" },
  { label: "065 - Banco Andbank S.A", value: "065" },
  { label: "492 - ING Bank N.V", value: "492" },
  { label: "250 - Banco BCV", value: "250" },
  { label: "145 - Levycam CCV Ltda", value: "145" },
  { label: "494 - Banco Rep Oriental Uruguay", value: "494" },
  { label: "253 - Bexs CC S.A", value: "253" },
  { label: "269 - HSBC Banco de Investimento", value: "269" },
  { label: "213 - Banco Arbi S.A", value: "213" },
  { label: "139 - Intesa Sanpaolo Brasil S.A", value: "139" },
  { label: "018 - Banco Tricury S.A", value: "018" },
  { label: "630 - Banco Intercap S.A", value: "630" },
  { label: "224 - Banco Fibra S.A", value: "224" },
  { label: "600 - Banco Luso Brasileiro S.A", value: "600" },
  { label: "623 - Banco PAN", value: "623" },
  { label: "204 - Banco Bradesco Cartões S.A", value: "204" },
  { label: "479 - Banco Itaubank S.A", value: "479" },
  { label: "456 - Banco MUFG Brasil S.A", value: "456" },
  { label: "464 - Banco Sumitomo Mitsui Brasil S.A", value: "464" },
  { label: "613 - Omni Banco S.A", value: "613" },
  { label: "652 - Itaú Unibanco Holding BM S.A", value: "652" },
  { label: "653 - Banco Indusval S.A", value: "653" },
  { label: "069 - Banco Crefisa S.A", value: "069" },
  { label: "370 - Banco Mizuho S.A", value: "370" },
  { label: "249 - Banco Investcred Unibanco S.A", value: "249" },
  { label: "318 - Banco BMG S.A", value: "318" },
  { label: "626 - Banco Ficsa S.A", value: "626" },
  { label: "270 - Sagitur CC Ltda", value: "270" },
  { label: "366 - Banco Societe Generale Brasil", value: "366" },
  { label: "113 - Magliano S.A", value: "113" },
  { label: "131 - Tullett Prebon Brasil CVC Ltda", value: "131" },
  { label: "011 - Credit Suisse Hedging-Griffo CV S.A", value: "011" },
  { label: "611 - Banco Paulista", value: "611" },
  { label: "755 - BofA Merrill Lynch BM S.A", value: "755" },
  { label: "089 - CCR Reg Mogiana", value: "089" },
  { label: "643 - Banco Pine S.A", value: "643" },
  { label: "140 - Easynvest - Título CV S.A", value: "140" },
  { label: "707 - Banco Daycoval S.A", value: "707" },
  { label: "288 - Carol DTVM Ltda", value: "288" },
  { label: "101 - Renascença DTVM Ltda", value: "101" },
  { label: "487 - Deutsche Bank S.A Banco Alemão", value: "487" },
  { label: "233 - Banco Cifra", value: "233" },
  { label: "177 - Guide", value: "177" },
  { label: "633 - Banco Rendimento S.A", value: "633" },
  { label: "218 - Banco BS2 S.A", value: "218" },
  { label: "292 - BS2 Distribuidora de Títulos e Investimentos", value: "292" },
  { label: "169 - Banco Olé Bonsucesso Consignado S.A", value: "169" },
  { label: "293 - Lastro RDV DTVM Ltda", value: "293" },
  { label: "285 - Frente CC Ltda", value: "285" },
  { label: "080 - B&T CC Ltda", value: "080" },
  { label: "753 - Novo Banco Continental S.A BM", value: "753" },
  { label: "222 - Banco Crédit Agricole Brasil S.A", value: "222" },
  { label: "754 - Banco Sistema", value: "754" },
  { label: "098 - Credialiança CCR", value: "098" },
  { label: "610 - Banco VR S.A", value: "610" },
  { label: "712 - Banco Ourinvest S.A", value: "712" },
  { label: "010 - Credicoamo", value: "010" },
  { label: "283 - RB Capital Investimentos DTVM Ltda", value: "283" },
  { label: "217 - Banco John Deere S.A", value: "217" },
  { label: "117 - Advanced CC Ltda", value: "117" },
  { label: "336 - Banco C6 S.A - C6 Bank", value: "336" },
  { label: "654 - Banco Digimais S.A", value: "654" }
];