import { Flex, Text } from "@chakra-ui/react";

interface IntegrationEmptyStateProps {
    message?: string;
}


export const IntegrationEmptyState = ({ message }: IntegrationEmptyStateProps) => {
    return (
        <Flex 
            w="100%" 
            bgColor="gray.25" 
            p="8px" 
            align="center"
            gap={6}
            borderRadius="4px"
            justify="center"
        >
            <Text fontSize="1.2rem" color="gray.400">{message}</Text>
        </Flex>
    )
}