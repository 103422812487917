
import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface PreviewLink {
  arrayInstallments?: any;
  nameLink?: string;
  creditCardTax?: string;
  valuePerMonth?: number;
  title?: string;
  cliente?: string;
  description?: string;
  value?: number;
  enablePix?: boolean;
  enableBankSlip?: boolean;
  enableCreditCard?: boolean;
  boleto?: {
    installments: number;
    discount?: string;
    tax?: string;
  }
  creditcard?: {
    installments?: number;
    discount?: any;
    scheduleDate?: string;
    tax?: string;
  }
  pix?: {
    installments: number;
    discount?: string;
    tax?: string;
  }
  expireDate?: string;
  hasDescription?: boolean;
  custonFields?: any;
}


interface PreviewContextProviderProps {
  children: ReactNode;
}

interface PreviewContextType {
  previewData: PreviewLink | undefined;
  setPreviewData: Dispatch<SetStateAction<PreviewLink | undefined>>;
}

const PreviewContext = createContext<PreviewContextType | undefined>(undefined);

export const PreviewContextProvider: React.FC<PreviewContextProviderProps> = ({ children }) => {
  const [previewData, setPreviewData] = useState<PreviewLink | undefined>(undefined);

  const contextValue: PreviewContextType = {
    previewData,
    setPreviewData,
  };

  return <PreviewContext.Provider value={contextValue}>{children}</PreviewContext.Provider>;
};

export const usePreviewContext = () => {
  const context = useContext(PreviewContext);
  if (!context) {
    throw new Error('usePreviewContext must be used within a PreviewContextProvider');
  }
  return context;
};
