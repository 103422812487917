import { Api } from "@/services";

export function useResetPassword() {
  const baseUrl = "/public/users";
  const baseUrlPassword = "/public/password-tokens";

  const sendEmailResetPassword = (email: string) => {
    return Api.patch(`${baseUrl}/${email}/reset-password`);
  };

  const sendNewPassword = (token: string, data: Object) => {
    return Api.patch(`${baseUrlPassword}/${token}`, data);
  };

  return {
    sendEmailResetPassword,
    sendNewPassword,
  };
}
