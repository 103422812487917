import { WithAuth } from "@/hoc";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button as ChakraButton, Center, Divider, Flex } from "@chakra-ui/react";
import { useCustomLink, usePlan } from "@/hooks";
import { Plan, CustomLink } from "@/models";
import { showToast } from "@/utils";
import { PunctualCharge } from "../seja-pago/punctual-charge";
import { CustomSignature } from "../seja-pago/custom-signature";
import { LeanCheckout } from "../seja-pago/lean-checkout";
import { usePreviewContext } from "@/contexts/PreviewContext";
import PreviewLinkPagamento from "@/components/PreviewLinkPagamento";
const CustomLinksEdit = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [initialData, setInitialData] = useState({} as CustomLink);
  const [customizableDate, setCustomizableDate] = useState(false);
  const { previewData, setPreviewData } = usePreviewContext();
  const { id } = useParams();

  const { getCustomLinkData } = useCustomLink();

  const { getPlans } = usePlan();

  async function getInitialData() {
    try {
      const getPlansReponse = await getPlans();
      const response = await getCustomLinkData(String(id));
      setPlans(getPlansReponse?.data);
      setInitialData(response?.data);
      setCustomizableDate(response.data.scheduledDate ? true : false);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  useEffect(() => {
    if (id === undefined) return;
    getInitialData();
  }, [id]);

  return (
    <Flex
      flexDir="column"
      align="start"
    >
      <Flex flexDir="row" maxW="100%" w="100%">
        {initialData?.type === "FAST_ORDER" && (
          <Flex
            w="100%"
            alignItems="center"
            justifyContent="center"
          >
            <LeanCheckout
              asEdition
              initialData={initialData}
            />
          </Flex>
        )}
        {(initialData?.type == "ORDER" || initialData?.type === "SUBSCRIPTION") && (
          <>
            <Flex w="60%">
              {initialData?.type === "ORDER" && (
                <PunctualCharge
                  asEdition
                  initialData={initialData}
                  previewData={previewData}
                  setPreviewData={setPreviewData}
                />
              )}
              {initialData?.type === "SUBSCRIPTION" && (
                <CustomSignature
                  asEdition
                  initialData={initialData}
                  initialDataPlan={plans}
                  setPreviewData={setPreviewData}
                  previewData={previewData}
                />
              )}

              <Flex>
                <Center w="1px" m=" -20px auto" height={'calc(100% - 70px)'}>
                  <Divider orientation='vertical' />
                </Center>
              </Flex>
            </Flex>
            <PreviewLinkPagamento
              previewData={previewData}
              isPontual={initialData?.type === "ORDER"}
            />
          </>
        )}
      </Flex>

    </Flex>
  );
};

export default WithAuth(CustomLinksEdit);
