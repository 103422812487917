import axios, { AxiosError } from "axios";
import { signOut } from "@/contexts/AuthContext";
import { useRefreshToken } from "@/hooks/useRefreshToken";
import { v1 as uuidv1 } from 'uuid';
function getAPIClient() {
  const token = localStorage.getItem("barte-auth-token");
  const { sendRefreshToken } = useRefreshToken()

  const publicPages = [
    "/login",
    "/cadastro",
    "/314119201815",
    "/redefinir-senha",
  ];
  if (
    !token &&
    !publicPages.includes(window.location.pathname) &&
    !window.location.pathname.startsWith("/redefinir-senha")
  ) {
    signOut();
  }


  const api = axios.create({
    baseURL: `${import.meta.env.VITE_API_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "access-control-allow-origin": "*"
    },
  });

  api.interceptors.request.use(
    (config) => {
      const newConfig = config;
      const uuid = uuidv1(); 
      newConfig.headers.post['x-idempotency-key'] = uuid;
      return newConfig;
    },
    (error) => Promise.reject(error)
  );
  api.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error: AxiosError) => {
      if (error?.response?.status === 401) {
        sendRefreshToken();
      } else if (error?.response?.status === 403) {
        signOut();
      }
      return Promise.reject(error);
    }
  );
  return api;
}

export const Api = getAPIClient();
function uuidv4(): any {
  throw new Error("Function not implemented.");
}

