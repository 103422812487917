import { CheckIcon2 } from "@/styles/icons/CheckIcon2";
import { Box, Flex, Text } from "@chakra-ui/react";

interface StepComponentProps {
    section: number;
    status: string;
}

export function StepComponent({
  section, status
}: StepComponentProps) {
    const borderColors = {
        hover: "1px solid #1890FF",
        before: "1px solid #00000073",
        after: "1px solid #1890FF",
    }
    const backgroundColors = {
        hover: "blue.200",
        before: "white",
        after: "white",
    }
    const colors = {
        hover: "white",
        before: "#00000073",
        after: "blue.200",
    }
    const getStatusPosition = (position: number) => {
        if(section === position) {
            return "hover"
        }
        if(section < position) {
            return "before"
        }
        return "after"
    }
    const items = [
        {
            id: 1,
            title: "Dados da empresa",
        },
        {
            id: 2,
            title: "Dados adicionais",
        },
        {
            id: 3,
            title: "Dados dos sócios",
        },
    ]
  return (
    <>
            <Flex
                w="100%"
                justify="space-between"
            >
                {items?.map(item => (
                    <Flex
                        gap="8px"
                        align="center"
                        w={item.id !== 3 ? "350px" : ""}
                        key={item.id}
                    >
                        <Flex
                            w="32px"
                            h="32px"
                            align="center"
                            justify="center"
                            color={colors[getStatusPosition(item?.id)]}
                            backgroundColor={backgroundColors[getStatusPosition(item?.id)]}
                            border={borderColors[status !== "INACTIVE" ? "after" : getStatusPosition(item?.id)]}
                            borderRadius="full"
                        >
                            {(getStatusPosition(item.id) === "after" || getStatusPosition(item.id) !== "hover" && status !== "INACTIVE") ? <CheckIcon2 /> : item?.id}
                        </Flex>
                        <Text>{item?.title}</Text>
                        {item?.id !== 3 && (
                            <Box
                                maxW="150px"
                                w="100%"
                                h="1px"
                                backgroundColor={(getStatusPosition(item.id) === "after" || status !== "INACTIVE")? "blue.200" : "#F3F3F3"}
                            />
                        )}
                        
                    </Flex>
                ))}
            </Flex>
    </>
  )
}