import { useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Link, Stack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRegister } from "@/hooks";
import { Input, Checkbox } from "@/components";
import { clearMask, getApplicationName, showToast } from "@/utils";
import { SellerRegisterData } from "@/models";
import { BannerLateral } from "@/layouts";

const validationSchema = Yup.object().shape({
  cnpj: Yup.string().required("O campo CNPJ é obrigatório!"),
  companyName: Yup.string().required("O campo razão social é obrigatório!"),
  email: Yup.string()
    .email("Digite um e-mail válido!")
    .required("O campo e-mail é obrigatório!"),
  password: Yup.string()
  .min(8, 'A senha precisa ter no mínimo 8 caracteres')
  .max(30, 'A senha precisa ter no máximo 30 caracteres')
  .required("O campo senha é obrigatório!"),
  confirm_password: Yup.string()
    .oneOf([null, Yup.ref("password")], "As senhas precisam ser iguais")
    .required("O campo confirme sua senha é obrigatório!"),
  privacyPolicy: Yup.bool().oneOf(
    [true],
    "O aceite de política de privacidade é obrigatório!"
  ),
  userTerms: Yup.boolean().oneOf(
    [true],
    "O aceite de termos de uso é obrigatório!"
  ),
});

type FormValues = {
  cnpj: string;
  companyName: string;
  email: string;
  password: string;
  confirm_password: string;
  userTerms: boolean;
  privacyPolicy: boolean;
};

const Register: React.FC = () => {
  const { registerSeller } = useRegister();
  const [ip, setIP] = useState("");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const [loading, setLoading] = useState(false);
  const applicationName = getApplicationName({});
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = handleSubmit(async (data: FormValues) => {
    setLoading(true);
    const hostNameArr = location?.host?.split('.');
    hostNameArr.shift();
    const formattedData: SellerRegisterData = {
      cnpj: clearMask(data.cnpj),
      companyName: data.companyName,
      userAdmin: {
        email: data.email,
        password: data.password,
        confirm_password: data.confirm_password,
      },
      acceptTerm: {
        ip: ip,
        dateTime: new Date().toISOString(),
        userTerms: data.userTerms,
        cookiePolicy: data.privacyPolicy,
        privacyPolicy: data.privacyPolicy,
      },
      domainUrl: hostNameArr?.join(".")
    };

    delete formattedData?.userAdmin?.confirm_password;

    try {
      const response = await registerSeller(formattedData);

      showToast({
        message: "Novo cadastro criado com sucesso!",
        type: "success",
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }

    setLoading(false);
  });

  return (
    <Flex flexDir={["column", "column", "column", "row"]} h="100vh">
      <Flex
        align="center"
        w={["100%", "100%", "100%", "50%"]}
        justify="center"
        overflow="auto"
      >
        <Flex
          as="form"
          direction="column"
          justify="center"
          w="450px"
          pt="180px"
          pb="50px"
          onSubmit={onSubmit}
          gap="6"
        >
          <img src={`/images/commons/logo-${import.meta.env.VITE_APPLICATION_NAME}.svg`} width="180" height="60" />
          <Flex w="100%" mb="40px">
            <Heading as="h3" fontSize="31px" w="60%">
              Criar Conta
            </Heading>
            <Flex
              fontSize="14px"
              color="gray.400"
              w="50%"
              flexDirection="column"
              justify="flex-start"
              align="flex-end"
            >
              Já possui uma conta?
              <Link fontSize="14px" href="/login" textDecoration="underline">
                Fazer login
              </Link>
            </Flex>
          </Flex>
          <Stack w="100%">
            <Input
              label="CNPJ"
              type="text"
              name="cnpj"
              mask="cnpj"
              placeholder="CNPJ"
              register={{ ...register("cnpj") }}
              errors={errors?.cnpj}
              aria-label="Digite seu CNPJ"
              aria-invalid={errors.cnpj ? "true" : "false"}
              fontSize="1rem"
              labelColor="black"
              mb="10px"
            />

            <Input
              label="Razão Social"
              type="text"
              name="companyName"
              placeholder="Razão Social"
              register={{ ...register("companyName") }}
              errors={errors?.companyName}
              aria-label="Digite a razão social da sua empresa"
              aria-invalid={errors.companyName ? "true" : "false"}
              fontSize="1rem"
              labelColor="black"
              mb="10px"
            />

            <Input
              label="Email"
              type="text"
              name="email"
              placeholder={"Insira seu email"}
              register={{ ...register("email") }}
              errors={errors?.email}
              aria-label={"Insira seu email"}
              aria-invalid={errors.email ? "true" : "false"}
              fontSize="1rem"
              labelColor="black"
              mb="10px"
            />

            <Input
              label="Senha"
              type="password"
              name="password"
              placeholder="Sua senha"
              register={{ ...register("password") }}
              errors={errors?.password}
              aria-label="Digite sua senha"
              aria-invalid={errors.password ? "true" : "false"}
              fontSize="1rem"
              labelColor="black"
              mb="10px"
            />

            <Input
              label="Confirme sua senha"
              type="password"
              name="confirm_password"
              placeholder="Sua senha"
              register={{ ...register("confirm_password") }}
              errors={errors?.confirm_password}
              aria-label="Digite sua senha"
              aria-invalid={errors.confirm_password ? "true" : "false"}
              fontSize="1rem"
              labelColor="black"
              mb="10px"
            />

            <Checkbox
              size="md"
              fontSize="1rem"
              textSize="1rem"
              name="privacyPolicy"
              register={{ ...register("privacyPolicy") }}
              errors={errors?.privacyPolicy}
              text={[
                "Concordo com a ",
                <a
                  href="https://www.barte.com/politica-de-privacidade"
                  title="Política de Privacidade"
                  target="_blank"
                >
                  Política de Privacidade
                </a>,
              ]}
            />

            <Checkbox
              size="md"
              fontSize="1rem"
              textSize="1rem"
              name="userTerms"
              register={{ ...register("userTerms") }}
              text={[
                "Concordo com os ",
                <a
                  href="https://www.barte.com/termos-de-uso"
                  title="Termos de Uso"
                  target="_blank"
                >
                  Termos de Uso
                </a>,
              ]}
              errors={errors?.userTerms}
            ></Checkbox>

            <Button
              isLoading={loading}
              type="submit"
              variant="outline"
              fontSize="1rem"
              py="1rem"
              w="150px"
              mt="20px!important"
            >
              Criar conta
            </Button>
          </Stack>
        </Flex>
      </Flex>

      <BannerLateral />
    </Flex>
  );
};

export default Register;