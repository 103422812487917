import { ClientData } from "@/models";
import { ArrowRightIcon } from "@/styles/icons/ArrowRightIcon";
import { phone_format } from "@/utils";
import { 
    Button, 
    Flex, 
    Heading, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay,
    Stack,
    Text,  
} from "@chakra-ui/react";

interface CreateTransferModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: any;
    clientData: ClientData;
    editedData: Array<string>;
}


export function EditConfirmationModal({ 
    isOpen, 
    onClose, 
    onConfirm,
    clientData,
    editedData,
}: CreateTransferModalProps) {

    return (
        <Modal 
            isOpen={isOpen} 
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Flex 
                        w="100%"  
                        align="center"
                        gap="2"
                    >
                        <Text fontSize="1.2rem">Revise as alterações</Text>
                        <ModalCloseButton />
                    </Flex>
                </ModalHeader>
                <ModalBody 
                    p="24px"
                >
                    <Flex 
                        w="100%" 
                        direction="column" 
                        gap="2"
                    >
                        <Heading fontSize="1rem">Identificação e contato</Heading>
                        <Stack>
                            <Text 
                                fontSize=".8rem"
                                color="gray.400"
                            >
                                Nome
                            </Text>
                            <Flex 
                                w="100%"
                                align="center"
                                gap="2"
                                flexWrap="wrap"
                            >
                                <Text
                                    fontSize="1rem"
                                    color="gray.600"
                                    as={clientData?.name !== editedData[0] ? "s" : "a"}
                                >
                                    {clientData?.name}
                                </Text>
                                {clientData?.name !== editedData[0] && (
                                    <>
                                        {clientData?.name !== "" && (
                                            <ArrowRightIcon />
                                        )}
                                        <Text
                                            fontSize="1rem"
                                            color="gold.7"
                                        >
                                            {editedData[0]}
                                        </Text>
                                    </>
                                )}
                            </Flex>
                        </Stack>

                        <Stack>
                            <Text 
                                fontSize=".8rem"
                                color="gray.400"
                            >
                                Telefone
                            </Text>
                            <Flex 
                                w="100%"
                                align="center"
                                gap="2"
                                flexWrap="wrap"
                            >
                                <Text
                                    fontSize="1rem"
                                    color="gray.600"
                                    as={clientData?.phone !== editedData[1] ? "s" : "a"}
                                >
                                    {phone_format(clientData?.phone ?? "")}
                                </Text>
                                {clientData?.phone !== editedData[1] && (
                                    <>
                                        {clientData?.phone !== "" && (
                                            <ArrowRightIcon />
                                        )}
                                        <Text
                                            fontSize="1rem"
                                            color="gold.7"
                                        >
                                            {editedData[1]}
                                        </Text>
                                    </>
                                )}
                            </Flex>
                        </Stack>

                        <Stack w="100$">
                            <Text 
                                fontSize=".8rem"
                                color="gray.400"
                            >
                                Email
                            </Text>
                            <Flex 
                                w="100%"
                                align="center"
                                gap="2"
                                flexWrap="wrap"
                            >
                                <Text
                                    fontSize="1rem"
                                    color="gray.600"
                                    as={clientData?.email !== editedData[2] ? "s" : "a"}
                                >
                                    {clientData?.email}
                                </Text>
                                {clientData?.email !== editedData[2] && (
                                    <>
                                        {clientData?.email !== "" && (
                                            <ArrowRightIcon />
                                        )}
                                        <Text
                                            fontSize="1rem"
                                            color="gold.7"
                                        >
                                            {editedData[2]}
                                        </Text>
                                    </>
                                )}
                            </Flex>
                        </Stack>

                        <Stack>
                            <Text 
                                fontSize=".8rem"
                                color="gray.400"
                            >
                                Email financeiro
                            </Text>
                            <Flex 
                                w="100%"
                                align="center"
                                gap="2"
                                flexWrap="wrap"
                            >
                                <Text
                                    fontSize="1rem"
                                    color="gray.600"
                                    as={clientData?.alternativeEmail !== editedData[3] ? "s" : "a"}
                                >
                                    {clientData?.alternativeEmail}
                                </Text>
                                {clientData?.alternativeEmail !== editedData[3] && (
                                    <>
                                        {clientData?.alternativeEmail !== "" && (
                                            <ArrowRightIcon />
                                        )}
                                        <Text
                                            fontSize="1rem"
                                            color="gold.7"
                                        >
                                            {editedData[3]}
                                        </Text>
                                    </>
                                )}
                            </Flex>
                        </Stack>

                        <Text 
                            fontSize=".8rem"
                            color="gray.400"
                        >
                            *Novas notificações de cobranças passarão a ser enviadas para esses novos contatos inseridos.
                        </Text>
                    </Flex>
                </ModalBody>
                <Flex 
                    w="100%" 
                    borderTop="1px solid" 
                    borderColor="gray.50"
                    p="2"
                    justifyContent="space-between"
                    gap="2"
                >
                    <Button  
                        variant="secondary"
                        onClick={onClose}
                    >
                        Voltar para edição
                    </Button>
                    <Button 
                        variant="primary"
                        onClick={onConfirm}
                    >
                        Confirmar
                    </Button>
                </Flex>
            </ModalContent>
        </Modal>
    )
}
