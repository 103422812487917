import { Flex, Box, Badge, Icon, Link } from "@chakra-ui/react";
import { Tabs, Table, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  historyColumns,
  manualsColumns,
  scheduledColumns,
} from "@/layouts/TableColumns";
import { useCharge, useSubscription } from "@/hooks";
import {
  Charge,
  ChargePaginator,
  Subscription,
  SubscriptionPaginator,
} from "@/models";
import { ClientData } from "@/models";
import { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Api } from "@/services";
import {
  formatCurrency,
  getColorBadgeStatus,
  getPeriodicity,
  getStatus,
  formatDate,
  showToast,
} from "@/utils";
import { useNavigate } from "react-router-dom";

interface ChargeHistoryProps {
  client: ClientData;
}

const ChargeHistory = ({ client }: ChargeHistoryProps) => {
  const [data, setData] = useState({} as SubscriptionPaginator);
  const [chargeData, setChargeData] = useState({} as ChargePaginator);
  const [manualsChargeData, setManualChargeData] = useState(
    {} as ChargePaginator
  );
  const [getTab, setGetTab] = useState("SUBSCRIPTIONS");
  const [getInternalTabReceived, setGetInternalTabReceived] =
    useState("HISTORY");
  const [chargeColumns, setChargeColumns] = useState(historyColumns);
  const { TabPane } = Tabs;
  const { getSubscriptions } = useSubscription();
  const { getCharges } = useCharge();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  async function getChargesData(type?: string) {
    if (!client || !client?.document) return;
    try {
      
      if(user?.roles?.includes("SELLER_MASTER") ||
      user?.roles?.includes("SELLER_ADMIN") ||
      user?.roles?.includes("SELLER_BACKOFFICE") ||
      user?.roles?.includes("SELLER_CHARGES")) {
        const response = await getCharges(
          {
            chargeFilterConfig: type ?? "ALL",
            customerDocument: client?.document,
          },
          {
            size: 10000,
            page: 0,
          }
        );
        setChargeData(response.data);
      }
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }
  let tabs = [];
  if(user?.roles?.includes("SELLER_MASTER") ||
    user?.roles?.includes("SELLER_ADMIN") ||
    user?.roles?.includes("SELLER_BACKOFFICE")) {
      tabs.push({
          tab: "Assinaturas",
          key: "SUBSCRIPTIONS",
          status: "SUBSCRIPTIONS",
        },
        {
          tab: "Recebíveis",
          key: "RECEIVEDS",
          status: "RECEIVEDS",
        }
      )
  }
  if(user?.roles?.includes("SELLER_SUBSCRIPTIONS")) {
    tabs.push({
      tab: "Assinaturas",
      key: "SUBSCRIPTIONS",
      status: "SUBSCRIPTIONS",
    })
  }
  if(user?.roles?.includes("SELLER_CHARGES")) {
    tabs.push({
      tab: "Recebíveis",
      key: "RECEIVEDS",
      status: "RECEIVEDS",
    })
  }

  const receivedTabs = [
    {
      tab: "Histórico",
      key: "HISTORY",
      status: "HISTORY",
    },
    {
      tab: "Agendados",
      key: "SCHEDULED",
      status: "SCHEDULED",
    },
  ];

  async function loadData() {
    if (!client || !client?.document) return;
      if(user?.roles?.includes("SELLER_MASTER") ||
      user?.roles?.includes("SELLER_ADMIN") ||
      user?.roles?.includes("SELLER_BACKOFFICE") ||
      user?.roles?.includes("SELLER_SUBSCRIPTIONS")) {
        const { data } = await getSubscriptions(
          { customerDocument: client?.document },
          {
            size: 10000,
            page: 0,
          }
        );
        setData(data);
      }
    
  }
  useEffect(() => {
    loadData();
    getChargesData();
  }, [client?.document]);

  const columns: ColumnsType<Subscription | Charge> = [
    {
      key: "startDate",
      title: "Contratação",
      dataIndex: "startDate",
      render: (startDate) => formatDate(startDate),
    },

    {
      key: "titlePlan",
      title: "Plano",
      dataIndex: "titlePlan",
    },

    {
      key: "type",
      title: "Periodicidade ",
      dataIndex: ["basicValue", "type"],
      render: (type) => getPeriodicity(type),
    },

    {
      key: "value",
      title: "Valor/mês",
      dataIndex: getTab === "SUBSCRIPTIONS" ? ["basicValue", "value"] : "value",
      render: (value) => formatCurrency({ value }),
    },

    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (status) =>
        status ? (
          <Badge fontSize="1rem" variant={getColorBadgeStatus(status)}>
            {getStatus(status)}
          </Badge>
        ) : (
          "-"
        ),
    },
    {
      key: "buttonOptions",
      title: "",
      render: (text, record) => (
        <Box p="0" w="80px" h="30px">
          <Flex
            w="100px"
            h="30px"
            align="center"
            justify="space-between"
            className="rowAction"
          >
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="40px"
              transition="all .2s"
              _hover={{
                transition: "all .2s",
                bgColor: "white",
              }}
            >
              <Link href={`/recorrencias/${record.uuid}`} h="20px">
                <a>
                  <Icon as={FaLongArrowAltRight} boxSize="20px" />
                </a>
              </Link>
            </Flex>
          </Flex>
        </Box>
      ),
    },
  ];

  async function handleChangeTab(tabOption: string) {
    setGetTab(tabOption);
  }

  async function handleChangeReceivedsTab(tabOption: string) {
    if (tabOption === "HISTORY") {
      getChargesData("ALL");
      setChargeColumns(historyColumns);
      setGetInternalTabReceived(tabOption);
      return;
    }
    if (tabOption === "SCHEDULED") {
      getChargesData("SCHEDULED");
      setChargeColumns(scheduledColumns);
      setGetInternalTabReceived(tabOption);
      return;
    }
  }
  return (
    <Tabs
      defaultActiveKey=""
      className="tabs-custom"
      onChange={handleChangeTab}
    >
      {tabs.map(({ key, tab }) => (
        <TabPane tab={tab} key={key}>
          {key === "SUBSCRIPTIONS" && (
            <Table<Subscription>
              columns={columns}
              dataSource={data.content}
              rowKey={(record) => record.uuid}
              onRow={(record) => {
                return {
                  onClick: () => navigate(`/recorrencias/${record.uuid}`),
                };
              }}
              locale={{ emptyText: "Sem dados de assinaturas" }}
            />
          )}
          {key === "RECEIVEDS" && (
            <Tabs
              defaultActiveKey=""
              className="tabs-custom"
              onChange={handleChangeReceivedsTab}
            >
              {receivedTabs.map(({ key, tab }) => (
                <TabPane tab={tab} key={key}>
                  <Table<Charge>
                    columns={chargeColumns}
                    dataSource={
                      getInternalTabReceived === "MANUALS"
                        ? manualsChargeData.content
                        : chargeData.content
                    }
                    rowKey={(record) => record.uuid}
                    onRow={(record) => {
                      if (getInternalTabReceived === "HISTORY")
                        return {
                          onClick: () =>
                            navigate(`/cobrancas-historico/${record?.uuid}`),
                        };
                      if (getInternalTabReceived === "SCHEDULED")
                        return {
                          onClick: () =>
                            navigate(`/cobrancas-agendadas/${record?.uuid}`),
                        };
                    }}
                    locale={{ emptyText: "Sem dados de cobranças" }}
                  />
                </TabPane>
              ))}
            </Tabs>
          )}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default ChargeHistory;
