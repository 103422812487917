import style from "./styles.module.scss";

export function LoadingSpinner() {
    return (
        <div className={style.spinner}>
            <div className={style.spinner__circle}>
                <div className={style.spinner__circleGradient}></div>
                <div className={style.spinner__circleInner}></div>
            </div>
        </div>
    )
}