import { useEffect, useState } from "react";
import {
  Flex,
  InputGroup,
  Spacer,
  IconButton,
  Switch,
  Stack,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Input, FormModal } from "@/components";
import { useIntegration, useSettings } from "@/hooks";
import { SellerWebhooks } from "@/models";
import { showToast, copyTextToClipboard } from "@/utils";
import { DuplicateIcon } from "@/styles/icons";
import { EditIcon } from "@/styles/icons/EditIcon";
import { TrashIcon } from "@/styles/icons/TrashIcon";

interface WebhookItemProps {
  webhook: SellerWebhooks;
}

const webhookCreateScheama = yup.object().shape({
  title: yup.string().required("Preenchimento obrigatório!"),
  url: yup
    .string()
    .matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      "Entre com uma url correta"
    )
    .required("Preenchimento obrigatório!"),
  subscriptions: yup.boolean(),
  order: yup.boolean(),
});

export const WebhookItem = ({ webhook }: WebhookItemProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { handleModal, isLoading, setIsLoading } = useIntegration();
  const { putSellerApiWebhook } = useSettings();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(webhookCreateScheama),
  });

  useEffect(() => {
    setValue("subscriptions", webhook.domains.includes("SUBSCRIPTION"));
    setValue("order", webhook.domains.includes("ORDER"));
  }, [webhook]);
  const getTitleValue = watch("title");
  const getUrlValue = watch("url");
  const getSubscriptionsValue = watch("subscriptions");
  const getOrderValue = watch("order");
  const onSubmit = handleSubmit(
    async (
      data: Pick<SellerWebhooks, "title" | "url" | "subscriptions" | "order">
    ) => {
      try {
        setIsLoading(true);
        let domains = [
          data.subscriptions ? "SUBSCRIPTION" : null,
          data.order ? "ORDER" : null,
        ];
        await putSellerApiWebhook(webhook.uuid, {
          title: data.title,
          url: data.url,
          domains: domains.filter((domain) => domain !== null),
          active: webhook.active,
        });
        setValue("textTilte", data.title);
        setValue("textUrl", data.url);
        setValue("subscriptions", data.subscriptions);
        setValue("order", data.order);
        showToast({
          message: "Webhook atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        error?.response?.data?.errors.map((error) => {
          showToast({
            type: "error",
            message: `${error?.code} - ${error?.description}`
          })
        });
        setValue("textTilte", webhook.title);
        setValue("textUrl", webhook.url);
        setValue("subscriptions", webhook.domains.includes("SUBSCRIPTION"));
        setValue("order", webhook.domains.includes("ORDER"));
      } finally {
        setIsEditModalOpen(!isEditModalOpen);
        setIsLoading(false);
      }
    }
  );

  return (
    <>
      <FormModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(!isEditModalOpen);
        }}
        title="Editar Webhook"
        handleConfirmModal={onSubmit}
        isSubmitting={isLoading}
        confirmationButtonText="Salvar"
        disableWhenIsFormEmpty={
          !getTitleValue ||
          !getUrlValue ||
          (!getSubscriptionsValue && !getOrderValue)
        }
      >
        <Stack spacing={4}>
          <Input
            type="text"
            label="Nome"
            name="title"
            defaultValue={webhook.title}
            register={{ ...register("title") }}
            errors={errors?.title}
            borderRadius="2px"
          />
          <Input
            type="text"
            label="Url"
            name="url"
            defaultValue={webhook.url}
            register={{ ...register("url") }}
            errors={errors?.url}
            borderRadius="2px"
          />
          <Text color="gray.400" fontSize=".8rem">
            Domínios
          </Text>
          <Checkbox
            maxW="80px"
            name="subscriptions"
            defaultChecked={webhook.domains.includes("SUBSCRIPTION")}
            {...register("subscriptions")}
          >
            Assinaturas
          </Checkbox>
          <Checkbox
            maxW="180px"
            name="orders"
            defaultChecked={webhook.domains.includes("ORDER")}
            {...register("order")}
          >
            Cobrança Pontual
          </Checkbox>
        </Stack>
      </FormModal>

      <Flex
        w="100%"
        bgColor="gray.25"
        p="8px"
        align="center"
        gap={6}
        borderRadius="4px"
      >
        <InputGroup maxW="280px">
          <Input
            type="text"
            name="textTitle"
            register={{ ...register("textTilte") }}
            defaultValue={webhook.title}
            border="none"
            isDisabled
            color="black"
            _disabled={{
              color: "black",
              bgColor: "transparent",
            }}
          />
        </InputGroup>
        <Spacer />
        <Flex
          maxW="360px"
          w="100%"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="4px"
        >
          <Input
            name="textUrl"
            register={{ ...register("textUrl") }}
            defaultValue={webhook.url}
            isReadOnly
            bg="transparent"
            border="none"
            type="text"
            p="5px"
            fontSize="16px"
            w="100%"
            _focus={{
              border: "none",
            }}
            _hover={{
              cursor: "text",
            }}
          />
          <IconButton
            aria-label="copy to clipboard"
            bgColor="transparent"
            icon={<DuplicateIcon />}
            onClick={() =>
              copyTextToClipboard(
                webhook.url,
                "Url copiado para a área de transferência!"
              )
            }
            _hover={{
              cursor: "pointer",
            }}
          />
        </Flex>
        <IconButton
          aria-label="edit title"
          bgColor="transparent"
          icon={<EditIcon />}
          onClick={() => {
            setIsEditModalOpen(!isEditModalOpen);
          }}
          _hover={{
            bgColor: "transparent",
          }}
        />
        <IconButton
          aria-label="delete token"
          bgColor="transparent"
          icon={<TrashIcon />}
          onClick={() => handleModal({ type: "deleteWebhook", webhook })}
          _hover={{
            bgColor: "transparent",
          }}
        />
        <Switch
          isChecked={webhook.active}
          onChange={() => handleModal({ type: "changeActiveWebhook", webhook })}
        />
      </Flex>
    </>
  );
};
