import { DownloadIcon } from "@/styles/icons/DownloadIcon";
import { 
    Popover, 
    PopoverArrow,  
    PopoverContent, 
    PopoverTrigger, 
    IconButton,
    Flex,
    Tooltip,
} from "@chakra-ui/react";
import { ReactNode } from "react"

interface DownloadButtonProps {
    children?: ReactNode;
    disabled?: boolean;
}

const DownloadButton = ({ children, disabled = false }: DownloadButtonProps ) => {
    return (
        <Popover >
            <PopoverTrigger>
                <IconButton
                    w="30px"
                    h="35px"
                    aria-label='Download nota fiscal'
                    bgColor="transparent"
                    onClick={(e) => { 
                        e.stopPropagation() 
                    }}
                    disabled={disabled}
                    icon={ 
                        <DownloadIcon 
                        opacity="0.45"
                        />
                    }
                />
            </PopoverTrigger>
            <PopoverContent p="2" maxW="180px">
                <PopoverArrow />
                <Flex
                    gap="2"
                    align="center"
                    justify="center"
                    direction="column"
                >
                    {children}
                </Flex>
            </PopoverContent>
        </Popover>
    )
}

export { DownloadButton }