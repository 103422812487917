import { Image } from "@chakra-ui/react";

const LateTotalIcon = () => {
  return(
    <Image
      maxW="20px"
      src="/images/icons/lateTotalIcon.svg"
      transition="filter .2s"
    />
  )
}

export { LateTotalIcon }