import { useEffect, useState } from "react";
import { SellerNotificationPanel } from "@/models";
import { showToast } from "@/utils";
import { useNotificationPanel } from "@/hooks";

export function UseInfiniteScroll() {
  const [data, setData] = useState<SellerNotificationPanel[]>();
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [notificationsQuantity, setNotificationsQuantity] = useState(10);
  const [hasNoMoreNotifications, setHasNoMoreNotifications] = useState(false);

  const { getNotifications, getQuantityUnreadNotifications } =
    useNotificationPanel();

  async function handleGetNotifications() {
    try {
      const response = await getNotifications(notificationsQuantity);
      setNotificationsQuantity(notificationsQuantity + 2);
      setData(response.data.content);
      if (notificationsQuantity > response.data.content.length) {
        setHasNoMoreNotifications(true);
      }
      setUnreadNotifications(0);
    } catch (error) {
      showToast({
        type: "error",
        message: "Falha no carregamento das notificações.",
      });
    }
  }

  async function handleGetUnreadNotifications() {
    try {
      const { data } = await getQuantityUnreadNotifications();
      setUnreadNotifications(data);
    } catch (error) {
      showToast({
        type: "error",
        message: "Falha no carregamento das notificações.",
      });
    }
  }

  return {
    data,
    unreadNotifications,
    notificationsQuantity,
    handleGetUnreadNotifications,
    handleGetNotifications,
    hasNoMoreNotifications,
  };
}
