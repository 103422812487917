import { formatCurrency, formatDate, getInitials } from "@/utils";
import { Badge, Flex, Text } from "@chakra-ui/react";
import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface ChargeMessageProps {
  uuid: string;
  buyerName: string;
  paidDate?: string;
  expirationDate: string;
  amount: number;
  status: string;
}

export function ChargeMessage({
  uuid,
  buyerName,
  paidDate,
  expirationDate,
  amount,
  status
}: ChargeMessageProps) {
  const [chargeShape, setChargeShape] = useState<ReactElement>();
  const formattedAmount = formatCurrency({value: amount});
  const navigate = useNavigate();

  function setChargeMessage() {
    if (status === "PAID") {
      setChargeShape(
        <Text
          color="blue.dayBreak"
          textAlign="center"
          bgColor="blue.10"
          fontSize="14px"
          p=".1rem"
        >
          {paidDate ? `Paga · ${formatDate(paidDate, true)}` : "Paga"}
        </Text>
      );
    }

    if (status === "LATE") {
      setChargeShape(
        <Text
          color="red.dust"
          bgColor="red.dustLigth"
          fontSize="14px"
          p=".1rem"
        >
          Em atraso
        </Text>
      );
    }

    if (status === "SCHEDULED") {
      setChargeShape(
        <Flex>
          <Text p=".1rem" fontSize="14px" color="gray.300"> A receber </Text>
        </Flex>
      );
    }
  }

  useEffect(() => {
    setChargeMessage();
  }, []);

  return (
    <>
      <Flex
        w="100%"
        p="1rem 1rem"
        justify="space-between"
        align="center"
        transition="all .2s"
        _hover={{
          transition: "all .2s",
          bgColor: "gray.25"
        }}
        cursor="pointer"
        onClick={() => navigate(`/cobrancas-historico/${uuid}`)}
      >
        <Flex w="50%">
          <Flex align="center" marginRight=".5rem">
            <Badge
              fontSize="1rem"
              minWidth="35px"
              minHeight="35px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              colorScheme="yellow"
              color="gray.800"
            >
              {getInitials(buyerName)}
            </Badge>
          </Flex>
          <Flex 
            flexDir="column"
            w="200px"
          >
            <Text 
              color="black"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              maxW="80%"
            >
              {buyerName}
            </Text>
            <Text color="gray.400" fontSize="12px">venc. {formatDate(expirationDate, true)}</Text>
          </Flex>
        </Flex>
        <Text w="25%" color="black" fontSize="15px">{formattedAmount}</Text>
        <Flex
          w="25%"
          align="center"
          justify="flex-start"
        >
          {chargeShape}
        </Flex>
      </Flex>
    </>
  );
}