import { InfoIconNotFilled } from "@/styles/icons/InfoIconNotFilled";
import { Flex, Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipsProps } from "@chakra-ui/react"
interface TooltipProps extends ChakraTooltipsProps {
    iconSize?: number;
    color?: string;
}
export function Tooltip( {iconSize = 10, color, ...rest}: TooltipProps ) {
    return(
        <ChakraTooltip {...rest}>
            <Flex>
                <InfoIconNotFilled 
                    size={iconSize} 
                    color={color}
                />
            </Flex>
        </ChakraTooltip>
    )
}