import axios from "axios";


function getAPIReport() {
    const reportApi = axios.create({
        baseURL: `${import.meta.env.VITE_REPORT_API_URL}`,
        headers: {
            "x-token-key": `${import.meta.env.VITE_REPORT_TOKEN}`,
            "Content-Type": "application/json"
        }
    })

    return reportApi;

}
export default getAPIReport;
