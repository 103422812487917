import { Plan } from "@/models/Plan";
import { Api } from "@/services";

export function usePlan() {
  const baseUrl = "/plans";

  const createPlan = (plan: Plan) => Api.post(baseUrl, plan);

  const getPlan = (idPlan: number) => Api.get<Plan>(`${baseUrl}/${idPlan}`);

  const getPlans = () => Api.get<Plan[]>(baseUrl);

  const updatePlan = (idPlan: number, plan: Plan) =>
    Api.put(`${baseUrl}/${idPlan}`, plan);

  const activatePlan = (idPlan: number, active: boolean) =>
    Api.patch(`${baseUrl}/${idPlan}`, { active });

  return { createPlan, getPlan, updatePlan, activatePlan, getPlans };
}
