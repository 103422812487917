import { Image } from "@chakra-ui/react";

const EditIcon = () => {
    return(
        <Image
            cursor="pointer"
            src="/images/icons/pencil.svg"
            transition="filter .2s"
            _hover={{
                transition: "filter .2s",
                filter: "brightness(.3)",
            }}
        />
    )
}

export { EditIcon }