import { useContext, useState, useEffect } from "react";
import { Button, Flex, Heading, Image, Link, Stack } from "@chakra-ui/react";
import { Resolver, useForm } from "react-hook-form";
import { Input } from "@/components";
import { AuthContext } from "@/contexts";
import { BannerLateral } from "@/layouts";
import { getApplicationName, showToast } from "@/utils";

type FormValues = {
  email: string;
  pass: string;
};

const resolver: Resolver<FormValues> = async (values) => {
  return {
    values: values.email ? values : {},
    errors: !values.email
      ? {
          email: {
            type: "required",
            message: "E-mail é um campo obrigatório.",
          },
          pass: {
            type: "required",
            message: "Senha é um campo obrigatório.",
          },
        }
      : {},
  };
};

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver });

  const { signIn } = useContext(AuthContext);
  const applicationName = getApplicationName({});
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async (data: any) => {
    setLoading(true);
    try {
     const response = await signIn(data);
   
    } catch (error) {

    } finally {
      setLoading(false);
    }
    
  });

  useEffect(() => {
    localStorage.removeItem("barte-auth-token");
    localStorage.removeItem("barte-user-info");
    localStorage.removeItem("barte-features-list-user");
  }, []);

  return (
    <Flex flexDir={["column", "column", "column", "row"]}>
      <Flex
        align="center"
        w={["100%", "100%", "100%", "50%"]}
        h="100vh"
        justify="center"
      >
        <Flex
          as="form"
          flexDir="column"
          align="flex-start"
          justify="center"
          h="100vh"
          w="380px"
          onSubmit={onSubmit}
          py="4"
        >
          <Image src={`/images/commons/logo-${import.meta.env.VITE_APPLICATION_NAME}.svg`} width="180px" />
          <Flex w="100%" mt="8">
            <Heading as="h3" mb="40px" w="50%">
              Login
            </Heading>
            {/* <Flex
              color="gray.400"
              w="50%"
              flexDirection="column"
              justify="flex-start"
              align="flex-end"
            >
              Primeiro acesso?
              <Link href="/cadastro" textDecoration="underline">
                Criar conta
              </Link>
            </Flex> */}
          </Flex>
          <Stack w="100%">
            <Input
              label="Email"
              type="text"
              name="email"
              placeholder={"Insira seu email"}
              className="mb-3"
              register={{ ...register("email") }}
              errors={errors?.email}
              aria-label={"Insira seu email"}
              aria-invalid={errors.email ? "true" : "false"}
              fontSize="1rem"
              labelColor="black"
              mb="30px"
            />

            <Input
              label={"Senha"}
              type="password"
              name="pass"
              placeholder={"Insira sua senha"}
              className="mb-4"
              register={{ ...register("pass") }}
              errors={errors?.pass}
              aria-label={"Insira sua senha"}
              aria-invalid={errors.pass ? "true" : "false"}
              fontSize="1rem"
              labelColor="black"
            />
            <Link
              href="/redefinir-senha"
              textAlign="right"
              textDecoration="underline"
            >
              Esqueci a senha
            </Link>

            <Button
              isLoading={loading}
              type="submit"
              variant="outline"
              fontSize="1rem"
              py="1rem"
              w="100px"
              mt="40px!important"
            >
              Entrar
            </Button>
          </Stack>
        </Flex>
      </Flex>

      <BannerLateral />
    </Flex>
  );
};

export default Login;
