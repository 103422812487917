import styled, { css } from "styled-components";

export const Container = styled.div`
    p {
      color: #3D485C;
    }
  .list-data {
    ul {
      list-style-type: none;
      margin: 2.3rem 3.3rem;
      li {
        font-weight: 600;
        color: #3D485C;
      }
    }
  }
  .chargeback-documents {
    margin-top: 2.3rem;
    ol {
      margin: 2.3rem 3.3rem;
      li {
        font-weight: 400;
        color: #3D485C;
      }
    }
    .italic-text {
      margin-bottom: 2.3rem;
      font-style: italic;
    }
    .final-content {
      margin-top: 2.3rem;
    }
  }
`