import {
  Flex,
  Heading,
  Text,
  Badge,
  Button as ChakraButton,
  Divider,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Alert, BackButton, Input } from "@/components";
import { WithAuth } from "@/hoc";
import { useClient } from "@/hooks";
import { ClientData, ClientsAddressData } from "@/models/Clients";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  getInitials,
  phone_format,
  cpf_cnpj,
  showToast,
  clearMask,
} from "@/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import ChargeHistory from "./_charges-history";
import { PhoneInput } from "@/components/Forms/PhoneInput";
import { EditConfirmationModal } from "./_editConfirmationModal";

const ClientDetails = () => {
  const infoValidationSchema = Yup.object().shape({
    name: Yup.string().required("Peenchimento obrigatório!"),
    countryCode: Yup.string().required(),
    phone: Yup.string().required("Peenchimento obrigatório!"),
    email: Yup.string()
      .email("Digite um e-mail válido!")
      .required("O campo e-mail é obrigatório!"),
  });
  
  const addressValidationSchema = Yup.object().shape({
    zipCode: Yup.string().required("Peenchimento obrigatório!"),
    street: Yup.string().required("Peenchimento obrigatório!"),
    district: Yup.string().required("Peenchimento obrigatório!"),
    city: Yup.string().required("Peenchimento obrigatório!"),
    state: Yup.string().required("Peenchimento obrigatório!"),
    country: Yup.string().required("Peenchimento obrigatório!"),
  });

  const [editButtonState, setEditButtonState] = useState(false);
  const [cancelAlertState, setCancelAlertState] = useState(false);
  const [editAlertState, setEditAlertState] = useState(false);
  const [editAddressButtonState, setEditAddressButtonState] = useState(false);
  const [isCepLoading, setIsCepLoading] = useState(false);
  const [data, setData] = useState<ClientData>({} as ClientData);
  const [loading, setLoading] = useState(false);
  const { putClient, getClient, patchClientAddress, consultCep } = useClient();
  const [initials, setInitials] = useState("");
  const [formattedDocument, setFormattedDocument] = useState("");
  const [validationSchema, setValidationSchema] = useState({} as any);
  const cancelRef = useRef();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const cep = watch("zipCode");
  const editedValues = watch(["name", "phone", "email", "alternativeEmail"]);

  useEffect(() => {
    if (id === undefined) return;
    GetClientData();
  }, [id]);

  async function GetClientData() {
    try {
      const response = await getClient(Number(id));
      setFormattedDocument(response.data.document);
      setData({
        ...response.data,
        phone: phone_format(response.data.phone),
      });
      setInitials(getInitials(response.data.name));
      Object.keys(response.data).map(value => {
        if(value === "document") return
        if(typeof response.data[value] === "object") {
          Object.keys(response.data[value]).map(addressValue => {
            setValue(addressValue, response.data[value][addressValue]);
          })
        }
        if(value === "phone") return setValue(value, phone_format(response.data[value]));
        setValue(value, response.data[value]);
      });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  async function handleCancelEdit() {
    setCancelAlertState(false);
    setEditAddressButtonState(false);
    setEditButtonState(false);
    GetClientData();
    setValidationSchema({} as any);
  }
  async function handleGetCep() {
    try {
        clearErrors("zipCode");
        setIsCepLoading(true);
        const { data } = await consultCep(clearMask(cep));
        setValue("street", data.logradouro);
        setValue("district", data.bairro);
        setValue("city", data.localidade);
        setValue("state", data.uf);
        setValue("complement", data.complemento);
        setValue("country", "BR");
    } catch {
        if(cep.length !== 10) return setError("zipCode", { type: "custom", message:"Cep inválido."})
    } finally {
        setIsCepLoading(false);
    }
}

  const handleUpdateClient: SubmitHandler<ClientData> = async (values) => {
    setLoading(true);
    const client = {
      document: clearMask(data.document),
      name: values.name,
      email: values.email,
      phone: clearMask(values.phone),
      countryCode: values.countryCode,
      alternativeEmail: values.alternativeEmail,
    };
    try {
      await putClient(data.id, client);
      showToast({
        message: "Cliente editado com sucesso!",
        type: "success",
      });
    } catch (error) {
      error.response.data.errors.forEach((item) => {
        showToast({
          message: item.description,
          type: "error",
        });
      });
    } finally {
      setLoading(false);
      setEditButtonState(!editButtonState);
      setEditAlertState(false)
      GetClientData();
    }
    
  };

  const handleUpdateClientAddress: SubmitHandler<ClientsAddressData> = async (values) => {
    setLoading(true);
    if(Object.keys(errors)?.length > 0) return;
    const clientAddress = {
      zipCode: clearMask(values.zipCode),
      street: values.street,
      district: values.district,
      number: Number(values.number),
      complement: values.complement,
      city: values.city,
      state: values?.state?.toLocaleUpperCase(),
      country: values.country,
    }
    try {
      await patchClientAddress(data.id, clientAddress);
      showToast({
        message: "Endereço editado com sucesso!",
        type: "success",
      });
    } catch (error) {
      error.response.data.errors.forEach((item) => {
        showToast({
          message: item.description,
          type: "error",
        });
      });
    } finally {
      setEditAddressButtonState(!editAddressButtonState);
      setLoading(false);
      GetClientData();
    }
  }

  return (
    <>
      <Alert
        closeButtonText="Não"
        confirmButtonText="Cancelar"
        isConfirmAlert={false}
        isOpen={cancelAlertState}
        onConfirm={handleCancelEdit}
        onClose={() => setCancelAlertState(!cancelAlertState)}
        title="Tem certeza que deseja cancelar?"
        messageBody="Todas as alterações serão perdidas"
        leastDestructiveRef={cancelRef}
      />
      <EditConfirmationModal
        isOpen={editAlertState}
        onClose={() => setEditAlertState(false)}
        onConfirm={handleSubmit(handleUpdateClient)}
        clientData={data}
        editedData={editedValues}
      />
      <Flex
        w="100%"
        flexDir="column"
      >
        <Flex align="center">
          <BackButton />
          <Text marginBottom=".5rem">Voltar</Text>
        </Flex>
        <Divider />
        <Flex marginTop="2rem">
          <Flex w="100%" maxW="995px" justify="space-between">
            <Flex align="center" gridGap="2" justify="center">
              <Badge
                fontSize="1.6rem"
                minWidth="35px"
                minHeight="35px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                colorScheme="yellow"
                color="gray.800"
              >
                {initials}
              </Badge>
              <Heading fontSize="1.6rem">{data.name}</Heading>
            </Flex>
          </Flex>
        </Flex>

        <Divider margin="2rem 0" />

        <Flex 
          as="form"
          onSubmit={handleSubmit(handleUpdateClient)}
          flexDirection="column"
        >
          <Flex
            align="center"
            justify="space-between"
          >
            <Heading
              fontSize="1.2rem"
            >
              Identificação e contato
            </Heading>
            {!editButtonState && (
                <ChakraButton
                  type="button"
                  variant="outline"
                  borderRadius="2px"
                  onClick={() => {
                    setEditButtonState(!editButtonState);
                    setValidationSchema(infoValidationSchema);
                  }}
                  disabled={editAddressButtonState}
                >
                  Editar
                </ChakraButton>
            )}

            {editButtonState && (
              <Flex gap="2">
                <ChakraButton 
                  onClick={() => { setCancelAlertState(!cancelAlertState) }} 
                  variant="outline" 
                  isLoading={loading}
                  borderRadius="2px"
                >
                  Cancelar
                </ChakraButton>
                <ChakraButton  
                  variant="primary" 
                  isLoading={loading}
                  onClick={() => setEditAlertState(!editAlertState)}
                >
                  Concluir
                </ChakraButton>
              </Flex>
            )}
          </Flex>
          <Grid
            w="100%"
            justifyContent="space-between"
            templateColumns="repeat(3, 1fr)"
            gap="3rem"
            mt="2"
          >
            <GridItem>
              <Input
                type="text"
                label="Nome"
                labelColor="black"
                name="name"
                register={{ ...register("name") }}
                errors={errors?.name}
                isDisabled={!editButtonState}
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0,
                }}
              />
            </GridItem>

            <GridItem>
              <Input
                type="text"
                label="Documento"
                labelColor="black"
                defaultValue={formattedDocument}
                name="document"
                register={{ ...register("document") }}
                errors={errors?.document}
                isDisabled
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0,
                }}
              />
            </GridItem>

            <GridItem>
              <PhoneInput
                type="text"
                label="Telefone"
                labelColor="black"
                name="phone"
                mask="cellphone"
                register={{ ...register("phone") }}
                defaultCountryCodeValue={data?.countryCode ?? "+55"}
                setValue={setValue}
                errors={errors?.phone}
                isDisabled={!editButtonState}
                padding="0"
              />
            </GridItem>

            <GridItem>
              <Input
                type="text"
                label="Email"
                labelColor="black"
                name="email"
                register={{ ...register("email") }}
                errors={errors?.email}
                isDisabled={!editButtonState}
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0, 
                }}
              />
            </GridItem>

            <GridItem>
              <Input
                type="text"
                label="Email financeiro"
                labelColor="black"
                name="alternativeEmail"
                register={{ ...register("alternativeEmail") }}
                errors={errors?.alternativeEmail}
                isDisabled={!editButtonState}
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0, 
                }}
              />
            </GridItem>
          </Grid>
        </Flex>

        <Divider margin="2rem 0" />
        <Flex 
          as="form"
          onSubmit={handleSubmit(handleUpdateClientAddress)}
          flexDirection="column"
        >
          <Flex
            align="center"
            justify="space-between"
            
          >
            <Heading
              fontSize="1.2rem"
            >
              Endereço
            </Heading>

            {!editAddressButtonState && (
                  <ChakraButton
                    type="button"
                    variant="outline"
                    borderRadius="2px"
                    onClick={() => {
                      setEditAddressButtonState(!editAddressButtonState);
                      setValidationSchema(addressValidationSchema);
                    }}
                    disabled={editButtonState}
                  >
                    Editar
                  </ChakraButton>
            )}

            {editAddressButtonState && (
              <Flex gap="2">
                <ChakraButton 
                  onClick={() => { setCancelAlertState(!cancelAlertState) }} 
                  variant="outline" 
                  isLoading={loading}
                  borderRadius="2px"
                >
                  Cancelar
                </ChakraButton>
                <ChakraButton 
                  type="submit" 
                  variant="primary" 
                  isLoading={loading}
                >
                  Concluir
                </ChakraButton>
              </Flex>
            )}
          </Flex>

          <Grid
            w="100%"
            justifyContent="space-between"
            templateColumns="repeat(3, 1fr)"
            gap="3rem"
            mt="2"
          >
            <GridItem>
              <Input
                type="text"
                label="CEP"
                labelColor="black"
                name="zipCode"
                register={{ ...register("zipCode") }}
                errors={errors?.zipCode}
                isDisabled={!editAddressButtonState || isCepLoading}
                onBlur={handleGetCep}
                mask="cep"
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0,
                }}
              />
            </GridItem>

            <GridItem>
              <Input
                type="text"
                label="Rua/Avenida"
                labelColor="black"
                name="street"
                register={{ ...register("street") }}
                errors={errors?.street}
                isDisabled={!editAddressButtonState || isCepLoading}
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0,
                }}
              />
            </GridItem>

            <GridItem>
              <Input
                type="text"
                label="Número"
                labelColor="black"
                name="number"
                register={{ ...register("number") }}
                errors={errors?.number}
                isDisabled={!editAddressButtonState || isCepLoading}
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0, 
                }}
              />
            </GridItem>

            <GridItem>
              <Input
                type="text"
                label="Complemento"
                labelColor="black"
                name="complement"
                register={{ ...register("complement") }}
                errors={errors?.complement}
                isDisabled={!editAddressButtonState || isCepLoading}
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0, 
                }}
              />
            </GridItem>

            <GridItem>
              <Input
                type="text"
                label="Bairro"
                labelColor="black"
                name="district"
                register={{ ...register("district") }}
                errors={errors?.district}
                isDisabled={!editAddressButtonState || isCepLoading}
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0, 
                }}
              />
            </GridItem>

            <GridItem>
              <Input
                type="text"
                label="Cidade"
                labelColor="black"
                name="city"
                register={{ ...register("city") }}
                mask="onlyLetters"
                errors={errors?.city}
                isDisabled={!editAddressButtonState || isCepLoading}
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0, 
                }}
              />
            </GridItem>

            <GridItem>
              <Input
                type="text"
                label="Estado"
                labelColor="black"
                name="state"
                register={{ ...register("state") }}
                maxLength={2}
                mask="onlyLetters"
                errors={errors?.state}
                isDisabled={!editAddressButtonState || isCepLoading}
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0, 
                }}
              />
            </GridItem>

            <GridItem>
              <Input
                type="text"
                label="País"
                labelColor="black"
                name="country"
                register={{ ...register("country") }}
                maxLength={2}
                mask="onlyLetters"
                errors={errors?.country}
                isDisabled={!editAddressButtonState || isCepLoading}
                padding="0"
                _disabled={{
                  bgColor: "transparent",
                  border: 0, 
                }}
              />
            </GridItem>
          </Grid>
        </Flex>

        <ChargeHistory client={data} />
      </Flex>
    </>
  );
};

export default WithAuth(ClientDetails);
