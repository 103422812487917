import { useEffect, useState } from "react";
import { Badge, Flex, Text, Button, UnorderedList, ListItem, Box, Img, useTheme } from "@chakra-ui/react";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { useBillsToPay } from "@/hooks/useBillsToPay";
import { Pagination } from "@/components";
import { WithAuth } from "@/hoc";
import { format, differenceInDays, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ModalSelectedBills from "../../components/ModalContasaPagar/ModalSelectedBills"
import { BillsToPayPaginator, BillsToPay } from "@/models";
import "./contasPagar.scss"
import {

    formatCurrency,
    getColorsBillsStatus,
    formatDate,
} from "@/utils";

import FormContasPagar from "../../components/formContasaPagar"
import { ModalEditeBills } from "./_modalEditeBills";
import { nextDayOfPaymentWeek } from "@/utils/smallerNextDay";

interface StatusLength {
    Agendadas: number;
    Aprovar: number;
    Histórico: number;
    Revisar: number;
}

const ContasPagar = () => {
    const [newForm, setNewForm] = useState(false)
    const [hasNewBills, setHasNewBills] = useState(false)
    const [hasNewEdit, setHasNewEdit] = useState(false)
    const [data, setData] = useState({} as BillsToPayPaginator);
    const [tabStatus, setTabStatus] = useState("Aprovar");
    const [currentPage, setCurrentPage] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [Loading, setLoading] = useState(false)
    const [openEditeBills, setOpenEditeBills] = useState(false);
    const [editeBillsData, SetEditeBillsData] = useState<any>();
    const [filterText, setFilterText] = useState("");
    const { newBills, getBills } = useBillsToPay();
    const [titleNextPayment, setTitleNextPayment] = useState("")
    const [dataSelectionRow, setDataSelectionRow] = useState([])
    const [openModalAprove, setOpenModalAprove] = useState<boolean>(false);
    const [modalMethod, setModalMethod] = useState<string>("");
    const [numberOfBillsForStatus, setNumberOfBillsForStatus] = useState<StatusLength | undefined>();
    const theme = useTheme();
    const [dayOfPayment, setDayOfPayment] = useState<any>()
    const [dataWithPaginator, setDataWithPaginator] = useState({
        length: 0,
        values: [{}]
    })
    const user = JSON.parse(localStorage.getItem("barte-user-info"))
    const filterFormulas = {
        Agendadas: 'OR(Status = "Aprovado", status = "Pagamento agendado")',
        Revisar: 'OR(Status = "Reprovado", status = "Atualização necessária", status = "Erro no pagamento", status = "Saldo insuficiente")',
        Histórico: 'OR(Status = "Paga", status = "Cancelada")',
        Aprovar: 'OR(Status = "Aprovar")',
    };
    const filterCliente = `idSeller = ${user.idSeller}`;

    const getNumberOfBillsForStatus = async () => {
        const pathURL = encodeURI(`${String(import.meta.env.VITE_API_AIRTABLE)}?filterByFormula=AND( ${filterCliente})`);

        try {
            const responseGetBills = await getBills(pathURL);
            const statusCounts: StatusLength = {
                Agendadas: 0,
                Revisar: 0,
                Histórico: 0,
                Aprovar: 0,
            };

            responseGetBills?.data?.records?.forEach((item) => {
                const status = item.fields.Status;
                if (status === 'Reprovado' || status === 'Atualização necessária' || status === 'Erro no pagamento' || status === 'Saldo insuficiente') {
                    if (calculateDayDifference(item?.fields?.Vencimento) <= 1) {
                        statusCounts.Revisar += 1;
                    }
                } else if (status === 'Aprovar') {
                    if ( calculateDayDifference(item?.fields?.Vencimento) <= 1) {
                        statusCounts.Aprovar += 1;
                    }
                }
            });

            setNumberOfBillsForStatus(statusCounts);
        } catch (error) {
            return error;
        }
    }

    const getBillsToPay = async (filter?: string) => {

        setFilterText(filter);
        setLoading(true)
        const filterFormula = filterFormulas[filter] || '';
        const ordenacao = {
            field: "Vencimento",
            direction: "asc",
        };

        const pathURL = encodeURI(`${String(import.meta.env.VITE_API_AIRTABLE)}?filterByFormula=AND(${filterFormula}, ${filterCliente})&sort[0][field]=${ordenacao.field}&sort[0][direction]=${ordenacao.direction}`);

        try {
            const responseGetBills = await getBills(pathURL);
            if (responseGetBills?.data?.records.length > 0) {

                const objectsExtracted = responseGetBills?.data?.records.map(object => {
                    const { fields, ...rest } = object;
                    return {
                        ...rest,
                        ...fields,
                    };
                });
                setData({ content: objectsExtracted });
                setLoading(false)

            }

            else {
                setData({ content: [] });
                setLoading(false)

            }

        } catch (error) {
            setLoading(false)
            return error;

        }
    };

    function smallerNextDayOfPayment() {
        const nextDayOfPayment = nextDayOfPaymentWeek(new Date())
        setTitleNextPayment(`Próximos pagamentos serão efetuados ${format(nextDayOfPayment, 'EEEE, d/MM', { locale: ptBR })}`)
        setDayOfPayment(format(nextDayOfPayment, 'd/MM/yyyy', { locale: ptBR }))
    }


    function calculateDayDifference(date: any) {
        const currentDate = new Date();
        const targetDate = parseISO(date);

        const difference = differenceInDays(targetDate, currentDate);
        return difference;
    }


    function paginate(dataObject: any, pageSize: any) {
        const pages = [];
        for (let i = 0; i < dataObject.length; i += pageSize) {
            pages.push(dataObject.slice(i, i + pageSize));
        }
        setDataWithPaginator({
            length: pages?.length,
            values: pages[currentPage]
        });
        return pages;
    }

    const tabs = [
        {
            tab: "Revisar",
            key: "Revisar",
            status: "Atualização necessária",
        },
        {
            tab: "Aprovar",
            key: "Aprovar",
            status: "Aprovar",
        },
        {
            tab: "Agendadas",
            key: "Agendadas",
            status: "Pagamento agendado",
        },
        {
            tab: "Histórico",
            key: "Histórico",
            status: "Histórico",
        },

    ];

    const { TabPane } = Tabs;


    const columns: ColumnsType<BillsToPay> = [
        {
            title: "Conta",
            dataIndex: "Nome da Conta",
            key: "Nome da Conta",
            render: (customerName, record) => customerName && (

                <Flex
                    flexDirection="column"

                >
                    <Text
                        fontFamily="Hero New"
                        color="rgba(20, 26, 40, 1)"
                        lineHeight="22px"
                        fontWeight="500"
                        fontSize="14px"
                    >
                        {customerName}

                    </Text>
                    <Text
                        fontFamily="Hero New"
                        color="rgba(165, 174, 192, 1)"
                        lineHeight="16px"
                        fontWeight="400"
                        fontSize="12px"

                    >
                        {record?.Beneficiário}

                    </Text>
                </Flex>
            ),
        },
        {
            title: "Pagamentos",
            dataIndex: "Data de Pagamento",
            key: "Data de Pagamento",
            render: (paymentDate) => paymentDate && filterText === "Histórico" && (
                <>
                    <Text>
                        {formatDate(paymentDate)}
                    </Text>
                </>
            )

        },
        {
            title: "Vencimento",
            dataIndex: `Vencimento`,
            key: `Vencimento`,
            render: (expireDate) => expireDate && (

                <Flex
                    gap="9px"
                >
                    <Text
                        fontSize="14px"
                    >
                        {formatDate(expireDate)}
                    </Text>
                    {
                        filterText !== "Histórico" && (
                            <>
                                {calculateDayDifference(expireDate) >= 0 && calculateDayDifference(expireDate) <= 1 ? (

                                    <Flex
                                        alignContent="end"
                                        alignItems="center"
                                        borderRadius="36px"
                                        p="0px 7px 0px 7px"
                                        backgroundColor="rgba(230, 233, 240, 1)"
                                        h="16px"
                                    >
                                        <Text
                                            fontSize="10px"
                                            color="rgba(61, 72, 92, 1)"
                                        >
                                            venc. próx.
                                        </Text>
                                    </Flex>
                                ) : calculateDayDifference(expireDate) <= -1 ? (
                                    <Flex
                                        alignContent="end"
                                        alignItems="center"
                                        borderRadius="36px"
                                        p="0px 7px 0px 7px"
                                        backgroundColor="rgba(230, 233, 240, 1)"
                                        h="16px"
                                    >
                                        <Text
                                            fontSize="10px"
                                            color="rgba(61, 72, 92, 1)"
                                        >
                                            em atraso


                                        </Text>
                                    </Flex>
                                ) :
                                    ("")


                                }
                            </>
                        )
                    }
                </Flex>

            )

        },
        {
            title: "Previsão",
            dataIndex: `Agendado`,
            key: `Previsão`,
            render: (paymentDate) => (paymentDate && tabStatus !== "Histórico") && (
                <>
                    <Text>
                        {formatDate(paymentDate)}
                    </Text>
                </>
            )
        },
        {
            title: "Valor",
            dataIndex: "Valor",
            key: "Valor",
            render: (value) => value >= 0 && <Flex fontSize="14px" >{formatCurrency({ value })}</Flex>,

        },
        {
            title: "Status",
            dataIndex: "Status",
            key: "Status",
            render: (status) => {
                const statusColor = theme?.components?.Badge?.variants[getColorsBillsStatus(status?.replace(" ", ""))]?.color;
                return status && (
                    <Flex fontSize="14px">
                        <Badge
                            fontSize="1rem"
                            variant={getColorsBillsStatus(status?.replace(" ", ""))}
                            borderRadius="36px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Flex w="8px" h="8px" borderRadius="36px" backgroundColor={statusColor} />
                            <UnorderedList listStyleType="none" >
                                <ListItem fontSize="12px">{status}</ListItem>
                            </UnorderedList>
                        </Badge>
                    </Flex>
                )
            },
        },
        {
            title: "",
            dataIndex: "id",
            key: "id",
            render: (id, record) =>
                id && (

                    <Flex
                        cursor="pointer"
                        w="90px"
                    >
                        <Flex
                            className="rowAction"
                            gap="5px"

                        >

                            <Text
                                color="rgba(110, 122, 145, 1)"
                                fontSize="12px"
                                fontWeight="400"
                                onClick={() => SeeDetails(record)}
                            >
                                Ver Detalhes
                            </Text>
                            <Img w="10px" h="10px" src="/images/icons/VectorArrowRight.svg" />
                        </Flex>

                    </Flex>


                )
        },
    ];


    function SeeDetails(data: any) {
        setOpenEditeBills(true)
        SetEditeBillsData(data)
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: BillsToPay[]) => {
            setDataSelectionRow(selectedRows);
            selectedRows.length > 0 ? setOpenModal(true) : setOpenModal(false)
        },
        getCheckboxProps: (record: BillsToPay) => ({
            disabled: record?.Status === '',
            id: record?.id,
        }),
    };

    useEffect(() => {

        if (data?.content?.length > 0) {
            paginate(data?.content, 9)
        } else {
            paginate([], 9)
        }
    }, [data, currentPage])

    useEffect(() => {
        getBillsToPay(tabStatus)
        getNumberOfBillsForStatus()
        if (tabStatus) {
            smallerNextDayOfPayment();
            if (dataSelectionRow.length > 0 && (tabStatus === "Aprovar" || tabStatus === "")) {

                setOpenModal(true)

            } else {
                setOpenModal(false)
            }
        }
        if (hasNewBills === true || hasNewEdit === true) {
            getNumberOfBillsForStatus()
            getBillsToPay(tabStatus || "Aprovado")
            setHasNewBills(false)
            setHasNewEdit(false)

        }
    }, [tabStatus, filterText, hasNewBills, hasNewEdit, newForm, openModalAprove])


    return (
        <>
            {
                newForm === true ? (
                    <Flex>
                        <FormContasPagar
                            setNewForm={setNewForm}
                            setHasNewBills={setHasNewBills}
                        />
                    </Flex>
                ) : (
                    <Flex letterSpacing="tighter"
                        justifyContent="space-between"
                        flexDirection="column"
                        alignContent="space-between"
                        alignItems=""
                        h="100%"
                    >

                        {/* <Filter
                    placeholder="Buscar assinaturas"
                    searchFunction={getSubscriptionsData}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    isVisibleDatepicker={true}
                /> */}

                        <Flex
                            flexDirection="column"
                            alignItems=""
                            minH="100%"

                        >

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                            >

                                <Flex
                                    alignItems="center"
                                    gap="24px"
                                    m="26px 0 28px 0"
                                >
                                    <Text
                                        fontSize="21px"
                                        fontWeight="600"
                                        fontFamily="Hero New"
                                        color="black"
                                    >
                                        Contas a Pagar
                                    </Text>
                                    <Text
                                        color="#874D00"
                                        backgroundColor="#FFFBE6"
                                        border="1px solid #FFE58F"
                                        borderRadius="32px"
                                        p=' 4px 12px '
                                    >
                                        {titleNextPayment}
                                    </Text>
                                </Flex>
                                <Button
                                    backgroundColor="#1890FF"
                                    color="white"
                                    onClick={() => setNewForm(true)}
                                >
                                    Nova conta
                                </Button>

                            </Flex>

                            <Tabs
                                defaultActiveKey="Aprovar"
                                className="tabs-custom-contas-a-pagar"
                                onChange={(activeKey) => {
                                    setCurrentPage(0);
                                    setTabStatus(activeKey || "aprovar");
                                }}

                            >
                                {tabs?.map(({ key, tab }) => (

                                    <TabPane tab={key && (

                                        <Flex gap="5px"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Text>
                                                {key}
                                            </Text>

                                            {numberOfBillsForStatus && numberOfBillsForStatus[key] !== 0 && (
                                                <Flex
                                                    backgroundColor="rgba(61, 72, 92, 1)"
                                                    w="16px"
                                                    h="16px"
                                                    color="rgba(230, 233, 240, 1)"
                                                    borderRadius="50%"
                                                    justifyContent="center"
                                                    alignContent="center"
                                                    alignItems="center"
                                                    p="0"
                                                >
                                                    <Text
                                                        fontSize="10px"
                                                    >
                                                        {numberOfBillsForStatus[key]}

                                                    </Text>
                                                </Flex>
                                            )}
                                        </Flex>

                                    )
                                    } key={key}>

                                        {Loading === false ?

                                            (<Table<BillsToPay>
                                                rowSelection={{
                                                    type: "checkbox",
                                                    ...rowSelection,
                                                }}
                                                columns={columns}
                                                dataSource={dataWithPaginator?.values || []}
                                                // onRow={(record) => {
                                                //     return {
                                                //         onClick: () => console.log(`${record.id}`),
                                                //     };
                                                // }}
                                                rowKey={(record) => record.id}
                                                locale={{ emptyText: "Sem dados de contas a pagar" }}
                                                pagination={false}
                                            />) : (<Flex

                                                w="100%"
                                                h="600px"
                                                justifyContent="center"
                                                alignContent="center"
                                                alignItems="center"
                                            >

                                                <Box
                                                    w="71px"
                                                    h="71px"
                                                    animation="spin 2s linear infinite"
                                                >
                                                    <Img src="/images/icons/Loading.png" alt="Rotating Image" />
                                                </Box>

                                            </Flex>)
                                        }
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Flex>


                        <Flex
                            width="95%"
                            position="relative"
                            bottom="-10px"
                            bgColor="rgba(237, 241, 247, 1)"
                            alignContent="center"
                            alignItems="center"
                            justifyContent="space-between"
                            p="10px 10px"
                            borderRadius="8px"
                        >

                            <Text
                                color="rgba(165, 174, 192, 1)"
                            >
                                {currentPage + 1} de {dataWithPaginator?.length} páginas
                            </Text>


                            <Pagination

                                totalPages={dataWithPaginator?.length}
                                setCurrentPage={setCurrentPage}
                            />


                        </Flex>
                        {openModal === true && (

                            <Flex
                                height="80px"
                                width="95%"

                                position="relative"
                                border="1px solid rgba(230, 233, 240, 1)"
                                bottom="40px"
                                bgColor="white"
                                alignContent="center"
                                alignItems="center"
                                justifyContent="end"
                                p="10px 17px"
                                gap="18px"
                                borderRadius="8px"
                                box-shadow=" 0px 3px 6px -4px rgba(0, 0, 0, 0.12),0px 6px 16px 0px rgba(0, 0, 0, 0.08),0px 9px 28px 8px rgba(0, 0, 0, 0.05);"

                            >
                                <Button
                                    color="red"
                                    backgroundColor="white"
                                    _hover={{ backgroundColor: "white" }}
                                    onClick={() => { setModalMethod("Delete"), setOpenModalAprove(true) }}


                                >
                                    Excluir selecionados
                                </Button>
                                <Button
                                    backgroundColor="white"
                                    color="rgba(0, 0, 0, 0.85)"
                                    border="1px solid rgba(0, 0, 0, 0.15)"
                                    onClick={() => { setModalMethod("Reprove"), setOpenModalAprove(true) }}


                                >
                                    Reprovar selecionados
                                </Button>
                                <Button
                                    backgroundColor="#1890FF"
                                    color="white"
                                    onClick={() => { setModalMethod("Aprove"), setOpenModalAprove(true) }}
                                >
                                    Aprovar selecionados
                                </Button>
                            </Flex>

                        )}

                        {openModalAprove === true && (
                            <ModalSelectedBills dayOfPayment={dayOfPayment} selectedActionRead={setDataSelectionRow} openModalAprove={openModalAprove} modalMethod={modalMethod} setOpenModalAprove={setOpenModalAprove} content={dataSelectionRow} />
                        )}
                        {openEditeBills === true && (
                            < ModalEditeBills dayOfPayment={dayOfPayment} setHasNewEdit={setHasNewEdit} setOpenEditeBills={setOpenEditeBills} billsData={editeBillsData} />
                        )}

                    </Flex>
                )
            }

        </>
    )
}

export default WithAuth(ContasPagar);