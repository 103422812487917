import { Input, TextArea } from "@/components";
import { ItemsList, PaymenthsMethods, Periodicity } from "@/layouts";
import { Box, Button, Flex, VStack } from "@chakra-ui/react";
import { useCheckout, usePlan, useSettings } from "@/hooks";
import { PlanWithAdditionalDetails, Bullet, Plan } from "@/models";
import { useEffect, useState } from "react";
import { formatCurrencyToApi, showToast } from "@/utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

interface AddPlanModalProps {
  getPlans: (id: number) => void;
  planModal: (isModal: boolean) => void;
}

const createPlanPageSchema = yup
  .object()
  .shape({
    title: yup.string().required("Preenchimento obrigatório!").max(60, 'O título deve ter no máximo 60 caracteres!'),
    monthly: yup.string().required("Preenchimento obrigatório"),
    semester: yup.string().required("Preenchimento obrigatório"),
    yearly: yup.string().required("Preenchimento obrigatório"),
    enableBankSlip: yup.boolean(),
    enableCreditCard: yup.boolean(),
    enablePixPayment: yup.boolean(),
  })
  .test("paymentMethodsValidation", null, (obj) => {
    if (obj.enableBankSlip || obj.enableCreditCard || obj.enablePixPayment) {
      return true;
    }
    return new yup.ValidationError(
      "Selecione ao menos um método de pagamento.",
      null,
      "paymentMethods"
    );
  });

export default function AddPlanModal({
  getPlans,
  planModal,
}: AddPlanModalProps) {
  const [basicValues, setBasicValues] = useState([]);
  const { getSellerCheckoutDefault } = useSettings();
  const { getCheckoutDefault } = useCheckout();
  const { createPlan } = usePlan();
  const [statePlan, setStatePlan] = useState<Plan>({} as Plan);
  const [loading, setLoading] = useState(false);
  const [typedLetters, setTypedLetters] = useState(0);
  const [initialData, setInitialData] = useState<Plan>({} as Plan);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(createPlanPageSchema),
  });

  function handlePlans(bullets: Bullet[]): void {
    setInitialData({
      ...initialData,
      bullets: bullets,
    });

    setValue("bullets", bullets);
  }

  const handleSavePlan: SubmitHandler<PlanWithAdditionalDetails> = async (
    values,
    event
  ) => {
    event.preventDefault();
    setLoading(true);

    const plan = {
      id: initialData?.id,
      title: values?.title,
      description: values?.description,
      bullets: values?.bullets,
      basicValues: [
        {
          type: "MONTHLY",
          value: formatCurrencyToApi(values?.monthly),
          valuePerMonth: formatCurrencyToApi(values?.monthly),
        },
        {
          type: "SEMESTER",
          value: formatCurrencyToApi(values?.semester) * 6,
          valuePerMonth: formatCurrencyToApi(values?.semester),
        },
        {
          type: "YEARLY",
          value: formatCurrencyToApi(values?.yearly) * 12,
          valuePerMonth: formatCurrencyToApi(values?.yearly),
        },
      ],
      idCheckout: statePlan?.idCheckout,
      enableBankSlip: values?.enableBankSlip,
      enableCreditCard: values?.enableCreditCard,
      enablePixPayment: values?.enablePixPayment,
    };

    try {
      const response = await createPlan(plan);
      showToast({ message: "Plano cadastrado com sucesso!", type: "success" });
      getPlans(response?.data?.id);
      planModal(false);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
    setLoading(false);
  };

  async function getPaymentMethodsDefault() {
    try {
      const { data } = await getSellerCheckoutDefault();

      setInitialData({
        ...initialData,
        enableBankSlip: data.defaultEnableBankSlip,
        enableCreditCard: data.defaultEnableCreditCard,
        enablePixPayment: data.defaultEnablePixPayment,
      });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  useEffect(() => {
    getPaymentMethodsDefault();
    if (!statePlan?.id) {
      getCheckoutDefault().then((response) =>
        setStatePlan({
          ...statePlan,
          idCheckout: response.data.id,
        })
      );
    }
  }, []);

  return (
    <Box maxW="520px" margin="0 auto">
      <VStack spacing={8} as="form" onSubmit={handleSubmit(handleSavePlan)}>
        <Flex marginTop="6" w="full" alignItems="center">
          <Input
            type="text"
            name="title"
            labelColor="#000000"
            color="#000000"
            placeholder="Título"
            label="Título"
            defaultValue={initialData?.title}
            register={{ ...register("title") }}
            errors={errors?.title}
          />
        </Flex>

        <Flex flexDir="column" w="100%">
          <TextArea
            opcional
            color="#000000"
            borderRadius="3"
            name="description"
            register={{ ...register("description") }}
            label="Descrição"
            defaultValue={initialData?.description}
            maxLength={500}
            onKeyUp={(e) =>
              setTypedLetters(
                (e.target as HTMLTextAreaElement)?.value.split("").length
              )
            }
          />
          <Flex justify="flex-end" color="gray.300" fontSize="sm">
            {typedLetters}/500
          </Flex>
        </Flex>

        <ItemsList
          tooltip="Produtos ou serviços inclusos no plano"
          optional
          label="Itens"
          name="itemsList"
          list={initialData?.bullets}
          updateList={handlePlans}
          buttonText="Salvar"
        />

        <Periodicity
          setValue={setValue}
          basicValues={basicValues}
          register={register}
          errors={errors}
          watch={watch}
        />

        {(initialData.enableCreditCard ||
          initialData.enableBankSlip ||
          initialData.enablePixPayment) && (
          <PaymenthsMethods
            data={initialData}
            register={register}
            errors={errors?.paymentMethods}
          />
        )}

        <Flex flexDir="row" align="center" justify="flex-end">
          <Button
            type="submit"
            marginBottom="2rem"
            marginRight="1rem"
            variant="solid"
            bgColor="blue.200"
            color="white"
            borderRadius="3"
            transition="filter .2s"
            _hover={{
              transition: "filter .2s",
              filter: "brightness(.9)",
            }}
            _active={{
              filter: "brightness(.6)",
            }}
            _focus={{}}
            isLoading={loading}
          >
            Salvar
          </Button>
          <Button
            variant="ghost"
            onClick={() => planModal(false)}
            marginBottom="2rem"
          >
            Cancelar
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
}
