import { IconProps } from "@/models/Icon";

export function ArrowRightIcon({ 
    size = 18, 
    color = "#000", 
}: IconProps) {
    return(
        <svg 
            width={size} 
            height={size} 
            viewBox="0 0 19 13" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M1 6.5H17M17 6.5L11.5217 1M17 6.5L11.5217 12" 
                stroke={color} 
                stroke-width="1.5" 
                stroke-linecap="round"
            />
        </svg>
    )
}
