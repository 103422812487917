import { Input } from "@/components";
import { useSettings } from "@/hooks";
import { SellerNotifications, SellerNotificationsBasic } from "@/models";
import { showToast } from "@/utils";
import { Flex, Text, Stack, Button as ChakraButton } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { WarningMessage } from "./_warningMessage";

interface GeneralInfoProps {
  data: Pick<
    SellerNotifications,
    "name" | "email" | "hiddenEmail" | "activeAllNotifications"
  >;
  isDisable?: boolean;
  refreshData: () => void;
}
const GeneralInfo = ({ data, isDisable = false, refreshData }: GeneralInfoProps) => {
  const [loading, setLoading] = useState(false);
  const [changeButtonState, setChangeButtonState] = useState(true);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Preenchimento obrigatório!"),
    email: Yup.string()
      .email("Digite um e-mail válido!")
      .required("Preenchimento obrigatório!"),
    hiddenEmail: Yup.string().email("Digite um e-mail válido!"),
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const { patchBasic } = useSettings();

  const handleSellerNotificationBasicChange: SubmitHandler<
    SellerNotificationsBasic
  > = async (values) => {
    setLoading(true);
    const sellerBasicData = {
      name: values.name,
      email: values.email,
      hiddenEmail: values.hiddenEmail,
      activeAllNotifications: data.activeAllNotifications,
    };

    try {
      await patchBasic(sellerBasicData);
      setChangeButtonState(!changeButtonState);

      showToast({
        message: "Identificação alterada com sucesso",
        type: "success",
      });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    } finally {
      setLoading(false);
      refreshData();
    }
  };

  function handleBasicDataChanges() {
    setChangeButtonState(false);
  }
  useEffect(() => {
    setValue("name", data?.name);
    setValue("email", data?.email);
    setValue("hiddenEmail", data?.hiddenEmail);
  }, [data]);

  return (
    <>
      <Flex
        as="form"
        flexDir="column"
        onSubmit={handleSubmit(handleSellerNotificationBasicChange)}
      >
        <Stack spacing="3rem">
          {isDisable && <WarningMessage />}
          <Text color="black" fontSize="2xl" fontWeight="bold">
            Identificação
          </Text>
          <Flex flexDir="column" justify="space-between">
            <Stack spacing="3rem">
              <Flex
                flexDir="row"
                minW="920px"
                maxW="1000px"
                justify="space-between"
              >
                <Flex maxW="441px" flexDir="column">
                  <Input
                    type="text"
                    minW="375px"
                    color="black"
                    borderRadius="3"
                    borderColor="neutral.200"
                    tooltipText="Nome utilizado ao final do
                                        email de notificação"
                    label="Seu nome de identificação"
                    labelColor="black"
                    name="name"
                    register={{ ...register("name") }}
                    defaultValue={data?.name}
                    onChangeCapture={handleBasicDataChanges}
                    errors={errors?.name}
                    isDisabled={isDisable}
                  />
                  <Text
                    color={isDisable ? "neutral.200" : "gray.500"}
                  >{`{nome-de-identificação}`}</Text>
                </Flex>
                <Text maxW="500px" color={isDisable ? "neutral.200" : "black"}>
                  Esse nome de identificação é o que será utilizado ao final do
                  corpo dos emails de notificações para os seus clientes, como
                  sua assinatura.
                </Text>
              </Flex>
              <Flex
                flexDir="row"
                minW="920px"
                maxW="1000px"
                justify="space-between"
              >
                <Flex maxW="441px" flexDir="column">
                  <Input
                    type="text"
                    minW="375px"
                    color="black"
                    borderRadius="3"
                    borderColor="neutral.200"
                    tooltipText="Seu email utilizado para contato
                                        ao final do email de notificação"
                    label="Seu email de contato"
                    labelColor="black"
                    name="email"
                    register={{ ...register("email") }}
                    defaultValue={data?.email}
                    errors={errors?.email}
                    onChangeCapture={handleBasicDataChanges}
                    isDisabled={isDisable}
                  />
                </Flex>
                <Text maxW="500px" color={isDisable ? "neutral.200" : "black"}>
                  Esse email de contato será inserido no corpo do email de
                  notifiações dos seus clientes para que contatem você. Você
                  pode aproveitar para utilizar um email dedicado de assuntos de
                  cobranças.
                </Text>
              </Flex>
              <Flex
                flexDir="row"
                minW="920px"
                maxW="1000px"
                justify="space-between"
              >
                <Flex maxW="441px" flexDir="column" position="relative">
                  <Input
                    type="text"
                    minW="375px"
                    color="black"
                    borderRadius="3"
                    borderColor="neutral.200"
                    tooltipText="Seu email de cópia oculta"
                    label="Cópia oculta"
                    labelColor="black"
                    name="hiddenEmail"
                    register={{ ...register("hiddenEmail") }}
                    defaultValue={data?.hiddenEmail}
                    errors={errors?.hiddenEmail}
                    onChangeCapture={handleBasicDataChanges}
                  />
                </Flex>
              </Flex>
              <ChakraButton
                disabled={changeButtonState || isDisable}
                type="submit"
                color={changeButtonState ? "gray.300" : "white"}
                bgColor={changeButtonState ? "bgRow" : "blue.200"}
                borderColor={changeButtonState ? "neutral.200" : "blue.200"}
                w="150px"
                borderRadius="3"
                variant="outline"
                transition="all .3s"
                isLoading={loading}
                _hover={{
                  transition: "all .3s",
                  filter: "brightness(.9)",
                }}
              >
                Salvar alterações
              </ChakraButton>
            </Stack>
          </Flex>
        </Stack>
      </Flex>
    </>
  );
};

export { GeneralInfo };
