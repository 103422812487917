import {
  Flex,
  FormControl,
  FormLabel,
  Text,
  Textarea,
  TextareaProps as TextareaPropsChakra,
} from "@chakra-ui/react";
import { useRef } from "react";

interface TextAreaProps extends TextareaPropsChakra {
  opcional?: boolean;
  label?: string;
  errors?: any;
  register?: any;
  labelFontSize?: string;
  labelColor?:string;
  currentLength?: number;
  maxLengthBlocks?: boolean;
}

const TextArea = ({
  opcional,
  label,
  register,
  errors,
  className,
  labelFontSize,
  labelColor,
  maxLength = null,
  currentLength,
  maxLengthBlocks = false,
  ...rest
}: TextAreaProps) => {
  return (
    <FormControl>
      {label && (
        <FormLabel >
          <Flex flexDir="row">
            <Text marginRight=".5rem" color={labelColor ? labelColor : "#000000"} fontSize={labelFontSize ? labelFontSize : "1xl"}>
              {label}
            </Text>
            {opcional && (
              <Text  color={labelColor ? labelColor : "#000000"} fontSize={labelFontSize ? labelFontSize : "gray.300"}>
                (opcional)
              </Text>
            )}
          </Flex>
        </FormLabel>
      )}
      <Textarea
        fontWeight="normal" 
        maxLength={(maxLength && maxLengthBlocks) ? maxLength : null}
        {...rest} 
        {...register}
      />
      {(currentLength >= 0 && maxLength && !rest.isDisabled) && (
        <Text 
          color={currentLength > maxLength ? "red.400" : "gray.200"}
          fontSize=".6rem"
          textAlign="right"
        >
          {currentLength}/{maxLength}
        </Text>
      )}
      {errors && (
        <Text color="danger" role="alert" fontSize=".8rem">
          {errors.message}
        </Text>
      )}
    </FormControl>
  );
};

export { TextArea };
