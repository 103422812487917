import {
  Box,
  Flex,
  Text,
  VStack,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { Input } from "@/components";
import { BasicValue } from "@/models/Plan";
import { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { formatCurrency, formatCurrencyToApi } from "@/utils";

interface PeriodicityProps {
  basicValues?: BasicValue[];
  errors?: any;
  register?: any;
  watch?: any;
  setValue?: any;
}

const Periodicity = ({
  basicValues,
  setValue,
  watch,
  errors,
  register,
}: PeriodicityProps) => {
  const [periodicityTypes, setPeriodicityTypes] = useState([
    {
      type: "MONTHLY",
      title: "Mensal",
      value: 0,
      valuePerMonth: 0,
      periodicity: 1,
    },
    {
      type: "SEMESTER",
      title: "Semestral",
      value: 0,
      valuePerMonth: 0,
      periodicity: 6,
    },
    {
      type: "YEARLY",
      title: "Anual",
      value: 0,
      valuePerMonth: 0,
      periodicity: 12,
    },
  ]);

  function handlePeriodicityErrors(type: string) {
    if (type === "MONTHLY" && errors?.monthly) return errors?.monthly?.message;
    if (type === "SEMESTER" && errors?.semester)
      return errors?.semester?.message;
    if (type === "YEARLY" && errors?.yearly) return errors?.yearly?.message;
  }

  function handlePeriodicityPaymentValue(type: string, value: number) {
    if (type === "MONTHLY") return formatCurrency({ value: value });
    if (type === "SEMESTER") return formatCurrency({ value: value * 6 });
    if (type === "YEARLY") return formatCurrency({ value: value * 12 });
  }

  useEffect(() => {
    if (basicValues.length > 0) {
      setPeriodicityTypes(
        periodicityTypes.map((periodicityType) => {
          const finded = basicValues.find(
            (basicValue) =>
              basicValue.type === periodicityType.type && basicValue.value !== 0
          );
          if (finded) {
            return {
              ...periodicityType,
              value: finded.value,
              valuePerMonth: finded.value / periodicityType.periodicity,
            };
          }
          return periodicityType;
        })
      );
    }
  }, [basicValues]);

  useEffect(() => {
    setValue(
      "monthly",
      formatCurrency({
        style: null,
        value: periodicityTypes[0]?.valuePerMonth,
      })
    );
    setValue(
      "semester",
      formatCurrency({
        style: null,
        value: periodicityTypes[1]?.valuePerMonth,
      })
    );
    setValue(
      "yearly",
      formatCurrency({
        style: null,
        value: periodicityTypes[2]?.valuePerMonth,
      })
    );
  }, [periodicityTypes]);

  return (
    <Box w="520px">
      <Flex mb="1rem">
        <Text
          color="#000000"
          fontSize="1.2rem"
          marginRight=".2rem"
          fontWeight="bold"
        >
          Valores por periodicidade
        </Text>
        <Tooltip
          placement="right-end"
          hasArrow
          label="Exibimos os valores por mês mas a cobrança
          é realizada de acordo com a periodicidade."
        >
          <span>
            <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
          </span>
        </Tooltip>
      </Flex>
      <Box w="100%">
        <VStack w="100%" spacing={"1.5rem"} flexDir="column">
          {periodicityTypes.map(({ type, title }) => (
            <Flex key={type} direction="column" w="100%" h="88px">
              <Text color="#000">{title}</Text>
              <Box w="100%">
                <InputGroup>
                  <InputLeftAddon
                    bg="#FAFAFA"
                    children="R$"
                    color="#000000"
                    borderRadius="3"
                  />
                  <Input
                    type="text"
                    color="#000000"
                    name={type.toLowerCase()}
                    register={{ ...register(type.toLowerCase()) }}
                    borderRadius={0}
                    mask="currency(noBrSign)"
                  />
                  <InputRightAddon
                    bg="#FAFAFA"
                    children="/mês"
                    color="#000000"
                    borderRadius="3"
                  />
                </InputGroup>
              </Box>

              {title !== "Mensal" && (
                <Flex h="24px">
                  <Text color={"gray.400"} fontSize="md">
                    {`Pagamento ${title}:
                        ${
                          watch(type.toLowerCase()) === ""
                            ? "R$ 0,00"
                            : handlePeriodicityPaymentValue(
                                type,
                                formatCurrencyToApi(watch(type.toLowerCase()))
                              )
                        }`}
                  </Text>
                </Flex>
              )}

              {handlePeriodicityErrors(type) && (
                <Text color="danger" mb="4" role="alert">
                  {handlePeriodicityErrors(type)}
                </Text>
              )}
            </Flex>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export { Periodicity };
