import { CalendarIcon } from "@/styles/icons";
import { 
    Button, 
    Flex, 
    forwardRef, 
    Heading, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay,
    Stack,
    Text,  
} from "@chakra-ui/react";
import { format, sub } from "date-fns";
import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import getAPIReport from "@/services/Api/reportApiConfig";
import { ReportType } from "@/models";
import { showToast } from "@/utils";
interface GenerateReportModalProps {
    isOpen: boolean;
    onClose: () => void;
    onCloseSuccess: () => void;
    reportType: ReportType;
}

export function GenerateReportModal({ 
    isOpen, 
    onClose, 
    onCloseSuccess,
    reportType
}: GenerateReportModalProps) {
    registerLocale("ptBR", ptBR);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [startDate, setStartDate] = useState<Date>(sub(new Date(), { days: 30 }));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const user = JSON.parse(localStorage.getItem("barte-user-info"));
    const reportApi = getAPIReport();
    const onDateChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) return setIsDatePickerOpen(false);
    };
    async function handleGenerateReport() {
        try {
            const formattedStartDate = format(startDate, "yyyy-MM-dd");
            const formattedEndDate = format(endDate, "yyyy-MM-dd");
            const formData = {
                "report_type": reportType.query_file,
                "recipient_email": user?.email,
                "start_date": `${formattedStartDate}T00:00:00`,
                "end_date": `${formattedEndDate}T23:59:00`,
            }
            await reportApi.post("", formData)
            onCloseSuccess();
        } catch {
            showToast({
                type: "error",
                message: "Falha ao tentar gerar o relatório.",
            });
            onClose();
        }
    }
    const CustomInput = forwardRef(({ value }: any, ref) => (
        <Button
          onClick={() => setIsDatePickerOpen(true)}
          ref={ref}
          variant="secondary"
          maxH="32px"
          borderColor="#E6E9F0"
          px="2"
          borderRadius="4px"
          _hover={{}}
        >
          <Flex align="center" gap="4">
            <CalendarIcon size={16} />
            <Text>Período</Text>
            <Text>{value}</Text>
          </Flex>
        </Button>
    ));
    return (
        <Modal 
            isOpen={isOpen} 
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent
                borderRadius="0px"
                maxW="380px"
            >
                <ModalHeader
                    borderBottom="1px solid" 
                    borderColor="gray.50"
                >
                    <Flex 
                        w="100%"  
                        align="center"
                        gap="2"
                    >
                        <Text 
                            color="black" 
                            fontSize="1rem"
                        >
                            Gerar relatórios
                        </Text>
                        <ModalCloseButton />
                    </Flex>
                </ModalHeader>
                <ModalBody 
                    p="24px"
                >
                    <Flex 
                        w="100%" 
                        direction="column" 
                        gap="2"
                    >
                        <Text fontSize="1rem">Selecione o período de geração do relatório.</Text>
                        <DatePicker
                            locale="ptBR"
                            selected={startDate}
                            onChange={(date) => onDateChange(date)}
                            startDate={startDate}
                            endDate={endDate}
                            onInputClick={() => setIsDatePickerOpen(true)}
                            onClickOutside={() => {
                                if (endDate) return setIsDatePickerOpen(false);
                            }}
                            open={isDatePickerOpen}
                            customInput={<CustomInput />}
                            selectsRange
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            minDate={sub(new Date(), { days: 90 })}

                        />
                    </Flex>
                </ModalBody>
                <Flex 
                    w="100%" 
                    borderTop="1px solid" 
                    borderColor="gray.50"
                    p="2"
                    gap="2"
                >
                    <Button  
                        variant="secondary"
                        onClick={onClose}
                        marginLeft="auto"
                    >
                        Cancelar
                    </Button>
                    <Button 
                        variant="primary"
                        onClick={handleGenerateReport}
                    >
                        Gerar relatório
                    </Button>
                </Flex>
            </ModalContent>
        </Modal>
    )
}
