import { Button, Flex } from "@chakra-ui/react";
import { useAnticipationStatus } from "../../hooks/useAnticipationStatus.ts";

interface CancelSignatureProps {
  isCancelableSubscription: boolean
  setShowAlert: (param: boolean) => void
}

export function CancelSignature({ isCancelableSubscription, setShowAlert }: CancelSignatureProps) {
  const { hasAntecipationActive } = useAnticipationStatus()
  return (
    <>
      <Flex flexDir="column" align="flex-start" paddingTop={hasAntecipationActive ? "1rem" : ""}>
        {isCancelableSubscription && (
          <Button
            p="0"
            variant="link"
            disabled={hasAntecipationActive}
            id="cancelar_assinatura"
            color={isCancelableSubscription && !hasAntecipationActive ? "red.700" : hasAntecipationActive ? "newGray.300" : "success"}
            _hover={!hasAntecipationActive ? {
              color: "red.700",
              filter: "brightness(0.9)",
              textDecoration: "underline",
            } : {}}
            onClick={() => setShowAlert(true)}
            fontSize="14px"
          >
            Cancelar recorrência
          </Button>
        )}
      </Flex>
    </>
  )
}