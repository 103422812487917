import { Flex, Switch, Text } from "@chakra-ui/react";

interface NotificationSwitchProps {
  emailName: string;
  whatsappName?: string;
  emailDefaultChecked: boolean;
  whatsappDefaultChecked?: boolean;
  handleSwitch: (name: string, isChecked: boolean) => void;
  isDisable?: boolean;
}

export function NotificationSwitch ({ 
  emailName, 
  whatsappName, 
  emailDefaultChecked, 
  whatsappDefaultChecked, 
  handleSwitch,
  isDisable = false,
}: NotificationSwitchProps) {
  return (
    <Flex flexDir="column">
      <Flex>
        <Switch
          h="20px"
          defaultChecked={emailDefaultChecked}
          isDisabled={isDisable}
          name={emailName}
          onChange={(e) =>
            handleSwitch(e.target.name, e.target.checked)
          }
        />
        <Text color="black" marginLeft="1rem">Email</Text>
      </Flex>
      <Flex>
        <Switch
          h="20px"
          defaultChecked={whatsappDefaultChecked}
          isDisabled={isDisable}
          name={whatsappName}
          onChange={(e) =>
            handleSwitch(e.target.name, e.target.checked)
          }
        />
        <Text color="black" marginLeft="1rem">Whatsapp</Text>
      </Flex>
    </Flex>
  );
}