import { IconProps } from "@/models";

export function DialogIcon({
  size = 20,
  color = "#000",
  opacity = "1",
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.45201 3.29454C0.924701 4.67696 0 6.483 0 8.45908C0 10.1847 0.705178 11.7807 1.90104 13.0781L0.75 16.5861L4.7391 15.1558C6.26735 15.9016 8.06981 16.3321 10 16.3321C11.6612 16.3321 13.2278 16.0132 14.6064 15.4489L2.45201 3.29454ZM16.8718 14.1788C16.4683 14.4794 16.033 14.7538 15.5702 14.9986L3.23365 2.66197C3.6425 2.36602 4.08273 2.09654 4.54998 1.85697L16.8718 14.1788ZM17.6433 13.5361C19.1137 12.1654 20 10.3937 20 8.45908C20 4.11093 15.5228 0.58606 10 0.58606C8.39097 0.58606 6.8707 0.885249 5.52404 1.41682L17.6433 13.5361Z"
        fill={color}
        fillOpacity={opacity}
      />
    </svg>
  );
}
