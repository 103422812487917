import { KeyboardEvent, useEffect, useRef, useState } from "react";
import {
  Flex,
  Input as ChakraInput,
  Text,
  InputProps as ChakraInputProps,
  FormControl,
  Box,
  Image,
} from "@chakra-ui/react";
import {
  cellPhone,
} from "@/utils";
import { useCountries } from 'use-react-countries';
import useOutsideClick from "@/utils/outsideClick";
import { RiArrowDropDownLine } from "react-icons/ri";
interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  labelColor?: string;
  register?: any;
  defaultCountryCodeValue?: string;
  setValue: any;
  errors?: any;
  mask?: string;
  isDisabled?: boolean;
}

export function PhoneInput({
  name,
  setValue,
  label,
  labelColor,
  register,
  errors,
  mask,
  variant,
  color,
  isDisabled,
  defaultCountryCodeValue,
  ...rest
}: InputProps) {
  const { countries } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState({} as any);
  const [isDropdownOpen,setIsDropdownOpen] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dropdownRef = useRef();
  const handleMaskInput = (e: KeyboardEvent<HTMLInputElement>) => {
    cellPhone(e);
  };
  const hasCallingCodeCountries = countries.filter(country => country.countryCallingCode !== "");
  const filteredCountries =  hasCallingCodeCountries.filter(country => country?.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
  function getBorderStatus(): string {
    if(isDisabled) return "none"
    if(errors) return "2.5px solid #d33939"
    if(onFocus) return "1px solid #1890FF"
    return "1px solid #C3CAD7"
  }
  useOutsideClick(dropdownRef, () => {
    setIsDropdownOpen(!isDropdownOpen);
  });

  function handleSelectCountry(country: any) {
    setSelectedCountry(country);
    setIsDropdownOpen(false);
  }

  useEffect(() => {
    const defaultCountryValue = countries.filter(country => country.countryCallingCode === defaultCountryCodeValue);
    setSelectedCountry(defaultCountryValue[0]);
  }, [defaultCountryCodeValue]);

  useEffect(() => {
    if(selectedCountry === undefined) return;
    setValue("countryCode", selectedCountry?.countryCallingCode);
  }, [selectedCountry]);
  return (
    <FormControl>
      <Flex flexDir="column">
        <Flex align="center">
          {label && (
            <Text
              marginBottom=".2rem"
              fontSize=".8rem"
              color="gray.600"
            >
              {label}
            </Text>
          )}
        </Flex>
        <Flex
            border={getBorderStatus()}
            onFocus={() =>  setOnFocus(true)}
            onBlurCapture={() =>  setOnFocus(false)}
            borderRadius="4px"
            _focus={{
                border: "1px solid",
                borderColor: "blue.200",
            }}
            position="relative"
            align="center"
        >
            <button 
              onClick={() => setIsDropdownOpen(true)}
              disabled={isDisabled}
              style={{
                cursor: isDisabled ? 'none' : 'pointer'
              }}
            >
              <Flex 
                alignItems="center"
                gap="2"
                px="2"
              >
                <Image 
                  w={6} 
                  h={4} 
                  src={selectedCountry?.flags?.png}
                />
                <Text>{selectedCountry?.countryCallingCode}</Text>
                <RiArrowDropDownLine size={45}/>
              </Flex>
            </button>
            {isDropdownOpen && (
              <Flex
                position="absolute"
                maxW={420}
                w="100%"
                h={360}
                bgColor="#F5F5F5"
                border="1px solid #C3CAD7"
                boxShadow="2px 10px 15px 3px rgba(0,0,0,0.4)"
                overflowY="auto"
                zIndex="1"
                borderRadius="4px"
                top="calc(100% + .4rem)"
                ref={dropdownRef}
              >
                <Box
                  w="100%"
                >
                  <ChakraInput
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.currentTarget.value)}
                      border="0px solid transparent"
                      outline="none"
                      w="100%"
                      color={color ? color : "black"}
                      _focus={{
                          outline: "none",
                          border: "none"
                      }}
                      _disabled={{
                        color: color ? color : "black"
                      }}
                      placeholder="Pesquisar país"
                      isDisabled={isDisabled}
                  />
                  {filteredCountries?.map(country => {
                    return (
                      <Flex
                        key={country.name}
                        align="center"
                        gap="2"
                        p="2"
                        _hover={{
                          bgColor: "#C3CAD7",
                          transition: "all .2s ease-in-out",
                          cursor: "pointer"
                        }}
                        onClick={() => handleSelectCountry(country)}
                      >
                            <Image w={6} h={4}src={country.flags.png}/>
                            <Text>{country.countryCallingCode} ({country.name})</Text>
                      </Flex>
                    )
                  })}
                </Box>
              </Flex>
            )}
            <ChakraInput
                onKeyUp={handleMaskInput}
                name={name}
                border="0px solid transparent"
                outline="none"
                variant={variant}
                w="100%"
                color={color ? color : "black"}
                _focus={{
                    outline: "none",
                    border: "none"
                }}
                _disabled={{
                  color: color ? color : "black"
                }}
                isDisabled={isDisabled}
                {...register}
                {...rest}
            />
        </Flex>
        {errors && (
          <Text fontSize=".8rem" color="danger" mb={errors?.message && "4"} role="alert">
            {errors?.message}
          </Text>
        )}
      </Flex>
    </FormControl>
  );
}
