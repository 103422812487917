import { formatCurrencyToApi } from "@/utils";
import { Button, Flex, Text } from "@chakra-ui/react";
import { SetStateAction } from "react";
interface SaldoComponentProps {
    data?: any;
    open?: React.Dispatch<SetStateAction<boolean>>
    openTransferList?: React.Dispatch<SetStateAction<boolean>>

}
export function SaldoComponent({ data, open, openTransferList }: SaldoComponentProps) {
    return (
        <Flex
            borderRadius="8px"
            border="1px solid"
            borderColor="gray.100"
            width="100%"
            padding='16px 24px 16px 24px'
            align="center"
            justify="space-between"
        >
            <Flex
                flexDir="column"  
            >
                <Text fontSize=".8rem">Saldo total</Text>
                <Flex
                    position="relative"
                >
                    <Text 
                        fontSize="16px"
                        position="absolute"
                        bottom="15%"
                        fontWeight={400}
                    >
                        R$
                    </Text>
                    <Text 
                        fontWeight={600} 
                        fontSize="2rem"
                        marginLeft="1.5rem"
                    >
                        {data?.totalAmount}
                    </Text>
                </Flex>
            </Flex>
            <Flex
                gap="1rem"
            >
                <Flex 
                    gap=".8rem"
                    flexDir="column"
                >
                    <Text fontSize=".8rem">Disponível</Text>
                    <Text fontSize=".8rem">A liberar</Text>
                </Flex>
                <Flex 
                    gap=".8rem"
                    flexDir="column"
                >
                    <Text fontSize=".8rem">{data?.amount}</Text>
                    <Text fontSize=".8rem">{data?.provisionedAmount}</Text>
                </Flex>
            </Flex>
            <Flex
                flexDir="column"
                gap="8px"
            >
                <Button 
                    variant="primary"
                    fontWeight="400"
                    onClick={() => open(true)}
                    disabled={formatCurrencyToApi(data?.amount) === 0 && formatCurrencyToApi(data?.provisionedAmount) === 0}
                >
                    Solicitar saque
                </Button>
                <Button 
                    variant="link" 
                    color="blue.200"
                    fontSize=".8rem"
                    fontWeight="400"
                    onClick={() => openTransferList(true)}
                >
                    Ver solicitações
                </Button>
            </Flex>
        </Flex>
    )
}