import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
    formatCurrency,
  formatDate,
} from "@/utils";

const SplitTable = ({ splitData }) => {

  return (
    <>
      <Flex w="100%" gap="6" alignItems="center" mt="8">
        <Text as="h2" fontSize="20" fontWeight="600">
          Split
        </Text>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
              Execução
            </Th>
            <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
              Destinatário
            </Th>
            <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
              Destino
            </Th>
            <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
              Valor
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {(splitData?.length > 0) &&
            splitData?.map((split) => (
              <Tr key={split?.idSplit}>
                <Td>
                    {formatDate(split?.executionDate)}
                </Td>
                <Td>
                    {split?.subSellerName}
                </Td>
                <Td>
                    {split?.providerType}
                </Td>
                <Td>
                  {formatCurrency({value: split?.amount})}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  );
};

export { SplitTable };