import { createContext, ReactNode, useContext, useState } from "react";
import { SellerTokens, SellerWebhooks } from "@/models";
import { showToast } from "@/utils";
import { useSettings } from "../useSettings";

interface IntegrationContextData {
  tokens: SellerTokens[];
  isAlertActiveOpen: boolean;
  isDeleteModalOpen: boolean;
  isWebhookAlertActiveOpen: boolean;
  isWebhookDeleteModalOpen: boolean;
  setIsAlertActiveOpen: (value: boolean) => void;
  setIsDeleteModalOpen: (value: boolean) => void;
  setIsWebhookAlertActiveOpen: (value: boolean) => void;
  setIsWebhookDeleteModalOpen: (value: boolean) => void;
  setTokens: (value: SellerTokens[]) => void;
  getTokensInitialData: () => void;
  getWebhooksInitialData: () => void;
  deleteToken: () => void;
  handleModal: (value: handleModalProps) => void;
  chooseToken: SellerTokens;
  setChooseToken: (value: SellerTokens) => void;
  changeActive: () => void;
  changeWebhookActive: () => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  webhooks: SellerWebhooks[];
  setWebhooks: (value: SellerWebhooks[]) => void;
  chooseWebhook: SellerWebhooks;
  setChooseWebhook: (value: SellerWebhooks) => void;
  deleteWebhook: () => void;
}
interface IntegrationProps {
  children: ReactNode;
}
type handleModalProps = {
  token?: SellerTokens;
  webhook?: SellerWebhooks;
  type: string;
};

const IntegrationContext = createContext<IntegrationContextData>(
  {} as IntegrationContextData
);

const IntegrationProvider = ({ children }: IntegrationProps) => {
  const [tokens, setTokens] = useState<SellerTokens[]>([]);
  const [chooseToken, setChooseToken] = useState({} as SellerTokens);
  const [webhooks, setWebhooks] = useState<SellerWebhooks[]>([]);
  const [chooseWebhook, setChooseWebhook] = useState({} as SellerWebhooks);
  const {
    getSellerApiTokens,
    deleteSellerApiToken,
    patchActiveSellerApiToken,
    getSellerApiWebhooks,
    patchActiveSellerApiWebhook,
    deleteActiveSellerApiWebhook,
  } = useSettings();
  const [isAlertActiveOpen, setIsAlertActiveOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isWebhookAlertActiveOpen, setIsWebhookAlertActiveOpen] =
    useState(false);
  const [isWebhookDeleteModalOpen, setIsWebhookDeleteModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getTokensInitialData = async () => {
    try {
      const response = await getSellerApiTokens();
      setTokens(response.data);
    } catch {
      showToast({
        message: "Ocorreu um erro ao listar os tokens!",
        type: "error",
      });
    }
  };

  const getWebhooksInitialData = async () => {
    try {
      const response = await getSellerApiWebhooks();
      setWebhooks(response.data);
    } catch {
      showToast({
        message: "Ocorreu um erro ao listar os webhooks!",
        type: "error",
      });
    }
  };

  const deleteToken = async () => {
    try {
      setIsLoading(true);
      await deleteSellerApiToken(chooseToken.uuid);
      await getTokensInitialData();
      showToast({ message: "Token deletado com sucesso!", type: "success" });
    } catch {
      showToast({
        message: "Ocorreu um erro ao deletar o token!",
        type: "error",
      });
    } finally {
      setIsDeleteModalOpen(!isDeleteModalOpen);
      setChooseToken({} as SellerTokens);
      setIsLoading(false);
    }
  };

  const changeActive = async () => {
    try {
      setIsLoading(true);
      await patchActiveSellerApiToken(chooseToken.uuid, {
        active: !chooseToken.active,
      });
      getTokensInitialData();
      showToast({
        message: chooseToken.active
          ? "Token desativado com sucesso"
          : "Token ativado com sucesso!",
        type: "success",
      });
    } catch {
      showToast({
        message: chooseToken.active
          ? "Ocorreu um erro ao desativar o token"
          : "Ocorreu um erro ao ativar o token",
        type: "error",
      });
    } finally {
      setIsAlertActiveOpen(!isAlertActiveOpen);
      setChooseToken({} as SellerTokens);
      setIsLoading(false);
    }
  };

  const deleteWebhook = async () => {
    try {
      setIsLoading(true);
      await deleteActiveSellerApiWebhook(chooseWebhook.uuid);
      await getWebhooksInitialData();
      showToast({ message: "Webhook deletado com sucesso!", type: "success" });
    } catch {
      showToast({
        message: "Ocorreu um erro ao deletar o token!",
        type: "error",
      });
    } finally {
      setIsWebhookDeleteModalOpen(!isWebhookDeleteModalOpen);
      setChooseWebhook({} as SellerWebhooks);
      setIsLoading(false);
    }
  };

  const changeWebhookActive = async () => {
    try {
      setIsLoading(true);
      await patchActiveSellerApiWebhook(chooseWebhook.uuid, {
        active: !chooseWebhook.active,
      });
      getWebhooksInitialData();
      showToast({
        message: chooseWebhook.active
          ? "Webhook desativado com sucesso"
          : "Webhook ativado com sucesso!",
        type: "success",
      });
    } catch {
      showToast({
        message: chooseWebhook.active
          ? "Ocorreu um erro ao desativar o webhook"
          : "Ocorreu um erro ao ativar o webhook",
        type: "error",
      });
    } finally {
      setIsWebhookAlertActiveOpen(!isWebhookAlertActiveOpen);
      setChooseWebhook({} as SellerWebhooks);
      setIsLoading(false);
    }
  };

  const handleModal = async (value: handleModalProps) => {
    setChooseToken(value.token ?? ({} as SellerTokens));
    setChooseWebhook(value.webhook ?? ({} as SellerWebhooks));
    if (value.type === "deleteWebhook") {
      setIsWebhookDeleteModalOpen(!isWebhookDeleteModalOpen);
      return;
    }
    if (value.type === "changeActiveWebhook") {
      setIsWebhookAlertActiveOpen(!isWebhookAlertActiveOpen);
      return;
    }
    if (value.type === "delete") {
      setIsDeleteModalOpen(!isDeleteModalOpen);
      return;
    }
    if (value.type === "changeActive") {
      setIsAlertActiveOpen(!isAlertActiveOpen);
      return;
    }
  };

  return (
    <IntegrationContext.Provider
      value={{
        webhooks,
        setWebhooks,
        tokens,
        setTokens,
        getTokensInitialData,
        getWebhooksInitialData,
        deleteToken,
        isAlertActiveOpen,
        isDeleteModalOpen,
        setIsAlertActiveOpen,
        setIsDeleteModalOpen,
        handleModal,
        chooseToken,
        setChooseToken,
        changeActive,
        changeWebhookActive,
        isWebhookAlertActiveOpen,
        isWebhookDeleteModalOpen,
        setIsWebhookAlertActiveOpen,
        setIsWebhookDeleteModalOpen,
        chooseWebhook,
        setChooseWebhook,
        deleteWebhook,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};

export function useIntegration(): IntegrationContextData {
  return useContext(IntegrationContext);
}

export { IntegrationProvider };
