import { IconProps } from "@/models";

export function PhoneIcon({
  size = 20,
  color = "#000",
  opacity = "1",
}: IconProps) {
  return (
    <svg 
        width={size} 
        height={size} 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M17.4375 1.45312H6.5625C5.73516 1.45312 5.0625 2.12578 5.0625 2.95312V20.9531C5.0625 21.7805 5.73516 22.4531 6.5625 22.4531H17.4375C18.2648 22.4531 18.9375 21.7805 18.9375 20.9531V2.95312C18.9375 2.12578 18.2648 1.45312 17.4375 1.45312ZM17.25 20.7656H6.75V3.14062H17.25V20.7656ZM11.0625 18.375C11.0625 18.6236 11.1613 18.8621 11.3371 19.0379C11.5129 19.2137 11.7514 19.3125 12 19.3125C12.2486 19.3125 12.4871 19.2137 12.6629 19.0379C12.8387 18.8621 12.9375 18.6236 12.9375 18.375C12.9375 18.1264 12.8387 17.8879 12.6629 17.7121C12.4871 17.5363 12.2486 17.4375 12 17.4375C11.7514 17.4375 11.5129 17.5363 11.3371 17.7121C11.1613 17.8879 11.0625 18.1264 11.0625 18.375Z" 
            fill={color}
        />
    </svg>
  );
}
