import { WithAuth } from "@/hoc";
import { Stack } from "@chakra-ui/react";
import { ApiKeys } from "./api-keys";
import { WebHooks } from "./web-hooks";

const Integracao = () => {
  return (
    <Stack spacing={10}>
      <ApiKeys />
      <WebHooks />
    </Stack>
  );
};

export default WithAuth(Integracao);
