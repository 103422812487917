import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Checkbox,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";

interface PaymenthsMethodsProps {
  register?: any;
  data?: any;
  errors?: any;
  noTitle?: boolean;
}

const PaymenthsMethods = ({
  register,
  data,
  errors,
  noTitle = false,
}: PaymenthsMethodsProps) => {
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    if (data) {
      setInitialData(data);
    }
  }, []);

  return (
    <>
      {initialData && (
        <Box w="520px">
          {!noTitle && (
            <Text mb="1" color="#000000" fontSize="1xl">
              Métodos de Pagamento do Plano
            </Text>
          )}
          <FormControl isInvalid={errors}>
            <Flex
              w="100%"
              flexDirection="column"
              align="flex-start"
              justify="space-evenly"
            >
              <Checkbox
                size="md"
                fontSize="1rem"
                textColor="black"
                name="enableCreditCard"
                {...register("enableCreditCard")}
                defaultChecked={initialData?.enableCreditCard}
                isInvalid={errors}
              >
                Cartão de crédito
              </Checkbox>
              <Checkbox
                size="md"
                fontSize="1rem"
                textColor="black"
                name="enablePixPayment"
                {...register("enablePixPayment")}
                defaultChecked={initialData?.enablePixPayment}
                isInvalid={errors}
              >
                PIX
              </Checkbox>
              <Checkbox
                size="md"
                fontSize="1rem"
                textColor="black"
                name="enableBankSlip"
                {...register("enableBankSlip")}
                defaultChecked={initialData?.enableBankSlip}
                isInvalid={errors}
              >
                Boleto
              </Checkbox>

              {errors && <FormErrorMessage>{errors?.message}</FormErrorMessage>}
            </Flex>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export { PaymenthsMethods };
