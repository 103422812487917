import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Flex,
  IconButton,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Alert } from "@/components";
import { DownloadButton } from "@/components/DownloadButton";
import { useCharge } from "@/hooks";
import {
  formatDate,
  getColorBadgeStatus,
  getInvoiceStatus,
  showToast,
} from "@/utils";
import { ShareIcon } from "@/styles/icons/ShareIcon";

const InvoiceTable = () => {
  const [isShareAlertOpen, setIsShareAlertOpen] = useState(false);
  const [invoiceModalState, setInvoiceModalState] = useState(false);
  const [cancelModalState, setCancelModalState] = useState(false);
  const [emitAgainModalState, setEmitAgainModalState] = useState(false);
  const [selectedUuidInvoice, setSelectedUuidInvoice] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    sendInvoiceEmail,
    cancelInvoice,
    emitInvoice,
    getChargeData,
    getChargeInvoicesData,
    charge,
    invoices,
  } = useCharge();
  const shareAlertRef = useRef();
  const { id } = useParams();
  const ref = useRef();
  const hasEmittedInvoice = invoices?.length && invoices?.findIndex(
    (invoice) =>
      invoice.status === "AUTHORIZED" ||
      invoice.status === "SEND_INVOICE_SYSTEM"
  );
  useEffect(() => {
    if (!id) return;
    getChargeInvoicesData(id);
    getChargeData(String(id));
  }, [id]);

  async function handleEmitInvoice() {
    try {
      await emitInvoice(id);
      showToast({
        type: "success",
        message: "Emissão realizada com sucesso.",
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possível emitir sua nota fiscal.",
      });
    } finally {
      setInvoiceModalState(false);
      setEmitAgainModalState(false);
      getChargeData(String(id));
      getChargeInvoicesData(id);
    }
  }

  const handleCancelInvoice = async () => {
    try {
      setIsLoading(true);
      await cancelInvoice(selectedUuidInvoice, charge?.cnpjSeller);
      showToast({
        message: "Nota fiscal cancelada com sucesso!",
        type: "success",
      });
    } catch (error) {
      error?.response?.data?.errors.map((itemError) => {
        showToast({
          message: `${itemError?.description} - ${itemError?.code}`,
          type: "error",
        });
      });
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setIsLoading(false);
      getChargeInvoicesData(id);
      getChargeData(String(id));
      setSelectedUuidInvoice("");
      setCancelModalState(false);
    }
  };

  const handleSendEmail = async () => {
    try {
      await sendInvoiceEmail(id);
      showToast({
        message: "E-mail enviado com sucesso!",
        type: "success",
      });
    } catch (error) {
      error?.response?.data?.errors.map((itemError) => {
        showToast({
          message: `${itemError?.description} - ${itemError?.code}`,
          type: "error",
        });
      });
    } finally {
      setIsShareAlertOpen(false);
    }
  };
  return (
    <>
      <Alert
        closeButtonText="Não"
        confirmButtonText="Sim"
        isOpen={isShareAlertOpen}
        onConfirm={handleSendEmail}
        onClose={() => {
          setIsShareAlertOpen(!isShareAlertOpen);
        }}
        title="Enviar Nota Fiscal ao cliente por email ?"
        leastDestructiveRef={shareAlertRef}
      />
      <Alert
        isOpen={invoiceModalState}
        title="Enviar Nota Fiscal antecipadamente para esse cliente?"
        messageBody="A NF não será enviada novamente após o pagamento da cobrança"
        leastDestructiveRef={ref}
        closeButtonText="Cancelar"
        confirmButtonText="Sim"
        onClose={() => setInvoiceModalState(false)}
        onConfirm={handleEmitInvoice}
      />
      <Alert
        isOpen={emitAgainModalState}
        title="Emitir novamente a nota fiscal ?"
        messageBody="A NF será emitida novamente!"
        leastDestructiveRef={ref}
        closeButtonText="Cancelar"
        confirmButtonText="Sim"
        onClose={() => setEmitAgainModalState(false)}
        onConfirm={handleEmitInvoice}
      />
      <Alert
        isOpen={cancelModalState}
        title="Cancelar Nota Fiscal para esse cliente?"
        messageBody="A NF será cancelada logo após a confirmação"
        leastDestructiveRef={ref}
        closeButtonText="Cancelar"
        confirmButtonText="Sim"
        onClose={() => setCancelModalState(false)}
        onConfirm={handleCancelInvoice}
        isLoading={isLoading}
      />
      <Flex w="100%" gap="6" alignItems="center" mt="8">
        <Text as="h2" fontSize="20" fontWeight="600">
          Nota Fiscal
        </Text>
        {(invoices?.length === 0) && (
          <Button
            variant="outline"
            maxW="180px"
            maxH="24px"
            paddingBottom="-1px"
            fontSize="14px"
            borderRadius="3"
            onClick={() => {
              setInvoiceModalState(!invoiceModalState);
            }}
            disabled={!charge?.activeInvoiceConfig}
          >
            Adiantar Emissão de NF
          </Button>
        )}
        {(invoices?.length > 0) && hasEmittedInvoice === -1 && (
          <Button
            variant="secondary"
            maxW="180px"
            maxH="24px"
            paddingBottom="-1px"
            fontSize="14px"
            borderRadius="3"
            onClick={() => {
              setEmitAgainModalState(!emitAgainModalState);
            }}
          >
            Emitir Novamente
          </Button>
        )}
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
              Número da NF
            </Th>
            <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
              Data de Emissão
            </Th>
            <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
              Status
            </Th>
            <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
              Ações
            </Th>
            <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal" />
          </Tr>
        </Thead>

        <Tbody>
          {(invoices?.length === 0) && (
            <Tr>
              <Td>-</Td>
              <Td>-</Td>
              <Td>
                <Badge
                  fontSize="1rem"
                  variant={getColorBadgeStatus("NOT_EMITTED")}
                >
                  {getInvoiceStatus("NOT_EMITTED")}
                </Badge>
              </Td>
              <Td></Td>
              <Td>
                <Flex w="100%" gap="2">
                  <DownloadButton disabled>
                    <Link
                      w="100%"
                      textAlign="center"
                      _hover={{
                        borderRadius: "5px",
                        bgColor: "gray.50",
                      }}
                    >
                      PDF
                    </Link>
                    <Link
                      w="100%"
                      textAlign="center"
                      _hover={{
                        borderRadius: "5px",
                        bgColor: "gray.50",
                      }}
                    >
                      XML
                    </Link>
                  </DownloadButton>
                  <IconButton
                    bgColor="transparent"
                    aria-label="Compartilhar com e-mail"
                    disabled
                    w="30px"
                    h="35px"
                    icon={<ShareIcon opacity="0.45" />}
                    onClick={() => {
                      setIsShareAlertOpen(!isShareAlertOpen);
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          )}
          {(invoices?.length > 0) &&
            invoices?.map((invoice) => (
              <Tr key={invoice.uuidInvoice}>
                <Td>{invoice?.invoiceNumber}</Td>
                <Td>{formatDate(invoice?.createdAt)}</Td>
                <Td>
                  {invoice?.status ? (
                    <Badge
                      fontSize="1rem"
                      variant={getColorBadgeStatus(invoice?.status)}
                    >
                      {getInvoiceStatus(invoice?.status)}
                    </Badge>
                  ) : (
                    "-"
                  )}
                </Td>
                <Td>
                  {invoice?.status === "AUTHORIZED" && (
                    <Button
                      variant="cancel.red"
                      maxW="180px"
                      maxH="24px"
                      paddingBottom="-1px"
                      fontSize="14px"
                      borderRadius="3"
                      onClick={() => {
                        setSelectedUuidInvoice(invoice?.uuidInvoice);
                        setCancelModalState(!cancelModalState);
                      }}
                    >
                      Cancelar
                    </Button>
                  )}
                </Td>
                <Td>
                  <Flex w="100%" gap="2">
                    <DownloadButton
                      disabled={
                        !invoice?.invoiceDownloadLink?.pdfImageLink &&
                        !invoice?.invoiceDownloadLink?.xmlImageLink
                      }
                    >
                      {invoice?.invoiceDownloadLink?.pdfImageLink && (
                        <Link
                          w="100%"
                          textAlign="center"
                          _hover={{
                            borderRadius: "5px",
                            bgColor: "gray.50",
                          }}
                          href={invoice?.invoiceDownloadLink?.pdfImageLink}
                          target="_blank"
                        >
                          PDF
                        </Link>
                      )}
                      {invoice?.invoiceDownloadLink?.xmlImageLink && (
                        <Link
                          w="100%"
                          textAlign="center"
                          _hover={{
                            borderRadius: "5px",
                            bgColor: "gray.50",
                          }}
                          href={invoice?.invoiceDownloadLink?.xmlImageLink}
                          target="_blank"
                        >
                          XML
                        </Link>
                      )}
                    </DownloadButton>
                    <IconButton
                      bgColor="transparent"
                      disabled={invoice?.status !== "AUTHORIZED"}
                      aria-label="Compartilhar com e-mail"
                      w="30px"
                      h="35px"
                      icon={<ShareIcon opacity="0.45" />}
                      onClick={() => {
                        setIsShareAlertOpen(!isShareAlertOpen);
                      }}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  );
};

export { InvoiceTable };