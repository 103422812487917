import { Button, ButtonProps, Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface RadioGroupProps extends FlexProps {
  children: ReactNode;
}

export function RadioGroup({children, ...rest}: RadioGroupProps) {
  return (
    <Flex
      {...rest}
    >
      {children}
    </Flex>
  );
}