import { Flex, Heading } from "@chakra-ui/react";
import { WithAuth } from "@/hoc";
import { PixKeysComponent } from "./_PixKeysComponent";
import { Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";

const AccountDetail = () => {
    const tabs = [
        // {
        //   tab: "Empresa",
        //   key: "company",
        //   status: "company",
        // },
        // {
        //   tab: "Sócios",
        //   key: "partners",
        //   status: "partners",
        // },
        {
          tab: "Chaves Pix",
          key: "pix",
          status: "pix",
        },
      ];
 
    return (
        <>
            <Flex
                w="100%"
                flexDir="column"
                gap="1rem"
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Flex
                        gap="8px"
                    >
                        <Heading fontSize="1.5rem">Dados da Conta</Heading>
                    </Flex>

                </Flex>
                <Tabs
                    defaultActiveKey="1"
                    className="tabs-custom"
                >
                    {tabs.map(({ key, tab }) => (
                        <TabPane tab={tab} key={key}>
                            {key === "pix" && (
                                <PixKeysComponent />
                            )}
                        </TabPane>
                    ))}
                </Tabs>
            </Flex>
        </>
    );
}

export default WithAuth(AccountDetail);
