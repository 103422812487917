import { Flex, Box, Text } from "@chakra-ui/react";
import { GhostButton } from "@/styles/components/Buttons";
import * as Yup from "yup";
import { Table, Tabs } from "antd";
import { SetStateAction, useEffect, useState } from "react";

import 'antd/dist/antd.css';
import ColumnGroup from "antd/lib/table/ColumnGroup";
import Column from "antd/lib/table/Column";
import { useTransfers } from "@/hooks/useTransfer";


interface ListProps {
    open?: React.Dispatch<SetStateAction<boolean>>
}

export default function TransferList({ open }: ListProps) {
    const [data, setData] = useState();
    const { findSeller, findSellerTransferList } = useTransfers();
    const [transferTotal, setTransferTotal] = useState<string>("0.00");

    async function transferListCall() {
        const params = {
            size: 50,
            sort: "date,DESC"
        }
        try {
            const response = await findSellerTransferList({params})
            setData(response?.data?.content)

        } catch (error) {
            console.log(error)
        }
    }

    async function findSellerTotal() {
        try {
            const response = await findSeller()
            setTransferTotal(response?.data)

        } catch (error) {
            console.log(error)
        }
    }

    const FormatResponse = (data: any) => {
        const objetoFormatado = data?.map(item => ({
            amount: `R$ ${item.amount.toFixed(2).replace('.', ',')}`,
            date: item.date.split('-').reverse().join('/'),
            scheduledDate: item.scheduledDate.split('-').reverse().join('/')
        }));
        return objetoFormatado
    }

    useEffect(() => {
        transferListCall();
        findSellerTotal();
    }, [])

    const columns = [
        {
            title: 'Solicitado em',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Previsão de execução',
            dataIndex: 'scheduledDate',
            key: 'scheduledDate',
        },
        {
            title: 'Valor',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    return (
        <Flex
            position="fixed"
            top="0"
            left="0"
            width="100%"
            minHeight="100%"
            backgroundColor="#262F4080"
            zIndex="9999"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
        >
            <Flex
                width="520px"
                bgColor="white"
                borderRadius="3px"
                flexDirection="column"
                justifyContent="space-between"
                p="16px 16px 10px"
                color="black"
            >

                <Flex
                    padding="0 8px"
                    justifyContent="space-between"

                >
                    <Flex
                        gap="12px"

                    >

                        <img src="/images/icons/newWallet.svg" />

                        <Text
                            fontSize="16px"
                            fontWeight="600"
                        >
                            Solicitações de repasse em processamento
                        </Text>
                    </Flex>
                    <Text
                        color="#00000073"
                        onClick={() => open(false)}
                        cursor="pointer"
                        // fontSize="20px"
                    >x</Text>
                </Flex>

                <Flex

                    flexDirection="column"
                    justifyContent="space-between"
                    height="178px"
                    width="100%"
                    overflowY="scroll"

                >

                    <Table
                        className="testDois"
                        rowClassName="teste"
                        dataSource={FormatResponse(data)}
                        columns={columns}
                        locale={{ emptyText: "Sem dados de cobranças" }}
                        pagination={false}
                    />


                </Flex>

                {/* <Pagination
                    // totalPages={data?.totalPages}
                    totalPages={1}
                    setCurrentPage={setCurrentPage}
                /> */}

                <Flex
                    justifyContent="end"
                    p="0px 20px 8px"
                    gap="14px"
                >
                    <Text
                        color="#8A94A8"
                    >
                        Total
                    </Text>
                    <Text
                        fontWeight="700"
                    >
                        {Number(transferTotal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </Text>
                </Flex>
                <Flex
                    justifyContent="end"
                    height="60px"
                >
                    <Box
                    >
                        <GhostButton onClick={() => open(false)}>Ok</GhostButton>
                    </Box>
                </Flex>
            </Flex>


        </Flex>

    )

}