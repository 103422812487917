import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Flex, Text, Icon, Box, Image, Link } from "@chakra-ui/react";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Filter, Pagination } from "@/components";
import { WithAuth } from "@/hoc";
import { ClientData, ClientsPaginator } from "@/models";
import { getInitials, phone_format, showToast } from "@/utils";
import { useClient } from "@/hooks";

const Clients = () => {
  const { getClients } = useClient();
  const [data, setData] = useState({} as ClientsPaginator);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState("name");

  async function getClientsData(filter?: string) {
    setLoading(true)

    try {
      const response = await getClients(
        {
          [filterParams]: filter ? filter : "",
        },
        {
          size: 20,
          page: currentPage,
          sort: "name,ASC",
        }
      );
      setData(response.data);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getClientsData();
  }, [currentPage]);

  const { TabPane } = Tabs;
  const navigate = useNavigate();

  const columns: ColumnsType<ClientData[]> = [
    {
      key: "name",
      title: "Cliente",
      dataIndex: "name",
      render: (name) => (
        <Flex align="center" gridGap="2">
          {/* <Badge
            fontSize="1rem"
            minWidth="35px"
            minHeight="35px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            colorScheme="yellow"
            color="gray.800"
          >
            {getInitials(name) ?? "-"}
          </Badge> */}
          <Text>{name}</Text>
        </Flex>
      ),
    },
    {
      key: "email",
      title: "E-mail",
      dataIndex: "email",
    },
    {
      key: "phone",
      title: "Telefone",
      dataIndex: "phone",
      render: (phone) => phone_format(phone),
    },
    {
      key: "buttonOptions",
      title: "",
      render: (text, record) => (
        <Box p="0" w="190px" h="100%">
          <Flex
            align="center"
            justify="center"
            h="40px"
            w="170px"
            bgColor="white"
            borderRadius="8px"
            zIndex="99"
            alignItems="center"
            className="rowAction"
            transition="all .2s"
            p=" 0 8px"
            _hover={{
              transition: "all .2s",
              bgColor: "white",
            }}
          >
            <Link
              onClick={(event) => event.stopPropagation()}
              /*@ts-ignore*/
              href={`/clientes/${record?.id}`}
              target="_blank"
              _hover={{ cursor: "pointer" }}
              display="flex"
              alignItems="center"
              gap="10px"
            >

              <Image
                id="excluir_cobranca"
                h="20px"
                w="20px"
                src="/images/icons/Export.svg"
                transition="filter .2s"
                _hover={{
                  transition: "filter .2s",
                  filter: "brightness(.3)",
                }}
              />
              <Text fontSize="12px" color="gray_600">
                Abrir em nova aba
              </Text>
            </Link>
          </Flex>
        </Box>
      ),
    },
  ];

  return (
    <Box letterSpacing="tighter">
      <Flex justify="flex-end" top="7rem" right="1.3rem" mb="20px">
        <Button
          size="sm"
          bgColor="blue.200"
          borderRadius="3"
          color="white"
          transition="filter .2s"
          _hover={{
            transition: "filter .2s",
            filter: "brightness(.9)",
          }}
          _focus={{}}
          onClick={() => navigate("/clientes/adicionar-cliente")}
        >
          Adicionar cliente
        </Button>
      </Flex>
      <Filter
        placeholder="Buscar clientes"
        searchFunction={getClientsData}
        isVisibleDatepicker={false}
        loading={loading}
        setFilterParams={setFilterParams}
        getListFilter={[
          { value: 'name', label: 'Nome' },
          { value: 'document', label: 'Documento' },
          { value: 'email', label: 'Email' },
        ]
        }

      />


      <Tabs defaultActiveKey="1" className="tabs-custom">
        <TabPane>
          <Table<ClientData>
            columns={columns as ColumnsType}
            dataSource={data?.content}
            rowKey={(record) => record.id}
            onRow={(record) => ({
              onClick: () => navigate(`/clientes/${record.id}`),
            })}
            locale={{ emptyText: "Sem dados de clientes" }}
            pagination={false}
          />
          <Pagination
            totalPages={data?.totalPages}
            setCurrentPage={setCurrentPage}
          />
        </TabPane>
      </Tabs>
    </Box>
  );
};

export default WithAuth(Clients);
