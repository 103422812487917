import { Text, VStack } from "@chakra-ui/react";
import { RowOptionLink } from "@/layouts/RowOptionLink";
import { WithAuth } from "@/hoc";
import { useState } from "react";

const MeuCheckout = () => {
  const [checked, setChecked] = useState(false);

  function handleChecked(e) {
    setChecked(!checked);
  }

  const baseUrl = "/meu-checkout";

  const optionsList = [
    {
      title: "Informações Gerais",
      link: `/checkout-info`,
    },

    // {
    //   title: "Meus métodos de pagamento",
    //   link: `${baseUrl}/metodos-pagamento`,
    // },

    {
      title: "Meus planos",
      link: `/meus-planos`,
    },

    // {
    //   title: "Periodicidade",
    //   link: `${baseUrl}/periodicidade`,
    // },

    // {
    //   title: "Valores pontuais",
    //   link: `${baseUrl}/valores-pontuais`,
    // },
  ];

  return (
    <VStack spacing="20px">
      {optionsList.map(({ link, title }) => (
        <RowOptionLink link={link} key={title}>
          <Text as="strong" fontSize="1.5rem">
            {title}
          </Text>
        </RowOptionLink>
      ))}
    </VStack>
  );
};

export default WithAuth(MeuCheckout);
