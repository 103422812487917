import { Flex, FlexProps, Icon, Switch, Text } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

interface ContainerProps extends FlexProps {
  setContainerValue?: (prop: boolean) => void;
  containerValue?:boolean;
  active?: boolean;
  startsOpen?: boolean;
  title: string;
  hasPadding?: boolean;
  children: ReactNode;
}

export function Container({ startsOpen, containerValue, setContainerValue, active, title, children, hasPadding, ...rest }: ContainerProps) {
  const [containerState, setContainerState] = useState(true);
  return (
    <Flex
      p={hasPadding ? "1.5rem" : ""}
      marginTop="2rem"
      border="1px solid"
      borderColor="newGray.100"
      borderRadius=".2rem"
      bgColor="white"
      gap="1rem"
      {...rest}
    >
      <Flex
        p={hasPadding ? "" : "1.5rem"}
        justify="space-between"
        alignItems="center"
        cursor="pointer"
        onClick={() => setContainerState(!containerState)}
      >
        {title && (
          <Text
            fontSize="1.2rem"
            color="black"
            fontWeight="600"
          >
            {title}
          </Text>
        )}
        <Icon fontSize="1.2rem" as={containerState ? MdKeyboardArrowUp : MdKeyboardArrowDown} />
      </Flex>

      <Flex
        flexDir="column"
        display={containerState ? "flex" : "none"}
        gap="1.5rem"
      >
        {active && (
          <Flex align="center" justify="space-between">
            <Text fontSize="16px" color="black" fontWeight="600">Ativar {title.toLowerCase()}</Text>
            <Switch 
              defaultChecked={containerValue}
              onChange={(e) => setContainerValue(e.target.checked)} 
            />
          </Flex>
        )}
        {children}
      </Flex>
    </Flex>
  )
}