import { Box, Button, Flex, Switch, Text, VStack } from "@chakra-ui/react";
import { BackButton, Input, TextArea } from "@/components";
import { ItemsList, PaymenthsMethods, Periodicity } from "@/layouts";
import { WithAuth } from "@/hoc";
import { useCheckout, usePlan, useSettings } from "@/hooks";
import { Bullet, Plan, PlanWithAdditionalDetails } from "@/models/Plan";
import { useEffect, useState } from "react";
import { formatCurrencyToApi, showToast } from "@/utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const createPlanPageSchema = yup.object().shape({
  title: yup
    .string()
    .required("Preenchimento obrigatório!")
    .max(60, "O título deve ter no máximo 60 caracteres!"),
  monthly: yup.string().required("Preenchimento obrigatório"),
  semester: yup.string().required("Preenchimento obrigatório"),
  yearly: yup.string().required("Preenchimento obrigatório"),
});

const PlanPage = () => {
  const { getSellerCheckoutDefault } = useSettings();
  const { getCheckoutDefault } = useCheckout();
  const { createPlan, updatePlan, activatePlan, getPlan } = usePlan();
  const navigate = useNavigate();
  const { id } = useParams();
  const [basicValues, setBasicValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typedLetters, setTypedLetters] = useState(0);

  const [initialData, setInitialData] = useState<Plan>({} as Plan);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(createPlanPageSchema),
  });

  async function getPaymentMethodsDefault() {
    try {
      const response = await getCheckoutDefault();
      const { data } = await getSellerCheckoutDefault();

      setInitialData({
        ...initialData,
        enableBankSlip: data.defaultEnableBankSlip,
        enableCreditCard: data.defaultEnableCreditCard,
        enablePixPayment: data.defaultEnablePixPayment,
        idCheckout: response.data.id,
      });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  async function getInitialData() {
    if (id === undefined) return;
    if (id === "0") {
      getPaymentMethodsDefault();
      return;
    }
    try {
      const { data } = await getPlan(Number(id));
      setInitialData(data);
      setBasicValues(data?.basicValues);
      setTypedLetters(data.description?.length);
      setValue("description", data?.description);
      setValue("title", data?.title);
      setValue("bullets", data?.bullets);
      setValue("id", data?.id);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  function handlePlans(bullets: Bullet[]): void {
    setInitialData({
      ...initialData,
      bullets: bullets,
    });

    setValue("bullets", bullets);
  }

  const handleSavePlan: SubmitHandler<PlanWithAdditionalDetails> = async (
    values,
    event
  ) => {
    event.preventDefault();
    setLoading(true);

    const plan = {
      id: initialData?.id,
      title: values?.title,
      description: values?.description,
      bullets: values?.bullets,
      basicValues: [
        {
          type: "MONTHLY",
          value: formatCurrencyToApi(values?.monthly),
          valuePerMonth: formatCurrencyToApi(values?.monthly),
        },
        {
          type: "SEMESTER",
          value: formatCurrencyToApi(values?.semester) * 6,
          valuePerMonth: formatCurrencyToApi(values?.semester),
        },
        {
          type: "YEARLY",
          value: formatCurrencyToApi(values?.yearly) * 12,
          valuePerMonth: formatCurrencyToApi(values?.yearly),
        },
      ],
      idCheckout: initialData?.idCheckout,
      enableBankSlip: values?.enableBankSlip,
      enableCreditCard: values?.enableCreditCard,
      enablePixPayment: values?.enablePixPayment,
    };

    try {
      if (initialData.id !== 0 && initialData.id !== undefined) {
        await updatePlan(initialData.id, plan);
      } else {
        await createPlan(plan);
      }

      showToast({ message: "Plano atualizado com sucesso!", type: "success" });
      setLoading(false);
      navigate("/meus-planos");
    } catch (error) {
      if (initialData?.id === 0 || initialData?.id === undefined) {
        error?.response?.data?.errors.map((error) => {
          showToast({
            type: "error",
            message: `${error?.code} - ${error?.description}`,
          });
        });
      } else {
        error?.response?.data?.errors.map((error) => {
          showToast({
            type: "error",
            message: `${error?.code} - ${error?.description}`,
          });
        });
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, [id]);

  return (
    <Box>
      <BackButton />
      <Box maxW="520px" margin="0 auto">
        <Flex
          flexDirection="column"
          h={initialData?.id === 0 ? "30px" : "80px"}
        >
          {(initialData?.id === 0 || initialData?.id === undefined) && (
            <Flex justify="space-between">
              <Text fontSize="1.5rem" color="#000000" fontWeight="bold">
                Novo Plano
              </Text>
              <Button
                color="red.500"
                alignSelf="flex-start"
                variant="unstyled"
                transition="filter .2s"
                _hover={{
                  transition: "color .2s",
                  filter: "brightness(.8)",
                }}
                onClick={() => navigate(-1)}
              >
                Cancelar
              </Button>
            </Flex>
          )}

          {initialData?.id !== 0 && initialData?.id !== undefined && (
            <Flex justify="space-between" mb="1rem">
              <Text fontSize="1.5rem" color="#000000" fontWeight="bold">
                Editar Plano
              </Text>
            </Flex>
          )}

        </Flex>

        <VStack
          as="form"
          spacing={8}
          mt="2rem"
          onSubmit={handleSubmit(handleSavePlan)}
        >
          <Flex marginTop="6" w="full" alignItems="center">
            <Input
              type="text"
              labelColor="#000000"
              color="#000000"
              placeholder="Título"
              label="Título"
              defaultValue={initialData?.title}
              name="title"
              register={{ ...register("title") }}
              errors={errors?.title}
            />
          </Flex>

          <Flex flexDir="column" w="100%">
            <TextArea
              opcional
              color="#000000"
              borderRadius="3"
              name="description"
              register={{ ...register("description") }}
              label="Descrição"
              defaultValue={initialData?.description}
              maxLength={500}
              onKeyUp={(e) =>
                setTypedLetters(
                  (e.target as HTMLTextAreaElement)?.value.split("").length
                )
              }
            />
            <Flex justify="flex-end" color="gray.300" fontSize="sm">
              {typedLetters}/500
            </Flex>
          </Flex>

          <ItemsList
            tooltip="Produtos ou serviços inclusos no plano"
            optional
            label="Itens"
            name="itemsList"
            list={initialData?.bullets}
            updateList={handlePlans}
            buttonText="Salvar"
          />

          <Periodicity
            setValue={setValue}
            basicValues={basicValues}
            register={register}
            errors={errors}
            watch={watch}
          />

          {(initialData.enableCreditCard ||
            initialData.enableBankSlip ||
            initialData.enablePixPayment) && (
            <PaymenthsMethods
              data={initialData}
              register={register}
              errors={errors?.paymentMethods}
            />
          )}

          <Button
            id="novo_plano"
            type="submit"
            borderRadius="3"
            bgColor="blue.200"
            border="none"
            h="46"
            w="67"
            variant="outline"
            isLoading={loading}
            color="white"
            transition="filter .2s"
            _hover={{
              transition: "filter .2s",
              filter: "brightness(.9)",
            }}
          >
            Finalizar
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default WithAuth(PlanPage);
