import { Flex } from "@chakra-ui/react";
import { BreadCrumbs } from "@/components";
import { NotificationDrawer } from "@/layouts";

export function Navbar() {
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  // const featureTogglesUser = localStorage?.getItem("barte-features-list-user");
  return (
    <Flex borderBottom="1px solid #E6E9F0" bgColor="gray.10" h="100%">
      <Flex w="100%" h="100%" align="center" justify="space-between" p="0 1rem">
        {<BreadCrumbs />}
        {(user?.roles?.includes("SELLER_ADMIN") ||
          user?.roles?.includes("SELLER_VIZUALIZATION") ||
          user?.roles?.includes("SELLER_BACKOFFICE")) && <NotificationDrawer />}
      </Flex>
    </Flex>
  );
}
