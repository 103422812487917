import { ReactNode } from "react";
import { Container } from "./styles";
interface AlertBannerProps {
    iconTitle?: ReactNode;
    title?: string;
    description?: string;
}

export function AlertBanner({ iconTitle, title, description }: AlertBannerProps) {
    return(
        <Container>
            <section>
                {iconTitle && iconTitle}
                <h1>{title}</h1>
            </section>
            <span>{description}</span>
        </Container>
    )
}