import { RadioElement, RadioGroup } from "@/components";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PaymentMethodData, PeriodicityButtonProps } from "@/models";

export function PeriodicityButton({
  Options,
  setSelectedOption,
  selectedOption,
}: PeriodicityButtonProps) {
  const [data, setData] = useState<Array<PaymentMethodData>>(Options);

  function setStartAndEndPosition(paymentMethod: Array<PaymentMethodData>) {
    const newPaymentMethod = paymentMethod.map((data, index) => {
      if (index === 0) {
        return (data = {
          ...data,
          position: "start",
        });
      } else if (index === paymentMethod.length - 1) {
        return (data = {
          ...data,
          position: "end",
        });
      } else {
        return (data = {
          ...data,
          position: "middle",
        });
      }
    });
    setData(newPaymentMethod);
  }

  function handleBorderRadius(position: string): string {
    if (position === "start") return ".1rem 0 0 .1rem";
    else if (position === "end") return "0 .1rem .1rem 0";

    return "none";
  }

  useEffect(() => {
    setStartAndEndPosition(Options);
  }, []);

  return (
    <Flex flexDir="column">
      <Text marginBottom=".2rem" fontSize=".8rem" color="gray.600">
        Periodicidade
      </Text>
      <RadioGroup height="40px">
        {data.map((method) => (
          <RadioElement
            fontWeight="normal"
            border="1px solid"
            borderColor={method.key === selectedOption ? "blue.200" : "#D9D9D9"}
            color={method.key === selectedOption ? "blue.200" : "black"}
            bgColor="white"
            p="0 .5rem"
            w="100px"
            transition="all .2s"
            borderRadius={handleBorderRadius(method?.position)}
            name={method.name}
            key={method.key}
            _hover={{
              transition: "all .2s",
              borderColor: "blue.200",
              color: "blue.200",
            }}
            _focus={{}}
            onClick={() => setSelectedOption(method.key)}
          />
        ))}
      </RadioGroup>
    </Flex>
  );
}
