import { WithAuth } from "@/hoc";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { TitleSection } from "@/layouts/TitleSection";
import { Input } from "@/components/Forms/Input";
import { TextArea } from "@/components/Forms/TextArea";
import { copyTextToClipboard } from "@/utils/copyToClipboard";

import {
  Flex,
  Button as ChakraButton,
  Box,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/react";
import { BackButton } from "@/components";
import { Api } from "@/services";
import { CheckoutProps } from "@/models";
import { showToast } from "@/utils";

type FormValues = {
  title: string;
  description: string;
  notes: string;
  subDomain: string;
};

const schema = yup.object().shape({
  title: yup.string().required("Título é um campo obrigatório!"),
  subDomain: yup.string().required("Url é um campo obrigatório!"),
});

const MeuCheckoutInfo = () => {
  const [checkoutDefault, setCheckoutDefault] = useState({} as CheckoutProps);
  const [isAddMode, setIsAddMode] = useState(0);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      return isAddMode ? createCheckout(values) : editCheckout(values);
    }
  };

  async function getInitialData() {
    const response = await Api.get(`/checkouts/default`);
    setCheckoutDefault(response.data);
    setValue("title", response.data.title);
    setValue("description", response.data.description);
    setValue("subDomain", response.data.subDomain);
  }
  useEffect(() => {
    getInitialData();
  }, []);

  async function createCheckout(data) {
    try {
      const urlCheckout = await Api.get(`subdomains/${data.subDomain}/exists`);
      if (urlCheckout.data.isValid) {
        toast("URL já em uso por outro checkout!", {
          closeOnClick: true,
          type: "error",
        });
      } else {
        try {
          const checkout = await Api.post("/checkouts", data);
          toast("Checkout cadastrado com sucesso!", {
            closeOnClick: true,
            type: "success",
          });
        } catch (error) {
          error?.response?.data?.errors.map((error) => {
            showToast({
              type: "error",
              message: `${error?.code} - ${error?.description}`
            })
          });
        }
        reset({ title: "", description: "", subDomain: "" });
      }
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  async function editCheckout(data: any) {
    try {
      const editCheckout = await Api.put(
        `checkouts/${checkoutDefault.id}`,
        data
      );
      toast("Checkout editado com sucesso!", {
        closeOnClick: true,
        type: "success",
      });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  useEffect(() => {
    if (!isAddMode) {
      const fields = ["description", "title", "notes", "subDomain"];
      fields.forEach((field: any) => setValue(field, checkoutDefault[field]));
    }
    setValue("subDomain", checkoutDefault.subDomain);
  }, []);

  return (
    <Box>
      <BackButton />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          textAlign="start"
          display="flex"
          flexDirection="column"
          margin="0 auto"
          maxW="800px"
        >
          <Flex direction="column" mb={5} w="100%">
            <TitleSection alignSelf="flex-start" text="Título do checkout" />
            <Input
              type="text"
              register={{ ...register("title") }}
              name="title"
              placeholder="Insira o título"
              errors={errors?.title}
              defaultValue={checkoutDefault.title}
            />
          </Flex>

          <Flex direction="column" mb={5}>
            <TitleSection alignSelf="flex-start" text="Descrição do checkout" />

            <TextArea
              register={{ ...register("description") }}
              name="description"
              placeholder="Descrição"
              variant="outline"
              errors={errors?.description}
              aria-label="Insira sua descrição aqui..."
              aria-invalid={errors.description ? "true" : "false"}
            />
          </Flex>

          {/* <Flex direction="column" mb={5} w="100%">
            <TitleSection
              alignSelf="flex-start"
              text={t("checkout-info:notesCheckout")}
            />

            <Input
              type="text"
              register={{ ...register("notes") }}
              name="notes"
              placeholder="Notes"
              defaultValue={checkoutDefault?.notes}
            />
          </Flex> */}

          <TitleSection alignSelf="flex-start" text="URL do checkout" />
          <Flex direction="column" mb={8} w="100%">
            <InputGroup>
              <InputLeftAddon
                children={`${import.meta.env.VITE_CHECKOUT_URL}`}
                cursor="default"
              />
              <Input
                type="text"
                register={{ ...register("subDomain") }}
                name="subDomain"
                fontWeight="600"
                color="black"
                errors={errors?.subDomain}
                defaultValue={checkoutDefault.subDomain}
                borderTopLeftRadius={0}
                borderBottomLeftRadius={0}
              />
              <InputRightAddon
                id="checkout"
                bgColor="transparent"
                color="blue.200"
                children={"Copiar link"}
                _hover={{
                  textDecoration: "underline",
                }}
                onClick={() =>
                  copyTextToClipboard(
                    `${import.meta.env.VITE_CHECKOUT_URL}${getValues(
                      "subDomain"
                    )}`
                  )
                }
                cursor="pointer"
              />
            </InputGroup>
          </Flex>

          <ChakraButton
            alignSelf="center"
            type="submit"
            loadingText={"Salvando"}
            variant="outline"
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Salvar
          </ChakraButton>
        </Flex>
      </form>
    </Box>
  );
};

export default WithAuth(MeuCheckoutInfo);
