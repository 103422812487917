import { RadioElement, RadioGroup } from "@/components";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PaymentMethodData, PeriodicityButtonProps } from "@/models";

interface TaxTypeGroupProps<T> {
  options: Array<T>;
  setSelectedOption: any;
  selectedOption: any;
}

export function TaxTypeGroup({
  options,
  setSelectedOption,
  selectedOption,
}: TaxTypeGroupProps<any>) {
  const [data, setData] = useState<Array<PaymentMethodData>>(options);

  function setStartAndEndPosition(paymentMethod: Array<PaymentMethodData>) {
    const newPaymentMethod = paymentMethod.map((data, index) => {
      if (index === 0) {
        return (data = {
          ...data,
          position: "start",
        });
      } else if (index === paymentMethod.length - 1) {
        return (data = {
          ...data,
          position: "end",
        });
      }
    });
    setData(newPaymentMethod);
  }

  function handleBorderRadius(position: string): string {
    if (position === "start") return ".1rem 0 0 .1rem";
    else if (position === "end") return "0 .1rem .1rem 0";

    return "none";
  }

  useEffect(() => {
    setStartAndEndPosition(options);
  }, []);

  return (
    <Flex flexDir="column">
      <Text marginBottom=".2rem" fontSize=".8rem" color="gray.600">
        Tipo de taxa
      </Text>
      <RadioGroup height="40px">
        {data.map(value => (
          <RadioElement
            fontWeight="normal"
            border="1px solid"
            borderColor={value.key === selectedOption ? "blue.200" : "#D9D9D9"}
            color={value.key === selectedOption ? "blue.200" : "black"}
            bgColor="white"
            p="0 .5rem"
            transition="all .2s"
            borderRadius={handleBorderRadius(value?.position)}
            name={value.name}
            key={value.key}
            _hover={{
              transition: "all .2s",
              borderColor: "blue.200",
              color: "blue.200",
            }}
            _focus={{}}
            onClick={() => setSelectedOption(value.key)}
          />
        ))}
      </RadioGroup>
    </Flex>
  );
}