import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Divider,
  Flex,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Icon,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Alert, BackButton } from "@/components";
import { WithAuth } from "@/hoc";
import {
  formatCharge,
  getInitials,
  getColorBadgeStatus,
  getStatus,
  showToast,
  phone_format,
  formatDate,
} from "@/utils";
import { useAlert, useCheckout, useCharge } from "@/hooks";
import { FaWhatsapp, FaRegEnvelope } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import { ExcludeCharge } from "@/layouts/ExcludeCharge";
import { ChangeToPaid } from "@/layouts/ChangeToPaid";
import { InvoiceTable } from "@/layouts/ChargeHistory/InvoiceTable";
import ChargeInstallments from "@/components/FeatureToggleComponents/charges/ChargeInstallments";

function CobrancaAgendadaDetalhes() {
  const [charge, setCharge] = useState({} as any);
  const [subDomain, setSubDomain] = useState("");
  const [deleteChargeModalState, setDeleteChargeModalState] = useState(false);
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  const { getCheckoutDefault } = useCheckout();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      return;
    }
    getChargeData();
  }, [id]);

  async function getChargeData() {
    try {
      const chargeResponse = await getChargeById(String(id));
      const checkoutResponse = await getCheckoutDefault();
      const formattedData = formatCharge(chargeResponse.data);

      setCharge(formattedData);
      setSubDomain(checkoutResponse.data.subDomain);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  const { onClose, showAlert, cancelRef, setShowAlert } = useAlert();
  const [isActiveCharge, setIsActiveCharge] = useState(false);
  const { setToPaid, getChargeById, deleteCharge } = useCharge();

  const paymentMethod = charge.paymentMethod === "Boleto" ? "boleto" : "pix";
  const paymentMethodMessage =
    charge.paymentMethod === "Boleto" ? "Ver Boleto" : "Ver código PIX";

  const initials = getInitials(charge?.customerName ?? "-");

  async function handleChangeToPaid() {
    try {
      const response = await setToPaid(id, { uuidCharge: charge.uuid });
      const response_charge = await getChargeById(charge.uuid);
      const formattedData = formatCharge(response_charge.data);

      setCharge({
        ...formattedData,
        customerPhone: phone_format(formattedData.customerPhone),
      });

      showToast({
        message: "Cobrança alterada para paga manualmente com sucesso!",
        type: "success",
      });

      onClose();
    } catch (error) {
      onClose();
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  function handleToggleDeleteChargeModal() {
    setDeleteChargeModalState(!deleteChargeModalState);
  }

  async function handleDeleteCharge() {
    try {
      await deleteCharge(id);

      showToast({
        type: "success",
        message: "Sucesso na exclusão da cobrança.",
      });

      setDeleteChargeModalState(!deleteChargeModalState);

      navigate(-1);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  useEffect(() => {
    setIsActiveCharge(charge.formattedStatus === "Ativo" ? true : false);
  }, [charge]);

  return (
    <>
      <Alert
        closeButtonText="Não"
        confirmButtonText="Sim"
        isConfirmAlert={false}
        isOpen={deleteChargeModalState}
        onConfirm={handleDeleteCharge}
        onClose={handleToggleDeleteChargeModal}
        title="Tem certeza que seja deletar essa cobrança?"
        messageBody="Essa ação não poderá ser desfeita"
        leastDestructiveRef={cancelRef}
      />

      <Box>
        <Box my={4}>
          <BackButton />
        </Box>

        <Divider />

        <Flex align="center" justify="space-between" my="8" mr="4rem">
          <Flex align="center" gridGap="4">
            <Badge
              fontSize="1rem"
              minWidth="35px"
              minHeight="35px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              colorScheme="yellow"
              color="gray.800"
            >
              {initials}
            </Badge>

            <Text color="gray.800" fontWeight="600" fontSize="25px">
              {charge.customerName}
            </Text>
          </Flex>

          <Flex>
            <Flex
              flexDirection="column"
              justifyContent="right"
              color="gray.800"
            >
              <Flex fontSize="1.2rem" alignItems="center">
                {charge.customerPhone ? (
                  <>
                    <Icon as={FaWhatsapp} boxSize="1.4rem" mr="6px" />
                    <ChakraLink
                      id="clique_em_whatsapp"
                      href={`https://Api.whatsapp.com/send?phone=${charge?.countryCode?.split("+")[1] ?? "55"}${charge?.customerPhone}`}
                      target="_blank"
                    >
                      Contatar via whatsapp
                    </ChakraLink>
                  </>
                ) : (
                  <>
                    <Icon
                      id="clique_em_whatsapp"
                      as={FaRegEnvelope}
                      boxSize="1.4rem"
                      mr="6px"
                      color="gray.400"
                    />
                    <Text id="clique_em_whatsapp" color="gray.400">
                      Contatar via whatsapp
                    </Text>
                  </>
                )}
              </Flex>
              <Flex ml="1.6rem">
                {charge.customerPhone
                  ? phone_format(charge.customerPhone)
                  : "-"}
              </Flex>
            </Flex>

            <Flex
              ml="3rem"
              flexDirection="column"
              justifyContent="right"
              color="gray.800"
            >
              <Flex fontSize="1.2rem" alignItems="center">
                {charge.customerEmail ? (
                  <>
                    <Icon as={FaRegEnvelope} boxSize="1.4rem" mr="6px" />
                    <ChakraLink
                      id="clique_em_email"
                      href={`mailto:${charge.customerEmail}`}
                      target="_blank"
                    >
                      Contatar via email
                    </ChakraLink>
                  </>
                ) : (
                  <>
                    <Icon
                      id="clique_em_email"
                      as={FaRegEnvelope}
                      boxSize="1.4rem"
                      mr="6px"
                      color="gray.400"
                    />
                    <Text color="gray.400">Contatar via email</Text>
                  </>
                )}
              </Flex>
              <Flex ml="1.6rem">
                {charge.customerEmail ? charge.customerEmail : "-"}
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Divider />

        <SimpleGrid columns={4} minChildWidth={100} my="8">
          <Text as="h2" fontSize="20" fontWeight="600">
            Informações gerais
          </Text>

          <Text></Text>

          {(charge.status === "SCHEDULED" || charge.status === "LATE") && (
            <>
              {charge.paymentMethod !== "Cartão de Crédito" && (
                <ChakraLink
                  href={subDomain !== "" ? `${import.meta.env.VITE_CHECKOUT_URL}${subDomain}/${paymentMethod}/${id}` : `${import.meta.env.VITE_CHECKOUT_URL}${paymentMethod}/${id}`}
                  target="_blank"
                  color="black"
                  border="1px solid"
                  borderColor="gray.200"
                  h="24px"
                  borderRadius="3"
                  variant="outline"
                  maxW="145px"
                  textAlign="center"
                  transition="background-color .2s"
                  _hover={{
                    transition: "background-color .2s",
                    bgColor: "gray.100",
                    textDecoration: "none",
                  }}
                >
                  {paymentMethodMessage}
                </ChakraLink>
              )}

              {charge.paymentMethod === "Cartão de Crédito" && <Text></Text>}

              <ExcludeCharge
                handleToggleDeleteChargeModal={handleToggleDeleteChargeModal}
              />
            </>
          )}
        </SimpleGrid>

        <SimpleGrid columns={4} my="8" rowGap={8} columnGap={0}>
          <Flex direction="column">
            <Text color="gray.300" fontSize="14px">
              Produto/Serviço
            </Text>

            <Text color="gray.800" fontSize="14px">
              {charge.title}
            </Text>
          </Flex>

          <Flex direction="column">
            <Text color="gray.300" fontSize="14px">
              Vencimento
            </Text>

            <Text color="gray.800" fontSize="14px">
              {formatDate(charge.expirationDate)}
            </Text>
          </Flex>

          <Flex direction="column" align="flex-start">
            <Text color="gray.300" fontSize="14px">
              Status
            </Text>

            <Badge variant={getColorBadgeStatus(charge.status)} fontSize="14px">
              {getStatus(charge.status)}
            </Badge>
          </Flex>

          <Flex direction="column">
            <Text color="gray.300" fontSize="14px">
              Código da cobrança
            </Text>

            <Text color="gray.800" fontSize="14px">
              {charge.uuid}
            </Text>
          </Flex>

          <Flex direction="column">
            <Text color="gray.300" fontSize="14px">
              Método de pagamento
            </Text>

            <Flex align="center" gap="2px">
              <Text color="gray.800" fontSize="14px"> {charge.paymentMethod} </Text>
              {charge?.installments && <ChargeInstallments installments={charge?.installments}/>}
            </Flex>
          </Flex>

          <Flex direction="column">
            <Text color="gray.300" fontSize="14px">
              Valor
            </Text>

            <Text color="gray.800" fontSize="14px">
              {charge.formattedValue}
            </Text>
          </Flex>

          <ChangeToPaid status={charge?.status} setShowAlert={setShowAlert} />
        </SimpleGrid>

        <Divider />
        {(user?.roles?.includes("SELLER_CONFIG_NF") ||
        user?.roles?.includes("SELLER_MASTER") ||
        user?.roles?.includes("SELLER_ADMIN") ||
        user?.roles?.includes("SELLER_BACKOFFICE")) && <InvoiceTable />}
        {(charge?.chargesTries && charge?.paymentMethod === "Cartão de Crédito") && (
          <>
            <Text as="h2" fontSize="20" fontWeight="600" mt="8">
              Histórico de tentativas
            </Text>
            <Table>
              <Thead>
                <Tr>
                  <Th
                    my="4"
                    color="gray.300"
                    fontSize="14px"
                    fontWeight="normal"
                  >
                    Tentativa
                  </Th>
                  <Th
                    my="4"
                    color="gray.300"
                    fontSize="14px"
                    fontWeight="normal"
                  >
                    Valor
                  </Th>
                  <Th
                    my="4"
                    color="gray.300"
                    fontSize="14px"
                    fontWeight="normal"
                  >
                    Status
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {charge?.chargesTries?.map(
                  ({ expirationDate, status, uuid, formattedValue }) => (
                    <Tr key={uuid}>
                      <Td>{formatDate(expirationDate)}</Td>
                      <Td>{formattedValue}</Td>
                      <Td>
                        {status ? (
                          <Badge
                            fontSize="1rem"
                            variant={getColorBadgeStatus(status)}
                          >
                            {getStatus(status)}
                          </Badge>
                        ) : (
                          "-"
                        )}
                      </Td>
                    </Tr>
                  )
                )}
              </Tbody>
            </Table>
          </>
        )}
        <Alert
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          onConfirm={handleChangeToPaid}
          isOpen={showAlert}
          title="Essa cobrança já foi paga?"
          messageBody={
            <p>
              Confirme para alterar o status dessa cobrança para <b>Paga Manualmente</b>. Essa alteração não poderá ser desfeita.
            </p>
          }
          closeButtonText="Cancelar"
          confirmButtonText="Confirmar"
        />
      </Box>
    </>
  );
}

export default WithAuth(CobrancaAgendadaDetalhes);
