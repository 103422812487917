import { ReactNode, KeyboardEvent } from "react";
import {
  Flex,
  Input as ChakraInput,
  Text,
  InputProps as ChakraInputProps,
  FormControl,
} from "@chakra-ui/react";
import { clearMask, cnpj as formatCnpj, cpf as formatCpf, showToast } from "@/utils";
import { OkayIcon } from "@/styles/icons/OkayIcon";
import { DocumentType } from "@/pages/configuracoes/subsellers/NewSubSeller";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { useClient } from "@/hooks";

interface InputProps extends ChakraInputProps {
  name: string;
  register?: any;
  errors?: any;
  mask?: string;
  hasSelectedLabel?: boolean;
  documentType?: string;
  documentIsValid: boolean;
  documentTypeFunc: (type: DocumentType) => void;
  setDocumentIsValid: (value: boolean) => void;
}

export function InputLabelTag({
  name,
  register,
  errors,
  mask,
  hasSelectedLabel,
  documentType,
  documentIsValid,
  documentTypeFunc,
  setDocumentIsValid,
  ...rest
}: InputProps) {
  const { consultCNPJ } = useClient();
  async function markLabel(validateFunc: any, doc: string, docType: DocumentType) {
    if(validateFunc === cnpj) {
      try {
        await consultCNPJ(clearMask(doc))
        setDocumentIsValid(true);
        documentTypeFunc(docType);
      } catch(error) {
        setDocumentIsValid(false);
        error?.response?.data?.errors?.map((error) => {
          showToast({
            type: "error",
            message: `${error?.code} - ${error?.description}`
          })
        });
      }
      return
    }
    setDocumentIsValid(validateFunc.isValid(clearMask(doc)));
    documentTypeFunc(docType);
  }

  function handleValidateDocumentType(document: string, docType: DocumentType): void {
    if (docType === "CPF") {
      markLabel(cpf, document, docType);
      return;
    }

    if (docType === "CNPJ") {
      markLabel(cnpj, document, docType);
      return;
    }

    if (docType === "") {
      setDocumentIsValid(false);
      return;
    }
  }

  function documentFormatFunction(document: string) {
    if (clearMask(document).length === 14) {
      handleValidateDocumentType(document, "CNPJ");
      return formatCnpj;
    }
    if (clearMask(document).length === 11) {
      handleValidateDocumentType(document, "CPF");
      return formatCpf;
    }
    handleValidateDocumentType(null, "");
  }

  const handleMaskOnBlur = (e: KeyboardEvent<HTMLInputElement>) => {
    documentFormatFunction(e.currentTarget.value);
  };

  const Component: ReactNode = (
    <ChakraInput
      onBlurCapture={handleMaskOnBlur}
      name={name}
      borderColor={errors ? "danger" : "newGray.200"}
      border={errors ? "1.5px solid" : "1px solid"}
      borderRadius="4px"
      w="100%"
      color={"black"}
      _focus={{
        border: "1px solid",
        borderColor: "blue.200",
      }}
      {...register}
      {...rest}
    />
  );

  const selectedTagComponent = (title: string) => (
    <Flex
      height="20px"
      borderRadius="4px"
      fontSize=".8rem"
      marginBottom=".2rem"
      bgColor={documentIsValid ? "#E6E9F0" : "#FFA39E"}
      padding="3px 4px"
      gap="1px"
      align="center"
    >
      <Text
        fontSize=".8rem"
        color={documentIsValid ? "#556077" : "#ff4d50ce"}
      >
        {title}
      </Text>
      <OkayIcon
        size={10}
        color={documentIsValid ? "#8A94A8" : "#ff4d50af"}
      />
    </Flex>
  )

  return (
    <FormControl isInvalid={!!errors?.message}>
      <Flex flexDir="column">
        <Flex align="center" justify="space-between">
          <Flex align="center">
            {hasSelectedLabel ? (
              <>
                {
                  (documentType === "CNPJ") ?
                    selectedTagComponent("CNPJ") :
                    (
                      <Text
                        marginBottom=".2rem"
                        fontSize=".8rem"
                      >
                        CNPJ
                      </Text>
                    )
                }
                <Text fontSize=".8rem" marginBottom=".2rem">/</Text>
                {
                  (documentType === "CPF") ?
                    selectedTagComponent("CPF") :
                    (
                      <Text
                        marginBottom=".2rem"
                        fontSize=".8rem"
                      >
                        CPF
                      </Text>
                    )
                }
              </>
            ) : (
              <>
                <Text fontSize=".8rem" marginBottom=".2rem">
                  {documentType}
                </Text>
              </>
            )}
          </Flex>
        </Flex>
        {Component}
        {errors && (
          <Text fontSize=".8rem" color="danger" mb={errors?.message && "4"} role="alert">
            {errors?.message}
          </Text>
        )}
      </Flex>
    </FormControl>
  );
}
