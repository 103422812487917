import { ClientData, ClientsAddressData, ClientsCnpjData, ClientsPaginator, GetClientData } from "@/models/Clients";
import { Api, cepApi } from "@/services";
import { filterAndPageableToRequest } from "@/utils";

export function useClient() {
  const baseUrl = "/seller-clients";

  const getClient = (id: number) => {
    return Api.get<GetClientData>(`${baseUrl}/${id}`);
  };

  const putClient = (id: number, data: ClientData) => {
    return Api.put<ClientData>(`${baseUrl}/${id}`, data);
  };

  const patchClientAddress = (id: number, data: ClientsAddressData) => {
    return Api.patch<ClientsAddressData>(`${baseUrl}/${id}/address`, data);
  }

  const getClients = (filter: object, pageable: object) => {
    return Api.get<ClientsPaginator>(
      `${baseUrl}${filterAndPageableToRequest({ filter, pageable })}`
    );
  };

  const postClient = (data: ClientData) => {
    return Api.post<ClientData>(`${baseUrl}`, data);
  };

  const consultCep = (cep: string) => {
    return cepApi.get(`${cep}/json`);
  }

  const consultCNPJ = (cnpj: string) => {
    return Api.get<ClientsCnpjData>(`/public/companies/${cnpj}`);
  }

  return {
    getClient,
    putClient,
    getClients,
    postClient,
    consultCep,
    consultCNPJ,
    patchClientAddress,
  };
}
