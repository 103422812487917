import { Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useLocation } from 'react-router-dom';

interface BackButtonProps {
  text?: string;
  hasLink?: boolean;
  link?: string
}

const BackButton = ({ text, hasLink = false, link = ""}: BackButtonProps) => {


  const location = useLocation();
  const path = location.pathname;
  const lastSlashIndex = path.lastIndexOf('/');
  const routeWithoutLastPart = path.slice(0, lastSlashIndex);

  const navigate = useNavigate();

  return (
    <Flex w="100">
      <Button
        onClick={() => hasLink ? navigate(link) : navigate(routeWithoutLastPart)}
        leftIcon={<MdOutlineArrowBackIos size={25} />}
        variant="unstyled"
        transition="color .3s"
        _hover={{
          transition: "color .3s",
          color: "black",
        }}
        _focus={{}}
      >
        {text}
      </Button>
    </Flex>
  );
};

export { BackButton };
