import { Badge, Box, Flex, Link, Icon } from "@chakra-ui/react";
import { ColumnsType } from "antd/lib/table";
import { Charge } from "@/models";
import { FaLongArrowAltRight } from "react-icons/fa";
import {
  formatCurrency,
  getColorBadgeStatus,
  getStatus,
  formatDate,
} from "@/utils";

export const historyColumns: ColumnsType<Charge> = [
  {
    key: "expirationDate",
    title: "Vencimento",
    dataIndex: "expirationDate",
    render: (expirationDate) => formatDate(expirationDate),
  },

  {
    key: "title",
    title: "Produto / Serviço",
    dataIndex: "title",
  },

  {
    key: "value",
    title: "Valor/mês",
    dataIndex: "value",
    render: (value) => formatCurrency({ value }),
  },

  {
    key: "status",
    title: "Status",
    dataIndex: "status",
    render: (status) =>
      status ? (
        <Badge fontSize="1rem" variant={getColorBadgeStatus(status)}>
          {getStatus(status)}
        </Badge>
      ) : (
        "-"
      ),
  },
  {
    key: "buttonOptions",
    title: "",
    render: (text, record) => (
      <Box p="0" w="80px" h="30px">
        <Flex
          w="100px"
          h="30px"
          align="center"
          justify="space-between"
          className="rowAction"
        >
          <Flex
            align="center"
            justify="center"
            h="40px"
            w="40px"
            transition="all .2s"
            _hover={{
              transition: "all .2s",
              bgColor: "white",
            }}
          >
            <Link href={`/cobrancas-historico/${record.uuid}`} h="20px">
              <a>
                <Icon as={FaLongArrowAltRight} boxSize="20px" />
              </a>
            </Link>
          </Flex>
        </Flex>
      </Box>
    ),
  },
];

export const scheduledColumns: ColumnsType<Charge> = [
  {
    key: "expirationDate",
    title: "Vencimento",
    dataIndex: "expirationDate",
    render: (expirationDate) => formatDate(expirationDate),
  },
  {
    key: "value",
    title: "Valor/mês",
    dataIndex: "value",
    render: (value) => formatCurrency({ value }),
  },

  {
    key: "status",
    title: "Status",
    dataIndex: "status",
    render: (status) =>
      status ? (
        <Badge fontSize="1rem" variant={getColorBadgeStatus(status)}>
          {getStatus(status)}
        </Badge>
      ) : (
        "-"
      ),
  },
  {
    key: "buttonOptions",
    title: "",
    render: (text, record) => (
      <Box p="0" w="80px" h="30px">
        <Flex
          w="100px"
          h="30px"
          align="center"
          justify="space-between"
          className="rowAction"
        >
          <Flex
            align="center"
            justify="center"
            h="40px"
            w="40px"
            transition="all .2s"
            _hover={{
              transition: "all .2s",
              bgColor: "white",
            }}
          >
            <Link href={`/cobrancas-agendadas/${record.uuid}`} h="20px">
              <a>
                <Icon as={FaLongArrowAltRight} boxSize="20px" />
              </a>
            </Link>
          </Flex>
        </Flex>
      </Box>
    ),
  },
];

export const manualsColumns: ColumnsType<Charge> = [
  {
    key: "date",
    title: "Data",
    dataIndex: "date",
    render: (date) => formatDate(date),
  },
  {
    key: "value",
    title: "Valor/mês",
    dataIndex: "value",
    render: (value) => formatCurrency({ value }),
  },
  {
    key: "buttonOptions",
    title: "",
    render: (text, record) => (
      <Box p="0" w="80px" h="30px">
        <Flex
          w="100px"
          h="30px"
          align="center"
          justify="space-between"
          className="rowAction"
        >
          <Flex
            align="center"
            justify="center"
            h="40px"
            w="40px"
            transition="all .2s"
            _hover={{
              transition: "all .2s",
              bgColor: "white",
            }}
          >
            <Link href={`/recebimentos-manuais`} h="20px">
              <a>
                <Icon as={FaLongArrowAltRight} boxSize="20px" />
              </a>
            </Link>
          </Flex>
        </Flex>
      </Box>
    ),
  },
];
