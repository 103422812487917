import React, { ReactNode, useEffect, useState } from "react";
import { useCustomLink } from "@/hooks";
import { formatCurrencyToApi, showToast } from "@/utils";
import { formatPercentageToApi } from "@/utils/formatPercentageToApi";
import { usePreviewContext } from "@/contexts/PreviewContext";

export interface Installment {
  installments: ReactNode;
  totalValue: number;
  totalToReceive: number;
  numberOfInstallments: number;
  installmentAmount: number;
  totalAmount: number;
  discount: number;
}

export interface InstallmentArray {
  installmentsToBuyer?: Installment[];
  installmentsToSeller?: {
    installments: ReactNode;
    discount: any;
    totalAfterDiscount: React.ReactNode;
    numberOfInstallments: number;
    receivedAmount: number;
  }[];
}

export function useAdvanceReceipt(
  anchoringState: boolean,
  watch: any,
  creditCardDiscount: string,
  linkType?: string,
  installmentsName?: string
) {
  const [installmentArray, setInstallmentArray] = useState<InstallmentArray>();
  const [totalValue, setTotalValue] = useState<string>();
  const { installmentsPayment } = useCustomLink();
  const { previewData, setPreviewData } = usePreviewContext();
  const value = watch("value")?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  async function createInstallmentArray(value: number, numberOfInstallments: number) {
    if (linkType === "customSignature") return;
    if (isNaN(value)) return;
    else {
      try {
        const { data } = await installmentsPayment(value, numberOfInstallments);
        const buyerInstallments = data?.installmentsToBuyer?.map((item, index) => {
          let totalAfterDiscount = item?.totalAmount - (item?.totalAmount * ((formatPercentageToApi(watch(creditCardDiscount)) ? formatPercentageToApi(watch(creditCardDiscount)) : 0) / 100));
          let totalToReceive = data?.installmentsToSeller[0].receivedAmount * (100 - Number(formatPercentageToApi(watch(creditCardDiscount)))) / 100
          let totalValue = data?.installmentsToBuyer?.at(0)?.totalAmount
          let discount = (1 - (totalAfterDiscount / totalValue)) * 100

          return {
            ...item,
            installmentAmount: totalAfterDiscount / (index + 1),
            totalAmount: totalAfterDiscount,
            totalToReceive: !Number.isNaN(totalToReceive) ? totalToReceive : totalValue,
            totalValue,
            discount: discount.toFixed(2) >= "0" ? discount.toFixed(2) : null
          }
        });
        const sellerInstallments = data?.installmentsToSeller?.map((item) => {
          let totalAfterDiscount = null;
          totalAfterDiscount = item?.receivedAmount - (item?.receivedAmount * ((formatPercentageToApi(watch(creditCardDiscount)) ? formatPercentageToApi(watch(creditCardDiscount)) : 0) / 100));
          return {
            ...item,
            receivedAmount: totalAfterDiscount ? totalAfterDiscount : item.receivedAmount,
            totalAfterDiscount: item?.totalAmount,
            discount: Number(formatPercentageToApi(watch(creditCardDiscount)))
          }
        });

        setInstallmentArray({
          installmentsToBuyer: buyerInstallments,
          installmentsToSeller: sellerInstallments
        });
        setPreviewData((prevPreviewData) => ({
          ...prevPreviewData,
          arrayInstallments: {
            installmentsToBuyer: buyerInstallments,
            installmentsToSeller: sellerInstallments
          }
        }));
      } catch (error) {
        showToast({
          type: "error",
          message: "Não foi possível carregar os dados de valores"
        });
      }
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      createInstallmentArray(formatCurrencyToApi(watch("value")), watch(installmentsName));
    }, 1000);
    return () => clearTimeout(timer);
  }, [watch("value"), watch("installments"), anchoringState, formatPercentageToApi(watch(creditCardDiscount)), watch(installmentsName)]);

  return {
    installmentArray
  }
}
