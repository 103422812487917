import styled, { css } from "styled-components";

interface OverlayProps {
  isOpen?: boolean;
  hasContentUpdwards?: boolean;
  hasContentDownwards?: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  height: 100vh;
  overflow-y: hidden;
  background-color: var(--white-025);
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 5% 30%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isOpen }) => !isOpen && css`
    display: none;
  `}

  .modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height:100%;
    max-height: 380px;
    z-index: 200;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05), 0px 4px 20px rgba(0, 0, 0, 0.04);

    .modal-header {
      box-shadow: ${props => props.hasContentUpdwards ? "0px 12px 20px rgba(0, 0, 0, 0.03)" : ""};
      border-bottom: 1px solid var(--gray-100);
      height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;

      div.header-text {
        display: flex;
        align-items: center;
        gap: .8rem;
      }

      p {
        color: black;
        font-size: 1.1rem;
      }

      button {
        padding: 0;
        display: flex;
        border-radius: 2px;
        border: 1px solid #D9D9D9;
        background-color: white;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
      }
    }

    .modal-body {
      overflow-y: scroll;
      padding: 1.5rem 2rem;
      height: 100%;
      width: 100%;
    }

    .modal-footer {
      box-shadow: ${props => props.hasContentDownwards ? "0px -12px 20px rgba(0, 0, 0, 0.03);" : ""};
      border-top: 1px solid var(--gray-100);
      height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;

      button {
        padding: 0;
        background-color: white;
      }

      .confirm-button {
        background-color: var(--blue-200);
        padding: 5px 16px;
        border-radius: 2px;
        color: white;
        transition: all .2s ease-in-out;

        &:hover {
          transition: all .2s ease-in-out;
          filter: brightness(.9);
        }
      }

      p {
        font-size: .8rem;
        color: color="#000000D9";
      }
    }
  }
`