import axios from "axios";



function getAPIAirtable(url?: string) {

    const airTableApi = axios.create({
        baseURL: url,
        headers: {
            "Authorization": "Bearer patwwgDOIjYYiXcfN.1934137bcbd63fc090cb67bab4e5c1d740a31f59c5b153f46683103f3a735099"
        }
    })

    return airTableApi;

}
export default getAPIAirtable;
