import { IconProps } from "@/models/Icon";

export function ArrowDownIcon({ 
    size = 18, 
    color = "#000", 
}: IconProps) {
    return(
        <svg 
            width={size} 
            height={size}  
            viewBox="0 0 18 18" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M14.7729 5.27344H3.2276C2.88131 5.27344 2.68795 5.63906 2.9024 5.88867L8.67506 12.5824C8.84029 12.774 9.15845 12.774 9.32545 12.5824L15.0981 5.88867C15.3126 5.63906 15.1192 5.27344 14.7729 5.27344Z" 
                fill={color}
            />
        </svg>
    )
}


















