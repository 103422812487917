import { ArrowDownIcon } from "@/styles/icons";
import { Box, Flex, FlexProps, Input, SelectProps, Text } from "@chakra-ui/react";
import { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import styles from "./styles.module.scss";


interface SearchDropDownProps<T> extends FlexProps {
  register?: any;
  errors?: any;
  setValue?: any;
  initialValue?: string;
  label: string;
  name: string;
  options: Array<T>;
}

export function SearchDropDown<T>({
  label,
  initialValue,
  setValue,
  register,
  errors,
  name,
  options,
  ...rest
}: SearchDropDownProps<T>) {
  const [initalData, setInitialData] = useState<Array<any>>(options as Array<T>);
  const [filteredData, setFilteredData] = useState<Array<any>>(options as Array<T>);
  const [selectedData, setSelectedData] = useState<any>(filterAndSetInitialValue());
  const [dropDownState, setDropDownState] = useState<boolean>(false);

  function filterAndSetInitialValue() {
    return initalData?.filter(item => item.value === initialValue).at(0)
  }

  function handleDropDownToggleState() {
    setTimeout(() => {
      setDropDownState(!dropDownState);
    }, 200);
  }

  function selectData(data: any) {
    setSelectedData(data);
    setValue(name, data.value);
    setDropDownState(false);
    setFilteredData(initalData);
  }

  function handleSearchBox(e: string) {
    if (e.length === 0) {
      setFilteredData(initalData);
    }

    if (initalData?.length > 0) {
      if (initalData[0]?.label) {
        setDropDownState(true);
        setFilteredData(
          initalData?.filter(item => {
            return item.label?.toLowerCase()?.includes(e?.toLowerCase());
          }))
      }
    } else {
      setDropDownState(false);
    }

    if (selectedData?.value) {
      setSelectedData({} as any);
    }
  }

  return (
    <Flex flexDir="column" {...rest}>
      <div className={styles.container}>
        {label && <Text fontSize="13px">{label}</Text>}
        <Flex align="center" position="relative">
          <input
            type="button"
            className={`${styles.clientInput}`}
            onFocusCapture={handleDropDownToggleState}
            defaultValue={rest?.defaultValue}
            value={selectedData?.label}
            autoComplete="off"
          />
          <Box position="absolute" right="3">
            <ArrowDownIcon size={10} />
          </Box>
        </Flex>
        {dropDownState && (
          <div className={styles.dropDown}>
            <ul>
              <li>
                <Flex
                  className={styles.stickyContainer}
                  align="center"
                  position="relative"
                >
                  <input
                    type="text"
                    className={styles.searchInput}
                    onChange={(e) => handleSearchBox(e.target.value)}
                  />
                  <Box position="absolute" right="8">
                    <IoSearchOutline />
                  </Box>
                </Flex>
              </li>
              {filteredData?.map(data => (
                <li
                  key={data?.value}
                  onClick={() => selectData(data)}
                >
                  {data.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {errors && (
        <Text fontSize=".8rem" color="danger" role="alert">
          {errors?.message}
        </Text>
      )}
    </Flex>
  )
}