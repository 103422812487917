import { Controller, useController } from "react-hook-form";
import DatePicker from "react-datepicker";
import {
  forwardRef,
  Button as ChakraButton,
  Flex,
  Icon,
  Box,
  Text,
  Switch,
  Tooltip,
} from "@chakra-ui/react";
import {
  AiFillCalendar,
  AiOutlineCalendar,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import ptBR from "date-fns/locale/pt-BR";

interface DatePickerInputProps {
  selectedDate: Date;
  isCustomizableDate?: boolean;
  control: any;
  name: string;
  rules: any;
  locale?: any;
  register?: any;
  startEnabled?: boolean;
  tooltipText?: string;
  label?: string;
  hasSwitch?: boolean;
  disabledState?: boolean;
  setDisabledState?: (data: boolean) => void;
}

export function DatePickerInput({
  selectedDate = new Date(),
  isCustomizableDate = true,
  control,
  name,
  rules,
  register,
  startEnabled,
  tooltipText,
  label,
  hasSwitch,
  disabledState,
  setDisabledState,
  ...rest
}: DatePickerInputProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error },
  } = useController({
    name,
    rules,
    control,
  });

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <ChakraButton
      onClick={onClick}
      ref={ref}
      {...register}
      name={name}
      p="5px 12px"
      w="100%"
      borderRadius="4px"
      bgColor="transparent"
      border="1px solid"
      borderColor={disabledState ? "newGray.100" : "newGray.200"}
      fontSize="16px"
      disabled={hasSwitch && !disabledState}
      _focus={{
        border: "1px solid",
        borderColor: "blue.200",
      }}
      _hover={{
      }}
      _disabled={{
        cursor: "not-allowed",
      }}
      position="relative"
      color={hasSwitch && !disabledState ? "newGray.200" : "black"}
      fontWeight="500"
    >
      <Text position="absolute" left="35px">
        {value}
      </Text>
    </ChakraButton>
  ));
  return (
    <Flex flexDir="column" gap={"4px"}>
      <Flex justify="space-between" align="center">
        <Flex align="center" marginRight={tooltipText ? ".2rem" : ""}>
          {label && (
            <Text
              marginRight={tooltipText ? ".2rem" : ""}
              marginBottom=".2rem"
              fontSize=".8rem"
              color={hasSwitch && !disabledState ? "newGray.200" : "newGray.600"}
            >
              {label}
            </Text>
          )}
          {tooltipText && (
            <Tooltip placement="top" hasArrow label={tooltipText}>
              <span>
                <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
              </span>
            </Tooltip>
          )}
        </Flex>
        {hasSwitch && (
          <Switch
            isChecked={disabledState}
            defaultChecked={startEnabled}
            onChange={() => setDisabledState(!disabledState)}
            size="sm"
          />
        )}
      </Flex>
      <Flex w="100%" align="center" position="relative" justify="space-between"  >
        <Icon
          position="absolute"
          left="10px"
          as={AiOutlineCalendar
          }
          color={"rgba(0, 0, 0, 0.25)"}
        />
        <Controller
          name={name}
          control={control}
          defaultValue={selectedDate ?? new Date()}
          render={({ field }: any) => (
            <DatePicker
              {...rest}
              locale={ptBR}
              onBlur={onBlur}
              wrapperClassName="datePicker"
              selected={field.value}
              onChange={(date) => field.onChange(date)}
              minDate={new Date()}
              disabled={hasSwitch && !disabledState}
              customInput={<CustomInput />}
              dateFormat="dd/MM/yyyy"
            />
          )}
        />
      </Flex>
      {disabledState &&
        <Flex
          marginTop='-4px'
          backgroundColor="#FFFbe6"
          padding="14px 12px 12px 12px"
          border="1px solid #FFF1B8"
          color="#874D00"
          gap="10px"
          flexDirection="column"

        >
          <Text
            fontWeight="400"
            fontSize="14px"
            lineHeight="21px"
          >
            Mesmo que o seu cliente efetue o pagamento hoje, a cobrança será realizada apenas na data escolhida.
          </Text>
          <Text
            fontSize="14px"
            lineHeight="21px"
            fontWeight="400"
          >
            💡 Utilize essa opção caso seu cliente esteja sem limite no cartão de crédito na data de hoje.
          </Text>

        </Flex>
      }
    </Flex>
  );
}
