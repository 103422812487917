import { IconProps } from "@/models";

export function InfoIconNotFilled({
  size = 20,
  color = "#000",
  opacity = "1",
}: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0.375C4.68477 0.375 0.375 4.68477 0.375 10C0.375 15.3152 4.68477 19.625 10 19.625C15.3152 19.625 19.625 15.3152 19.625 10C19.625 4.68477 15.3152 0.375 10 0.375ZM10 17.9922C5.58711 17.9922 2.00781 14.4129 2.00781 10C2.00781 5.58711 5.58711 2.00781 10 2.00781C14.4129 2.00781 17.9922 5.58711 17.9922 10C17.9922 14.4129 14.4129 17.9922 10 17.9922Z" fill={color} opacity={opacity}/>
    <path d="M8.96841 13.7812C8.96841 14.0548 9.07706 14.3171 9.27046 14.5105C9.46386 14.7038 9.72616 14.8125 9.99966 14.8125C10.2732 14.8125 10.5355 14.7038 10.7289 14.5105C10.9223 14.3171 11.0309 14.0548 11.0309 13.7812C11.0309 13.5077 10.9223 13.2454 10.7289 13.052C10.5355 12.8587 10.2732 12.75 9.99966 12.75C9.72616 12.75 9.46386 12.8587 9.27046 13.052C9.07706 13.2454 8.96841 13.5077 8.96841 13.7812ZM9.48404 11.375H10.5153C10.6098 11.375 10.6872 11.2977 10.6872 11.2031V5.35938C10.6872 5.26484 10.6098 5.1875 10.5153 5.1875H9.48404C9.38951 5.1875 9.31216 5.26484 9.31216 5.35938V11.2031C9.31216 11.2977 9.38951 11.375 9.48404 11.375Z" fill={color} opacity={opacity}/>
    </svg>
  );
}
