import { useState } from "react";
import { Button, Flex, Heading, Stack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Input } from "@/components";
import { useResetPassword } from "@/hooks";
import { getApplicationName, showToast } from "@/utils";
import { BannerLateral } from "@/layouts";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Digite um e-mail válido!")
    .required("O campo e-mail é obrigatório!"),
});

type FormValues = {
  email: string;
};

const ResetPassword: React.FC = () => {
  const { sendEmailResetPassword } = useResetPassword();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({ resolver: yupResolver(validationSchema) });
  const applicationName = getApplicationName({});
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async (data: any) => {
    setLoading(true);

    try {
      const response = await sendEmailResetPassword(data.email);
      showToast({
        message: "Link enviado para seu e-mail, por favor, verifique!",
        type: "success",
      });

      setTimeout(() => {
        navigate("/login");
      }, 1500);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });

      setTimeout(() => {
        navigate("/login");
      }, 1500);
    }

    setLoading(false);
  });

  return (
    <Flex flexDir={["column", "column", "column", "row"]} overflow="hidden">
      <Flex align="center" w={["100%", "100%", "100%", "50%"]} justify="center">
        <Flex
          as="form"
          direction="column"
          align="flex-start"
          justify="center"
          h="100vh"
          w="380px"
          onSubmit={onSubmit}
          gap="6"
        >
          <img src={`/images/commons/logo-${import.meta.env.VITE_APPLICATION_NAME}.svg`} width="180" height="60" />
          <Flex w="100%" flexDirection="column" mb="40px">
            <Heading as="h3" mb="10px">
              Trocar senha
            </Heading>
            <Flex color="gray.400">
              Informe o seu email e enviaremos um link para você realizar a
              troca de senha
            </Flex>
          </Flex>
          <Stack w="100%">
            <Input
              label="Email"
              type="text"
              name="email"
              placeholder={"Insira seu email"}
              className="mb-3"
              register={{ ...register("email") }}
              errors={errors?.email}
              aria-label={"Insira seu email"}
              aria-invalid={errors.email ? "true" : "false"}
              fontSize="1rem"
              labelColor="black"
              mb="30px"
            />

            <Button
              isLoading={loading}
              type="submit"
              variant="outline"
              fontSize="1rem"
              py="1rem"
              w="150px"
              mt="40px!important"
            >
              Resetar Senha
            </Button>
          </Stack>
        </Flex>
      </Flex>

      <BannerLateral />
    </Flex>
  );
};

export default ResetPassword;
