import { Badge, Flex, Box, Text, Link, Icon, Image, Tooltip } from "@chakra-ui/react";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { FaLongArrowAltRight, FaWhatsapp, FaRegEnvelope } from "react-icons/fa";
import { Filter } from "@/components/Filter";
import { WithAuth } from "@/hoc";
import { useCharge } from "@/hooks";
import { Charge, ChargePaginator } from "@/models";
import { useEffect, useRef, useState } from "react";
import {
  getInitials,
  formatCurrency,
  getColorBadgeStatus,
  getStatus,
  formatDate,
  showToast,
  getInvoiceStatus,
} from "@/utils";
import { useNavigate } from "react-router-dom";
import { Alert } from "@/components";
import { format } from "date-fns";
import { DownloadButton, Pagination } from "@/components";

const Cobrancas = () => {
  const navigate = useNavigate();
  const cancelRef = useRef();
  const [data, setData] = useState({} as ChargePaginator);
  const [deleteChargeModalState, setDeleteChargeModalState] = useState(false);
  const [chargeUUID, setChargeUUID] = useState("");
  const [tabStatus, setTabStatus] = useState("ALL");
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [currentPage, setCurrentPage] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [filterParams, setFilterParams] = useState("customerName");

  const user = JSON.parse(localStorage.getItem("barte-user-info"));

  const tabs = [
    {
      tab: "Todas",
      key: "ALL",
      status: "ALL",
    },
    {
      tab: "Pagas",
      key: "PAID",
      status: "PAID",
    },
    {
      tab: "Atrasadas",
      key: "LATE",
      status: "LATE",
    },
    {
      tab: "Canceladas",
      key: "CANCELED",
      status: "CANCELED",
    },
    {
      tab: "Desistência",
      key: "ABANDONED",
      status: "ABANDONED",
    },
    {
      tab: "Estornadas",
      key: "CHARGEBACK",
      status: "CHARGEBACK",
    },
    {
      tab: "Erro",
      key: "FAILED",
      status: "FAILED"
    }
  ];

  const { TabPane } = Tabs;

  const { getCharges, deleteCharge } = useCharge();
  const [loading, setLoading] = useState<boolean>(false);

  async function getChargesData(filter?: string) {
    setLoading(true)
    setFilterText(filter);
    try {
      const response = await getCharges(
        {
          chargeFilterConfig: tabStatus,
          expirationDateInitial: startDate
            ? format(startDate, "yyyy-MM-dd")
            : "",
          expirationDateFinal: endDate ? format(endDate, "yyyy-MM-dd") : "",
          [filterParams]: filter ? filter : "",
        },
        {
          size: 20,
          page: currentPage,
          sort: "expirationDate,DESC",
        }
      );
      setData(response.data);
    } catch (error) {
      if (!endDate) {
        showToast({
          message: "Por Favor, selecione uma data final.",
          type: "warning",
        });
        return;
      }
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    } finally {
      setLoading(false)

    }
  }

  const columnsWithNF: ColumnsType<Charge> = [
    {
      key: "customerName",
      title: "Cliente",
      dataIndex: "customerName",
      render: (customerName, record) => (
        <Flex
          flexDirection="column"
          maxWidth={["200px", "200px", "200px", "250px", "250px", "100%"]}
          fontSize="14px"

        >
          {/* <Badge
            fontSize="1rem"
            minWidth="35px"
            minHeight="35px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            colorScheme="yellow"
            color="gray.800"
          >
            {getInitials(customerName ?? "-")}
          </Badge> */}
          <Tooltip fontSize=".9rem" label={customerName}>
            <Text noOfLines={1} fontSize="14px" fontWeight="500"  >{customerName}</Text>
          </Tooltip>
          <Tooltip fontSize=".9rem" label={record.email}>
            <Text fontSize="12px" >{record.email}</Text>
          </Tooltip>
        </Flex>
      ),
    },
    {
      key: "title",
      title: "Cobrança",
      dataIndex: "title",
      render: (title) =>
      (<Text w="300px" fontSize="14px" fontWeight="500">
        {title}
      </Text>)
      ,
    },
    {
      key: "expirationDate",
      title: "Vencimento",
      dataIndex: "expirationDate",
      render: (expirationDate) =>
      (<Text fontSize="14px">
        {formatDate(expirationDate)}
      </Text>)
      ,
    },
    {
      key: "value",
      title: "Valor",
      dataIndex: "value",
      render: (value) =>
      (<Text fontSize="14px">
        {
          formatCurrency({ value })
        }
      </Text>)
      ,
    },

    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (status) =>
        status ? (
          <Box maxWidth="160px">
            <Badge fontSize=".9rem" variant={getColorBadgeStatus(status)}>
              {getStatus(status)}
            </Badge>
          </Box>
        ) : (
          "-"
        ),
    },
    {
      key: "invoiceCharge",
      title: "Nota Fiscal",
      dataIndex: "invoiceCharge",
      render: (invoiceCharge, render) => (
        <Flex maxWidth="160px" w="150px" gap="2" align="center">
          <Text
            fontSize="14px"
          >
            {getInvoiceStatus(invoiceCharge)}{" "}
          </Text>
          {invoiceCharge !== "NOT_EMITTED" && (
            <DownloadButton
              disabled={
                !render?.invoiceDownloadLink?.pdfImageLink &&
                !render?.invoiceDownloadLink?.xmlImageLink
              }
            >
              {render?.invoiceDownloadLink?.pdfImageLink && (
                <Link
                  w="100%"
                  fontSize="14px"
                  textAlign="center"
                  onClick={(e) => e.stopPropagation()}
                  _hover={{
                    borderRadius: "5px",
                    bgColor: "gray.50",
                  }}
                  href={render?.invoiceDownloadLink?.pdfImageLink}
                  target="_blank"
                >
                  PDF
                </Link>
              )}
              {render?.invoiceDownloadLink?.xmlImageLink && (
                <Link
                  w="100%"
                  fontSize="14px"
                  textAlign="center"
                  onClick={(e) => e.stopPropagation()}
                  _hover={{
                    borderRadius: "5px",
                    bgColor: "gray.50",
                  }}
                  href={render?.invoiceDownloadLink?.xmlImageLink}
                  target="_blank"
                >
                  XML
                </Link>
              )}
            </DownloadButton>
          )}
        </Flex>
      ),
    },
    {
      key: "buttonOptions",
      title: "",
      render: (text, record) => (
        <Box p="0" w="300px" h="30px">
          <Flex
            w="300px"
            h="30px"
            gap="10px"

            align="center"
            justify="space-between"
            className="rowAction"

          >
            {record.status !== "PAID" && record.status !== "ABANDONNED"
              && record.status !== "CANCELED" && record.status !== "REFUND"
              && record.status !== "PAID_MANUALLY" && record.status !== "CHARGEBACK" && (
                <Flex
                  id="excluir_cobranca"
                  display="flex"
                  align="center"
                  justify="center"
                  h="40px"
                  w="40px"
                  bgColor="white"
                  borderRadius="8px"
                  transition="all .2s"
                  cursor="pointer"
                  _hover={{
                    transition: "all .2s",
                    bgColor: "white",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleGetChargeUUID(record.uuid);
                  }}
                >
                  <Image
                    id="excluir_cobranca"
                    h="20px"
                    w="36px"
                    src="/images/icons/Trash.svg"
                    transition="filter .2s"
                    _hover={{
                      transition: "filter .2s",
                      filter: "brightness(.3)",
                    }}
                  />
                </Flex>
              )}
            <Flex
              id="clique_em_email"
              display="flex"
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bgColor="white"
              borderRadius="8px"

              transition="all .2s"
              _hover={{
                transition: "all .2s",
                bgColor: "white",
              }}
            >
              <Link
                id="clique_em_email"
                onClick={(event) => event.stopPropagation()}
                href={`mailto:${record?.email}`}
                h="20px"
              >
                <a id="clique_em_email">
                  <Image
                    id="excluir_cobranca"
                    h="20px"
                    w="20px"
                    src="/images/icons/Mail.svg"
                    transition="filter .2s"
                    _hover={{
                      transition: "filter .2s",
                      filter: "brightness(.3)",
                    }}
                  />

                </a>
              </Link>
            </Flex>
            <Flex
              id="clique_em_whatsapp"
              display="flex"
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bgColor="white"
              borderRadius="8px"
              transition="all .2s"
              _hover={{
                transition: "all .2s",
                bgColor: "white",
              }}
            >
              <Link
                id="clique_em_whatsapp"
                onClick={(event) => event.stopPropagation()}
                href={`https://Api.whatsapp.com/send?phone=55${record?.phone}`}
                target="_blank"
                h="20px"
              >
                <a id="clique_em_whatsapp">
                  <Image
                    id="clique_em_whatsapp"
                    h="20px"
                    w="20px"
                    src="/images/icons/Whatsapp.svg"
                    transition="filter .2s"
                    _hover={{
                      transition: "filter .2s",
                      filter: "brightness(.3)",
                    }}
                  />
                </a>
              </Link>
            </Flex>
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="170px"
              bgColor="white"
              borderRadius="8px"
              zIndex="99"
              transition="all .2s"
              p=" 0 8px"
              _hover={{
                transition: "all .2s",
                bgColor: "white",
              }}
            >
              <Link
                onClick={(event) => event.stopPropagation()}
                href={`/cobrancas-historico/${record?.uuid}`}
                target="_blank"
                _hover={{ cursor: "pointer" }}
                display="flex"
                alignItems="center"
                gap="10px"
              >

                <Image
                  id="excluir_cobranca"
                  h="20px"
                  w="20px"
                  src="/images/icons/Export.svg"
                  transition="filter .2s"
                  _hover={{
                    transition: "filter .2s",
                    filter: "brightness(.3)",
                  }}
                />
                <Text fontSize="12px" color="gray_600">
                  Abrir em nova aba
                </Text>
              </Link>
            </Flex>
          </Flex>
        </Box>
      ),
    },
  ];
  const columnsWithNoNF: ColumnsType<Charge> = [
    {
      key: "customerName",
      title: "Cliente",
      dataIndex: "customerName",
      render: (customerName) => (
        <Flex
          align="center"
          gridGap="2"
          maxWidth={["200px", "200px", "200px", "250px", "300px", "100%"]}
        >
          {/* <Badge
            fontSize="1rem"
            minWidth="35px"
            minHeight="35px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            colorScheme="yellow"
            color="gray.800"
          >
            {getInitials(customerName ?? "-")}
          </Badge> */}
          <Tooltip label={customerName}>
            <Text noOfLines={1} >{customerName}</Text>
          </Tooltip>


        </Flex>
      ),
    },
    {
      key: "expirationDate",
      title: "Vencimento",
      dataIndex: "expirationDate",
      render: (expirationDate) => formatDate(expirationDate),
    },

    {
      key: "value",
      title: "Valor",
      dataIndex: "value",
      render: (value) => formatCurrency({ value }),
    },

    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (status) =>
        status ? (
          <Box maxWidth="160px">
            <Badge fontSize=".9rem" variant={getColorBadgeStatus(status)}>
              {getStatus(status)}
            </Badge>
          </Box>
        ) : (
          "-"
        ),
    },
    {
      key: "buttonOptions",
      title: "",
      render: (text, record) => (
        <Box p="0" w="300px" h="30px">
          <Flex
            w="300px"
            h="30px"
            gap="10px"

            align="center"
            justify="space-between"
            className="rowAction"


          // na vai existir duplicar e o icone vai ser copiar link
          >
            {record.status !== "PAID" && record.status !== "ABANDONNED"
              && record.status !== "CANCELED" && record.status !== "REFUND"
              && record.status !== "PAID_MANUALLY" && record.status !== "CHARGEBACK" && (
                <Flex
                  id="excluir_cobranca"
                  display="flex"
                  align="center"
                  justify="center"
                  h="40px"
                  w="40px"
                  bgColor="white"
                  borderRadius="8px"
                  transition="all .2s"
                  cursor="pointer"
                  _hover={{
                    transition: "all .2s",
                    bgColor: "white",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleGetChargeUUID(record.uuid);
                  }}
                >
                  <Image
                    id="excluir_cobranca"
                    h="20px"
                    w="36px"
                    src="/images/icons/Trash.svg"
                    transition="filter .2s"
                    _hover={{
                      transition: "filter .2s",
                      filter: "brightness(.3)",
                    }}
                  />
                </Flex>
              )}
            <Flex
              id="clique_em_email"
              display="flex"
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bgColor="white"
              borderRadius="8px"

              transition="all .2s"
              _hover={{
                transition: "all .2s",
                bgColor: "white",
              }}
            >
              <Link
                id="clique_em_email"
                onClick={(event) => event.stopPropagation()}
                href={`mailto:${record?.email}`}
                h="20px"
              >
                <a id="clique_em_email">
                  <Image
                    id="excluir_cobranca"
                    h="20px"
                    w="20px"
                    src="/images/icons/Mail.svg"
                    transition="filter .2s"
                    _hover={{
                      transition: "filter .2s",
                      filter: "brightness(.3)",
                    }}
                  />

                </a>
              </Link>
            </Flex>
            <Flex
              id="clique_em_whatsapp"
              display="flex"
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bgColor="white"
              borderRadius="8px"
              transition="all .2s"
              _hover={{
                transition: "all .2s",
                bgColor: "white",
              }}
            >
              <Link
                id="clique_em_whatsapp"
                onClick={(event) => event.stopPropagation()}
                href={`https://Api.whatsapp.com/send?phone=55${record?.phone}`}
                target="_blank"
                h="20px"
              >
                <a id="clique_em_whatsapp">
                  <Image
                    id="clique_em_whatsapp"
                    h="20px"
                    w="20px"
                    src="/images/icons/Whatsapp.svg"
                    transition="filter .2s"
                    _hover={{
                      transition: "filter .2s",
                      filter: "brightness(.3)",
                    }}
                  />
                </a>
              </Link>
            </Flex>
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="170px"
              bgColor="white"
              borderRadius="8px"
              zIndex="99"
              transition="all .2s"
              p=" 0 8px"
              _hover={{
                transition: "all .2s",
                bgColor: "white",
              }}
            >
              <Link
                onClick={(event) => event.stopPropagation()}
                href={`/cobrancas-historico/${record?.uuid}`}
                target="_blank"
                _hover={{ cursor: "pointer" }}
                display="flex"
                alignItems="center"
                gap="10px"
              >

                <Image
                  id="excluir_cobranca"
                  h="20px"
                  w="20px"
                  src="/images/icons/Export.svg"
                  transition="filter .2s"
                  _hover={{
                    transition: "filter .2s",
                    filter: "brightness(.3)",
                  }}
                />
                <Text fontSize="12px" color="gray_600">
                  Abrir em nova aba
                </Text>
              </Link>
            </Flex>
          </Flex>
        </Box>
      ),
    },
  ];

  function handleToggleDeleteChargeModal() {
    setDeleteChargeModalState(!deleteChargeModalState);
  }

  function handleGetChargeUUID(uuid: string) {
    setChargeUUID(uuid);
    setDeleteChargeModalState(!deleteChargeModalState);
  }

  async function handleDeleteCharge(uuid: string) {
    try {
      await deleteCharge(uuid);

      getChargesData();

      showToast({
        type: "success",
        message: "Sucesso na exclusão da cobrança.",
      });

      setDeleteChargeModalState(!deleteChargeModalState);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  // useEffect(() => {
  //   getChargesData();
  // }, [currentPage]);

  useEffect(() => {
    if (tabStatus) {
      getChargesData(filterText);
    }
  }, [tabStatus, currentPage]);

  return (
      <>
        <Box letterSpacing="tighter">
          <Alert
            closeButtonText="Não"
            confirmButtonText="Sim"
            isConfirmAlert={false}
            isOpen={deleteChargeModalState}
            onConfirm={() => handleDeleteCharge(chargeUUID)}
            onClose={handleToggleDeleteChargeModal}
            title="Tem certeza que seja deletar essa cobrança?"
            messageBody="Essa ação não poderá ser desfeita"
            leastDestructiveRef={cancelRef}
          />

          <Filter
            placeholder="Buscar"
            searchFunction={getChargesData}
            setFilterParams={setFilterParams}
            filterParams={filterParams}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            loading={loading}
            endDate={endDate}
            isVisibleDatepicker={true}
            getListFilter={[
              { value: 'customerName', label: 'Cliente' },
              { value: 'title', label: 'Cobrança' },
              { value: 'customerEmail', label: 'Email' },
              { value: 'customerDocument', label: 'Documento' }
            ]}
          />

          <Tabs
            defaultActiveKey=""
            className="tabs-custom"
            onChange={(activeKey) => {
              setCurrentPage(0);
              setTabStatus(activeKey);
            }}
          >
            {tabs.map(({ key, tab }) => (
              <TabPane tab={tab} key={key}>
                <Table<Charge>
                  columns={user?.roles?.includes("SELLER_CONFIG_NF") ||
                    user?.roles?.includes("SELLER_MASTER") ||
                    user?.roles?.includes("SELLER_ADMIN") ||
                    user?.roles?.includes("SELLER_BACKOFFICE") ? columnsWithNF : columnsWithNoNF}
                  dataSource={data?.content}
                  rowKey={(record) => record.uuid}
                  onRow={(record) => {
                    return {
                      onClick: () =>
                        navigate(`/cobrancas-historico/${record?.uuid}`),
                    };
                  }}
                  locale={{ emptyText: "Sem dados de cobranças" }}
                  pagination={false}
                  loading={loading}
                />
                <Pagination
                  totalPages={data?.totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </TabPane>
            ))}
          </Tabs>
        </Box>
      </>
  );
};

export default WithAuth(Cobrancas);
