import { WithAuth } from "@/hoc";
import { useSettings } from "@/hooks";
import { SellerInvoice } from "@/models";
import { useEffect, useState } from "react";
import NotaFiscalActiveState from "./_nota-fiscal-active";
import NotaFiscalConfig from "./_nota-fiscal-config";

const NotaFiscal = () => {
  const [invoiceData, setInvoiceData] = useState({} as SellerInvoice);
  const { getSellerInvoiceStatus } = useSettings();
  async function getInitialData() {
    const { data } = await getSellerInvoiceStatus();
    setInvoiceData(data);
  }
  useEffect(() => {
    getInitialData();
  }, []);
  return invoiceData.active ? <NotaFiscalActiveState /> : <NotaFiscalConfig />;
};

export default WithAuth(NotaFiscal);
