import { SellerRegisterData } from "@/models";
import { Api } from "@/services";

export function useRegister() {
  const baseUrl = "/public/sellers";

  const registerSeller = (data: SellerRegisterData) => {
    return Api.post(`${baseUrl}`, data);
  };

  return {
    registerSeller,
  };
}
