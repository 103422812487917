import { DashboardChargePaginator, DashData } from "@/models";
import { Api } from "@/services";
import { add, format, lastDayOfMonth, startOfMonth } from "date-fns";

export interface DashSelectionResponse {
  startDate: string;
  endDate: string;
  status: string;
}

export interface RangeSelectionProps {
  startDate: Date;
  endDate: Date;
}

export function useDash() {
  const getChargesData = (
    startDate: string,
    endDate: string,
    status: string
  ) => {
    return Api.get<DashboardChargePaginator>(
      `/charges/dashboard?startDate=${startDate}&endDate=${endDate}&status=${status}&size=1000&sort=expirationDate,DESC`
    );
  };

  const getDashboardData = (startDate: string, endDate: string) => {
    return Api.get<DashData>(
      `/seller/dashboard?startDate=${startDate}&endDate=${endDate}`
    );
  };

  // get the first range without selection by actual time
  const getStartAndEndDate = (visualization: string) => {
    if (visualization === "WEEKLY") {
      const endDay = add(new Date(), {
        days: 6,
      });
      return {
        startDate: new Date(), // actual day on week
        endDate: endDay, //last day of the week (in range)
      };
    } else if (visualization === "MONTHLY") {
      const endDate =
        format(lastDayOfMonth(new Date()), "yyyy-MM-dd") + "T23:59:59.999Z";
      const startDate =
        format(startOfMonth(new Date()), "yyyy-MM-dd") + "T23:59:59.999Z";

      return {
        startDate: new Date(startDate), //first day on month
        endDate: new Date(endDate), // last day on month
      };
    }
  };

  // format period to match with backend date param
  const handleFormatPeriod = (
    range: RangeSelectionProps
  ): DashSelectionResponse => {
    const formattedStartDate = format(range.startDate, "yyyy-MM-dd");
    const formattedEndDate = format(range.endDate, "yyyy-MM-dd");

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      status: "All",
    };
  };

  const getFirstChargeDate = () => {
    return Api.get(
      "/charges?chargeFilterConfig=ALL&size=1&page=0&sort=expirationDate,ASC"
    );
  };

  return {
    getChargesData,
    getDashboardData,
    handleFormatPeriod,
    getStartAndEndDate,
    getFirstChargeDate,
  };
}
