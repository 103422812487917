



import { IconProps } from "@/models";

export function MoneyIn({
  size = 20,
  color = "#000",
  opacity = "1",
}: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M1.09766 6.70336C1.09766 5.9468 1.71097 5.3335 2.46752 5.3335H21.536C22.2926 5.3335 22.9059 5.9468 22.9059 6.70336V18.9773H21.9196V6.70336C21.9196 6.49152 21.7478 6.3198 21.536 6.3198H2.46752C2.25568 6.3198 2.08396 6.49152 2.08396 6.70336V17.6567C2.08396 17.8685 2.25568 18.0403 2.46752 18.0403H16.6045V19.0266H2.46752C1.71097 19.0266 1.09766 18.4133 1.09766 17.6567V6.70336ZM18.8233 21.7893V14.7033H19.8096V21.7671L20.9201 20.7033L21.6024 21.4156L19.6576 23.2786L19.3212 23.6008L18.9804 23.2833L16.98 21.4203L17.6522 20.6986L18.8233 21.7893ZM19.7818 8.56641H16.6585V9.55271H19.7818V8.56641ZM4.22102 14.813H7.28951V15.7993H4.22102V14.813ZM10.0769 11.1093C9.5419 11.6443 9.54191 12.5116 10.0769 13.0466L11.0334 14.0032C11.5684 14.5381 12.4357 14.5381 12.9707 14.0032L13.9273 13.0466C14.4622 12.5116 14.4622 11.6443 13.9273 11.1093L12.9707 10.1527C12.4357 9.61778 11.5684 9.61778 11.0334 10.1527L10.0769 11.1093ZM10.7743 12.3492C10.6245 12.1994 10.6245 11.9565 10.7743 11.8067L11.7309 10.8502C11.8806 10.7004 12.1235 10.7004 12.2733 10.8502L13.2299 11.8067C13.3796 11.9565 13.3796 12.1994 13.2299 12.3492L12.2733 13.3057C12.1235 13.4555 11.8806 13.4555 11.7309 13.3057L10.7743 12.3492Z" 
            fill={color}
        />
    </svg>
  );
}
