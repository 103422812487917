import { SellerNotificationPanelPaginator } from "@/models";
import { Api } from "@/services";

export function useNotificationPanel() {
  const baseUrl = "/notification-panel";

  const getNotifications = (quantity: number) => {
    return Api.get<SellerNotificationPanelPaginator>(
      `${baseUrl}?size=${quantity}&sort=createdAt,desc`
    );
  };

  const getQuantityUnreadNotifications = () =>
    Api.get(`${baseUrl}/quantity-unread`);

  return {
    getNotifications,
    getQuantityUnreadNotifications,
  };
}
