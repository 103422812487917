import {
  Checkbox as CheckboxChakra,
  CheckboxProps as CheckboxPropsChakra,
  Text,
} from "@chakra-ui/react";

interface CheckboxProps extends CheckboxPropsChakra {
  text?: any;
  disabled?: boolean;
  register?: any;
  size?: string;
  textSize?: string;
  errors?: any;
}

const Checkbox = ({
  size = "lg",
  textSize = "1.2rem",
  text,
  disabled = false,
  register,
  errors,
  colorScheme = "cyan",
  ...rest
}: CheckboxProps) => {
  return (
    <>
      <CheckboxChakra
        {...rest}
        size={size}
        disabled={disabled}
        {...register}
        colorScheme={colorScheme}
      >
        <Text fontSize={textSize}>{text}</Text>
      </CheckboxChakra>
      {errors && (
        <Text color="danger" mb="4" role="alert">
          {errors.message}
        </Text>
      )}
    </>
  );
};

export { Checkbox };
