import { WithAuth } from "@/hoc";
import { DashSelectionResponse, useDash } from "@/hooks/useDash";
import { FormattedDashData, Graph, LinesProps } from "@/models";
import { showToast } from "@/utils";
import { useEffect, useState } from "react";
import { DashboardCharge } from "./dash-components/dashboard-charge";
import { DashboardChart } from "./dash-components/dashboard-chart";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Button,
  forwardRef,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Img,
  Text,
} from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { add, lastDayOfMonth, startOfMonth, format, parseISO } from "date-fns";
import { useRedeem } from "@/hooks";
import NewTransfer from "@/components/NewTransferModal";
import TransferList from "@/components/TransferListModal";
import { SaldoComponent } from "../resgates/_SaldoComponent";
import { CapitalGiroComponent } from "../resgates/_CapitalGiroComponent";

function newDashboard() {
  const [data, setData] = useState<FormattedDashData>();
  const [chartHasData, setChartHasData] = useState(true);
  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(lastDayOfMonth(new Date()));
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [visualization, setVisualization] = useState("MONTHLY");
  const [firstChargeDate, setFirstChargeDate] = useState("");
  const [alreadyHadData, setAlreadyHadData] = useState(false);
  const featureToggle = JSON.parse(localStorage.getItem("barte-features-list-user"));
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalList, setOpenModalList] = useState<boolean>(false);
  const { initialData, getInitialData } = useRedeem();
  const navigate = useNavigate();
  registerLocale("ptBR", ptBR);
  
  const {
    getChargesData,
    getDashboardData,
    handleFormatPeriod,
    getStartAndEndDate,
    getFirstChargeDate,
  } = useDash();

  function handleSortAndTranslateLines(chartValues: Graph): Graph {
    const indexOfScheduled = chartValues.lines.findIndex((line) =>
      line.name.includes("SCHEDULED")
    );
    const indexOfLate = chartValues.lines.findIndex((line) =>
      line.name.includes("LATE")
    );
    const indexOfPaid = chartValues.lines.findIndex((line) =>
      line.name.includes("PAID")
    );
    const finalChartData = [];

    finalChartData.push(
      chartValues.lines[indexOfPaid],
      chartValues.lines[indexOfLate],
      chartValues.lines[indexOfScheduled]
    );

    let line_names = {
      PAID: "Recebido",
      LATE: "Em atraso",
      SCHEDULED: "Agendado",
    };

    finalChartData.map((line) => {
      line.name = line_names[line.name];
      return line;
    });

    return {
      ...chartValues,
      lines: finalChartData,
    };
  }

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      onClick={() => setIsDatepickerOpen(true)}
      ref={ref}
      p="5px 12px"
      w="200px"
      fontSize="14px"
      borderRadius="4"
      _hover={{}}
      bgColor="gray.49"
      fontWeight="500"
    >
      {value}
    </Button>
  ));

  function handleChartHasDataVerification({ lines }: Graph) {
    const hasData = lines
      .map((item) => item.data.filter((item2) => item2 > 0))
      .filter((item) => item.length > 0);

    setChartHasData(hasData.length > 0 ? true : false);
  }

  async function getData(): Promise<void> {
    try {
      const response: DashSelectionResponse = handleFormatPeriod({
        startDate,
        endDate,
      });

      const chargeResponse = await getFirstChargeDate();
      setAlreadyHadData(chargeResponse?.data?.content?.at(0)?.expirationDate ? true : false);

      if (chargeResponse.data.content[0]) {
        const expirationDate = chargeResponse.data.content[0].expirationDate;
        const formattedFirstChargeDate =
          chargeResponse.data.content[0].expirationDate.split("-");

        if (visualization === "MONTHLY") {
          setFirstChargeDate(
            `${formattedFirstChargeDate[0]}-${formattedFirstChargeDate[1]}-01`
          );
        }

        if (visualization === "WEEKLY") {
          setFirstChargeDate(
            format(parseISO(`${expirationDate}`), "yyyy-MM-dd") +
              "T23:59:59.999Z"
          );
        }
      }

      const chargesResponse = await getChargesData(
        response.startDate,
        response.endDate,
        response.status
      );

      const dashResponse = await getDashboardData(
        response.startDate,
        response.endDate
      );

      setData({
        chartMetrics: {
          paidAmount: dashResponse.data.metrics.paidAmount,
          lateAmount: dashResponse.data.metrics.lateAmount,
          scheduledAmount: dashResponse.data.metrics.scheduledAmount,
        },
        chargeMetrics: {
          defaultRate: dashResponse.data.metrics.defaultRate,
          timeSaved: dashResponse.data.metrics.timeSaved,
          newBuyerQty: dashResponse.data.metrics.newBuyerQty,
        },
        chargesData: chargesResponse.data.content,
        graphData: {
          ...dashResponse.data,
          graph: handleSortAndTranslateLines(dashResponse.data.graph),
        },
      });

      handleChartHasDataVerification(dashResponse?.data?.graph);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  function handleStartDateAndEndDate() {
    const response = getStartAndEndDate(visualization);

    setStartDate(response.startDate);
    setEndDate(response.endDate);
  }

  const onWeekChange = (dates) => {
    const [start] = dates;
    const endDay = add(start, {
      days: 6,
    });
    setStartDate(start);
    setEndDate(endDay);

    setTimeout(() => {
      setIsDatepickerOpen(false);
    }, 500);
  };

  const onMonthChange = (date) => {
    const dateStart =
      format(startOfMonth(date), "yyyy-MM-dd") + "T23:59:59.999Z";
    const dateEnd =
      format(lastDayOfMonth(date), "yyyy-MM-dd") + "T23:59:59.999Z";

    setStartDate(new Date(dateStart));
    setEndDate(new Date(dateEnd));
    setIsDatepickerOpen(false);
  };

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    handleStartDateAndEndDate();
  }, [visualization]);

  useEffect(() => {
    handleStartDateAndEndDate();
  }, []);

  useEffect(() => {
    if((user?.roles?.includes("SELLER_FINANCES") || user?.roles?.includes("SELLER_ADMIN") || user?.roles?.includes("SELLER_BACKOFFICE")) && featureToggle?.includes("view_seller_finance")) {
      getInitialData(
        {},
        {},
        true
      );
    }
  }, [openModal]);


  return (
    <>
      {openModal && <NewTransfer data={initialData} open={setOpenModal} />}
      {openModalList && <TransferList open={setOpenModalList} />}
      {data && (
        <Box w="100%" h="100%" p="0" bgColor="gray.10">
          <Flex
            w="100%"
            marginBottom="1rem"
            align="flex-end"
            justify="space-between"
          >
            <Heading w={{ base: "50%", "2xl": "60%" }} fontSize="1.6rem">
              Olá, Bem-vindo!
            </Heading>
            <Flex justify="space-between" gap="10px" color="black">
              <Menu>
                <MenuButton
                  color="white"
                  bgColor="blue.200"
                  as={Button}
                  rightIcon={<IoIosArrowDown />}
                  _hover={{}}
                  _expanded={{
                    bgColor: "blue.200",
                    filter: "brightness(.9)",
                  }}
                >
                  Criar cobrança
                </MenuButton>
                <MenuList
                  zIndex={100}
                  p="5px"
                  border="none"
                  boxShadow="5px 5px 20px 5px rgba(0,0,0,.09)"
                >
                  <MenuItem
                    borderRadius="4"
                    onClick={() =>
                      navigate("/seja-pago/criar/cobranca-pontual")
                    }
                  >
                    Link de Pagamento
                  </MenuItem>
                  {featureToggle?.includes("view_fast_payment_link") &&
                    <MenuItem
                      borderRadius="4"
                      onClick={() =>
                        navigate("/seja-pago/criar/link-rapido")
                      }
                    >
                      Link Rápido
                    </MenuItem>
                  }
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
          {((user?.roles?.includes("SELLER_FINANCES") 
          || user?.roles?.includes("SELLER_ADMIN") || user?.roles?.includes("SELLER_BACKOFFICE"))
           && featureToggle?.includes("view_seller_balance")) && (
            <Flex
              gap="12px"
              w="100%"
            >
              <Flex 
                maxW={700}
                w="100%"
              >
                <SaldoComponent
                  openTransferList={setOpenModalList}
                  open={setOpenModal}
                  data={initialData}
                /> 
              </Flex>
              <Flex>
                <CapitalGiroComponent />
              </Flex> 
            </Flex>
          )} 
          <Flex
            width="100%"
            flexDir="column"
            border="1px solid"
            borderColor="gray.50"
            borderRadius="4"
            bgColor="white"
            boxShadow="5px 5px 5px rgba(0,0,0,0.04)"
            padding="8px"
            marginTop="12px"
            h="600px"
          >
            <Flex gap="2" marginBottom="8px">
              <Button
                fontWeight="normal"
                variant="ghost"
                bgColor={
                  visualization === "WEEKLY" ? "gray.49" : "transparent"
                }
                _hover={{
                  bgColor: "gray.49",
                }}
                onClick={() => setVisualization("WEEKLY")}
              >
                Semana
              </Button>
              <Button
                fontWeight="normal"
                variant="ghost"
                bgColor={
                  visualization === "MONTHLY" ? "gray.49" : "transparent"
                }
                _hover={{
                  bgColor: "gray.49",
                }}
                onClick={() => setVisualization("MONTHLY")}
              >
                Mês
              </Button>
              {visualization === "WEEKLY" && (
                <Flex zIndex={99}>
                  <DatePicker
                    locale="ptBR"
                    wrapperClassName="datePicker"
                    dateFormat="dd/MM/yyyy"
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onWeekChange}
                    minDate={new Date(firstChargeDate)}
                    customInput={<CustomInput />}
                    onInputClick={() => setIsDatepickerOpen(true)}
                    onClickOutside={() => setIsDatepickerOpen(false)}
                    open={isDatepickerOpen}
                    selectsRange
                  />
                </Flex>
              )}
              {visualization === "MONTHLY" && (
                <Flex zIndex={99}>
                  <DatePicker
                    locale="ptBR"
                    selected={startDate}
                    onChange={(date) => onMonthChange(date)}
                    startDate={startDate}
                    dateFormat="MMMM/yyyy"
                    minDate={new Date(firstChargeDate)}
                    onInputClick={() => setIsDatepickerOpen(true)}
                    onClickOutside={() => setIsDatepickerOpen(false)}
                    open={isDatepickerOpen}
                    customInput={<CustomInput />}
                    showMonthYearPicker
                  />
                </Flex>
              )}
            </Flex>
            <Flex
              width="100%"
              h="530px"
              flexDir="row"
              border="1px solid"
              borderColor="gray.50"
              borderRadius="4"
              bgColor="white"
              boxShadow="5px 5px 5px rgba(0,0,0,0.04)"
            >
              {chartHasData ? (
                <>
                  <Flex w={{ base: "50%", "2xl": "60%" }} flexDir="column">
                    <DashboardChart
                      graph={data?.graphData?.graph}
                      chartMetrics={data?.chartMetrics}
                    />
                  </Flex>
                  <Divider orientation="vertical" />
                  <Flex w={{ base: "50%", "2xl": "40%" }} flexDir="column">
                    <DashboardCharge
                      charges={data?.chargesData}
                      chargeMetrics={data?.chargeMetrics}
                    />
                  </Flex>
                </>
              ) : (
                <Flex 
                  w="100%" 
                  h="100%" 
                  maxH="530px"
                  flexDir="column" 
                  align="center" 
                  justify="center"
                >
                  <Img
                    w="534px"
                    h="187px"
                    src="/images/icons/noMetrics.svg"
                    marginBottom="1rem"
                  />
                  <Text fontSize="1.2rem" color="gray.900">
                    {alreadyHadData ? (
                      "Sem dados de receita para o período selecionado"
                    ) : (
                      "Sem dados de receita ainda"
                    )}
                  </Text>
                  <Text fontSize="1rem" color="gray.400">
                    Você verá o resumo da sua receita recebida e projetada aqui
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>   
        </Box>
      )}
    </>
  );
}

export default WithAuth(newDashboard);
