import { ReactNode, useState, useEffect, forwardRef } from "react";
import {
  Flex,
  Input as ChakraInput,
  Text,
  InputProps as ChakraInputProps,
  Tooltip,
  Icon,
  InputGroup,
  FormControl,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Input from 'react-phone-number-input/input'

interface InputProps extends ChakraInputProps {
  type: string;
  name: string;
  valueData?: any;
  tooltipText?: string;
  label?: string;
  labelColor?: string;
  register?: any;
  setValue?: any;
  errors?: any;
  classInput?: string;
  variant?: string;
  color?: string;
  afterFontSize?: string;
  afterColor?: string;
  afterBackgroundColor?: string;
}

export function PhoneInput2({
  type,
  name,
  setValue,
  valueData,
  tooltipText,
  label,
  labelColor = "gray.600",
  register,
  errors,
  classInput,
  variant,
  color,
  afterFontSize = "2xl",
  afterColor = "gray.400",
  afterBackgroundColor = "gray.100",
  ...rest
}: InputProps) {
    const [isFocused, setIsFocused] = useState(false);
    const Component = forwardRef(({ value }: any, ref) => (
        <ChakraInput
            ref={ref}
            type={type}
            name={name}
            borderColor={errors ? "danger" : "newGray.200"}
            border={errors ? "1.5px solid" : "1px solid"}
            borderRadius="4px"
            variant={variant}
            w="100%"
            color={color ? color : "black"}
            _focus={{
                border: "1px solid",
                borderColor: "blue.200",
            }}
            {...register}
            {...rest}
        />
    ));
  return (
    <FormControl isInvalid={!!errors?.message}>
      <Flex flexDir="column">
        <Flex align="center" justify="space-between">
          <Flex align="center" marginRight={tooltipText ? ".2rem" : ""}>
            {label && (
              <Text
                marginRight={tooltipText ? ".2rem" : ""}
                marginBottom=".2rem"
                fontSize=".8rem"
                color={labelColor}
              >
                {label}
              </Text>
            )}
            {tooltipText && (
              <Tooltip placement="top" hasArrow label={tooltipText}>
                <span>
                  <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
                </span>
              </Tooltip>
            )}
          </Flex>
        </Flex>
        <Flex flexDir="column" position="relative">
          <InputGroup>
            <Input 
                value={valueData}
                onChange={setValue}
                inputComponent={ChakraInput}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                style={{
                    border: errors ? "1.5px solid" : "1px solid",
                    borderColor:  errors ? "#FF4D4F" : isFocused ? "#90CDF4" : "#C3CAD7",
                    borderRadius: "4px",
                    color: color ? color : "black",
                }}
            />
          </InputGroup>
        </Flex>
        {errors && (
          <Text fontSize=".8rem" color="danger" mb={errors?.message && "4"} role="alert">
            {errors?.message}
          </Text>
        )}
      </Flex>
    </FormControl>
  );
}
