import { Alert } from "@/components";
import { useAlert } from "@/hooks";
import { getColorBadgeStatus } from "@/utils";
import { Flex, Badge, Text, Button } from "@chakra-ui/react";
import { useInvoiceSendStatus } from "./useInvoiceSendStatus";

interface InvoiceSendStatusProps {
  status: boolean
}

export function InvoiceSendStatus({ status }: InvoiceSendStatusProps) {
  const { 
    cancelRef
  } = useAlert()
  const {
    componentStatus,
    invoiceSendStatusModal,
    translateStatus,
    getStatus,
    handleToggleAutomaticInvoiceEmit,
    setInvoiceSendStatusModal
  } = useInvoiceSendStatus(status)
  return (
    <>
      {componentStatus !== undefined && (
        <>
          <Alert
            closeButtonText="Cancelar"
            confirmButtonText="Sim"
            isConfirmAlert={false}
            isOpen={invoiceSendStatusModal}
            onConfirm={() => handleToggleAutomaticInvoiceEmit(componentStatus)}
            onClose={() => setInvoiceSendStatusModal(false)}
            title={`${!componentStatus ? "Ativar" : "Desativar"} envio automático da Nota Fiscal?`}
            messageBody={`${!componentStatus ? 
            "As notas referentes a essa assinatura passarão a ser enviadas automaticamente." : 
            "As notas referentes a essa assinatura não serão mais enviadas automaticamente.Você ainda poderá emití-las manualmente."}`}
            leastDestructiveRef={cancelRef}
          />
          <Flex direction="column" align="flex-start" gridGap="1">
            <Text color="gray.300" fontSize="14px">
              Envio da Nota Fiscal
            </Text>

            <Flex gap=".5rem" align="center">
              <Badge
                variant={getColorBadgeStatus(translateStatus(componentStatus))}
                fontSize="14px"
              >
                {getStatus(componentStatus)}
              </Badge>

              <Button
                variant="ghost"
                p="0"
                m="0"
                height="20px"
                fontSize="14px"
                transition="all .2s"
                _hover={{
                  transition: "all .2s",
                  filter: "brightness(.9)",
                  textDecoration: "underline"
                }}
                onClick={() => setInvoiceSendStatusModal(true)}
              >
                {componentStatus ? 'Desabilitar' : 'Reativar envio'}
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </>
  )
}